import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,private router: Router,) { }

  loading: Boolean = false;
  ngOnInit(){
    
  }
  refresh() {
    this.router.navigateByUrl('/about-us', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/about-us']);
    });
  }
}
