<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-primary " role="status"></div>
    </div>
</div>

<app-menu></app-menu>
<section>
    <div class="breadcrumbs_container" *ngIf="product">
        <div class="container">
            <div class="col-md-12">
                <div class="breadcrumbs">
                    <h1 class="breadcrumb-heading">{{product.productName}}</h1>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/shop-products">Products</a></li>
                        <li>{{product.productName}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="sec-gap bg-light">
    <div class="container">


        <div class="row mb-4" *ngIf="product">
            <div class="col-lg-5 col-md-5 col-sm-12 ">


                <div class="tab-content quickview-big-img prod-view-box" *ngIf="product.productImageUrl">
                    <ng-container *ngFor="let image of product.productImageUrl; let j = index">
                        <div id="pro-{{j}}" class="tab-pane fade show " [class.show]="selectedImageIndex == j"
                            [class.active]="selectedImageIndex == j">
                            <img [src]="image | safe: 'resourceUrl'" alt="" class="zoom" />
                        </div>
                    </ng-container>
                </div>



                <!-- Thumbnail Large Image End -->
                <!-- Thumbnail Image End -->
                <div class="quickview-wrap mt-15 prod-thumb"
                    *ngIf="product.productImageUrl && product.productImageUrl.length > 0">
                    <div class="quickview-slide-active owl-carousel " role="tablist">
                        <ng-container *ngFor="let image of product.productImageUrl; let i =index">
                            <a [class.active]="selectedImageIndex == i" data-toggle="tab" href="#pro-{{i}}"
                                (click)="selectedImageIndex = i"><img [src]="image | safe: 'url'" alt="" /></a>
                        </ng-container>
                    </div>
                </div>
            </div>



            <div class="col-lg-7 col-md-7 col-sm-12">

                <div class="product-details-content prod-single-view">
                    <h2>{{product.productName}}</h2>

                    <div class="pro-details-rating-wrap"
                        *ngIf="ratingAndreviewsList && ratingAndreviewsList.length > 0 && averageRating > 0">
                        <div class="review_rating_stars">
                            <div *ngIf="averageRating >= 1 && averageRating < 2" class="rating_r rating_r_1">
                                <i></i><i></i><i></i><i></i><i></i>
                            </div>
                            <div *ngIf="averageRating >= 2 && averageRating < 3" class="rating_r rating_r_2">
                                <i></i><i></i><i></i><i></i><i></i>
                            </div>
                            <div *ngIf="averageRating >= 3 && averageRating < 4" class="rating_r rating_r_3">
                                <i></i><i></i><i></i><i></i><i></i>
                            </div>
                            <div *ngIf="averageRating >= 4 && averageRating < 5" class="rating_r rating_r_4">
                                <i></i><i></i><i></i><i></i><i></i>
                            </div>
                            <div *ngIf="averageRating >= 5" class="rating_r rating_r_5">
                            </div>
                        </div>
                        <span class="read-review"><a class="reviews" href="javascript:void(0)">Read reviews
                                ({{ratingAndreviewsList.length}})</a></span>
                    </div>
                    <div [innerHTML]="product.productDescription | safe: 'html'"></div>


                    <div class="row prod-order mt-4">
                        <div class="col-md-6 ">
                            <label for="">Select</label>
                            <select class="form-control" (change)="setSelectedProduct($event)"
                                [(ngModel)]="selectedProductName" name="productName">
                                <option value="">--Select Package Quantity--</option>
                                <option *ngFor="let packageQuantity of product.packagingQuantities">
                                    {{packageQuantity.packageQuantity}}
                                </option>
                            </select>
                        </div>

                        <div class="col-md-3 ">
                            <label for="">@Price</label>
                            <h5>{{currency}} {{selectedPackagePrice}}</h5>
                        </div>

                        <div class="col-md-3 ">
                            <label for="">Qty</label>
                            <!-- <div class="qty"> -->
                            <!-- <span class="minus bg-dark" (click)="decrement()"><i class="ft-minus"></i></span>
                                <span><input type="number" class="count ml-1" name="counter" [(ngModel)]="counter"
                                        #counterInput min="1" (change)="setQuantity($event.target.value)"
                                        (oninput)="checkValidity()"></span>
                                <span class="plus bg-dark" (click)="increment()"><i class="ft-plus"></i></span> -->
                            <div class="qty">
                                <span class="minus bg-dark" (click)="decrement()"><i class="ft-minus"></i></span>
                                <input type="number" class="count" name="qty" value="{{counter}}" disabled="">
                                <span class="plus bg-dark" (click)="increment()"><i class="ft-plus"></i></span>
                            </div>
                            <!-- </div> -->
                        </div>
                    </div>
                    <div class="prod-order mt-2" *ngIf="totalPrice > 0">
                        <div class="col-md-3 mt-0 p-0">
                            <label for="" class="p-0 m-0">Total</label>
                            <h6 class="p-0 m-0 text-success">INR {{totalPrice}}</h6>
                        </div>
                    </div>

                    <!-- <div class="mt-4">
                            <button class="yellow-btn" (click)="addToCart(product)"
                                [class.disabled]="!product.availableStock" [disabled]="!product.availableStock"><i
                                    class="fa fa-cart-plus"></i>Add to cart</button>
                            <button class="orange-btn" (click)="buyNow(product)"
                                [class.disabled]="!product.availableStock" [disabled]="!product.availableStock"><i
                                    class="fa fa-share"></i>Buy Now</button>
                        </div> -->







                    <div class="text-left mt-4 mb-4">
                        <button class="btn btn-success" (click)="openEnquiryModal()"><i class="ft-heart"></i> I am
                            interested!</button>
                        <!-- <button class="btn btn-outline-success btn-cart-modal " (click)="addToWishList(product)"> <i
                                class="ft-heart">
                            </i> ADD TO Wishlist
                        </button> -->
                    </div>



                    <!-- <div class="text-left mt-4 mb-4">
                        <button class="btn btn-outline-success btn-cart-modal" (click)="addToCart(product)"> <i
                                class="ft-plus"></i> ADD
                            TO CART</button>
                        <button class="btn btn-outline-primary btn-cart-modal ml-1 " (click)="buyNow(product)"> <i
                                class="ft-shopping-cart">
                            </i> BUY NOW
                        </button>
                    </div> -->

                    <div class="social-info-box">
                        <span>Share</span>
                        <div class="social-share">
                            <ul>
                                <li class="fb">
                                    <a href="https://www.facebook.com/Soybag-Private-Limited-107121007976317"
                                        target="https://www.facebook.com/Soybag-Private-Limited-107121007976317"><i
                                            class="ft-facebook"></i></a>
                                </li>
                                <li class="tw">
                                    <a href="https://twitter.com/SoybagL" target="https://twitter.com/SoybagL"><i
                                            class="ft-twitter"></i></a>
                                </li>
                                <li class="insta">
                                    <a href="	https://www.instagram.com/soybagprivate/"
                                        target="	https://www.instagram.com/soybagprivate/"><i
                                            class="ft-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCuyWns6nl8a9bG8dlx7nUiQ"
                                        target="https://www.youtube.com/channel/UCuyWns6nl8a9bG8dlx7nUiQ"><i
                                            class="la la-youtube"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>


            </div>

        </div>

        <div class="description-review-area mb-60px" *ngIf="product">
            <!-- <div class="description-review-wrapper">
                <div class="description-review-topbar nav">
                    <a data-toggle="tab" href="#des-details1" class="active">Description</a>
                    <a class="" data-toggle="tab" href="#des-details2">Product Details</a>
                    <a data-toggle="tab" href="#des-details3" class=""
                        *ngIf="ratingAndreviewsList && ratingAndreviewsList.length > 0">Reviews
                        ({{ratingAndreviewsList.length}})</a>
                    <a data-toggle="tab" href="#des-details3" class=""
                        *ngIf="!ratingAndreviewsList || ratingAndreviewsList.length == 0">Reviews (0)</a>
                </div>
                <div class="tab-content description-review-bottom">
                    <div id="des-details1" class="tab-pane active">
                        <div class="product-description-wrapper">
                            <p>{{product.productDescription}}</p>
                        </div>
                    </div>
                    <div id="des-details2" class="tab-pane">
                        <div class="product-anotherinfo-wrapper">
                            <ul>
                                <li><span>Weight</span> 400 g</li>
                                <li><span>Dimensions</span>10 x 10 x 15 cm</li>
                                <li><span>Materials</span> 60% cotton, 40% polyester</li>
                                <li><span>Other Info</span> American heirloom jean shorts pug seitan letterpress
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="des-details3" class="tab-pane">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="review_rating_container"
                                    *ngIf="ratingAndreviewsList && ratingAndreviewsList.length > 0">
                                    <div
                                        class="row d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                                        <div class="review_rating col-md-4">
                                            <div class="review_rating_num">{{averageRating | number:'1.1-1'}}</div>
                                            <div class="review_rating_stars">
                                                <div *ngIf="averageRating >= 1 && averageRating < 2"
                                                    class="rating_r rating_r_1">
                                                    <i></i><i></i><i></i><i></i><i></i></div>
                                                <div *ngIf="averageRating >= 2 && averageRating < 3"
                                                    class="rating_r rating_r_2">
                                                    <i></i><i></i><i></i><i></i><i></i></div>
                                                <div *ngIf="averageRating >= 3 && averageRating < 4"
                                                    class="rating_r rating_r_3">
                                                    <i></i><i></i><i></i><i></i><i></i></div>
                                                <div *ngIf="averageRating >= 4 && averageRating < 5"
                                                    class="rating_r rating_r_4">
                                                    <i></i><i></i><i></i><i></i><i></i></div>
                                                <div *ngIf="averageRating >= 5" class="rating_r rating_r_5">
                                                    <i></i><i></i><i></i><i></i><i></i></div>
                                                <div class="review_rating_text">({{totalRatingAndreviews}} Ratings)
                                                </div>
                                            </div>
                                        </div>
                                        <div class="review_rating_bars col-md-8">
                                            <ul>
                                                <li><span>5 Star</span>
                                                    <div class="review_rating_bar">
                                                        <div [style.width.%]='fiveStarRatingPercent' class="bg-success">
                                                        </div>
                                                    </div>
                                                </li>
                                                <li><span>4 Star</span>
                                                    <div class="review_rating_bar">
                                                        <div [style.width.%]='fourStarRatingPercent' class="bg-primary">
                                                        </div>
                                                    </div>
                                                </li>
                                                <li><span>3 Star</span>
                                                    <div class="review_rating_bar">
                                                        <div [style.width.%]='threeStarRatingPercent' class="bg-info">
                                                        </div>
                                                    </div>
                                                </li>
                                                <li><span>2 Star</span>
                                                    <div class="review_rating_bar">
                                                        <div [style.width.%]='twoStarRatingPercent' class="bg-warning">
                                                        </div>
                                                    </div>
                                                </li>
                                                <li><span>1 Star</span>
                                                    <div class="review_rating_bar">
                                                        <div [style.width.%]='oneStarRatingPercent' class="bg-danger">
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="review_rating_container"
                                    *ngIf="!ratingAndreviewsList || ratingAndreviewsList.length == 0">
                                    <h6>No Rating and Reviews</h6>
                                </div>
                                <div class="review-wrapper"
                                    *ngIf="ratingAndreviewsList && ratingAndreviewsList.length > 0">
                                    <ng-container *ngFor="let ratingAndReviewObj of ratingAndreviewsList">
                                        <div class="single-review mt-4">
                                            <div class="review-img">
                                                <img src="assets/images/noimage_person.png" alt="">
                                            </div>
                                            <div class="review-content">
                                                <div class="review-top-wrap">
                                                    <div class="review-left">
                                                        <div class="review-name">
                                                            <h4>{{ratingAndReviewObj.username}}</h4>
                                                        </div>
                                                        <div class="rating-product">
                                                            <ng-container *ngIf="ratingAndReviewObj.star == 'ONE_STAR'">
                                                                <i class="fa fa-star"></i>
                                                            </ng-container>
                                                            <ng-container *ngIf="ratingAndReviewObj.star == 'TWO_STAR'">
                                                                <i class="fa fa-star"></i>
                                                                <i class="fa fa-star"></i>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="ratingAndReviewObj.star == 'THREE_STAR'">
                                                                <i class="fa fa-star"></i>
                                                                <i class="fa fa-star"></i>
                                                                <i class="fa fa-star"></i>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="ratingAndReviewObj.star == 'FOUR_STAR'">
                                                                <i class="fa fa-star"></i>
                                                                <i class="fa fa-star"></i>
                                                                <i class="fa fa-star"></i>
                                                                <i class="fa fa-star"></i>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="ratingAndReviewObj.star == 'FIVE_STAR'">
                                                                <i class="fa fa-star"></i>
                                                                <i class="fa fa-star"></i>
                                                                <i class="fa fa-star"></i>
                                                                <i class="fa fa-star"></i>
                                                                <i class="fa fa-star"></i>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div class="review-right">
                                                        <a href="javascript:void(0)"
                                                            (click)="openReplyComment(ratingAndReviewObj.id)">Reply</a>
                                                    </div>
                                                </div>
                                                <div class="review-bottom">
                                                    <p>
                                                        {{ratingAndReviewObj.reviewComment}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="ratingAndReviewObj.replies && ratingAndReviewObj.replies.length > 0">
                                            <div class="single-review child-review mt-3"
                                                *ngFor="let replies of ratingAndReviewObj.replies">
                                                <div class="review-img">
                                                    <img src="assets/images/noimage_person.png" alt=""
                                                        *ngIf="replies.username != 'Zasta Infotek'">
                                                    <img src="../../../assets/images/logo.jpg" alt=""
                                                        *ngIf="replies.username == 'Zasta Infotek'">
                                                </div>
                                                <div class="review-content">
                                                    <div class="review-top-wrap">
                                                        <div class="review-left">
                                                            <div class="review-name">
                                                                <h4>{{replies.username}}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="review-bottom">
                                                        <p>{{replies.reviewComment}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="replyToReview && selectedReplyToReviewId == ratingAndReviewObj.id">
                                            <div class="review-content mt-4 mb-4">
                                                <textarea placeholder="Your Reply" name="replyComment"
                                                    #replyComments="ngModel" class="form-control" autofocus
                                                    [(ngModel)]="replyComment"></textarea>
                                                <button (click)="cancelReplyComment()"
                                                    class="btn btn-danger btn-sm mt-2 ml-2 float-right rounded-pill">Cancel</button>
                                                <button class="btn btn-success btn-sm mt-2 float-right rounded-pill"
                                                    [disabled]="!replyComment || replyComment == ''"
                                                    (click)="submitReplyComment(ratingAndReviewObj.id)">Submit</button>
                                            </div>
                                        </ng-container>
                                    </ng-container>

                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="ratting-form-wrapper pl-50" *ngIf="!isUserRatingPresent">
                                    <h3>Add a Review</h3>
                                    <div class="ratting-form">
                                        <form #form='ngForm'>
                                            <div class="star-box">
                                                <span>Your rating:</span>
                                                <div class="rating">
                                                    <input type="radio" id="star5" name="rating" value="FIVE_STAR"
                                                        #ratingVal="ngModel" [(ngModel)]="contentRating" />
                                                    <label class="full" for="star5" title="Awesome"></label>
                                                    <input type="radio" id="star4" name="rating" value="FOUR_STAR"
                                                        #ratingVal="ngModel" [(ngModel)]="contentRating" />
                                                    <label class="full" for="star4" title="Pretty"></label>
                                                    <input type="radio" id="star3" name="rating" value="THREE_STAR"
                                                        #ratingVal="ngModel" [(ngModel)]="contentRating" />
                                                    <label class="full" for="star3" title="Avarage"></label>
                                                    <input type="radio" id="star2" name="rating" value="TWO_STAR"
                                                        #ratingVal="ngModel" [(ngModel)]="contentRating" />
                                                    <label class="full" for="star2" title="Bad"></label>
                                                    <input type="radio" id="star1" name="rating" value="ONE_STAR"
                                                        #ratingVal="ngModel" [(ngModel)]="contentRating" />
                                                    <label class="full" for="star1" title="Worst"></label>
                                                </div>
                                                <small class="error"
                                                    *ngIf="(ratingVal.invalid && (ratingVal.dirty || ratingVal.touched)) && ratingVal.errors.required">
                                                    Please rate the Class.
                                                </small>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="rating-form-style form-submit">
                                                        <textarea name="Your Review" placeholder="Your Review Comments"
                                                            name="contentReview" #reviewComments="ngModel"
                                                            [(ngModel)]="contentReview"></textarea>
                                                        <small class="error"
                                                            *ngIf="(reviewComments.invalid && (reviewComments.dirty || reviewComments.touched)) && reviewComments.errors.required">
                                                            Please enter your feedback.
                                                        </small>
                                                        <button class="btn btn-success btn-lg rounded-pill"
                                                            (click)="submitRatingandReview()">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- Best Deals -->
            <hr>
            <div class="mt-4" *ngIf="allProducts && allProducts.length > 0">
                <div class="section-title">
                    <h2>Similar Products You may like</h2>
                    <p>Add bestselling products to weekly line up</p>
                </div>


                <div class="mt-4">
                    <div class="similar-product-slider owl-carousel owl-nav-style inner-slider-prod">
                        <article class="list-product" *ngFor="let productView of allProducts">
                            <div class="img-block">
                                <a href="javascript:void(0)" class="thumbnail" (click)='changeId(productView.id)'>
                                    <img class="first-img" *ngIf="product.productImageUrl[0]"
                                        [src]="productView.productImageUrl[0] | safe: 'url'" alt="">
                                </a>
                            </div>


                            <ul class="product-flag">
                                <li class="new">New</li>
                            </ul>

                            <div class="product-decs">
                                <a class="inner-link"
                                    href="javascript:void(0)"><span>{{productView.categoryName}}</span></a>
                                <h2><a href="javascript:void(0)" class="product-link">{{productView.productName}}</a>
                                </h2>

                                <div class="pricing-meta">
                                    <!-- <ul>
                                        <li class="old-price">{{currency}} {{productView.productCost}}</li>
                                        <li class="current-price">{{currency}} {{productView.productDiscountedCost}}
                                        </li>
                                        <li class="discount-price">-{{productView.offer}}%</li>
                                    </ul> -->
                                </div>
                            </div>
                        </article>
                    </div>

                </div>

            </div>

        </div>
    </div>
</section>


<div *ngIf="successModal" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" (click)="successModal = false"
                    aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>{{messageHeader}}</h4>
                <p class="text-success">{{successMessage}}</p>
                <button class="btn btn-success" data-dismiss="modal" (click)="successModal = false">Close</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="errorModal" id="fail-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" (click)="errorModal = false"
                    aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>{{messageHeader}}</h4>
                <p class="text-danger">{{errorMessaage}}</p>
                <button class="btn btn-danger" data-dismiss="modal" (click)="errorModal = false">Close</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isEnquiryModal" id="fail-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content modal-confirm ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-user"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" (click)="isEnquiryModal = false;"
                    aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">


                <form [formGroup]="regiForm" class="contact-form-style" id="contact-form" method="post">
                    <div class="row">

                        <div class="form-group col-md-12">
                            <div class="input-group ">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ft-user"></i></span>
                                </div>
                                <input type="text" class="form-control" formControlName="username"
                                    placeholder="Your Name">
                                <small class="error"><label
                                        *ngIf="!regiForm.get('username').valid && regiForm.get('username').touched">
                                        Please enter your name !!!</label></small>
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <div class="input-group ">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ft-at-sign"></i></span>
                                </div>
                                <input type="text" class="form-control" formControlName="email"
                                    placeholder="Email Address">
                                <small class="error"><label
                                        *ngIf="!regiForm.get('email').valid && regiForm.get('email').touched">
                                        Please enter the Email Address !!!</label></small>
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <div class="input-group ">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ft-phone-call"></i></span>
                                </div>
                                <input type="text" class="form-control" formControlName="contactNumber"
                                    placeholder="Contact Number">
                                <small class="error"><label
                                        *ngIf="!regiForm.get('contactNumber').valid && regiForm.get('contactNumber').touched">
                                        Please enter the Phone Number !!!</label></small>
                            </div>
                        </div>

                        <!--<div class="form-group col-md-12">
                            <div class="input-group ">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ft-grid"></i></span>
                                </div>
                                <input type="text" class="form-control" formControlName="subject" placeholder="Subject">
                                <small class="error"><label
                                        *ngIf="!regiForm.get('subject').valid && regiForm.get('subject').touched">
                                        Please enter the Subject !!!</label></small>
                            </div>
                        </div>-->

                        <div class="form-group col-md-12">
                            <div class="input-group ">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ft-mail"></i></span>
                                </div>
                                <textarea name="message" rows="5" class="form-control" formControlName="message"
                                    placeholder="Your Message*"></textarea>
                                <small class="error"><label
                                        *ngIf="!regiForm.get('message').valid && regiForm.get('message').touched">
                                        Please enter the Message !!!</label></small>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <button class="btn btn-success btn-lg rounded-pill" data-dismiss="modal"
                                (click)="sendMail()" [disabled]="!regiForm.valid"> <i class="
                        ft-mail"></i> Send Mail</button>
                        </div>



                    </div>
                </form>
            </div>
        </div>
    </div>
</div>