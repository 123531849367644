import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Wholesale } from 'src/app/model/wholesale';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WholesaleService {
  constructor(private httpClient: HttpClient, private router: Router) { }

  baseUrl: string = environment.baseUrl;

  saveWholesaleUrl: string = this.baseUrl + '/api/addWholesaleProducts';
  updateWholesaleUrl: string = this.baseUrl + '/api/updateWholesaleProductsById';
  deleteWholesaleUrl: string = this.baseUrl + '/api/deleteWholesaleProductsById/';
  getAllWholesaleUrl: string = this.baseUrl + '/api/getAllWholesaleProductss';

  getWholesaleProductsByIdUrl: string = this.baseUrl + '/api/getWholesaleProducts/';
  getWholesaleByProductIdUrl: string = this.baseUrl + '/api/getWholesaleProductsById/';
  updateWholesaleStockUrl:string=this.baseUrl+'/api/wholesaleProducts/stockOut';

  saveWholesale(wholsale: Wholesale) {
      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.post(this.saveWholesaleUrl, wholsale, { headers: reqHeader });
  }

  updateWholesale(wholesale: Wholesale) {

      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.put(this.updateWholesaleUrl , wholesale, { headers: reqHeader });
  }

  deleteWholesale(wholesale) {
      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.delete(this.deleteWholesaleUrl + wholesale, { headers: reqHeader });
  }

  getAllWholesales() {
      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.get(this.getAllWholesaleUrl, { headers: reqHeader });
  }

  getwholeSaleProductbyuserId(productId) {
      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.get(this.getWholesaleProductsByIdUrl + productId, { headers: reqHeader });
  }

  getWholesaleProductsByProductId(categoryId) {
      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.get(this.getWholesaleByProductIdUrl, { headers: reqHeader });
  }
  updateWholesaleStock(data){
    var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.post(this.updateWholesaleStockUrl, data, { headers: reqHeader });
  }
  
}
