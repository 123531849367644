import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/model/user';

import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { isNullOrUndefined } from 'util';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  errorPopup: boolean = false;
  loading: boolean = false;

  constructor(private authenticationService: AuthenticationService,
    private router: Router, private socialAuthService: SocialAuthService) { }

  ngOnInit() { }

  /** New Backend Changes */
  user: User;
  userName: string;
  userPassword: string;
  errorMessage: string;

  login() {
    if (this.userName && this.userPassword && (this.userName != '' && this.userPassword != '')) {
      this.loading = true;
      this.authenticationService.authenticateUser(this.userName, this.userPassword).toPromise().then(
        response => {
          this.authenticationService.setAccessToken(response['accessToken']);
          this.authenticationService.getUser(this.userName).toPromise().then(
            response => {
              this.loading = false;
              this.user = new User(response);
              this.authenticationService.setLoggedInData(this.user);
              localStorage.setItem("currency", "INR");
              if (this.user.admin) {
                this.router.navigate(['/admin/admin-dashboard']);
              } else if (this.user.userType == 'WHOLESALE') {
                this.router.navigate(['/admin/wholesaleDashboard']);
              } else {
                this.router.navigate(['/shop-products']);
                //this.authenticationService.logOut();
              }

            }, error => {
              this.loading = false;
              console.log(error);
              this.errorMessage = error.error.failure;
              this.errorPopup = true;
              setTimeout(() => {
                this.errorPopup = false;
              }, 2000);
            }
          )

        }, error => {
          this.loading = false;
          console.log(error);
          this.errorMessage = error.error.failure;
          this.errorPopup = true;
          setTimeout(() => {
            this.errorPopup = false;
          }, 2000);
        }
      );
    }
  }
  /** New Backend Changes */
  signup() {
    this.router.navigate(['/signup']);
  }


  // Social Login Integration - Start
  socialSignInToken: string = "";
  socialImageUrl: string;
  isSocialFBLogin: boolean = false;
  isSocialLogin: Boolean = true;

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
      this.isSocialFBLogin = true;
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
      this.isSocialFBLogin = false;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(userData => {
      this.isSocialLogin = true;
      this.apiConnection(userData);
    }, msg => {
      console.log(msg);
      this.isSocialLogin = false;
    });
  }

  apiConnection(data) {
    if (this.isSocialFBLogin) {
      this.socialSignInToken = data.token;
    } else {
      this.socialSignInToken = data.idToken;
    }
    if (!isNullOrUndefined(data.image)) {
      this.socialImageUrl = data.image;
    }
    this.validateFields(data);
  }

  validateFields(data) {
    if (!isNullOrUndefined(data.email) && data.email != "") {
      var socialUserName = data.email.substring(0, data.email.indexOf('@'));
      this.user = new User();
      this.user.firstName = data.firstName;
      this.user.lastName = data.lastName;
      this.user.email = data.email;
      this.user.password = data.id;
      this.user.username = socialUserName;
      if (this.isSocialLogin) {
        this.user.socialLogin = this.isSocialLogin;
        this.user.accessToken = this.socialSignInToken;
        this.user.socialFbLogin = this.isSocialFBLogin;
      }
      this.socialLogin();
    }
  }

  socialLogin() {
    this.loading = true;
    this.authenticationService.authenticateSocialUser(this.user).toPromise().then(
      response => {
        this.authenticationService.setAccessToken(response['accessToken']);
        this.authenticationService.getUser(this.userName).toPromise().then(
          response => {
            this.loading = false;
            this.user = new User(response);
            this.authenticationService.setLoggedInData(this.user);
            if (this.user.admin) {
              this.router.navigate(['/admin/dashboard']);
            } else {
              this.router.navigate(['/shop-mask']);
            }
          }, error => {
            this.loading = false;
            this.errorMessage = error.message;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;

            }, 2000);
          }
        )

      }
    ).catch(
      error => {
        this.loading = false;
        this.errorMessage = error.error.message;
        this.errorPopup = true;
        setTimeout(() => {
          this.errorPopup = false;
        }, 2000);
      }
    );

  }
  // Social Login Integration - End


}
