import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';
import { OrderService } from '../shared/services/order.service';
import { User } from '../model/user';
import { Order } from '../model/order';
import { DataHelper } from '../shared/helper/data.helper';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.css']
})
export class OrderSuccessComponent implements OnInit {

  constructor(private authService: AuthenticationService, private orderService: OrderService,
    private router: Router, private dataHelper: DataHelper) { }

  loading: Boolean = false;
  user: User;
  orderObj: Order;
  error: Boolean = false;
  orderId: String;
  currency: string;

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.dataHelper.getOrderId.subscribe(parameter => this.orderId = parameter);
      if (this.orderId && this.orderId != '') {
        this.loading = true;
        this.user = JSON.parse(localStorage.getItem('user'));
        this.currency = localStorage.getItem('currency');
        this.getOrderDetails();
      } else {
        this.error = true;
      }
    }
  }

  getOrderDetails() {
    this.orderService.getOrderDetails(this.orderId).toPromise().then(
      response => {
        this.loading = false;
        this.orderObj = response;
      }, err => {

        this.loading = false;
        this.error = true;
      }
    )

  }
  refresh() {
    this.router.navigateByUrl('/order-success', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/order-success']);
    });
  }

}
