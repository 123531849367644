import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/shared/services/cart.service';
import { Product } from 'src/app/model/product';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../model/user';

import { AuthenticationService } from '../shared/services/authentication.service';
import { ProductService } from '../shared/services/product.service';
import { ShoppingCartResponse, ShoppingCartEntries } from '../model/shoppingCartResponse';
import { ShoppingCartRequest } from '../model/shoppingCartRequest';
import { CartHelpers } from '../shared/helper/cart.helper';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  config: any;
  p: number = 1;
  count: number = 10;
  quantity: number;
  totalAmount: number;
  user: User;
  product: Product[] = [];
  value: any;
  productShopping: ShoppingCartResponse;
  shoppingList: any[];
  total: number = 0.00;
  loading: boolean = false;
  success: boolean = false;
  errorPopup: boolean = false;
  currency: String = "";
  minOrder: number = 0;

  constructor(private cartService: CartService,
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    private authenticationService: AuthenticationService) { }

  private cartHelper: CartHelpers = new CartHelpers(this.cartService, this.productService);

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.minOrder = parseInt(localStorage.getItem('minOrder'));
      this.refreshCart();
    }
  }

  refreshCart() {
    this.loading = true;
    this.cartService.getCartList(this.user.id).subscribe(Cart => {
      this.productShopping = Cart;
      this.total = parseInt(this.productShopping.totalPrice);
      this.shoppingList = this.productShopping.shoppingCartEntries;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  incrementQuantity(cartEntry: ShoppingCartEntries) {
    this.loading = true;
    cartEntry.quantity += 1;
    this.updateCart(cartEntry, 1);
  }

  decrementQuantity(cartEntry: ShoppingCartEntries) {
    if (cartEntry.quantity > 1) {
      this.loading = true;
      cartEntry.quantity -= 1;
      this.updateCart(cartEntry, -1);
    }
  }

  emptyCart() {
    this.loading = true;
    this.cartService.deleteCart(this.user.id).toPromise().then(
      response => {
        this.loading = false;
        this.refresh();
        this.refreshCart();
      }, error => {

        this.loading = false;
      }
    );
  }

  updateCart(product, counter) {
    this.cartHelper.updateCartQuantity(product, counter).then(updatedCartEntries => {
      let cart: ShoppingCartRequest = {
        shoppingCart: updatedCartEntries,
        userId: this.user.id
      }
      this.cartService.addToCart(cart).toPromise()
        .then(() => {
          this.refresh();
          this.refreshCart();
        });
    });
  }

  removeFromCart(i) {
    this.loading = true;
    this.cartHelper.removeFromCart(i).then(updatedCartEntries => {
      let cart: ShoppingCartRequest = {
        shoppingCart: updatedCartEntries,
        userId: this.user.id
      }
      this.cartService.addToCart(cart).toPromise()
        .then(() => {
          this.loading = false;
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 2000);
          this.refresh();
          this.refreshCart();
        }, error => {
          this.loading = false;
          this.errorPopup = true;
          setTimeout(() => {
            this.errorPopup = false;
          }, 2000);
        });
    });
  }

  estimate() {
    this.router.navigate(['/checkout']);
  }

  refresh() {
    this.router.navigateByUrl('/shopping-cart', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/shopping-cart']);
    });
  }

  productQuickView: Product;
  productDetail(cartProd: ShoppingCartEntries) {
    this.productQuickView = null;
    this.loading = true;
    this.productService.getProductByProductId(cartProd.productId).toPromise().then(
      response => {
        this.productQuickView = response;
        // this.productService.getProductImage(this.productQuickView.productImageUrl[0]).toPromise()
        //   .then(
        //     response => {
        //       this.loading = false;
        //       this.productQuickView.imageUrl = window.URL.createObjectURL(response.body);
        //     }, error => {
        //       this.loading = false;
        //       this.productQuickView.imageUrl = null;
        //       this.productQuickView = null;
        //     });
      }, error => {
        this.loading = false;
        this.productQuickView = null
      }
    )
  }
}
