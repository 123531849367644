import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Faq } from 'src/app/model/faq';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FaqService } from 'src/app/shared/services/faq.service';
import { OrderService } from 'src/app/shared/services/order.service';
declare function reflowTable(): any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqAdminComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  enquries: any;
  user: User;
  categoryFilter: string;
  loading: boolean = false;
  success: boolean = false;
  income: number;
  allCounts: any;
  answerForm: FormGroup;
  answer: boolean = false;
  totalMOHFund: number;
  totalBoxesSold: number;
  currency: String = "";
  successDelete: boolean = false;
  selectedFAQ: any;

  constructor(private fb: FormBuilder,
    private router: Router, private serviceFaq: FaqService, private orderService: OrderService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.getAllFaq();
      this.getTotalCounts();
    }
  }
  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }
  getAllFaq() {
    this.loading = true;
    this.serviceFaq.getAllFaq().subscribe(response => {
      this.enquries = response;
      reflowTable();
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }
  addFaqPage() {
    this.loading = true;
    this.router.navigate(['/admin/add-editFaq']);
  }
  delete(faq) {
    this.successDelete=false;
    this.loading = true;
    this.serviceFaq.deleteFaq(faq).toPromise()
      .then(() => {
        this.loading = false;
        this.getAllFaq();
      });

  }
  details(answers) {
    this.answer = true;
    this.intializeForm(answers);
  }
  intializeForm(answers: Faq) {
    this.answerForm = this.fb.group({
      'id': [answers ? answers.id : null],

      'question': [answers ? answers.question : null, Validators.required],
      'createdDate': [answers ? answers.createdDate : null],
      'deletedFlag': [answers ? answers.deletedFlag : false],
      'lastupdatedDate': [answers ? answers.lastupdatedDate : null],

      'answer': [answers ? answers.answer : null, Validators.required],
    })
  }
  submitForm() {
    this.loading = true;
    let answers = new Faq(this.answerForm.value);
    this.serviceFaq.updateFaq(answers).toPromise().then(
      response => {
        this.answer = false;
        this.success = true;
        setTimeout(() => {
          this.success = false;
          this.getAllFaq();
        }, 2000);

        this.loading = false;

      }, error => {
        this.loading = false;


      })

  }
  close() {
    this.answer = false;
  }
  selectedFaq(faq){
    this.selectedFAQ=faq.id;
    this.successDelete = true;
  }
  cancel(){
    this.successDelete=false;
  }
}
