import { WishListRequest } from 'src/app/model/wishlistRequest';
import { myWishlist, WishListResponse } from 'src/app/model/wishistResponse';
import { User } from 'src/app/model/user';
import { WishService } from '../services/wishlist.service';

export class WishListHelpers {

    constructor(private wishListService: WishService) { }

    user: User;

    checkWishListandUpdate(product): Promise<myWishlist[]> {
       
        this.user = JSON.parse(localStorage.getItem('user'));
        let promise = new Promise<myWishlist[]>((resolve, reject) => {
            this.wishListService.getWishList(this.user.id).subscribe(response => {
                let existingCart: WishListResponse = response;
                let isExistingProduct: Boolean = false;
                if (existingCart && existingCart.myWishlist && existingCart.myWishlist.length > 0) {
                    let productsForWishList: myWishlist[] = existingCart.myWishlist;
                    productsForWishList.forEach(wishList => {
                        if (wishList.productId == product.id) {

                            isExistingProduct = true;
                        }
                    });
                    if (isExistingProduct) {
                        resolve(productsForWishList);
                    } else {
                        productsForWishList.push({
                            productId: product.id,
                            productName: product.productName,


                        });
                        resolve(productsForWishList);
                    }
                } else {
                    let productsForWishList: myWishlist[] = [];
                    productsForWishList.push({
                        productId: product.id,
                        productName: product.productName,
                    });
                    resolve(productsForWishList);
                }
            });

        });
        return promise;
    }



    removeFromWishList(i) {
        this.user = JSON.parse(localStorage.getItem('user'));

        let promise = new Promise<myWishlist[]>((resolve, reject) => {
            this.wishListService.getWishList(this.user.id).subscribe(response => {
                let existingWishList: WishListResponse = response;
                let productsForWishList: myWishlist[];
                if (existingWishList && existingWishList.myWishlist && existingWishList.myWishlist.length > 0) {
                    productsForWishList = existingWishList.myWishlist;
                    productsForWishList.splice(i, 1);                    
                }
                resolve(productsForWishList);
            }, error => {
                reject();
            });

        });
        return promise;
    }

}