import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BannerImages, FilterInput } from 'src/app/model/settings';

@Injectable()
export class SettingsService {

    constructor(private httpClient: HttpClient) { }

    baseUrl: string = environment.baseUrl;

    saveBannerImages(formData) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post(this.baseUrl + '/api/admin/createBanners', formData, { headers: reqHeader });
    }

    updateBannerImages(formData) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.put(this.baseUrl + '/api/admin/updateBanner', formData, { headers: reqHeader });
    }

    getAllBannerImages() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<BannerImages[]>(this.baseUrl + '/api/getAllBannerImages', { headers: reqHeader });
    }


    saveFilterInputs(filterInput) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post(this.baseUrl + '/api/addFilterInput', filterInput, { headers: reqHeader });
    }

    updateFilterINputs(filterInput) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.put(this.baseUrl + '/api/updateFilterInputById', filterInput, { headers: reqHeader });
    }

    getAllFilterInputs() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<FilterInput[]>(this.baseUrl + '/api/getAllFilterInputs', { headers: reqHeader });
    }
}