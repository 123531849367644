import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
declare function reflowTable(): any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userForm: FormGroup;
  isEditForm: boolean = false;
  edit: boolean = false;

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService) { }

  loading: boolean = false;
  user: User;
  resetPasswordForm: FormGroup;
  successMessage: string;
  errorMessage: string;
  currency: String;
  success: boolean = false;
  error: boolean = false;

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.currency = localStorage.getItem('currency');
    this.getUserDetails();
    // reflowTable();
  }

  loadPasswordTab() {
    this.resetPasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },
      { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') })
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        if (passwordConfirmationInput.hasError('notEquivalent')) {
          return passwordConfirmationInput.updateValueAndValidity();
        }
      }
    }
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.loading = true;
      let data = {
        email: this.user.email,
        password: this.resetPasswordForm.get('oldPassword').value,
        newPassword: this.resetPasswordForm.get('password').value
      }
      this.authenticationService.resetPassword(data).toPromise().then(
        response => {
          this.loading = false;
          this.resetPasswordForm.reset();
          this.success = true;
          this.successMessage = "Password reset Successful. Logging out adn redirecting to Login Page.";
          setTimeout(() => {
            this.successMessage = null;
            this.success = false;
            this.authenticationService.logOut();
          }, 2000);
        }, error => {
          this.loading = false;
          console.log(error);
          this.resetPasswordForm.reset();
          this.error = true;
          this.errorMessage = error.error.failure;
          setTimeout(() => {
            this.error = false;
            this.errorMessage = null;
          }, 2000);
        }
      )
    }
  }
  //======================admin profile edit=====//
  enableEdit() {
    this.edit = true;
    this.userForm.get('firstName').enable();
    this.userForm.get('lastName').enable();
    this.userForm.get('mobileno').enable();
  }

  getUserDetails() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.authenticationService.getUser(this.user.username).toPromise().then(
        response => {
          this.loading = false;
          this.user = response;
          this.authenticationService.setLoggedInData(this.user);
          this.createUserForm();
        }, error => {
          console.log(error);
          this.loading = false;
        }
      )
    }
  }

  createUserForm() {
    this.userForm = this.formBuilder.group({
      firstName: new FormControl(this.user.firstName, Validators.required),
      lastName: new FormControl(this.user.lastName, Validators.required),
      email: new FormControl(this.user.email, Validators.required),
      mobileno: new FormControl(this.user.mobileno, Validators.required),
      username: new FormControl(this.user.username, Validators.required)
    });
    this.edit = false;
    this.userForm.disable();
  }

  saveUser() {
    if (this.userForm.valid) {
      this.userForm.disable();
      this.loading = true;
      this.user.firstName = this.userForm.get('firstName').value;
      this.user.lastName = this.userForm.get('lastName').value;
      this.user.mobileno = this.userForm.get('mobileno').value;
      this.user.email = this.userForm.get('email').value;
      this.user.username = this.userForm.get('username').value;
      this.isEditForm = false;

      console.log(this.user);
      this.authenticationService.updateuser(this.user.id, this.user).subscribe(
        response => {
          this.getUserDetails();
        }, error => {
          console.log(error);
          this.loading = false;
        });
    }
  }

}
