import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/model/user';
import { DeleteComponent } from '../delete/delete.component';
import { MatDialog } from '@angular/material/dialog';
import { BillingDetails, Order, ShippingDetails } from 'src/app/model/order';
import { TotalCount } from 'src/app/model/totalcount';
import { OrderService } from 'src/app/shared/services/order.service';
import { Wholesale } from 'src/app/model/wholesale';
import { WholesaleService } from 'src/app/shared/services/wholesale.service';
declare function reflowTable(): any;
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  user: User;
  allUsers: any;
  shippingAddress: ShippingDetails[];
  billingAddress: BillingDetails[];
  loading: boolean = false;
  successDelete: boolean = false;
  allCounts: any;
  income: number;
  selectedUserObj: User;
  filter: string;
  currency: String;
  selectedUser: any;
  changedOrder: string = "ALL";
  myOrders: Order[];
  allInventories: Wholesale;

  constructor(private authenticationService: AuthenticationService,
    private orderService: OrderService,private wholesaleService:WholesaleService,
   ) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.getUserList();
      this.getTotalCounts();
    }

  }
  getUserList() {
    this.loading = true;
    this.authenticationService.getAllUser().subscribe(
      response => {
        this.allUsers = response;
        reflowTable();
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    )
  }

  delete(user) {
    this.successDelete=false;
    this.loading = true;
    
    this.authenticationService.deleteUser(user).toPromise()
      .then(() => {
        this.loading = false;
        
        this.getUserList();
      });

  }

  getUsersByType(){
    if (this.changedOrder == "ALL") {
      this.getUserList();
    } else {
      this.getUsersByuserType(this.changedOrder);
    }
  }

  userDetails(user) {
    this.selectedUserObj = user;
    this.getOrderByUserId(this.selectedUserObj.id);
    if(user.userType=='WHOLESALE'){
      this.getWholesaleInventorylist(this.selectedUserObj.id);
    }
    // this.loading = true;
    //this.billingAddress = user.billingAddress;
    //this.shippingAddress = user.shippingAddress;
    //this.loading = false;
  }

  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }

  selecteduser(user){
    this.selectedUser=user.id;
    this.successDelete = true;
  }
  cancel(){
    this.successDelete=false;
  }
  getUsersByuserType(usertype){
    this.loading = true;
    this.allUsers = [];
    this.authenticationService.getUsersByUsertype(usertype).subscribe(response => {
      this.allUsers = response;
      reflowTable();
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
  getOrderByUserId(id){
    this.orderService.getMyOrders(id).subscribe(response=>{
      this.myOrders=response as Order[];
    })
  }
  getWholesaleInventorylist(id) {
    this.loading = true;
    this.wholesaleService.getwholeSaleProductbyuserId(id).subscribe(response => {
      this.allInventories = response as Wholesale;
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }
  resendSuccess:boolean=false;
  resendmail(user){
    this.loading=true;
    this.authenticationService.resendLoginCredential(user.id).subscribe(response=>{
      this.loading=false;
      this.resendSuccess=true;
      setTimeout(() => {
        this.resendSuccess = false;
       
      }, 2000);
    }
    , error => {
      this.loading = false;
    })
  }
}
