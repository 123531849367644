import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PackageQuantities, Product } from 'src/app/model/product';
import { Quantity } from 'src/app/model/quantity';
import { ProductService } from '../shared/services/product.service';
import { ShoppingCartRequest } from '../model/shoppingCartRequest';
import { User } from '../model/user';
import { ShoppingCartEntries, ShoppingCartResponse } from '../model/shoppingCartResponse';
import { CartService } from '../shared/services/cart.service';
import { CartHelpers } from '../shared/helper/cart.helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { RatingAndReview, RatingAndReviewResponse } from '../model/rating';
import { ReviewService } from '../shared/services/review.service';
import { WishListHelpers } from '../shared/helper/wishlist.helper';
import { WishService } from '../shared/services/wishlist.service';
import { WishListRequest } from '../model/wishlistRequest';
import { isNullOrUndefined } from 'util';
import { OrderService } from '../shared/services/order.service';
import { Order } from '../model/order';
import { Enquiry } from '../model/enquiry';
import { EnquiryService } from '../shared/services/enquiry.service';
declare function productCarousel(): any;

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css']
})
export class ProductdetailsComponent implements OnInit {

  product: Product;
  productShoppingCart: any[];
  counter: number = 1;
  quantity: Quantity;
  showMsg: boolean = false;
  user: User;
  loading: boolean = false;
  prodDescArray: string[];
  selectedImageIndex: number = 0;
  ratingForm: FormGroup;
  success: boolean = false;
  ratingAndReview: RatingAndReview;
  ratingAndreviewsList: RatingAndReview[] = [];
  totalRatingAndreviews: number = 0;
  isUserRatingPresent: Boolean = true;
  fiveStarRating: number = 0;
  fourStarRating: number = 0;
  threeStarRating: number = 0;
  twoStarRating: number = 0;
  oneStarRating: number = 0;
  averageRating: number = 0;
  fiveStarRatingPercent: number = 0;
  fourStarRatingPercent: number = 0;
  threeStarRatingPercent: number = 0;
  twoStarRatingPercent: number = 0;
  oneStarRatingPercent: number = 0;
  selectedPackagePrice: number = 0;
  selectedProductName: string="";
  selectedPackage: PackageQuantities;
  

  constructor(private route: ActivatedRoute, private fb: FormBuilder, private reviewService: ReviewService,
    private cartService: CartService, private productService: ProductService, private serviceWishlist: WishService,
    private _snackBar: MatSnackBar, private router: Router, private orderService: OrderService, private serviceEnquiry: EnquiryService) { }

  private cartHelper: CartHelpers = new CartHelpers(this.cartService, this.productService);
  private wishListHelper: WishListHelpers = new WishListHelpers(this.serviceWishlist);

  totalPrice: number = 0;
  allProducts: Product[];
  currency: String = "";

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.currency = localStorage.getItem('currency');
    this.route.queryParams.subscribe(params => {
      var id = params['key'];
      this.loaddetails(id);
    });
  }

  loaddetails(id: string) {
    this.loading = true;
    window.scrollTo(0, 0);
    this.productService.getProductByProductId(id)
      .subscribe(productObj => {
        this.product = productObj as Product;
        this.ratingAndreviewsList = this.product.reviews;
        this.totalRatingAndreviews = this.ratingAndreviewsList ? this.ratingAndreviewsList.length : 0;
        this.calculateRatingPercentages();
        if (this.product && this.product.productDescription && this.product.productDescription != "") {
          this.prodDescArray = this.product.productDescription.split(/\n/);
        }
        this.productService.getAllProducts().toPromise().then(
          response => {
            this.allProducts = response;
            this.getUserOrders().then(() => {
              this.loading = false;
            }).catch(() => {
              this.loading = false;
            });
            productCarousel();
          }, error => {
            this.loading = false;
            this.getUserOrders().then(() => {
              this.loading = false;
            }).catch(() => {
              this.loading = false;
            });
            productCarousel();
          }
        )
      }, error => {
        this.loading = false;
      });
  }

  increment() {
    this.counter += 1;
    this.totalPrice = this.counter * this.selectedPackagePrice;
  }
  setQuantity(e) {
    this.counter = e;
    this.totalPrice = this.counter * this.selectedPackagePrice;
  }
  decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
      this.totalPrice = this.counter * this.selectedPackagePrice;
    }
  }

  checkValidity() {
    if (this.counter < 0) {
      this.counter = 1;
    }
  }

  addToCart(product) {
    let productNew = {
      id: product.productId,
      imageId: product.productImageUrl[0],
      productImageUrl: product.productImageUrl,
      productCost: this.selectedPackagePrice,
      productName: product.productName,
      productCategory:product.categoryName,
      packageQuantity:this.selectedPackage
    }
    this.cartHelper.checkCartandUpdate(productNew, this.counter).then(updatedCartEntries => {
      console.log(updatedCartEntries);
      let cart: ShoppingCartRequest = {
        shoppingCart: updatedCartEntries,
        userId: this.user.id
      }
      this.cartService.addToCart(cart).toPromise()
        .then(() => {
          this.router.navigate(['/shopping-cart']);
        });
    });
  }
  addToWishList(product) {
    this.loading = true;
    this.wishListHelper.checkWishListandUpdate(product)
      .then(updatedmyWishlist => {
        let wishList: WishListRequest = {
          productsForWishList: updatedmyWishlist,
          userId: this.user.id
        }
        this.serviceWishlist.addToWishlist(wishList).toPromise()
          .then(() => {
            this.loading = false;
            this.router.navigate(['/wishlist']);
          }, error => {
            this.loading = false;
          });
      });
  }
  buyNow(product) {
    let productNew = {
      id: product.productId,
      imageId: product.productImageUrl[0],
      productImageUrl: product.productImageUrl,
      productCost: this.selectedPackagePrice,
      productName: product.productName,
      productCategory:product.categoryName,
      packageQuantity:this.selectedPackage
    }
    this.cartHelper.checkCartandUpdate(productNew, this.counter).then(updatedCartEntries => {
      let cart: ShoppingCartRequest = {
        shoppingCart: updatedCartEntries,
        userId: this.user.id
      }
      this.cartService.addToCart(cart).toPromise()
        .then(() => {
          this.router.navigate(['/checkout']);
        });
    });
  }

  rating() {
    this.ratingForm = this.fb.group({
      comment: new FormControl(''),
      createdBy: new FormControl(''),
      createdDate: new FormControl(''),
      deletedFlag: new FormControl(false),
      email: new FormControl(''),
      id: new FormControl(''),
      lastupdatedDate: new FormControl(''),
      productId: new FormControl(this.product.id),
      productName: new FormControl(this.product.productName),
      rating: new FormControl(null),
      updatedBy: new FormControl(null),
    });
  }


  changeId(productId) {
    this.router.navigateByUrl('/product-details?key=' + productId, { skipLocationChange: true }).then(() => {
      this.router.navigate(['/product-details'], { queryParams: { 'key': productId } });
      this.refresh();
    });
  }


  refresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  myProducts: string[] = [];
  getUserOrders(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      this.loading = true;
      this.orderService.getMyOrders(this.user.id).toPromise().then(
        response => {
          this.loading=false;
          let myOrders: Order[] = response;
          if (myOrders && myOrders.length > 0) {
            myOrders.forEach(order => {
              order.shoppingCartEntry.forEach(cart => {
                this.myProducts.push(cart.productId);
              });
            });
            this.checkIfUserRatingPresent().then(() => {
              this.loading=false;
              resolve();
            }).catch(() => {
              reject();
            });
          } else {
            resolve();
          }
        }, error => {
          console.log(error);
          this.loading=false;
          reject();
        }
      )
    });
    return promise;
  }

  checkIfUserRatingPresent(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      if (this.myProducts.includes(this.product.id)) {
        if (!isNullOrUndefined(this.ratingAndreviewsList) && this.ratingAndreviewsList.length > 0) {
          this.ratingAndreviewsList.forEach(ratingAndReviewObj => {
            if (ratingAndReviewObj.userId != null && ratingAndReviewObj.userId == this.user.id) {
              this.isUserRatingPresent = true;
            } else {
              this.isUserRatingPresent = false;
            }
          });
          resolve();
        } else {
          this.isUserRatingPresent = false;
        }
      } else {
        this.isUserRatingPresent = true;
        resolve();
      }

    });
    return promise;

  }

  calculateRatingPercentages() {
    if (!isNullOrUndefined(this.ratingAndreviewsList) && this.ratingAndreviewsList.length > 0) {
      this.ratingAndreviewsList.forEach(ratingAndReviewObj => {
        if (ratingAndReviewObj.star != null && ratingAndReviewObj.star == "FIVE_STAR") {
          this.fiveStarRating++;
        } else if (ratingAndReviewObj.star != null && ratingAndReviewObj.star == "FOUR_STAR") {
          this.fourStarRating++;
        } else if (ratingAndReviewObj.star != null && ratingAndReviewObj.star == "THREE_STAR") {
          this.threeStarRating++;
        } else if (ratingAndReviewObj.star != null && ratingAndReviewObj.star == "TWO_STAR") {
          this.twoStarRating++;
        } else if (ratingAndReviewObj.star != null && ratingAndReviewObj.star == "ONE_STAR") {
          this.oneStarRating++;
        }
      });
      this.fiveStarRatingPercent = this.fiveStarRating > 0 ? ((this.fiveStarRating / this.totalRatingAndreviews) * 100) : 0;
      this.fourStarRatingPercent = this.fourStarRating > 0 ? ((this.fourStarRating / this.totalRatingAndreviews) * 100) : 0;
      this.threeStarRatingPercent = this.threeStarRating > 0 ? ((this.threeStarRating / this.totalRatingAndreviews) * 100) : 0;
      this.twoStarRatingPercent = this.twoStarRating > 0 ? ((this.twoStarRating / this.totalRatingAndreviews) * 100) : 0;
      this.oneStarRatingPercent = this.oneStarRating > 0 ? ((this.oneStarRating / this.totalRatingAndreviews) * 100) : 0;

      this.averageRating = (1 * this.oneStarRating) + (2 * this.twoStarRating) + (3 * this.threeStarRating) + (4 * this.fourStarRating) + (5 * this.fiveStarRating);
      this.averageRating = this.averageRating > 0 ? (this.averageRating / this.totalRatingAndreviews) : 0;
    }
  }

  contentRating: string;
  contentReview: string;
  messageHeader: string;
  successMessage: string;
  errorMessaage: String;
  successModal: Boolean = false;
  errorModal: Boolean = false;
  replyToReview: Boolean = false;
  replyComment: string;
  selectedReplyToReviewId: string;

  submitRatingandReview() {
    this.ratingAndReview = new RatingAndReview();
    this.ratingAndReview.star = this.contentRating;
    this.ratingAndReview.reviewComment = this.contentReview;
    this.ratingAndReview.userId = this.user.id;
    this.ratingAndReview.username = this.user.firstName + " " + this.user.lastName;
    this.ratingAndReview.email = this.user.email;
    this.ratingAndReview.productId = this.product.id;
    this.ratingAndReview.productName = this.product.productName;

    this.loading = true;
    this.reviewService.saveReview(this.ratingAndReview).subscribe(
      response => {
        this.loading = false;
        this.messageHeader = "Thank you for your Feedback";
        this.successMessage = "Feedback submitted successfully";
        this.successModal = true;
        this.changeId(this.product.id);
      }, error => {
        this.loading = false;
        this.messageHeader = "Sorry for the inconvenience";
        this.errorMessaage = error.error.failure;
        this.errorModal = true;
        this.loaddetails(this.product.id);
      });
  }

  openReplyComment(ratingId) {
    this.selectedReplyToReviewId = ratingId;
    this.replyComment = null;
    this.replyToReview = true;
  }

  cancelReplyComment() {
    this.replyToReview = false;
    this.replyComment = null;
    this.selectedReplyToReviewId = null;
  }

  submitReplyComment(ratingId) {
    this.loading = true;
    let ratingReviewTobeSubmitted: RatingAndReview;
    this.ratingAndreviewsList.forEach(ratingReview => {
      if (ratingReview.id == ratingId) {
        if (!ratingReview.replies) {
          ratingReview.replies = [];
        }
        let ratingResponse: RatingAndReviewResponse = new RatingAndReviewResponse();
        ratingResponse.email = this.user.email;
        ratingResponse.userId = this.user.id;
        ratingResponse.username = this.user.firstName + " " + this.user.lastName;
        ratingResponse.reviewComment = this.replyComment;
        ratingReview.replies.push(ratingResponse);
        ratingReviewTobeSubmitted = ratingReview;
      }
    });
    this.reviewService.updateReview(ratingReviewTobeSubmitted).subscribe(
      response => {
        this.loading = false;
        this.messageHeader = "Thank you for Comments";
        this.successMessage = "Reply for the comment submitted successfully";
        this.successModal = true;
        this.cancelReplyComment();
        this.changeId(this.product.id);
      }, error => {
        this.loading = false;
        this.messageHeader = "Sorry for the inconvenience";
        this.errorMessaage = error.error.failure;
        this.errorModal = true;
        this.cancelReplyComment();
        this.loaddetails(this.product.id);
      });
  }
  setSelectedProduct(event) {
    if (event.target.value) {
      this.product.packagingQuantities.forEach(product => {
        if (event.target.value == product.packageQuantity) {
          this.selectedPackagePrice = product.retailPrice;
          this.selectedProductName = product.packageQuantity;
          this.selectedPackage=product;
        }
      });
    }
    this.totalPrice = this.counter * this.selectedPackagePrice;
  }
  //==========enquiry=============//
  regiForm: FormGroup;
  isEnquiryModal: Boolean = false;

  contactForm() {
    this.regiForm = this.fb.group({
      username: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      contactNumber: new FormControl('', Validators.required),
      subject: new FormControl(''),
      message: new FormControl('', Validators.required),
      comments: new FormControl(''),
      contactUsStatus: new FormControl('REQUESTED'),
      createdDate: new FormControl(''),
      deletedFlag: new FormControl(false),
      id: new FormControl(''),
      lastUpdatedDate: new FormControl(''),
      productId: this.product.id,
      productName: this.product.productName,
      categoryId: this.product.categoryId,
      categoryName: this.product.categoryName
    });
  }
  sendMail() {
    this.loading = true;
    this.isEnquiryModal = false;
    let enquiries = new Enquiry(this.regiForm.value);
    enquiries.subject = "[Thank you for showing interest on " + this.product.productName + "] ";
    enquiries.message = "[Product Reference: " + this.product.categoryName + " - " + this.product.productName + "] \n " + enquiries.message;
    this.serviceEnquiry.saveEnquiry(enquiries).toPromise().then(
      response => {
        this.loading = false;
        this.success = true;
        this.messageHeader = "Thank you for showing interest in our product.";
        this.successMessage = "We will reach you throught email and contact number provided soon.";
        setTimeout(() => {
          this.success = false;
          this.messageHeader = null;
          this.successMessage = null;
        }, 2000);
        this.regiForm.reset();
      }, error => {
        this.loading = false;
      });
  }

  openEnquiryModal() {
    this.contactForm();
    this.isEnquiryModal = true;
  }
}
