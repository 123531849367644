import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors, AbstractControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { Category } from 'src/app/model/category';
import { PackageQuantities, Product } from 'src/app/model/product';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { CategoryService } from 'src/app/shared/services/category.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { isNullOrUndefined } from 'util';

declare function openUpload(): any;

@Component({
  selector: 'app-addeditproduct',
  templateUrl: './addeditproduct.component.html',
  styleUrls: ['./addeditproduct.component.css']
})
export class AddeditproductComponent implements OnInit {
  imgSrc: string;
  imageurl: string;
  selectedImage: any = null;
  regiForm: FormGroup;
  product: Product;
  categories: Category[];
  myDate = new Date();
  user: User;
  idProduct: string;
  loading: boolean = false;
  allCounts: any;
  income: number;
  errorPopup: boolean = false;
  success: boolean = false;
  ProductImageUrl: any;
  productImageUrl: any;
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;
  files = [];
  currency: string = "";
  uploadedImage: File;

  packagingQuantities: FormArray;

  constructor(private fb: FormBuilder, private serviceCategorie: CategoryService,
    private orderService: OrderService, private serviceProduct: ProductService,
    private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.loading = true;
    this.getTotalCounts();
    if (this.route.snapshot.queryParams['key']) {
      this.route.queryParams.subscribe(params => {
        this.idProduct = params['key'];
      })
    }
    this.imgSrc = '/../../../assets/images/no-image.jpg';
    this.user = JSON.parse(localStorage.getItem('user'));
    this.currency = localStorage.getItem('currency');
    if (!this.idProduct) {
      this.serviceCategorie.getAllCategories()
        .subscribe(categories => {
          this.categories = categories as Category[];
          this.initalizeProduct(null);
          this.packagingQuantities = this.regiForm.get('packagingQuantities') as FormArray;

          this.loading = false;
        }, error => {
          this.loading = false;
        });
    }
    else {
      this.serviceCategorie.getAllCategories()
        .pipe(
          mergeMap(categories => this.serviceProduct.getProductByProductId(this.idProduct).pipe(
            map(productObj => {
              return ([categories, productObj])
            })
          ))).subscribe(([categories, productObj]) => {
            this.categories = categories as Category[];
            this.product = productObj as Product;
            this.initalizeProduct(this.product);
            this.packagingQuantities = this.regiForm.get('packagingQuantities') as FormArray;
            this.showpreview();
            // 
            // this.regiForm.get('available_stock').disable();
            this.loading = false;



          })
    }
  }
  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }

  initalizeProduct(productObj: Product) {
    let prodChoiceArray = this.fb.array([]);
    if (productObj) {
      productObj.packagingQuantities.forEach(prodChoiceObj => {
        let prodChoiceGroup = this.fb.group({
          packageQuantity: [prodChoiceObj.packageQuantity, Validators.required],
          packagePrice: [prodChoiceObj.packagePrice],
          packageStatus: [prodChoiceObj.packageStatus],
          packageDiscount: [prodChoiceObj.packageDiscount, Validators.required],
          packageDiscountedPrice: [prodChoiceObj.packageDiscountedPrice, Validators.required],
          barcode: [prodChoiceObj.barcode],
          retailPrice: [prodChoiceObj.retailPrice],
          wholesalePrice: [prodChoiceObj.wholesalePrice],
          totalStockCnt: [prodChoiceObj.totalStockCnt],
          availableStockCnt: [prodChoiceObj.availableStockCnt],
          soldStockCnt: [prodChoiceObj.soldStockCnt],
        });
        prodChoiceArray.push(prodChoiceGroup);
      });
    };
    this.regiForm = this.fb.group({
      'id': [productObj ? productObj.id : null],
      'categoryId': [productObj ? productObj.categoryId : null, Validators.required],
      'categoryName': [productObj ? productObj.categoryName : null, Validators.required],
      'productName': [productObj ? productObj.productName : null, Validators.required],
      'productDescription': [productObj ? productObj.productDescription : null, Validators.required],
      // 'productCost': [productObj ? productObj.productCost : null, [Validators.min(1), Validators.required]],
      // 'productDiscountedCost': [productObj ? productObj.productDiscountedCost : null],
      // 'imageId': [productObj ? productObj.imageId : null],
      'productImageUrl': [productObj ? productObj.productImageUrl : null],
      // 'available_stock': [productObj ? productObj.available_stock : null, [Validators.min(1), Validators.required]],
      // 'sold': [productObj ? productObj.sold : '0'],
      // 'offer': [productObj ? productObj.offer : 0, [Validators.min(0), Validators.max(80)]],
      'createdBy': [productObj ? productObj.createdBy : null],
      'createdDate': [productObj ? productObj.createdDate : null],
      'updatedBy': [productObj ? productObj.updatedBy : null],
      'lastupdatedDate': [productObj ? productObj.lastupdatedDate : null],
      'deletedFlag': [productObj ? productObj.deletedFlag : false],
      'hotDeals': [productObj ? productObj.hotDeals : true],
      'newArrivals': [productObj ? productObj.newArrivals : true],
      'totalSold': [productObj ? productObj.totalSold : 0],
      // 'packagingQuantities': [productObj ? productObj.packagingQuantities : null],
      'packagingQuantities': productObj ? prodChoiceArray : this.fb.array([this.fb.group({
        packageQuantity: [null, Validators.required],
        packageDiscount: [0],
        packagePrice: [0, Validators.required],
        packageDiscountedPrice: [0],
        packageStatus: [true],
        barcode: [null],
        retailPrice: [0],
        totalStockCnt: [0],
        availableStockCnt: [0],
        soldStockCnt: [0],
        wholesalePrice: [0]
      })]),
      // 'colour': [productObj ? productObj.colour : null],
    }
      //, { validators: this.CostValidator }
    );

  }

  private CostValidator(control: AbstractControl) {
    const productCost: number = control.get('productCost').value;
    const offer: number = control.get('offer').value;
    if (productCost && offer) {
      const discountPrice = productCost - (productCost * (offer / 100));
      if (productCost < discountPrice) {
        control.get('offer').setErrors({ invalidOffer: true });
      } else {
        if (control.get('offer').hasError("invalidOffer")) {
          control.get('offer').updateValueAndValidity();
        }
      }
    }
  };
  addProductChoice() {
    let productChoiceObj: PackageQuantities = new PackageQuantities();
    this.packagingQuantities.push(this.fb.group({
      packageQuantity: [null, Validators.required],
      packageDiscount: [0],
      packagePrice: [0, Validators.required],
      packageDiscountedPrice: [0],
      packageStatus: [true],
      barcode: [null],
      retailPrice: [0],
      totalStockCnt: [0],
      availableStockCnt: [0],
      soldStockCnt: [0],
      wholesalePrice: [0]
    }));
  }

  removeProductChoice(index) {
    this.packagingQuantities.removeAt(index);
  }

  selectedPkgQuantity: PackageQuantities;
  selectedPkgQuantityIndex: number;
  newStockCount: number = 0;
  quantityModal: boolean = false;

  showStockCountModal(index) {
    this.newStockCount = 0;
    this.selectedPkgQuantityIndex = index;
    this.selectedPkgQuantity = this.packagingQuantities[index];
    this.quantityModal = true;
  }

  closeQuantityModal() {
    this.quantityModal = false;
    this.newStockCount = 0;
    this.selectedPkgQuantityIndex = null;
    this.selectedPkgQuantity = null;
  }

  addStockCount() {
    this.quantityModal = false;
    let control = this.packagingQuantities.at(this.selectedPkgQuantityIndex).value;
    control.totalStockCnt = control.totalStockCnt + this.newStockCount;
    control.availableStockCnt = control.availableStockCnt + this.newStockCount;
    this.packagingQuantities.at(this.selectedPkgQuantityIndex).setValue(control);
  }

  chooseFile() {
    openUpload();
  }

  onSubmit() {
    this.product = new Product(this.regiForm.value);


    // //Set discounted Cost
    // if (this.product.offer > 0) {
    //   this.product.productDiscountedCost = this.product.productCost - (this.product.productCost * (this.product.offer / 100));
    // } else {
    //   this.product.productDiscountedCost = this.product.productCost;
    // }

    if (this.uploadedFiles && this.uploadedFiles.length > 0) {
      if (!this.idProduct) {
        this.loading = true;
        var formData = new FormData();
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          formData.append("productImage", this.uploadedFiles[i]);
        }
        formData.append("productRequest", JSON.stringify(this.product));
        this.serviceProduct.saveProduct(formData).toPromise().then(
          response => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {
              this.success = false;
              this.router.navigate(['/admin/display-products']);
            }, 2000);
          }, error => {
            this.loading = false;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;
            }, 2000);

          }
        )
      }
      else {
        this.loading = true;
        var formData = new FormData();
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          formData.append("file", this.uploadedFiles[i]);

        }
        formData.append("productRequest", JSON.stringify(this.product));
        this.serviceProduct.updateProduct(this.product.id, formData).toPromise().then(
          response => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {
              this.success = false;
              this.router.navigate(['/admin/display-products']);
            }, 2000);
          }, error => {
            this.loading = false;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;
            }, 2000);

          }
        )
      }
    }

  }
  cancel() {
    this.router.navigate(['/admin/display-products'])
  }

  uploadedFiles: File[] = [];
  onFileUpload(event) {
    var addedFiles = event.addedFiles;
    addedFiles.forEach(file => {
      if (this.uploadedFiles.indexOf(file.name) === -1) {
        /* this.ng2ImgMax.resizeImage(file, 400, 400).subscribe(
           result => {
             let data = result;
             let metadata = {
               type: 'image/jpeg'
             };
             let newFile = new File([data], file.name, metadata);
             this.uploadedFiles.push(newFile);
 
             /*this.uploadedImage = result;
             this.uploadedFiles.push(this.uploadedImage);
 
 
           },
           error => {
             console.log('😢 Oh no!', error);
           }
         );*/
        this.uploadedFiles.push(file);
      }
    })
  }
  onFileRemoved(removedFile: File) {
    this.uploadedFiles.splice(this.uploadedFiles.indexOf(removedFile), 1);
  }

  async showpreview() {
    if (this.product && this.product.productImageUrl && this.product.productImageUrl.length > 0) {
      this.product.productImageUrl.forEach(async imageurl => {
        let decodedURL = decodeURI(imageurl);
        let filename = decodedURL.substring(decodedURL.lastIndexOf("/") + 1, decodedURL.length);
        this.serviceProduct.getProductImageBlob("SoyaBag Portal/Product Images/" + filename).toPromise().then(response => {
          let data = response.body;
          let metadata = {
            type: 'image/jpeg'
          };
          let file = new File([data], filename, metadata);
          this.uploadedFiles.push(file);
        }, error => {
          console.log(error);
        })
      });
    }
  }
  setCategoryName(event) {
    if (this.categories && this.categories.length > 0) {
      this.categories.forEach(category => {
        if (category.id == event.target.value) {
          this.regiForm.get('categoryName').setValue(category.categoryName);
          return;
        }
      });
    }
  }
  //====================================Image Resizing==========================================//
  onImageChange(event) {
    let reader = new FileReader();
    let file = event.target.files[0];

    /*this.ng2ImgMax.resizeImage(file, 400, 400).subscribe(
      result => {
        this.uploadedImage = result;
        this.uploadedFiles.push(this.uploadedImage);
        const img = new Image();
        img.src = window.URL.createObjectURL(this.uploadedImage);

        reader.readAsDataURL(this.uploadedImage);
        reader.onload = () => {

          const width = img.naturalWidth;
          const height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
        }
      },
      error => {
        console.log('😢 Oh no!', error);
      }
    );*/
    this.uploadedImage = file;
    this.uploadedFiles.push(this.uploadedImage);
  }
  //=====================end=======================//
  colours: string;
  coloursAddedList: string[] = [];
  colourBlock: boolean = false;
  sizeBlock: boolean = false;
  quantityBlock: boolean = false;
  size: string;
  sizeAddedList: string[] = [];
  quantity: string;
  quantityAddedList: string[] = [];

  addColours() {
    this.colourBlock = true;
    this.sizeBlock = false;
    this.quantityBlock = false;
  }
  addSize() {
    this.sizeBlock = true;
    this.colourBlock = false;
    this.quantityBlock = false;
  }
  addQuantities() {
    this.quantityBlock = true;
    this.colourBlock = false;
    this.sizeBlock = false;
  }
  pushColours() {
    this.coloursAddedList.push(this.colours);
    this.colours = null;
    this.regiForm.get('colour').setValue(this.coloursAddedList);
  }
  pushSize() {
    this.sizeAddedList.push(this.size);
    this.size = null;
    this.regiForm.get('size').setValue(this.sizeAddedList);
  }
  pushQuantity() {
    this.quantityAddedList.push(this.quantity);
    this.quantity = null;
    this.regiForm.get('packagingQuantities').setValue(this.quantityAddedList);
  }
  closeBlock() {
    this.colourBlock = false;
    this.sizeBlock = false;
    this.quantityBlock = false;
  }
  deletecolour(i) {
    this.coloursAddedList.splice(i, 1);
    this.regiForm.get('colour').setValue(this.coloursAddedList);
  }
  deletesize(i) {
    this.sizeAddedList.splice(i, 1);
    this.regiForm.get('size').setValue(this.sizeAddedList);
  }
  deletequantity(i) {
    this.quantityAddedList.splice(i, 1);
    this.regiForm.get('packagingQuantities').setValue(this.quantityAddedList);
  }

  // Toolbar configuration generated automatically by the editor based on config.toolbarGroups.
  config: any = {
    "toolbar": [
      // { name: 'document', groups: [ 'mode', 'document', 'doctools' ], items: [ 'Source', '-', 'Save', 'NewPage', 'ExportPdf', 'Preview', 'Print', '-', 'Templates' ] },
      //{ name: 'clipboard', groups: [ 'clipboard', 'undo' ], items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
      // { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ], items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
      // { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
      '/',
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
      // { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
      // { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
      '/',
      { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      // { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
      // { name: 'others', items: [ '-' ] },
      // { name: 'about', items: [ 'About' ] }
    ],
    /*"toolbarGroups" : [
      { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
      { name: 'editing', groups: [ 'find', 'selection', 'spellchecker' ] },
      { name: 'forms' },
      '/',
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
      { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
      { name: 'links' },
      { name: 'insert' },
      '/',
      { name: 'styles' },
      { name: 'colors' },
      { name: 'tools' },
      { name: 'others' },
      { name: 'about' }
    ]*/
  }
}
