import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Enquiry } from '../model/enquiry';
import { EnquiryService } from '../shared/services/enquiry.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  constructor(private fb: FormBuilder, private router: Router, private serviceEnquiry: EnquiryService) { }

  loading: Boolean = false;
  success: boolean = false;
  regiForm: FormGroup;
  ngOnInit() {
    this.contactForm();

  }
  contactForm() {
    this.regiForm = this.fb.group({
      username: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      contactNumber: new FormControl('', Validators.required),
      subject: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      comments: new FormControl(''),
      contactUsStatus: new FormControl('REQUESTED'),
      createdDate: new FormControl(''),
      deletedFlag: new FormControl(false),
      id: new FormControl(''),
      lastUpdatedDate: new FormControl(''),
    });
  }
  sendMail() {
    this.loading = true;
    let enquiries = new Enquiry(this.regiForm.value);
    this.serviceEnquiry.saveEnquiry(enquiries).toPromise().then(
      response => {
        this.loading = false;
        this.success = true;
        setTimeout(() => {
          this.success = false;
        }, 2000);
        this.regiForm.reset();
      }, error => {
        this.loading = false;
      });
  }
  refresh() {
    this.router.navigateByUrl('/contact-us', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/contact-us']);
    });
  }

}
