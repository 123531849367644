import { ShoppingCartEntries } from './shoppingCartResponse';

export class Order {

  shippingDetails: ShippingDetails;
  shoppingCartEntry: ShoppingCartEntries[];  
  status: string;
  billingDetails: BillingDetails;
  cartAmount: number;
  createdDate: Date;
  createdby: string;
  deletedFlag: boolean;
  deliveryCharge: number;
  id: string;
  lastupdatedDate: Date;
  modeOfOrder: string;
  orderDateForEmail: string;
  orderNumber: string;
  paymentAdvanceAmt: number;
  paymentDetails: PaymentDetails;
  paymentFailureReason: string;
  paymentStatus: string;
  payment_method: string;
  payment_method_title: string;
  reasonForCancellation: string;
  sequenceName: string;  
  stockInStatus: boolean;
  stockOutStatus: boolean;
  total_Amount_to_Pay: number;
  updatedby: string;
  userId: string;
  wholesaleOrder: boolean
}

export class BillingDetails {
  address_1: string;
  address_2: string;
  city: string;
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  mobileNo: string;
  postcode: string;
  state: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}

export class ShippingDetails {
  address_1: string;
  address_2: string;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  mobileNo: string;
  postcode: string;
  state: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }

}

export class PaymentDetails {
  brand: string;
  funding: string;
  id: string;
  last4: string;
  expMonth: string;
  expYear: string;
  chequeNo: string;
  paymentReceiptName: string;
  paymentReceiptUrl: string;
  bankTxnId:string;
  gatewayName: string;
  
}
