<mat-tab-group>
    <!--top nav-->
    <mat-tab label="Top Navbar">

        <div class="container">
            <mat-card>
                <mat-card-title>
                    <div>Add Top Navbar</div>

                </mat-card-title>
                <mat-card-content>
                    <form>
                        <table>
                            <tr>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Telephone Number</mat-label>
                                        <input matInput placeholder="Telephone Number">
                                    </mat-form-field>

                                </td>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Email <Address></Address>
                                        </mat-label>
                                        <input matInput placeholder="Email Address">
                                    </mat-form-field>

                                </td>

                            </tr>

                            <tr>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Content</mat-label>
                                        <input matInput placeholder="Content">
                                    </mat-form-field>

                                </td>


                            </tr>
                            <tr>

                                <button mat-raised-button color="accent">Submit</button>
                            </tr>
                        </table>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>





    </mat-tab>

    <!--/top nav-->

    <!--nav bar-->

    <mat-tab label="Navbar">

        <div class="container">
            <mat-card>
                <mat-card-title>
                    <div>Add Navbar</div>

                </mat-card-title>
                <mat-card-content>
                    <form>
                        <table>
                            <tr>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Company Name</mat-label>
                                        <input matInput placeholder="Company Name">
                                    </mat-form-field>

                                </td>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Menu Items</mat-label>
                                        <input matInput placeholder="Menu Items">
                                    </mat-form-field>

                                </td>

                            </tr>


                            <tr>

                                <button mat-raised-button color="accent">Submit</button>
                            </tr>
                        </table>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>




    </mat-tab>

    <!--/nav bar-->

    <!--slider-->
    <mat-tab label="Slider">
        <div class="container">
            <mat-card>
                <mat-card-title>
                    <div>Add Slide Content</div>

                </mat-card-title>
                <mat-card-content>
                    <form>
                        <table>
                            <tr>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Slide Heading1</mat-label>
                                        <input matInput placeholder="Slider Heading 1">
                                    </mat-form-field>

                                </td>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Slide Heading2</mat-label>
                                        <input matInput placeholder="Slide Heading2">
                                    </mat-form-field>

                                </td>

                            </tr>

                            <tr>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Slide Sub-Heading1</mat-label>
                                        <input matInput placeholder="Slide Sub-Heading1">
                                    </mat-form-field>

                                </td>

                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Slide Sub-Heading2</mat-label>
                                        <input matInput placeholder="Slide Sub-Heading2">
                                    </mat-form-field>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Slide Link1</mat-label>
                                        <input matInput placeholder="Slide Link1">
                                    </mat-form-field>

                                </td>

                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Slide Link2</mat-label>
                                        <input matInput placeholder="Slide Link2">
                                    </mat-form-field>

                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <div class="text-center">
                                        <img [src]="imgSrc" alt="product" width="350px" height="250px">
                                    </div>
                                    <div class="form-group">
                                        <label>Select file to upload</label>
                                        <input type="file" accept="image/*" class="form-control"
                                            (change)="showPreview($event)">

                                    </div>


                                </td>
                                <td>
                                    <div class="text-center">
                                        <img [src]="imgSrc1" alt="product" width="350px" height="250px">
                                    </div>
                                    <div class="form-group">
                                        <label>Select file to upload</label>
                                        <input type="file" accept="image/*" class="form-control"
                                            (change)="showPreview1($event)">

                                    </div>

                                </td>

                            </tr>

                            <tr>

                                <button mat-raised-button color="accent">Submit</button>
                            </tr>
                        </table>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <!--/slider-->

    <!--service-->

    <mat-tab label="Service">
        <div class="container">
            <mat-card>
                <mat-card-title>
                    <div>Add Service</div>

                </mat-card-title>
                <mat-card-content>
                    <form>
                        <table>
                            <tr>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Service Icon</mat-label>
                                        <input matInput placeholder="Service Icon">
                                    </mat-form-field>

                                </td>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Service Name </mat-label>
                                        <input matInput placeholder="Service Name">
                                    </mat-form-field>

                                </td>

                            </tr>

                            <tr>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Content</mat-label>
                                        <input matInput placeholder="Content">
                                    </mat-form-field>

                                </td>


                            </tr>
                            <tr>

                                <button mat-raised-button color="accent">Submit</button>
                            </tr>
                        </table>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>

    <!--/service-->

    <!--Product Category-->
    <mat-tab label="Product Category">
        <div class="container">
            <mat-card>
                <mat-card-title>
                    <div>Add Product Category</div>

                </mat-card-title>
                <mat-card-content>
                    <form>
                        <table>
                            <tr>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Category Name</mat-label>
                                        <input matInput placeholder="Category Name">
                                    </mat-form-field>

                                </td>
                                <td>
                                    <mat-form-field class="field">
                                        <mat-label>Category Image</mat-label>
                                        <input matInput placeholder="Category Image">
                                    </mat-form-field>

                                </td>

                            </tr>

                            <tr>

                                <button mat-raised-button color="accent">Submit</button>
                            </tr>
                        </table>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>


    </mat-tab>

    <!--/product category-->

    <!--today deal-->
    <mat-tab label="Today Deal"> Content 2 </mat-tab>
    <!--/today deal-->
    <!--testimony-->
    <mat-tab label="Testimony"> Content 3 </mat-tab>
    <!--/testimony-->
    <!--footer-->
    <mat-tab label="Footer"> Content 3 </mat-tab>
    <!--/footer-->
</mat-tab-group>