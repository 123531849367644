import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Product } from 'src/app/model/product';
import { ShoppingCartRequest } from 'src/app/model/shoppingCartRequest';
import { ShoppingCartResponse } from 'src/app/model/shoppingCartResponse';
import { Order } from 'src/app/model/order';
import { MostSellingReportObject, OnlineOfflineOrdersObject, OrderUserReportObject } from 'src/app/model/report';
import { StockOutByBarcode } from 'src/app/model/stockOutByBarcode';

@Injectable()
export class OrderService {

    constructor(private httpClient: HttpClient, private router: Router) { }

    baseUrl: string = environment.baseUrl;

    createOrderUrl: string = this.baseUrl + '/api/order/createOrder';
    deleteOrderByIdUrl: string = this.baseUrl + '/api/order/deleteOrder?id=';
    getAllOrdersUrl: string = this.baseUrl + '/api/order/getAllOrders';
    getOrderByIdUrl: string = this.baseUrl + '/api/order/getOrder?id=';
    updateOrderUrl: string = this.baseUrl + '/api/order/updateOrder?id=';
    getOrderTodayUrl: string = this.baseUrl + '/api/getOrdersForToday';
    StockOutByBarcodeUrl:string=this.baseUrl+'/api/inventory/stockOutByProductIdBarcode';
    getStockOutByOrderIdUrl:string=this.baseUrl+'/api/order/calculateStockOut?orderId=';
    getStockInByOrderIdUrl:string=this.baseUrl+'/api/order/calculateStockIn?orderId=';
    createOrder(data) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post(this.createOrderUrl, data, { headers: reqHeader });
    }
    getAllOrders() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.getAllOrdersUrl, { headers: reqHeader });

    }
    getOrderForToday() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.getOrderTodayUrl, { headers: reqHeader });

    }
    getAllCountsForAdmin() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.baseUrl + '/api/admin/totalCounts', { headers: reqHeader });
    }

    updateOrder(orderId, orderObj) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.put(this.updateOrderUrl + orderId, orderObj, { headers: reqHeader });
    }

    getMyOrders(userId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Order[]>(this.baseUrl + '/api/getMyOrders?userId=' + userId, { headers: reqHeader });
    }

    getOrderDetails(orderId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Order>(this.getOrderByIdUrl + orderId, { headers: reqHeader });
    }

    getOrdersByStatus(status) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Order[]>(this.baseUrl + '/api/ordersByStatus/' + status, { headers: reqHeader });
    }

    getMostSellingProdCategry() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<MostSellingReportObject[]>(this.baseUrl + '/api/getMostSellingProductCategory', { headers: reqHeader });
    }

    getOfflineOnlineOrders() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<OnlineOfflineOrdersObject[]>(this.baseUrl + '/api/monthlyOfflineOnlineOrders', { headers: reqHeader });
        }
    getMonthlyOrdersUserCnt() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<OrderUserReportObject[]>(this.baseUrl + '/api/ordersUsersCountMonthly', { headers: reqHeader });
    }
    // getMonthlyUsersCnt() {
    //     var bearerToken = localStorage.getItem('bearerToken');
    //     var authhdr = 'Bearer ' + bearerToken;
    //     let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    //     return this.httpClient.get<UserReportObject[]>(this.baseUrl + '/api/requestUserCountMonthly', { headers: reqHeader });
    // }
    exportExcel() {

        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.baseUrl + '/api/orders/exportExcel', { headers: reqHeader, responseType: 'blob' as 'blob' });
    }
    stockout(data:StockOutByBarcode){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post(this.StockOutByBarcodeUrl, data, { headers: reqHeader });

    }
    getStockOut(orderId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Order>(this.getStockOutByOrderIdUrl + orderId, { headers: reqHeader });
    }
    getStockIn(orderId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Order>(this.getStockInByOrderIdUrl + orderId, { headers: reqHeader });
    }


}