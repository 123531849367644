<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-primary " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">
        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <!-- <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">

                                <li class="breadcrumb-item active" aria-current="page">My Profile</li>
                            </ol>
                        </nav> -->
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">

                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div>
                                        <span class="d-block"> <span class="label ">INCOME</span>
                                        </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                        <span class="sparkline-bar text-warning">
                                            <i class="ft-bar-chart-2"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row row-sm">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">

                            <div class="row d-flex">
                                <div class=" col-md-6 ml-auto mt-2">
                                    <h4 class="card-title mb-0">My Admin Profile</h4>
                                    <div class="clearfix"></div>
                                    <p class="sub-text">Manage Profile and Account Setting</p>
                                </div>

                                <div class=" col-md-6 ml-auto mt-2 text-right">

                                </div>
                            </div>
                            <hr>

                            <div class="container-fluid" *ngIf="user">
                                <div class="row">
                                    <div class="col-xl-4">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="profile-details text-center">
                                                    <img src="../../../assets/images/logo.jpg" alt=""
                                                        class="img-fluid img-90 rounded-circle">
                                                    <h5 class="f-w-600 mb-0">{{user.firstName}} {{user.lastName}}</h5>
                                                    <span>{{user.email}}</span>
                                                    <!--<div class="social">
                                                    <div class="form-group btn-showcase">
                                                        <button class="btn social-btn btn-fb d-inline-block"> <i
                                                                class="fa fa-facebook"></i></button>
                                                        <button
                                                            class="btn social-btn btn-twitter d-inline-block"><i
                                                                class="fa fa-twitter"></i></button>
                                                        <button
                                                            class="btn social-btn btn-linked d-inline-block mr-0"><i
                                                                class="fa fa-linkedin"></i></button>
                                                    </div>
                                                </div>-->
                                                </div>
                                                <hr>
                                                <!-- <div class="project-status">
                                                <h5 class="f-w-600">Employee Status</h5>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h6>Performance<span class="pull-right">80%</span></h6>
                                                        <div class="progress sm-progress-bar">
                                                            <div class="progress-bar bg-primary"
                                                                role="progressbar" style="width: 90%"
                                                                aria-valuenow="25" aria-valuemin="0"
                                                                aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h6>Overtime <span class="pull-right">60%</span></h6>
                                                        <div class="progress sm-progress-bar">
                                                            <div class="progress-bar bg-secondary"
                                                                role="progressbar" style="width: 60%"
                                                                aria-valuenow="25" aria-valuemin="0"
                                                                aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="media">
                                                    <div class="media-body">
                                                        <h6>Leaves taken<span class="pull-right">70%</span></h6>
                                                        <div class="progress sm-progress-bar">
                                                            <div class="progress-bar bg-danger"
                                                                role="progressbar" style="width: 70%"
                                                                aria-valuenow="25" aria-valuemin="0"
                                                                aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>-->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-8">
                                        <div class="card tab2-card">
                                            <div class="card-body">
                                                <ul class="nav nav-tabs nav-material" id="top-tab" role="tablist">
                                                    <li class="nav-item"><a class="nav-link active show"
                                                            id="top-profile-tab" data-toggle="tab" href="#top-profile"
                                                            role="tab" aria-controls="top-profile" aria-selected="true">
                                                            <i class="ft-user"></i> Profile</a>
                                                    </li>
                                                    <li class="nav-item"><a class="nav-link" id="setting-top-tab"
                                                            data-toggle="tab" href="#password" role="tab"
                                                            (click)="loadPasswordTab()" aria-controls="password"
                                                            aria-selected="false">
                                                            <i class="ft-settings"></i> Change Password</a>
                                                    </li>
                                                </ul>
                                                <div class=" tab-content" id="top-tabContent">
                                                    <div class="tab-pane fade active show" id="top-profile"
                                                        role="tabpanel" aria-labelledby="top-profile-tab">
                                                        <h5 class="f-w-600">Profile</h5>
                                                        <div class="profile-table">
                                                            <ng-container *ngIf="!edit">
                                                                <table
                                                                    class="table res-table table-striped admin-tbl reflow-table reflow-table-w-50">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>First Name:</td>
                                                                            <td>{{user.firstName}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Last Name:</td>
                                                                            <td>{{user.lastName}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Email:</td>
                                                                            <td>{{user.email}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Mobile Number:</td>
                                                                            <td>{{user.mobileno}}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Location:</td>
                                                                            <td>Malaysia</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div class="row text-left">
                                                                    <button class="btn btn-primary"
                                                                        (click)="enableEdit()">Edit</button>
                                                                </div>
                                                            </ng-container>

                                                            <form [formGroup]="userForm" *ngIf="edit && userForm">
                                                                <div class="row">
                                                                    <div class="form-group col-md-12">
                                                                        <label>First Name <span
                                                                                class="required">*</span></label>
                                                                        <input required="" class="form-control"
                                                                            name="name" type="text"
                                                                            formControlName="firstName">
                                                                    </div>
                                                                    <div class="form-group col-md-12">
                                                                        <label>Last Name <span
                                                                                class="required">*</span></label>
                                                                        <input required="" class="form-control"
                                                                            name="name" type="text"
                                                                            formControlName="lastName">
                                                                    </div>
                                                                    <div class="form-group col-md-12">
                                                                        <label>User Name<span
                                                                                class="required">*</span></label>
                                                                        <input required="" class="form-control"
                                                                            name="dname" type="text"
                                                                            formControlName="username">
                                                                    </div>
                                                                    <div class="form-group col-md-12">
                                                                        <label>Phone <span
                                                                                class="required">*</span></label>
                                                                        <input required="" class="form-control"
                                                                            name="phone" formControlName="mobileno">
                                                                    </div>

                                                                    <div class="form-group col-md-12">
                                                                        <label>Email Address <span
                                                                                class="required">*</span></label>
                                                                        <input required="" class="form-control"
                                                                            name="email" type="email"
                                                                            formControlName="email">
                                                                    </div>

                                                                    <div class="col-md-12 mt-4">
                                                                        <button type="submit" class="btn btn-success"
                                                                            name="submit" value="Submit"
                                                                            [disabled]="userForm.invalid"
                                                                            (click)="saveUser()">Save</button>
                                                                        <button type="submit"
                                                                            class="btn btn-danger ml-2" name="submit"
                                                                            value="Submit"
                                                                            (click)="createUserForm()">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="password" role="tabpanel"
                                                        aria-labelledby="setting-top-tab">

                                                        <form [formGroup]="resetPasswordForm" *ngIf="resetPasswordForm">
                                                            <div class="table-responsive profile-table">
                                                                <table
                                                                    class="table res-table table-striped admin-tbl reflow-table reflow-table-w-50">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Old Password:</td>
                                                                            <td><input type="password"
                                                                                    class="form-control"
                                                                                    placeholder="Old Password"
                                                                                    formControlName="oldPassword">
                                                                                <small class="error"><label
                                                                                        *ngIf="!resetPasswordForm.get('oldPassword').valid && resetPasswordForm.get('oldPassword').touched && resetPasswordForm.get('oldPassword').errors.required">
                                                                                        Please enter your Old Password
                                                                                        !!!</label></small>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>New Password:</td>
                                                                            <td> <input type="password"
                                                                                    class="form-control"
                                                                                    placeholder="New Password"
                                                                                    formControlName="password">
                                                                                <small class="error"><label
                                                                                        *ngIf="!resetPasswordForm.get('password').valid && resetPasswordForm.get('password').touched && resetPasswordForm.get('password').errors.required">
                                                                                        Please enter your new Password
                                                                                        !!!</label></small>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Confirm Password:</td>
                                                                            <td><input type="password"
                                                                                    class="form-control"
                                                                                    placeholder="Re-enter Password"
                                                                                    formControlName="confirmPassword">
                                                                                <small class="error"><label
                                                                                        *ngIf="!resetPasswordForm.get('confirmPassword').valid && resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('confirmPassword').errors.required">
                                                                                        Please re-enter new Password
                                                                                        !!!</label></small>
                                                                                <small class="error"><label
                                                                                        *ngIf="!resetPasswordForm.get('confirmPassword').valid && resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('confirmPassword').errors.notEquivalent">
                                                                                        Password does not
                                                                                        match</label></small>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <button class="btn btn-block btn-success rounded-pill"
                                                                    [disabled]="resetPasswordForm.invalid"
                                                                    (click)="resetPassword()">Submit</button>
                                                            </div>
                                                        </form>
                                                        <hr>

                                                        <!--<div class="account-setting deactivate-account">
                                                        <h5 class="f-w-600">Deactivate Account</h5>
                                                        <div class="row">
                                                            <div class="col">
                                                                <label class="d-block" for="edo-ani">
                                                                    <input class="radio_animated" id="edo-ani"
                                                                        type="radio" name="rdo-ani" checked="">
                                                                    I have a privacy concern
                                                                </label>
                                                                <label class="d-block" for="edo-ani1">
                                                                    <input class="radio_animated" id="edo-ani1"
                                                                        type="radio" name="rdo-ani">
                                                                    This is temporary
                                                                </label>
                                                                <label class="d-block mb-0" for="edo-ani2">
                                                                    <input class="radio_animated" id="edo-ani2"
                                                                        type="radio" name="rdo-ani" checked="">
                                                                    Other
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <button type="button"
                                                            class="btn btn-danger rounded-pill">Deactivate
                                                            Account</button>
                                                    </div>
                                                    <div class="account-setting deactivate-account">
                                                        <h5 class="f-w-600">Delete Account</h5>
                                                        <div class="row">
                                                            <div class="col">
                                                                <label class="d-block" for="edo-ani3">
                                                                    <input class="radio_animated" id="edo-ani3"
                                                                        type="radio" name="rdo-ani1" checked="">
                                                                    No longer usable
                                                                </label>
                                                                <label class="d-block" for="edo-ani4">
                                                                    <input class="radio_animated" id="edo-ani4"
                                                                        type="radio" name="rdo-ani1">
                                                                    Want to switch on other account
                                                                </label>
                                                                <label class="d-block mb-0" for="edo-ani5">
                                                                    <input class="radio_animated" id="edo-ani5"
                                                                        type="radio" name="rdo-ani1" checked="">
                                                                    Other
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <button type="button" class="btn btn-danger rounded-pill">Delete
                                                            Account</button>
                                                    </div>-->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>



<div *ngIf="success" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4><strong>Success</strong></h4>
                <p class="text-success"><strong>{{successMessage}}</strong> </p>


            </div>
        </div>
    </div>
</div>

<div *ngIf="error" id="fail-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4><strong>Sorry. Failure</strong></h4>
                <p class="text-danger"><strong>{{errorMessage}}</strong></p>
                <p></p>

            </div>
        </div>
    </div>
</div>