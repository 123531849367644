import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Faq } from 'src/app/model/faq';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private httpClient: HttpClient, private router: Router) { }
  baseUrl: string = environment.baseUrl;

  saveFaqUrl: string = this.baseUrl + '/api/addFAQ';
  updateFaqUrl: string = this.baseUrl + '/api/updateFAQById';
  deleteFaqUrl: string = this.baseUrl + '/api/deleteFAQById/';
  getAllFaqUrl: string = this.baseUrl + '/api/getAllFAQs';
  getFaqByIdUrl: string = this.baseUrl + '/api/getFAQById/';
  saveFaq(faq: Faq) {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.post(this.saveFaqUrl, faq, { headers: reqHeader });
  }

  updateFaq(faq: Faq) {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.put(this.updateFaqUrl, faq, { headers: reqHeader });
  }

  deleteFaq(faq) {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.delete(this.deleteFaqUrl + faq, { headers: reqHeader });
  }

  getAllFaq() {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.get(this.getAllFaqUrl, { headers: reqHeader });
  }

  getFaqbyId(faq) {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.get(this.getFaqByIdUrl + faq, { headers: reqHeader });
  }

}
