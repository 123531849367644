import { Component, OnInit } from '@angular/core';
import { TotalCount } from '../model/totalcount';
import { OrderService } from '../shared/services/order.service';
declare function resTable(): any;
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
 // styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  
  constructor(private orderService:OrderService) { }

  ngOnInit() {
    resTable();
    
  }
  
}
