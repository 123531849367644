
export class RatingAndReview {

    id: string;
    star: string;
    email: string;
    username: string;
    userId: string;
    reviewComment: String;
    productId: string;
    productName: string;
    replies: RatingAndReviewResponse[];
    createdBy: string;
    createdDate: Date;
    deletedFlag: Boolean;
    lastupdatedDate: Date;
    updatedBy: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}


export class RatingAndReviewResponse {

    id: string;
    email: String;
    username: string;
    userId: String;
    reviewComment: String;
    createdDate: Date;
    lastupdatedDate: Date;
    deletedFlag: Boolean;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}


