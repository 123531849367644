import { Component } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from './shared/services/authentication.service';
import { User } from './model/user';
import { SettingsService } from './shared/services/settings.service';
import { FilterInput } from './model/settings';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'vege';
  test() {
    var test = StripeCheckout.value;
  }

  footermenu: any;
  navigationSubscription: any;
  user: User;
  isAdmin: Boolean = false;

  constructor(private authenticationService: AuthenticationService, private router: Router, private settingService: SettingsService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }
  ngOnInit() { }

  initialiseInvites() {
    /*if (this.authenticationService.isLoggedIn()) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.authenticationService.getUserbyId(this.user.id).toPromise().then(
        response => {
          this.user = new User(response);
          this.authenticationService.setLoggedInData(this.user);
        });
    }*/
    if (this.authenticationService.isLoggedIn()) {
      this.user = JSON.parse(localStorage.getItem('user'));
    }
    this.getFilterInputs();
  }

  filterInput: FilterInput;
  getFilterInputs(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      this.settingService.getAllFilterInputs().toPromise().then(response => {
        let filterInputList: FilterInput[] = response;
        if (filterInputList && filterInputList.length > 0) {
          this.filterInput = filterInputList[0];
          localStorage.setItem("currency", this.filterInput.currency);
          localStorage.setItem("minOrder", this.filterInput.minOrder.toString());
        } else {
          localStorage.setItem("currency", "INR");
          localStorage.setItem("minOrder", "0");
        }
        resolve();
      }, error => {
        console.log(error);
        localStorage.setItem("currency", "INR");
        localStorage.setItem("minOrder", "0");
        reject();
      });
    });
    return promise;
  }
}
