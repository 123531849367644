import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Testimonial } from 'src/app/model/testimonial';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { TestimonialService } from 'src/app/shared/services/testimonial.service';

@Component({
  selector: 'app-add-edit-testimonial',
  templateUrl: './add-edit-testimonial.component.html',
  styleUrls: ['./add-edit-testimonial.component.css']
})
export class AddEditTestimonialComponent implements OnInit {
  regiForm: FormGroup;
  user: User;
  testimonial: Testimonial;
  myDate = new Date();
  idTestimonial: any;
  loading: boolean = false;
  success: boolean = false;
  errorPopup: boolean = false;
  allCounts: any;
  income: number;
  currency: string = "";

  constructor(private fb: FormBuilder,
    private serviceTestimonial: TestimonialService,
    private orderService: OrderService,
    private authenticationService: AuthenticationService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.route.snapshot.queryParams['key']) {
      this.loading = true;
      this.route.queryParams.subscribe(params => {

        this.idTestimonial = params['key'];


      })
    }
    this.currency = localStorage.getItem('currency');
    this.testimonialDetails();
    this.getTotalCounts();
  }
  testimonialDetails() {
    this.loading = true;
    if (!this.idTestimonial) {
      this.serviceTestimonial.getAllTestimonials().subscribe(testimonials => {
        this.testimonial = testimonials as Testimonial;
        this.initalizeTestimonial(null);
        this.loading = false;
      }, error => {
        this.loading = false;
      }
      );
    }

    else {
      this.loading = true;
      this.serviceTestimonial.getTestimonialByTestimonialId(this.idTestimonial)
        .subscribe((testimonials) => {
          this.testimonial = testimonials as Testimonial;
          this.initalizeTestimonial(testimonials);
          this.loading = false;

        }, error => {
          this.loading = false;
        }
        )
    }
  }
  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }
  initalizeTestimonial(testimonials) {

    this.regiForm = this.fb.group({
      'name': [testimonials ? testimonials.name : null, Validators.required],
      'email': [testimonials ? testimonials.email : null, Validators.required],
      'comment': [testimonials ? testimonials.comment : null, Validators.required],

    });
  }
  onSubmit(form) {


    if (this.regiForm.valid) {
      let testimonial: Testimonial = {
        id: this.idTestimonial ? this.idTestimonial : '',
        name: form.name,
        email: form.email,
        comment: form.comment,

        createdDate: this.myDate,
        deletedFlag: false,
        lastupdatedDate: this.myDate,

      }
      if (!this.idTestimonial) {
        this.loading = true;
        this.serviceTestimonial.saveTestimonial(testimonial).toPromise().then(
          response => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {
              this.success = false;
              this.router.navigate(['/admin/testimonialList']);
            }, 2000);


          }, error => {
            this.loading = false;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;
            }, 2000);

          }
        )

      }
      else {
        this.loading = true;
        this.serviceTestimonial.updateTestimonial(testimonial).toPromise().then(
          response => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {

              this.success = false;
              this.router.navigate(['/admin/testimonialList']);
            }, 2000);


          }, error => {
            this.loading = false;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;

            }, 2000);

          }

        )
      }
    }
  }
  cancel() {
    this.loading = true;
    this.router.navigate(['/admin/testimonialList']);
  }


}
