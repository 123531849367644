<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">
        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">

                                <li class="breadcrumb-item active" aria-current="page">Inventory List</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">

                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row row-sm">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row d-flex">
                            <div class=" col-md-6 mt-2">
                                <h4 class="card-title mb-0">Manage Wholesale Inventory</h4>
                                <div class="clearfix"></div>
                                
                            </div>

                           
                        </div>
                        <hr>

                        <div class="card-header text-right">
                            <div class="row d-flex">
                                <div class="input-group col-md-6 mr-auto">
                                    <input type="text" class="form-control" placeholder="Search"
                                        [(ngModel)]="categoryFilter">
                                </div>
                            </div>
                        </div>

                        <table class="table res-table table-striped admin-tbl reflow-table ">
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Package Quantity</th>
                                    <th>Available Stock</th>
                                    <th>Total Sold Quantity</th>
                                    <th>Update Sold Quantity</th>
                                    <!-- <th>Action</th> -->
                                </tr>
                            </thead>
                            <tbody *ngIf="!allInventories || allInventories.productsInWholesaleOrd.length == 0">
                                <tr>
                                    <td colspan="5" class="text-center">
                                        <h6 class="text-center">No inventory is created</h6>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="allInventories">
                                
                                <tr
                                    *ngFor="let inventory of allInventories.productsInWholesaleOrd| filter: categoryFilter | paginate: { itemsPerPage: count, currentPage: p } let i=index">

                                    <td data-th="Product Id">{{inventory.productName}}</td>
                                    <td data-th="Package Quantity">{{inventory.packageQuantity.packageQuantity}}</td>
                                    <td data-th="Available Stock">{{inventory.stockInQuantity}} </td>
                                    <td data-th="Stock Out Quantity">{{inventory.stockOutQuantity}}</td>
                                    <td data-th="Update Sold Quantity"><button (click)="openUpdateQuantity(i)" class="btn btn-outline-primary btn-sm auto ml-2 rounded-pill" >Update Quantity</button></td>

                                    <!-- <td data-th="Action">
                                        <a routerLink="/admin/add-editCategory" [queryParams]="{ key: category.id }"
                                            class="btn btn-sm btn-outline-primary auto" data-tooltip="tooltip" title=""
                                            data-original-title="Disable"><i class="la la-edit"></i></a>
                                        <button (click)="selectedcategory(category)" class=" btn btn-sm btn-outline-danger auto"
                                            data-tooltip="tooltip" title="" data-original-title="Delete"><i
                                                class="la la-trash"></i></button>
                                    </td> -->
                                </tr>
                            

                            </tbody>
                        </table>

                        <div class="row d-flex">
                            <div class=" col-md-12 ml-auto mt-2 text-right">
                                <pagination-controls directionLinks="true" autoHide="true" responsive="true"
                                    (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showUpdateQuantity" class="modal fade show" id="ModalFailed" style="display:block!important;"
        tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered modal-md">
            <div class="modal-content modal-confirm">
                <div class="modal-header">
                    <h4>Update Quantity</h4>
                    <button type="button" (click)="showUpdateQuantity = false; loading = false;" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body text-center">
                    <ng-container >
                    <form [formGroup]="registerForm" (submit)="stockOut()">
                        <div class="row d-flex mb-3 mt-2 col-md-12">
                            <div class="input-group col-md-6 mr-auto ml-auto ">
                                <input type="text" class="form-control" formControlName="soldQuantity" placeholder="Sold Quantity">
                            </div>
                            
                        </div>
                        
                        
                        <button type="submit" class="btn btn-success " [disabled]="!registerForm.valid">Update</button>
                        <button class="btn btn-danger ml-3" (click)="showUpdateQuantity = false; loading = false;" data-dismiss="modal">Close</button>
                    </form>
                    </ng-container>
                    
                   
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="success" id="email-verifiacation-modal" class="modal fade show"
    style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
               
                <h4 >Sold Quantity Updated</h4>
                <p class="text-success">Successfully </p>


            </div>
        </div>
    </div>
</div>
<div *ngIf="errorPopup" id="fail-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Sorry</h4>
                <p class="text-danger">Some went wrong. Try again</p>
                <p></p>

            </div>
        </div>
    </div>
</div>