import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/model/user';
import { CartService } from 'src/app/shared/services/cart.service';
import { Category } from '../model/category';
import { ShoppingCartRequest } from '../model/shoppingCartRequest';
import { ShoppingCartEntries } from '../model/shoppingCartResponse';
import { myWishlist, WishListResponse } from '../model/wishistResponse';
import { WishListRequest } from '../model/wishlistRequest';
import { CartHelpers } from '../shared/helper/cart.helper';
import { WishListHelpers } from '../shared/helper/wishlist.helper';
import { AuthenticationService } from '../shared/services/authentication.service';
import { CategoryService } from '../shared/services/category.service';
import { ProductService } from '../shared/services/product.service';
import { WishService } from '../shared/services/wishlist.service';
import { Product } from '../model/product';
import { FilterInput, PriceInput } from '../model/settings';
import { SettingsService } from '../shared/services/settings.service';
import { SearchInput, PriceInputSearch } from '../model/searchInput';

@Component({
  selector: 'app-shopproducts',
  templateUrl: './shopproducts.component.html',
  styleUrls: ['./shopproducts.component.css']
})
export class ShopproductsComponent implements OnInit {
  counter: number = 1;
  user: User;
  message: string;
  action: string;
  p: number = 1;
  count: number = 8;
  categories: Category;
  allproducts: Product[];
  categoryKey: string;
  allCategories: any;
  value: any;
  productQuickView: any;
  successCart: boolean = false;
  successWishlist: boolean = false;
  loading: boolean = false;
  wishlist: any;
  filterInput: FilterInput;
  selectedCategories: Category[] = [];
  selectedPriceRanges: PriceInputSearch[] = [];
  selectedPriceRangeIds: string[] = [];
  currency: string = "";
  sortingCode: string;

  constructor(
    private serviceWishlist: WishService,
    private cartService: CartService,
    private serviceCategory: CategoryService,
    private serviceProduct: ProductService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private router: Router,
    private authenticationService: AuthenticationService,
    private settingService: SettingsService
  ) { }

  private cartHelper: CartHelpers = new CartHelpers(this.cartService, this.serviceProduct);
  private wishListHelper: WishListHelpers = new WishListHelpers(this.serviceWishlist);

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      //this.refresh();
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
    }
      this.serviceCategory.getAllCategories().subscribe(Category => {
        this.allCategories = Category as Category[];

        if (this.route.snapshot.queryParams['key']) {
          this.route.queryParams.subscribe(params => {
            this.categoryKey = params['key'];
            this.allCategories.forEach(category => {
              if (category.id == this.categoryKey) {
                this.selectedCategories.push(category);
              }
            });
          })
        } else {
          this.categoryKey = 'all';
          this.allCategories.forEach(category => {
            this.selectedCategories.push(category);
          });
        }
        this.getFilterInputs().then(() => {
          this.getProductsList();
        }).catch(() => {
          this.getProductsList();
        });
      }, error => {
        console.log(error);
        this.loading = false;
      });

    
  }

  getWishlist() {
    this.serviceWishlist.getWishList(this.user.id).toPromise().then(
      response => {

      }, error => {
        console.log(error);
      })
    //end  
  }

  getProductsList() {
    if (this.categoryKey == 'all') {
      this.loading = true;
      this.serviceProduct.getAllProducts().subscribe(
        product => {
          this.allproducts = product;
          this.loading = false;
        }, error => {
          this.loading = false;
        });
    } else {
      this.loading = true;
      this.serviceProduct.getProductsByCatgoryId(this.categoryKey).subscribe(
        product => {
          this.allproducts = product;
          this.loading = false;
        }, error => {
          this.loading = false;
        });
    }
  }

  incrementQuantity() {
    this.counter = this.counter + 1;
  }

  decrementQuantity() {
    this.counter = this.counter - 1;
  }

  addToCart(product) {
    this.loading = true;
    this.cartHelper.checkCartandUpdate(product, this.counter).then(updatedCartEntries => {
      let cart: ShoppingCartRequest = {
        shoppingCart: updatedCartEntries,
        userId: this.user.id
      }
      this.cartService.addToCart(cart).toPromise()
        .then(() => {
          this.loading = false;
          this.successCart = true;
          this.refresh();
          // this.getProductsList();
        }, error => {
          this.loading = false;
        });
    });
  }

  addToWishList(product) {
    this.loading = true;
    this.wishListHelper.checkWishListandUpdate(product)
      .then(updatedmyWishlist => {
        let wishList: WishListRequest = {
          productsForWishList: updatedmyWishlist,
          userId: this.user.id
        }
        this.serviceWishlist.addToWishlist(wishList).toPromise()
          .then(() => {
            this.loading = false;
            this.successWishlist = true;
          }, error => {
            this.loading = false;
          });
      });
  }

  closeCart() {
    this.successCart = false;
  }

  closeWishlist() {
    this.successWishlist = false;
  }

  viewCart() {
    this.router.navigate(['/shopping-cart']);
  }

  viewWishlist() {
    this.router.navigate(['/wishlist']);
  }

  productQuickview(product) {
    this.resetCounter();
    this.productQuickView = product;
    this.productQuickView.discount = (this.productQuickView.productCost * (this.productQuickView.offer / 100));
    this.productQuickView.discountPrice = this.productQuickView.productCost - this.productQuickView.discount;
  }

  resetCounter() {
    this.counter = 1;
  }

  refresh() {
    this.router.navigateByUrl('/shop-products', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/shop-products']);
    });
  }

  getFilterInputs(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      this.settingService.getAllFilterInputs().toPromise().then(response => {
        let filterInputList: FilterInput[] = response;
        if (filterInputList && filterInputList.length > 0) {
          this.filterInput = filterInputList[0];
          this.currency = this.filterInput.currency;
          if (this.filterInput && this.filterInput.sortInputs && this.filterInput.sortInputs.length > 0) {
            this.sortingCode = this.filterInput.sortInputs[0].code;
          }
          if (this.filterInput && this.filterInput.priceInputs && this.filterInput.priceInputs.length > 0) {
            this.filterInput.priceInputs.forEach(price => {
              this.selectedPriceRangeIds.push(price.priceRangeName);
              this.selectedPriceRanges.push({
                minPrice: price.minValue,
                maxPrice: price.maxValue
              })
            });
          }
        }
        resolve();
      }, error => {
        console.log(error);
        reject();
      });
    });
    return promise;
  }

  selectCategory(selectedCateObj: Category) {
    if (this.selectedCategories && this.selectedCategories.length > 0 && this.selectedCategories.includes(selectedCateObj)) {
      this.selectedCategories.splice(this.selectedCategories.indexOf(selectedCateObj), 1);
    } else {
      this.selectedCategories.push(selectedCateObj);
    }
    this.searchProducts();
  }

  selectPriceRange(priceInput: PriceInput) {
    let priceObj = {
      minPrice: priceInput.minValue,
      maxPrice: priceInput.maxValue
    }
    if (this.selectedPriceRangeIds && this.selectedPriceRangeIds.length > 0 && this.selectedPriceRangeIds.includes(priceInput.priceRangeName)) {
      this.selectedPriceRangeIds.splice(this.selectedPriceRangeIds.indexOf(priceInput.priceRangeName), 1);
      this.selectedPriceRanges.forEach(priceRange => {
        if (priceRange.minPrice == priceInput.minValue && priceRange.maxPrice == priceInput.maxValue) {
          this.selectedPriceRanges.splice(this.selectedPriceRanges.indexOf(priceRange), 1);
        }
      });
    } else {
      this.selectedPriceRangeIds.push(priceInput.priceRangeName);
      this.selectedPriceRanges.push(priceObj);
    }
    this.searchProducts();
  }

  selectSorting(event) {
    this.sortingCode = event.target.value;
    this.searchProducts();
  }

  searchProducts() {
    if (this.selectedCategories && this.selectedPriceRanges) {
      this.allproducts = [];
      this.loading = true;
      let searchInput: SearchInput = new SearchInput();
      searchInput.categories = this.selectedCategories;
      searchInput.priceInput = this.selectedPriceRanges;
      searchInput.code = this.sortingCode;
      this.serviceProduct.searchProducts(searchInput).toPromise().then(response => {
        this.allproducts = response;
        this.loading = false;
      }, error => {
        console.log(error);
        this.loading = false;
      })
    }
  }

}
