import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import '@google-pay/button-element';
import { CartService } from 'src/app/shared/services/cart.service';
import { environment } from 'src/environments/environment';
import { BillingDetails, Order, ShippingDetails } from '../model/order';
import { ShoppingCartEntries, ShoppingCartResponse } from '../model/shoppingCartResponse';
import { User } from '../model/user';
import { AuthenticationService } from '../shared/services/authentication.service';
import { OrderService } from '../shared/services/order.service';
import { PaymentService } from '../shared/services/payment.service';
import { Router } from '@angular/router';
import { DataHelper } from '../shared/helper/data.helper';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})

export class CheckoutComponent implements OnInit {

  regiForm: FormGroup;
  user: User;
  checkout: any[];
  shown: any;
  total: number = 0;
  productShopping: ShoppingCartResponse;
  shoppingList: ShoppingCartEntries[];
  billingDetails: BillingDetails;
  myDate = new Date();
  shippingDetails: ShippingDetails;
  handler: any;
  tokenObject: any;
  loading: boolean = false;
  successModal: boolean = false;
  successMessage: string = '';
  shippingAddress: FormGroup;
  order: Order;
  currency: string;
  minOrder: number = 0;

  constructor(private fb: FormBuilder, private orderService: OrderService,
    private authenticationService: AuthenticationService, private cartService: CartService,
    private paymentservice: PaymentService, private router: Router, private dataHelper: DataHelper) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.currency = localStorage.getItem('currency');
      this.minOrder = parseInt(localStorage.getItem('minOrder'));
      this.dataHelper.setOrderObj("");
      this.getUserDetails().then(() => {
        this.refreshCart().then(() => {
          this.createOrderObject();
          this.loading = false;
        }, error => {
          this.loading = false;
        });
      }).catch(error => {
        this.loading = false;
      })
    }
  }

  getUserDetails(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.authenticationService.getUser(this.user.username).toPromise().then(
        response => {
          this.user = response;
          this.authenticationService.setLoggedInData(this.user);
          resolve();
        }, error => {

          reject(error);
        }
      )
    });
    return promise;
  }

  refreshCart(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      this.cartService.getCartList(this.user.id).subscribe(Cart => {
        this.productShopping = Cart;
        this.shoppingList = this.productShopping.shoppingCartEntries;
        resolve();
      }, error => {
        reject();
      });
    });
    return promise;
  }

  onPay() {
    this.handler = StripeCheckout.configure({
      key: environment.stripeKey,
      locale: 'auto',
      token: token => {
        this.tokenObject = token;
        let data = {
          token: token.id,
          amount: this.total
        }
        this.paymentservice.chargeStripe(data).toPromise().then(
          response => {
            this.handler.close();
          }, error => {

            this.handler.close();
          }
        )
      }
    })
  }

  selectedShipping(shipAddr) {
    this.order.shippingDetails = shipAddr;
  }

  createOrderObject() {
    this.order = {
      id: null,
      sequenceName: null,
      orderNumber: null,
      cartAmount: parseFloat(this.productShopping.totalPrice),
      deliveryCharge: 0.00,
      total_Amount_to_Pay: (parseFloat(this.productShopping.totalPrice) + 0.00),
     
      payment_method: null,
      paymentDetails: null,
      payment_method_title: null,
      shippingDetails: null,
      billingDetails: this.user.billingAddress,
      shoppingCartEntry: this.shoppingList,
      userId: this.user.id,
      status: 'CREATED',
      orderDateForEmail: null,
      lastupdatedDate: null,
      createdDate: this.myDate,
      deletedFlag: false,
      reasonForCancellation: null,
      modeOfOrder: 'ONLINE',
      createdby:null,
      updatedby:null,
      paymentStatus:null,
      paymentFailureReason:null,
     paymentAdvanceAmt:0,
     stockOutStatus:false,
     stockInStatus:false,
      wholesaleOrder:false
    }

  }

  onSubmit() {
    if (this.order && this.order.shippingDetails && this.order.billingDetails) {
      this.order.billingDetails.firstName = this.user.firstName;
      this.order.billingDetails.lastName = this.user.lastName;
      this.order.shippingDetails.firstName = this.user.firstName;
      this.order.shippingDetails.lastName = this.user.lastName;
      this.dataHelper.setOrderObj(JSON.stringify(this.order));
      this.router.navigate(['/payment']);
    }
  }
  refresh() {
    this.router.navigateByUrl('/checkout', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/checkout']);
    });
  }
}

