import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { BillingDetails, ShippingDetails, Order, PaymentDetails } from 'src/app/model/order';
import { PackageQuantities } from 'src/app/model/product';
import { ShoppingCartRequest } from 'src/app/model/shoppingCartRequest';
import { ShoppingCartEntries } from 'src/app/model/shoppingCartResponse';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { StringDecoder } from 'string_decoder';
declare function reflowTable(): any;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  user: User;
  allOrders: any;
  orderId: any;
  billingAddress: any;
  userorder: Order;
  shippingDetails: ShippingDetails[];
  shippingAddress: any;
  shoppingList: ShoppingCartEntries[];
  total: number;
  loading: boolean = false;
  @ViewChild('htmlData') htmlData: ElementRef;
  allCounts: any;
  income: number;
  changedOrder: string = "ALL";
  ordersFilter: string;
  cartTotal: number;
  deliveryCharge: number;
  cancelComments: string;
  currency: String;
  registerForm: FormGroup;
  barcodeAssigned: any;
  quantityAssigned: any;

  constructor(private authenticationService: AuthenticationService, private fb: FormBuilder,
    private orderService: OrderService, private router: Router,
    private productService: ProductService, private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.route.queryParams.subscribe(params => {
        var id = params['key'];
        this.loaddetails(id);
      });
      this.getTotalCounts();
    }
  }
  loaddetails(id){
    this.orderService.getOrderDetails(id).subscribe(response=>{
      this.loading=false;
      console.log(response);
      this.address(response);
    })
  }
  getOrderlist() {
    this.loading = true;
    this.allOrders = [];
    this.orderService.getAllOrders().subscribe(response => {
      this.allOrders = response;
      reflowTable();
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  getOrdersByStatusList(status) {
    this.loading = true;
    this.allOrders = [];
    this.orderService.getOrdersByStatus(status).subscribe(response => {
      this.allOrders = response;
      reflowTable();
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
  productID:string[]=[];
  productAssign:any[]=[];
  address(userorder) {
    this.userorder = userorder;
    this.total = this.userorder.total_Amount_to_Pay;
    this.cartTotal = this.userorder.cartAmount;
    this.deliveryCharge = this.userorder.deliveryCharge;
    this.billingAddress = this.userorder.billingDetails;
    this.shippingAddress = this.userorder.shippingDetails;
    this.shoppingList = this.userorder.shoppingCartEntry;
    if(this.shoppingList.length>0){
      var groupByAssigned = {};

    this.shoppingList.forEach(function (a) {
      groupByAssigned [a.productId] = groupByAssigned [a.productId] || [];
      groupByAssigned [a.productId].push({ assigned: a.assignPackageQuantity });
});

console.log(groupByAssigned);
    }
  }


 
  
  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }

  selectedOrderStatus: string;
  errorModal: Boolean = false;
  successModal: Boolean = false;

  updateOrder() {
    if (this.selectedOrderStatus && this.selectedOrderStatus != "") {
      this.userorder.status = this.selectedOrderStatus;
      this.userorder.reasonForCancellation = this.cancelComments;
      this.loading = true;
      this.orderService.updateOrder(this.userorder.id, this.userorder).toPromise().then(
        response => {
          this.selectedOrderStatus = null;
          this.loading = false;
          this.successModal = true;
          this.getOrderlist();
          setTimeout(() => {
            this.successModal = false;
          }, 2000);
        }, error => {
          this.loading = false;
          this.selectedOrderStatus = null;
          this.getOrderlist();
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
          }, 2000);
        }
      )
    }
  }
  selectedPackageQuantity:PackageQuantities;
  showBarcodeScanModal:boolean=false;
  selectedProductId:string;
  selectedOrderQuantity:number;
  assigned:boolean=false;
  stockAssigned:boolean=false;
  selectedShoppingcart:ShoppingCartEntries;
  selectedIndex:number;
  
  openBarcodeScanner(index) {
    this.loading = true;
    this.selectedIndex=index;
    this.selectedPackageQuantity = this.shoppingList[index].packageQuantity;
    this.selectedProductId=this.shoppingList[index].productId;
    this.selectedOrderQuantity=this.shoppingList[index].quantity;
    this.selectedShoppingcart=this.shoppingList[index];
    this.showBarcodeScanModal = true;
   
  }
  closeBarcodeModal() {
    this.showBarcodeScanModal = false;
    this.loading = false;
    
  }
 stockOutForm(){
    this.registerForm = this.fb.group({
      barcode: new FormControl(''),
      packageQuantity: new FormControl(this.selectedPackageQuantity.packageQuantity),
      productId: new FormControl(this.selectedProductId),
      quantity: new FormControl(''),
      
    });
  
 }
 stockUpdated:any[]=[];
 stockOut(){
  this.loading=true;
  
  this.orderService.stockout(this.registerForm.value).subscribe(response=>{
    this.showBarcodeScanModal = false;
    
    this.loading = false;
    this.assigned=true;
  },error=>{
    this.loading=false;
  })
 }
 allStockout(){
   this.orderService.getStockOut(this.userorder.id).subscribe(response=>{
     console.log(response);
     this.loaddetails(this.userorder.id);
   })
  
 }
 stockInAll(){
  this.orderService.getStockIn(this.userorder.id).subscribe(response=>{
    console.log(response);
    this.loaddetails(this.userorder.id);
  })
}
 //====================payment=====//
 paymentStatus: string = "";
 amountPaid: number;
 paymentField: boolean = false;
 paymentMethod: string = "";
 card: boolean = false;
  cheque: boolean = false;
  cardBrand: string = "";
  fourDigit: string;
  chequeNo: string;
  paymentdetails: PaymentDetails;
 selectedPayment(e) {
  this.paymentStatus = e;
  this.amountPaid = 0;
  if (e == 'PAID') {
    this.paymentField = true;
    this.amountPaid = this.userorder.total_Amount_to_Pay;
  } else if (e == 'ADVANCE_PAID') {
    this.paymentField = true;
  } else {
    this.paymentField = false;
  }
}
selectedMethod(e) {
  this.paymentMethod = e;
  if (e == 'CARD') {
    this.card = true;
    this.cheque = false;
  } else if (e == 'CHEQUE') {
    this.cheque = true;
    this.card = false;
  } else {
    this.card = false;
    this.cheque = false;
  }
}
receipt:boolean=false;
  gpay:boolean=false;
  paytm:boolean=false;
  
updatePaymentStatus(){
  this.paymentdetails = {
    brand: this.cardBrand,
    funding: null,
    id: null,
    last4: this.fourDigit,
    expMonth: null,
    expYear: null,
    chequeNo: this.chequeNo,
    paymentReceiptName:null,
    paymentReceiptUrl:null,
    bankTxnId:null,
    gatewayName: null
  }
  this.userorder.paymentDetails=this.paymentdetails;
  this.userorder.payment_method=this.paymentMethod;
  this.userorder.paymentStatus=this.paymentStatus;
  this.userorder.paymentAdvanceAmt=this.amountPaid;  
   
  this.orderService.updateOrder(this.userorder.id, this.userorder).toPromise().then(
    response => {    
      this.loading = false;     
      this.getOrderlist();
         
    }, error => {
      this.loading = false;     
      this.getOrderlist();
      
    }
  )
  }
  ////////////end payment//////////////

  //==================Assign Stock===================//
  assigningBarCode:string="";
  assigningQuantity:number=0;
  errorMessage:string="";
  error:boolean=false;
  assignPackage(){
    if(this.selectedPackageQuantity.barcode != this.assigningBarCode){
      this.errorMessage="You have assigned wrong Barcode";
      this.error=true;
    }else if(this.selectedOrderQuantity!=this.assigningQuantity){
      this.errorMessage="You have assigned wrong Quantity";
      this.error=true;
    }else{
      this.error=false;
      this.showBarcodeScanModal=false;
      this.userorder.shoppingCartEntry[this.selectedIndex].assignPackageQuantity=true;
      this.orderService.updateOrder(this.userorder.id, this.userorder).toPromise().then(
        response => {    
          this.loading = false;     
          this.getOrderlist();
             
        }, error => {
          this.loading = false;     
          this.getOrderlist();
          
        }
      )
    }
  }
}
