import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { CouponCode } from 'src/app/model/coupon';

@Injectable()
export class CouponService {

    constructor(private httpClient: HttpClient, private router: Router) { }

    baseUrl: string = environment.baseUrl;

    saveCouponUrl: string = this.baseUrl + '/api/createCoupon';
    updateCouponUrl: string = this.baseUrl + '/api/updateCoupon?id=';
    deleteCouponUrl: string = this.baseUrl + '/api/deleteCoupon?id=';
    getAllCouponUrl: string = this.baseUrl + '/api/getAllCoupons';
    getCouponByIdUrl: string = this.baseUrl + '/api/getCoupon?id=';


    saveCoupon(coupon: CouponCode) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post(this.saveCouponUrl, coupon, { headers: reqHeader });
    }

    updateCoupon(coupon: CouponCode) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.put(this.updateCouponUrl + coupon.id, coupon, { headers: reqHeader });
    }

    deleteCoupon(coupon) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.delete(this.deleteCouponUrl + coupon, { headers: reqHeader });
    }

    getAllCoupon() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.getAllCouponUrl, { headers: reqHeader });
    }

    getCouponByCouponId(couponId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.getCouponByIdUrl + couponId, { headers: reqHeader });
    }



}