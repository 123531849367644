<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-primary " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">

        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="d-flex flex-column flex-md-row align-items-center text-center text-md-left">

                <div class="breadcrumb-header justify-content-between">
                    <div class="row w-100">
                        <div class="col-md-8">
                            
                        </div>
                        <div class="col-md-4 ml-auto">
                            <div
                                class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                                <div class="row w-100">
                                    <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                        <div> <span class="d-block"> <span class="label"></span> </span>
                                            <span class="value"> </span>
                                        </div>
                                        <div class="ml-3 mt-0">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                        <div>
                                            <span class="d-block"> <span class="label ">INCOME</span>
                                            </span>
                                            <span class="value">{{currency}} {{income}} </span>
                                        </div>
                                        <div class="ml-3 mt-0">
                                            <span class="sparkline-bar text-warning">
                                                <i class="ft-bar-chart-2"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row row-sm">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-0" >Add User</h4>
                            
                            <p class="sub-text"></p>
                            <hr>
                            <section class="inner-section-gap" *ngIf="registerForm">
                                <form [formGroup]="registerForm" (submit)="registerUser()">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                            <label class="label">User Type</label>
                                            <i class="ft-user"></i>
                                            <select formControlName="userType" class="form-control transparent-tb" (change)=selectedUserType($event.target.value)>
                                                <option value="WHOLESALE">WHOLESALER</option>
                                                <option value="ADMIN">ADMIN</option>
                                            </select>
                                            
                                        </div>
                                        </div>
                                    <div class="row-sm">
                                        <div class="justify-content-left row m-auto my-auto">
                                            
                                            
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                                <label class="label">First Name</label>
                                                <i class="ft-user"></i>
                                                <input type="text" class="form-control transparent-tb "
                                                    placeholder="First Name" formControlName="firstName">
                                                    <small class="form-text text-danger"
                                                    *ngIf="!registerForm.get('firstName').valid && registerForm.get('firstName').touched">
                                                    Please enter the First Name !!!</small>
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                                <label class="label">Last Name</label>
                                                <i class="ft-user"></i>
                                                <input type="text" class="form-control transparent-tb"
                                                    placeholder="Last Name" formControlName="lastName">
                                                
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                                <label class="label">Mobile Number</label>
                                                <i class="ft-phone-call"></i>
                                                <input type="number" class="form-control transparent-tb"
                                                    placeholder="Mobile Number" formControlName="mobileno">
                                                    <small class="error"><label
                                                        *ngIf="!registerForm.get('mobileno').valid && registerForm.get('mobileno').touched">
                                                        Please enter the Mobile number !!!</label></small>
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                                <label class="label">Email</label>
                                                <i class="ft-mail"></i>
                                                <input type="email" class="form-control transparent-tb"
                                                    placeholder="Email" formControlName="email">
                                                    <small class="error"><label
                                                        *ngIf="!registerForm.get('email').valid && registerForm.get('email').touched">
                                                        Please enter the Email !!!</label></small>
                                            </div>
                                           
                                            <ng-container formGroupName="billingAddress" *ngIf="addressForm">
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form" >
                                                <label class="label">Address Line 1</label>
                                                <i class="ft-map-pin"></i>
                                                <input type="text" class="form-control transparent-tb"
                                                    placeholder="Address 1" type="text" formControlName="address_1">
                                               
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form" >
                                                <label class="label">Address Line 2</label>
                                                <i class="ft-home"></i>
                                                <input type="text" class="form-control transparent-tb"
                                                    placeholder="Address 2" type="text" formControlName="address_2">
                                               
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form" >
                                                <label class="label">City</label>
                                                <i class="ft-home"></i>
                                                <input type="text" class="form-control transparent-tb"
                                                    placeholder="City" type="text" formControlName="city">
                                               
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form" >
                                                <label class="label">State</label>
                                                <i class="ft-home"></i>
                                                <input type="text" class="form-control transparent-tb"
                                                    placeholder="State" type="text" formControlName="state">
                                               
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form" >
                                                <label class="label">Country</label>
                                                <i class="ft-home"></i>
                                                <input type="text" class="form-control transparent-tb"
                                                    placeholder="Country" type="text" formControlName="country">
                                               
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form" >
                                                <label class="label">Postal Code</label>
                                                <i class="ft-map"></i>
                                                <input type="text" class="form-control transparent-tb"
                                                    placeholder="Postal Code" type="text" formControlName="postcode">
                                               
                                            </div>
                                        </ng-container>
                                            <div class="spacer-30"></div>

                                            <div class="mt-4 text-center col-md-4 m-auto">
                                                <button routerLink="/admin/user-list"
                                                    class="btn  btn-danger btn-sm rounded-pill mr-2">Cancel
                                                </button>
                                                <button type="submit" 
                                                    
                                                    class=" btn btn-primary btn-sm rounded-pill ml-3"
                                                    [disabled]="!registerForm.valid">CREATE USER </button>
                                                
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </section>

                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="successMessage" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
                <div class="modal-dialog  modal-dialog-centered modal-md">
                    <div class="modal-content modal-confirm">
                        <div class="modal-header">
                            <div class="icon-box">
                                <i class="ft-check"></i>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body text-center">
                            <h6><strong>Thank you for registering your account.</strong></h6>
                            <p class="text-success"><strong>You can now login and enjoy shopping with us.</strong></p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div *ngIf="errorMessage" id="fail-modal" class="modal fade show " style="display:block!important;">
                <div class="modal-dialog modal-dialog-centered modal-md">
                    <div class="modal-content modal-reject ">
                        <div class="modal-header">
                            <div class="icon-box">
                                <i class="ft-alert-triangle"></i>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body text-center">
                            <h6><strong>{{errorMessageHdr}}</strong></h6>
                            <p class="text-danger"><strong>{{errorMessageStr}}</strong></p>
                        </div>
                    </div>
                </div>
            </div>