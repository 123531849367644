export class Enquiry{

    comments: string;
    contactNumber: string;
    contactUsStatus: string;
    createdDate: Date;
    deletedFlag: Boolean;
    email: string;
    id: string;
    lastUpdatedDate: Date;
    message: string;
    subject: string;
    username: string;
    
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
    }