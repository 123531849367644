import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Product } from 'src/app/model/product';

@Injectable()
export class ProductService {

    constructor(private httpClient: HttpClient, private router: Router) { }

    baseUrl: string = environment.baseUrl;

    saveProductUrl: string = this.baseUrl + '/api/admin/createProducts';
    updateProductUrl: string = this.baseUrl + '/api/admin/updateProduct?id=';
    deleteProductUrl: string = this.baseUrl + '/api/admin/deleteProduct?id=';
    getAllProductUrl: string = this.baseUrl + '/api/getAllProducts';
    getAllProductForHomePageUrl: string = this.baseUrl + '/api/home/getAllProducts';
    getProductByIdUrl: string = this.baseUrl + '/api/getProduct?id=';
    getProductByCategoryIdUrl: string = this.baseUrl + '/api/getProducts/categoryId?categoryId=';
    getProductImageIdUrl: string = this.baseUrl + '/api/retrieveProductImage/';
    getBestSellingProductForHomePageUrl:string=this.baseUrl+'/api/getBestSellingProducts';
    saveProduct(formData) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post(this.saveProductUrl, formData, { headers: reqHeader });
    }

    updateProduct(productId, formData) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.put(this.updateProductUrl + productId, formData, { headers: reqHeader });
    }

    deleteProduct(productId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.delete(this.deleteProductUrl + productId, { headers: reqHeader });
    }

    getAllProducts() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Product[]>(this.getAllProductUrl, { headers: reqHeader });
    }

    getProductByProductId(productId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Product>(this.getProductByIdUrl + productId, { headers: reqHeader });
    }

    getProductsByCatgoryId(categoryId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Product[]>(this.getProductByCategoryIdUrl + categoryId, { headers: reqHeader });
    }

    getProductImage(imageId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.getProductImageIdUrl + imageId, { headers: reqHeader, responseType: 'blob', observe: 'response' });
    }

    /*getProductImageBlob(imageURL) {
        let reqHeader = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'Access-Control-Request-Headers': '*' });
        return this.httpClient.get(imageURL, { headers: reqHeader, responseType: 'blob', observe: 'response' });
    }*/

    getProductImageBlob(imageURL) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.baseUrl + '/api/getProductImage?productImage=' + imageURL, { headers: reqHeader, responseType: 'blob', observe: 'response' });
    }

    getAllProductsForHomePage() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Product[]>(this.getAllProductForHomePageUrl);
    }
    getBestSellingProductsForHomePage() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Product[]>(this.getBestSellingProductForHomePageUrl);
    }

    searchProducts(searchInput) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post<Product[]>(this.baseUrl + '/api/addFiltersWithPriceRange', searchInput, { headers: reqHeader });
    }
}