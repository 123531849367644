import { Category } from './category';

export class SearchInput {
    categories: Category[];
    priceInput: PriceInputSearch[];
    code: string;
}

export class PriceInputSearch {
    maxPrice: number;
    minPrice: number
}