<div class="displayTable">
    <div class="displayTableCell">
  
      <div class="authBlock">
        <h3></h3>
  
        
        <!-- Calling SendVerificationMail() method using authService Api -->
        
  
      </div>
  
      
  
    </div>
  </div>
  
  <section>

    <div class="container">
      <div class="login-form">
        <h1>Thank You for Registering</h1>
        <form >
          <div class="formGroup" >
            <p class="text-center">We have sent a confirmation email to <strong></strong>.</p>
            <p class="text-center">Please check your email and click on the link to verfiy your email address.</p>
          </div>
          
          <div class="formGroup">
            <button type="submit" class="btn btnPrimary" >
              <i class="fas fa-redo-alt"></i>
              Resend Verification Email
            </button>
          </div>
          <br>
          <div class="redirectToLogin">
            <span>Go back to?<a class="redirect" routerLink="/login"> Sign in</a></span>
          </div>
          
        </form>
  
      </div>
    </div>
  
  </section>
  
  
  
  
  
  