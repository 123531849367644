import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AuthenticationService } from '../shared/services/authentication.service';
import { User } from '../model/user';
import { CartService } from '../shared/services/cart.service';
import { ShoppingCartResponse } from '../model/shoppingCartResponse';
import { CategoryService } from '../shared/services/category.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  user: User;
  topNav: any;
  navigationSubscription: any;
  mycart: ShoppingCartResponse;
  myCartSize: number = 0;
  myCartPrice: string = "0.00";
  isAdmin: Boolean = false;
  currency: string = "";

  allCategories: any;
  constructor(private cartService: CartService, private router: Router,
    private authenticationService: AuthenticationService, private serviceCategorie: CategoryService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
  }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.isAdmin = this.user.admin;
     // this.refreshCart();
      
    }
    this.serviceCategorie.getAllCategories().subscribe(Category => {
      this.allCategories = Category;
    });

  }

  initialiseInvites() {
    if (this.authenticationService.isLoggedIn()) {
      this.user = JSON.parse(localStorage.getItem('user'));
      if(this.user.userType=='ADMIN' || this.user.userType=='WHOLESALE'){
        this.isAdmin = true;
      }else{
        this.isAdmin = false;
      }
     
     // this.refreshCart();
    }
  }

  refreshCart() {
    this.cartService.getCartList(this.user.id).subscribe(response => {
      if (response) {
        this.mycart = response;
        if (this.mycart.shoppingCartEntries) {
          this.myCartSize = this.mycart.shoppingCartEntries.length;
          this.myCartPrice = this.mycart.totalPrice;
        }
      }
    });
  }

  logout() {
    this.authenticationService.logOut();
  }

  recapShopping() {
    if (this.myCartSize <= 0) return;
    this.router.navigate(['/shopping-cart']);
  }
}
