import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../shared/services/product.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { mergeMap, map } from 'rxjs/operators';
import { CategoryService } from 'src/app/shared/services/category.service';
import { AddeditproductComponent } from '../addeditproduct/addeditproduct.component';
import { DeleteComponent } from '../delete/delete.component';
import { Category } from 'src/app/model/category';
import { Product } from 'src/app/model/product';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { TotalCount } from 'src/app/model/totalcount';
import { OrderService } from 'src/app/shared/services/order.service';
import { RatingAndReview, RatingAndReviewResponse } from 'src/app/model/rating';
import { isNullOrUndefined } from 'util';
import { ReviewService } from 'src/app/shared/services/review.service';
declare function reflowTable(): any;


@Component({
  selector: 'app-displayproductlist',
  templateUrl: './displayproductlist.component.html',
  styleUrls: ['./displayproductlist.component.css']
})
export class DisplayproductlistComponent implements OnInit {
  allCategories: any;
  allproducts: any;
  p: number = 1;
  count: number = 10;
  selectedcategoryId: string;
  selectedCategory: string;
  user: User;
  productFilter: string;
  loading: boolean = false;
  successDelete: boolean = false;
  allCounts: any;
  income: number;
  currency: string = "";
  showIndex: number = 0;
  selectedProduct: any;

  constructor(private serviceDialog: MatDialog, private router: Router,
    private authenticationService: AuthenticationService,
    private orderService: OrderService, private reviewService: ReviewService,
    private serviceProduct: ProductService, private serviceCategorie: CategoryService) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.selectedcategoryId = 'All';

      this.serviceCategorie.getAllCategories().subscribe(Category => {
        this.allCategories = Category;
      });

      this.getProductsList('ALL', 'ALL');
      this.getTotalCounts();
    }
  }

  addProductPage() {
    this.loading = true;
    this.router.navigate(['/admin/add-editProduct']);
  }

  getProductsList(categoryId, categoryName) {
    this.selectedcategoryId = categoryId;
    this.selectedCategory = categoryName;
    if (this.selectedcategoryId == 'ALL') {
      this.loading = true;
      this.serviceProduct.getAllProducts().subscribe(
        product => {
          this.allproducts = product;
          reflowTable();
          this.loading = false;
        }, error => {
          this.loading = false;
        }
      );
    } else {
      this.loading = true;
      this.serviceProduct.getProductsByCatgoryId(this.selectedcategoryId).subscribe(
        product => {
          this.allproducts = product;
          reflowTable();
          this.loading = false;
        }, error => {
          this.loading = false;
        }
      );
    }
  }

  delete(Product) {
    this.successDelete=false;
    this.loading = true;
    this.serviceProduct.deleteProduct(Product).toPromise()
      .then(() => {
        this.loading = false;
       
        this.getProductsList('ALL', 'ALL');
      });

  }



  addStock(product) {
    this.loading = false;
    this.serviceDialog.open(DeleteComponent, {
      width: '500px', data: { product: product }
    }).afterClosed().subscribe(() => {
      this.getProductsList('ALL', 'ALL');
    });
  }

  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }

  ratingAndreviewsList: RatingAndReview[] = [];
  totalRatingAndreviews: number = 0;
  fiveStarRating: number = 0;
  fourStarRating: number = 0;
  threeStarRating: number = 0;
  twoStarRating: number = 0;
  oneStarRating: number = 0;
  averageRating: number = 0;
  fiveStarRatingPercent: number = 0;
  fourStarRatingPercent: number = 0;
  threeStarRatingPercent: number = 0;
  twoStarRatingPercent: number = 0;
  oneStarRatingPercent: number = 0;
  replyToReview: Boolean = false;
  replyComment: string;
  selectedReplyToReviewId: string;
  messageHeader: string;
  successMessage: string;
  errorMessaage: String;
  successModal: Boolean = false;
  errorModal: Boolean = false;
  reviewsModal: Boolean = false;

  openReviewsModal(product: Product) {
    this.cancelReplyComment();
    this.ratingAndreviewsList = product.reviews;
    this.totalRatingAndreviews = this.ratingAndreviewsList ? this.ratingAndreviewsList.length : 0;
    this.calculateRatingPercentages();
    this.reviewsModal = true;
  }

  calculateRatingPercentages() {
    if (!isNullOrUndefined(this.ratingAndreviewsList) && this.ratingAndreviewsList.length > 0) {
      this.ratingAndreviewsList.forEach(ratingAndReviewObj => {
        if (ratingAndReviewObj.star != null && ratingAndReviewObj.star == "FIVE_STAR") {
          this.fiveStarRating++;
        } else if (ratingAndReviewObj.star != null && ratingAndReviewObj.star == "FOUR_STAR") {
          this.fourStarRating++;
        } else if (ratingAndReviewObj.star != null && ratingAndReviewObj.star == "THREE_STAR") {
          this.threeStarRating++;
        } else if (ratingAndReviewObj.star != null && ratingAndReviewObj.star == "TWO_STAR") {
          this.twoStarRating++;
        } else if (ratingAndReviewObj.star != null && ratingAndReviewObj.star == "ONE_STAR") {
          this.oneStarRating++;
        }
      });
      this.fiveStarRatingPercent = this.fiveStarRating > 0 ? ((this.fiveStarRating / this.totalRatingAndreviews) * 100) : 0;
      this.fourStarRatingPercent = this.fourStarRating > 0 ? ((this.fourStarRating / this.totalRatingAndreviews) * 100) : 0;
      this.threeStarRatingPercent = this.threeStarRating > 0 ? ((this.threeStarRating / this.totalRatingAndreviews) * 100) : 0;
      this.twoStarRatingPercent = this.twoStarRating > 0 ? ((this.twoStarRating / this.totalRatingAndreviews) * 100) : 0;
      this.oneStarRatingPercent = this.oneStarRating > 0 ? ((this.oneStarRating / this.totalRatingAndreviews) * 100) : 0;

      this.averageRating = (1 * this.oneStarRating) + (2 * this.twoStarRating) + (3 * this.threeStarRating) + (4 * this.fourStarRating) + (5 * this.fiveStarRating);
      this.averageRating = this.averageRating > 0 ? (this.averageRating / this.totalRatingAndreviews) : 0;
    }
  }


  openReplyComment(ratingId) {
    this.selectedReplyToReviewId = ratingId;
    this.replyComment = null;
    this.replyToReview = true;
  }

  cancelReplyComment() {
    this.replyToReview = false;
    this.replyComment = null;
    this.selectedReplyToReviewId = null;
  }

  submitReplyComment(ratingId) {
    this.loading = true;
    let ratingReviewTobeSubmitted: RatingAndReview;
    this.ratingAndreviewsList.forEach(ratingReview => {
      if (ratingReview.id == ratingId) {
        if (!ratingReview.replies) {
          ratingReview.replies = [];
        }
        let ratingResponse: RatingAndReviewResponse = new RatingAndReviewResponse();
        ratingResponse.email = this.user.email;
        ratingResponse.userId = this.user.id;
        ratingResponse.username = "Zasta Infotek";
        ratingResponse.reviewComment = this.replyComment;
        ratingReview.replies.push(ratingResponse);
        ratingReviewTobeSubmitted = ratingReview;
      }
    });
    this.reviewService.updateReview(ratingReviewTobeSubmitted).subscribe(
      response => {
        this.loading = false;
        this.messageHeader = "Successfully submitted!!";
        this.successMessage = "Reply for the comment posted.";
        this.successModal = true;
        this.cancelReplyComment();
      }, error => {
        this.loading = false;
        this.messageHeader = "Sorry for the inconvenience";
        this.errorMessaage = error.error.failure;
        this.errorModal = true;
        this.cancelReplyComment();
      });
  }
  selectedproduct(product){
    this.selectedProduct=product.id;
    this.successDelete = true;
  }
  cancel(){
    this.successDelete=false;
  }
}
