<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">

        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="d-flex flex-column flex-md-row align-items-center text-center text-md-left">

                <div class="breadcrumb-header justify-content-between">
                    <div class="row w-100">
                        <div class="col-md-8">
                            <h4 class="content-title mb-2">Hi, welcome back!</h4>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">

                                    <li class="breadcrumb-item active" *ngIf="!idCategory" aria-current="page">Add
                                        Category</li>
                                    <li class="breadcrumb-item active" *ngIf="idCategory" aria-current="page">Edit
                                        Category</li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-md-4 ml-auto">
                            <div
                                class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                                <div class="row w-100">
                                    <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                        <div> <span class="d-block"> <span class="label"></span> </span>
                                            <span class="value"> </span>
                                        </div>
                                        <div class="ml-3 mt-0">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                        <div>
                                            <span class="d-block"> <span class="label ">INCOME</span>
                                            </span>
                                            <span class="value">{{currency}} {{income}} </span>
                                        </div>
                                        <div class="ml-3 mt-0">
                                            <span class="sparkline-bar text-warning">
                                                <i class="ft-bar-chart-2"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row row-sm">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-0" *ngIf="!idCategory">Add Category</h4>
                            <h4 class="card-title mb-0" *ngIf="idCategory">Update Category</h4>
                            <p class="sub-text">It is Very Easy to Customize and it uses in your website
                                apllication.
                            </p>
                            <hr>
                            <section class="inner-section-gap" *ngIf="regiForm">
                                <form [formGroup]="regiForm">
                                    <div class="row-sm">
                                        <div class="justify-content-left row m-auto my-auto">

                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                                <label class="label">Category Code</label>
                                                <i class="ft-package"></i>
                                                <input type="text" class="form-control transparent-tb"
                                                    placeholder="Category Code" formControlName="categoryCode">
                                                <small class="form-text text-danger"
                                                    *ngIf="!regiForm.get('categoryCode').valid && regiForm.get('categoryCode').touched">
                                                    Please enter the category code !!!</small>
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                                <label class="label">Category Name</label>
                                                <i class="ft-package"></i>
                                                <input type="text" class="form-control transparent-tb"
                                                    placeholder="Category Name" formControlName="categoryName">
                                                <small class="form-text text-danger"
                                                    *ngIf="!regiForm.get('categoryName').valid && regiForm.get('categoryName').touched">
                                                    Please enter the category name !!!</small>
                                            </div>


                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                                <label class="label">Enter Product Category Image</label>
                                                <div class="custom-file">
                                                    <input type="file" (change)="showPreview($event)"
                                                        class="custom-file-input" id="customFile">
                                                    <label class="custom-file-label" for="customFile">Choose
                                                        file</label>
                                                </div>

                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                                                <img [src]="imgSrc| safe: 'url'"
                                                    style="max-width:400px;max-height:200px;" class="first-img">

                                            </div>



                                            <div class="col-lg-12 col-md-12 col-sm-12 form-group icon-form">
                                                <label class="label"> Description</label>
                                                <i class="ft-package"></i>
                                                <textarea class="form-control transparent-tb"
                                                    formControlName="categoryDescription"
                                                    placeholder="Category Description"></textarea>
                                                <small class="form-text text-danger"
                                                    *ngIf="!regiForm.get('categoryDescription').valid && regiForm.get('categoryDescription').touched">
                                                    Please enter the category description!!!</small>
                                            </div>











                                            <div class="spacer-30"></div>

                                            <div class="mt-4 text-center col-md-4 m-auto mr-2">
                                                <button (click)="cancel()"
                                                    class="btn  btn-danger btn-sm rounded-pill">Cancel
                                                </button>
                                                <button type="submit" *ngIf="!idCategory" [disabled]="!regiForm.valid"
                                                    class=" btn btn-primary btn-sm rounded-pill ml-2"
                                                    (click)="onSubmit(regiForm.value)">Add </button>
                                                <button type="submit" *ngIf="idCategory" [disabled]="!regiForm.valid"
                                                    class=" btn btn-primary btn-sm rounded-pill ml-2"
                                                    (click)="onSubmit(regiForm.value)">Update </button>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </section>

                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="success" id="email-verifiacation-modal" class="modal fade show"
                style="display:block!important;">
                <div class="modal-dialog  modal-dialog-centered modal-md">
                    <div class="modal-content modal-confirm">
                        <div class="modal-header">
                            <div class="icon-box">
                                <i class="ft-check"></i>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body text-center">
                            <h4 *ngIf="!idCategory">Category Added</h4>
                            <h4 *ngIf="idCategory">Category Updated</h4>
                            <p class="text-success">Successfully </p>


                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="errorPopup" id="fail-modal" class="modal fade show" style="display:block!important;">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content modal-reject ">
                        <div class="modal-header">
                            <div class="icon-box">
                                <i class="ft-alert-triangle"></i>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body text-center">
                            <h4>Sorry</h4>
                            <p class="text-danger">Some went wrong. Try again</p>
                            <p></p>

                        </div>
                    </div>
                </div>
            </div>