import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Faq } from '../model/faq';
import { AuthenticationService } from '../shared/services/authentication.service';
import { FaqService } from '../shared/services/faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  loading: boolean = false;
  faqLists: any;
  questionForm: FormGroup;
  question: boolean = false;
  showIndex: number;

  constructor(private authenticationService: AuthenticationService, private fb: FormBuilder,
    private router: Router, private serviceFaq: FaqService) { }

  ngOnInit() {
    this.loading = true;
    this.faqList();
  }

  faqList() {
    this.loading = true;
    this.serviceFaq.getAllFaq().subscribe(faqsList => {
      this.faqLists = faqsList;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  setShowIndex(index) {
    if (this.showIndex == index) {
      this.showIndex = -1;
    } else {
      this.showIndex = index
    }
  }
}
