import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Category } from 'src/app/model/category';
import { CategoryService } from 'src/app/shared/services/category.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/model/user';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/shared/services/order.service';
import { TotalCount } from 'src/app/model/totalcount';



@Component({
  selector: 'app-addeditcategory',
  templateUrl: './addeditcategory.component.html',
  styleUrls: ['./addeditcategory.component.css'],
  providers: [DatePipe]

})
export class AddeditcategoryComponent implements OnInit {
  regiForm: FormGroup
  user: User;
  category: Category;
  myDate = new Date();
  idCategory: any;
  loading: boolean = false;
  success: boolean = false;
  errorPopup: boolean = false;
  allCounts: any;
  income: number;
  currency: string = "";

  constructor(private fb: FormBuilder,
    private serviceCategorie: CategoryService,
    private orderService: OrderService,
    private authenticationService: AuthenticationService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.route.snapshot.queryParams['key']) {
      this.loading = true;
      this.route.queryParams.subscribe(params => {

        this.idCategory = params['key'];


      })
    }

    this.currency = localStorage.getItem('currency');
    this.categoryDetails();
    this.getTotalCounts();
  }
  categoryDetails() {
    this.loading = true;
    if (!this.idCategory) {
      this.serviceCategorie.getAllCategories()
        .subscribe(categories => {
          this.category = categories as Category;
          this.imgSrc = 'assets/images/no-image.jpg';
          this.initalizeCategory(null);
          this.loading = false;
        }, error => {
          this.loading = false;
        }
        );
    }

    else {
      this.loading = true;
      this.serviceCategorie.getCategoryByCategoryId(this.idCategory)
        .subscribe((categories) => {
          this.category = categories as Category;
          this.initalizeCategory(this.category);
          this.selectedImage = this.category.categoryImage;
          this.imgSrc = this.category.categoryImage;
          this.loading = false;
        }, error => {
          this.loading = false;
        }
        )
    }
  }
  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }
  initalizeCategory(categories: Category) {

    this.regiForm = this.fb.group({
      id: [categories ? categories.id : null],
      categoryName: [categories ? categories.categoryName : null, Validators.required],
      categoryDescription: [categories ? categories.categoryDescription : null, Validators.required],
      categoryCode: [categories ? categories.categoryCode : null, Validators.required],
      createdBy: [categories ? categories.createdBy : null],
      categoryImage: [categories ? categories.categoryImage : null],
      createdDate: [categories ? categories.createdDate : null],
      deletedFlag: [categories ? categories.deletedFlag : null],
      lastupdatedDate: [categories ? categories.lastupdatedDate : null],
      updatedBy: [categories ? categories.updatedBy : null],
      productCount: [categories ? categories.productCount : 0],
      totalSold: [categories ? categories.totalSold : 0],

    });
  }
  onSubmit(form) {


    if (this.regiForm.valid) {
      let category: Category = new Category(this.regiForm.value);
      if (!this.idCategory) {
        this.loading = true;
        var formData = new FormData();
        formData.append("categoryImage", this.selectedImage);
        formData.append("categoryRequest", JSON.stringify(category));
        this.serviceCategorie.saveCategory(formData).toPromise().then(
          response => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {
              this.success = false;
              this.router.navigate(['/admin/display-categoryList']);
            }, 2000);
          }, error => {
            this.loading = false;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;
            }, 2000);

          }
        )

      }
      else {
        this.loading = true;
        var formData = new FormData();
        formData.append("categoryImage", (this.selectedImage ? this.selectedImage : null));
        formData.append("categoryRequest", JSON.stringify(category));
        this.serviceCategorie.updateCategory(formData).toPromise().then(
          response => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {

              this.success = false;
              this.router.navigate(['/admin/display-categoryList']);
            }, 2000);


          }, error => {
            this.loading = false;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;

            }, 2000);

          }

        )
      }
    }
  }
  cancel() {
    this.loading = true;
    this.router.navigate(['/admin/display-categoryList']);
  }
  imgSrc: string;
  selectedImage: any = null;
  showPreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => this.imgSrc = e.target.result;
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage = event.target.files[0];
    }
    else {
      this.imgSrc = '/../../../assets/images/no-image.jpg';
      this.selectedImage = null;
    }
  }
}
