import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webpage-design',
  templateUrl: './webpage-design.component.html',
  styleUrls: ['./webpage-design.component.css']
})
export class WebpageDesignComponent implements OnInit {
  imgSrc: string;
  imgSrc1: string;
  imageurl: string;
  imageurl1: string;
  selectedImage: any = null;
  selectedImage1: any = null;

  constructor() { }

  ngOnInit() {
  }
  showPreview(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => this.imgSrc = e.target.result;
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage = event.target.files[0];
    }
    else {
      this.imgSrc = '/../../../assets/img/image_placeholder.jpg';
      this.selectedImage = null;
    }
  }
  showPreview1(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => this.imgSrc1 = e.target.result;
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage1 = event.target.files[0];
    }
    else {
      this.imgSrc1 = '/../../../assets/img/image_placeholder.jpg';
      this.selectedImage1 = null;
    }
  }
}
