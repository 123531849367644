import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {

  constructor(private route: ActivatedRoute, private authService: AuthenticationService, private formBuilder: FormBuilder,
    private router: Router) { }

  hasResetKey: Boolean = false;
  resetKey: string;
  passwordResetEmail: string = null;
  errorMessage: string = null;
  successMessage: string = null;
  resetPasswordForm: FormGroup;
  loading: Boolean = false;

  ngOnInit() {
    if (this.route.snapshot.queryParams['key']) {
      this.route.queryParams.subscribe(params => {
        this.resetKey = params['key'];
        this.hasResetKey = true;
        this.resetPasswordForm = this.createForm();
      })
    }
  }

  sendEmail() {
    if (this.passwordResetEmail && this.passwordResetEmail != "") {
      this.loading = true;
      this.authService.forgotPasswordSendEmail(this.passwordResetEmail).toPromise().then(
        response => {
          this.loading = false;
          this.successMessage = "Email Sent successfully. Kindly check your email";
          this.passwordResetEmail = null;
          setTimeout(() => {
            this.successMessage = null;
          }, 2000);
        }, error => {
          this.loading = false;
          console.log(error);
          this.errorMessage = error.error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      )
    }
  }

  createForm() {
    return this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },
      { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') })
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        if(passwordConfirmationInput.hasError('notEquivalent')){
          return passwordConfirmationInput.updateValueAndValidity();
        }        
      }
    }
  }

  finishForgotPassword() {
    if (this.resetPasswordForm.valid) {
      this.loading = true;
      let data = {
        key: this.resetKey,
        newPassword: this.resetPasswordForm.get('password').value
      }
      this.authService.forgotPasswordFinish(data).toPromise().then(
        response => {
          this.loading = false;
          this.resetPasswordForm.reset();
          this.successMessage = "Password reset Successful. Redirecting to Login Page";
          setTimeout(() => {
            this.successMessage = null;
            this.router.navigate(['/login']);
          }, 2000);
        }, error => {
          this.loading = false;
          console.log(error);
          this.resetPasswordForm.reset();
          this.errorMessage = error.error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      )
    }
  }

}
