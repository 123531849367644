import { Component, OnInit } from '@angular/core';


import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { User } from 'src/app/model/user';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  registerForm: FormGroup;
  errorMessage: boolean = false;
  successMessage: boolean = false;
  loading: boolean = false;
  errorMessageStr: string = "";
  errorMessageHdr: string = "";


  constructor(private router: Router, private fb: FormBuilder,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.createRegisterForm();
  }

  /** New Backend Changes */
  user: User;
  createRegisterForm() {
    this.registerForm = this.fb.group({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      mobileno: new FormControl('', Validators.required),
      username: new FormControl(''),
      confirmPassword: new FormControl('',Validators.required)
    }, {validator: this.checkIfMatchingPasswords('password','confirmPassword') });
  }
  checkPasswords(control: AbstractControl) { // here we have the 'passwords' group
  let pass = control.get('password').value;
  let confirmPass = control.get('confirmPassword').value;
  if(pass&&confirmPass)
  if(pass!=confirmPass){
    control.get('confirmPassword').setErrors({invalid: true});
  }
     
}
checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  return (group: FormGroup) => {
    let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
    if (passwordInput.value !== passwordConfirmationInput.value) {
      return passwordConfirmationInput.setErrors({notEquivalent: true})
    }
    else {
        return passwordConfirmationInput.setErrors(null);
    }
  }
}

  registerUser() {
    if (this.registerForm.valid) {
      this.loading = true;
      this.user = new User(this.registerForm.value);
      this.authenticationService.registerUser(this.user).toPromise().then(
        response => {
          this.loading = false;
          if (response && response['success']) {
            this.successMessage = true;
            setTimeout(() => {
              this.successMessage = false;
              this.router.navigate(['login']);
            }, 2000);
          }
        }
      ).catch(
        error => {
          this.loading = false;
          if (error && error.error && error.error.failure) {
            this.errorMessageStr = error.error.failure;
            this.errorMessageHdr = "Sorry. Error while creating your account.";
          } else {
            this.errorMessageStr = "Server Error. Please try again after some time.";
            this.errorMessageHdr = "Sorry for the inconvenience.";
          }
          this.errorMessage = true;
          setTimeout(() => {
            this.errorMessage = false;
          }, 3000);
        }
      );
    }
  }
  /** New Backend Changes */
}
