import { Component, OnInit } from '@angular/core';
import { Enquiry } from 'src/app/model/enquiry';
import { User } from 'src/app/model/user';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EnquiryService } from 'src/app/shared/services/enquiry.service';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/shared/services/order.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { TotalCount } from 'src/app/model/totalcount';
declare function reflowTable(): any;
@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.component.html',
  styleUrls: ['./enquiry.component.css']
})
export class EnquiryComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  enquries: any;
  user: User;
  categoryFilter: string;
  loading: boolean = false;
  success: boolean = false;
  income: number;
  allCounts: any;
  commentsForm: FormGroup;
  comment: boolean = false;
  totalMOHFund: number;
  totalBoxesSold: number;
  currency: String = "";

  constructor(private serviceEnquiry: EnquiryService, private fb: FormBuilder,
    private router: Router, private orderService: OrderService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.getAllEnquiry();
      this.getTotalCounts();
    }
  }
  getAllEnquiry() {
    this.loading = true;
    this.serviceEnquiry.getAllEnquiry().subscribe(response => {
      this.enquries = response;
      reflowTable();
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }
  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }
  details(comments) {
    this.comment = true;
    this.intializeForm(comments);
  }
  intializeForm(comments: Enquiry) {
    this.commentsForm = this.fb.group({
      'id': [comments ? comments.id : null],
      'contactNumber': [comments ? comments.contactNumber : null, Validators.required],
      'contactUsStatus': ['REPLIED'],
      'email': [comments ? comments.email : null, Validators.required],
      'createdDate': [comments ? comments.createdDate : null],
      'deletedFlag': [comments ? comments.deletedFlag : false],
      'lastUpdatedDate': [comments ? comments.lastUpdatedDate : null],
      'message': [comments ? comments.message : null, Validators.required],
      'subject': [comments ? comments.subject : null, Validators.required],
      'username': [comments ? comments.username : null, Validators.required],
      'comments': [comments ? comments.comments : null, Validators.required],
    })
  }
  submitForm() {
    this.loading = true;
    let enquiries = new Enquiry(this.commentsForm.value);
    this.serviceEnquiry.updateEnquiry(enquiries).toPromise().then(
      response => {
        this.comment = false;
        this.success = true;
        setTimeout(() => {
          this.success = false;
          this.getAllEnquiry();
        }, 2000);

        this.loading = false;

      }, error => {
        this.loading = false;


      })

  }
  close() {
    this.comment = false;
  }
}
