<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<app-menu></app-menu>

<section class="sec-gap bg-light">

    <div class=" mt-70">
        <div class="container">

            <div class="checkout_form" *ngIf="order">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <form>
                            <h3>Order Details</h3>
                            <div class="order_table table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="shoppingList&&shoppingList.length>0">
                                        <tr *ngFor="let cartEntry of shoppingList">
                                            <td> {{cartEntry.productName}}<strong> × {{cartEntry.quantity}}</strong>
                                            </td>
                                            <td> {{currency}} {{(cartEntry.productTotalPrice)}}</td>
                                        </tr>

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Cart Subtotal</th>
                                            <td>{{currency}} {{order.total_Amount_to_Pay}}</td>
                                        </tr>
                                        <tr>
                                            <th>Shipping</th>
                                            <td><strong>free shipping</strong></td>
                                        </tr>
                                        <tr class="order_total">
                                            <th>Order Total</th>
                                            <td><strong>{{currency}} {{order.total_Amount_to_Pay}}</strong></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <h3>Billing Address
                            <a href="javascript:void(0)" class="btn float-right" routerLink="/profile"><i
                                    class="ft-edit"></i></a>
                        </h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card mb-6 mb-lg-0">
                                    <div class="card-body" *ngIf="user && user.billingAddress">
                                        <address>
                                            {{user.billingAddress.address_1}}<br>
                                            {{user.billingAddress.address_2}}<br>
                                            {{user.billingAddress.city}}<br>
                                            {{user.billingAddress.state}}<br>
                                            {{user.billingAddress.postcode}}
                                        </address>
                                        <p><strong>{{user.billingAddress.country}}</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h3>Shipping Address
                            <a href="javascript:void(0)" class="btn float-right" routerLink="/profile"><i
                                    class="ft-edit"></i></a>
                            <a href="javascript:void(0)" class="btn ml-2 float-right" routerLink="/profile"><i
                                    class="ft-plus"></i></a>
                        </h3>
                        <div class="row">
                            <ng-container *ngIf="user && user.shippingAddress && user.shippingAddress.length > 0">
                                <ng-container *ngFor="let shipAddr of user.shippingAddress; let i = index">
                                    <div class="col-lg-6">
                                        <div class="card">
                                            <div class="card-body">
                                                <address>
                                                    {{shipAddr.address_1}}<br>
                                                    {{shipAddr.address_2}}<br>
                                                    {{shipAddr.city}}<br>
                                                    {{shipAddr.state}}<br>
                                                    {{shipAddr.postcode}}
                                                </address>
                                                <p><strong>{{shipAddr.country}}</strong></p>
                                                <input type="radio" name="shipAddr" class="float-right"
                                                    (click)="selectedShipping(shipAddr)">
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>

                        <div class="payment_method">
                            <h6 class="text-danger" *ngIf="order.total_Amount_to_Pay < minOrder"><i
                                    class="ft-shopping-cart"></i> Minimum order amount is S$
                                20</h6>
                            <button class="btn btn-success btn-lg rounded-pill float-right"
                                [disabled]="!order || !order.shoppingCartEntry || order.shoppingCartEntry.length == 0 
                                || !order.shippingDetails || !order.billingDetails || (order.total_Amount_to_Pay < minOrder)" (click)="onSubmit()"><i class="ft-credit-card"></i>
                                Proceed To Pay</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<div *ngIf="successModal" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>{{successMessage}}</h4>
                <p class="text-success">Successfully </p>

            </div>
        </div>
    </div>
</div>