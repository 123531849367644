import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './home/login/login.component';
import { SignupComponent } from './home/signup/signup.component';
import { ForgetpasswordComponent } from './home/forgetpassword/forgetpassword.component';
import { VerifyemailComponent } from './home/verifyemail/verifyemail.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { DisplayproductlistComponent } from './admin/displayproductlist/displayproductlist.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { ShopproductsComponent } from './shopproducts/shopproducts.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { DisplaycategorylistComponent } from './admin/displaycategorylist/displaycategorylist.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { WebpageDesignComponent } from './admin/webpage-design/webpage-design.component';
import { MakePaymentComponent } from './payments/make-payment/make-payment.component';
import { UserComponent } from './user/user.component';
import { AddeditcategoryComponent } from './admin/addeditcategory/addeditcategory.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { AddeditproductComponent } from './admin/addeditproduct/addeditproduct.component';
import { OrdersComponent } from './admin/orders/orders.component';
import { OfferComponent } from './admin/offer/offer.component';
import { AddeditofferComponent } from './admin/addeditoffer/addeditoffer.component';
import { UserListComponent } from './admin/user-list/user-list.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { AdminGuard } from './shared/guard/admin.guard';
import { UserGuard } from './shared/guard/user.guard';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ProfileComponent } from './admin/profile/profile.component';
import { DeliveryCostComponent } from './admin/delivery-cost/delivery-cost.component';
import { AddEditDeliverycostComponent } from './admin/add-edit-deliverycost/add-edit-deliverycost.component';
import { EnquiryComponent } from './admin/enquiry/enquiry.component';
import { SettingsComponent } from './admin/settings/settings.component';

import { FaqComponent } from './faq/faq.component';
import { FaqAdminComponent } from './admin/faq/faq.component';
import { AddEditFaqComponent } from './admin/add-edit-faq/add-edit-faq.component';

import { AddEditTestimonialComponent } from './admin/add-edit-testimonial/add-edit-testimonial.component';
import { TestimonialListComponent } from './admin/testimonial-list/testimonial-list.component';
import { CreateOrderComponent } from './admin/create-order/create-order.component';
import { AddadminComponent } from './admin/addadmin/addadmin.component';
import { OrderDetailsComponent } from './admin/order-details/order-details.component';
import { WholesaleDashboardComponent } from './wholesale/wholesale-dashboard/wholesale-dashboard.component';
import { WholesaleInventoryComponent } from './wholesale/wholesale-inventory/wholesale-inventory.component';


const routes: Routes = [

  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent },

  //** Phase 2 Changes */

  { path: 'home', component: HomeComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'forgot-password', component: ForgetpasswordComponent },
  { path: 'emailverfication', component: VerifyemailComponent },
  { path: 'shop-products', component: ShopproductsComponent },
  { path: 'product-details', component: ProductdetailsComponent},
  { path: 'shopping-cart', component: ShoppingCartComponent, canActivate: [UserGuard] },
  { path: 'wishlist', component: WishlistComponent, canActivate: [UserGuard] },
  { path: 'checkout', component: CheckoutComponent, canActivate: [UserGuard] },
  { path: 'webdesign', component: WebpageDesignComponent, canActivate: [UserGuard] },
  { path: 'payment', component: MakePaymentComponent, canActivate: [UserGuard] },
  { path: 'order-success', component: OrderSuccessComponent, canActivate: [UserGuard] },
  { path: 'profile', component: UserComponent, canActivate: [UserGuard] },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'faq', component: FaqComponent },

  //** Phase 2 Changes */

  //Admin pages
  {
    path: 'admin', component: AdminComponent,
    children: [
      { path: 'addadmin', component: AddadminComponent, data: { title: 'admin' } },
      { path: 'profile', component: ProfileComponent, data: { title: 'profile' } },
      { path: 'admin-dashboard', component: DashboardComponent, data: { title: 'Dashboard' } },
      { path: 'display-categoryList', component: DisplaycategorylistComponent, data: { title: 'Category List' } },
      { path: 'add-editCategory', component: AddeditcategoryComponent, data: { title: 'Add Category List' } },
      { path: 'display-products', component: DisplayproductlistComponent, data: { title: 'Product List' } },
      { path: 'add-editProduct', component: AddeditproductComponent, data: { title: 'Add Product List' } },
      { path: 'display-orders', component: OrdersComponent, data: { title: 'Orders' } },
      { path: 'createOrder', component: CreateOrderComponent, data: { title: 'Create Order' } },
      { path: 'orderDetails', component: OrderDetailsComponent, data: { title: 'Order Details' } },
      { path: 'user-list', component: UserListComponent, data: { title: 'User List' } },
      { path: 'settings', component: SettingsComponent, data: { title: 'Settings' } },
      { path: 'faq-admin', component: FaqAdminComponent, data: { title: 'FAQ' } },
      { path: 'add-editFaq', component: AddEditFaqComponent, data: { title: 'Add Faq' } }, 
      { path: 'add-editTestimonial', component: AddEditTestimonialComponent, data: { title: 'Add Testimonial' } },
      { path: 'testimonialList', component: TestimonialListComponent, data: { title: 'Testimonial List' } },
      { path: 'wholesaleDashboard', component: WholesaleDashboardComponent, data: { title: 'Wholesale Dashboard' } },
      { path: 'Inventory', component: WholesaleInventoryComponent, data: { title: 'Wholesale Inventory' } },
      { path: 'enquiries', component: EnquiryComponent, data: { title: 'Enquiry List' } },   


      //** Phase 2 Changes */
      /*{ path: 'delivery-Cost', component: DeliveryCostComponent, data: { title: 'Delivery Cost' } },
      { path: 'add-editDeliveryCost', component: AddEditDeliverycostComponent, data: { title: 'Add Delivery Cost' } },      
      { path: 'display-offer', component: OfferComponent, data: { title: 'Offer' } },
      { path: 'add-editOffer', component: AddeditofferComponent, data: { title: 'Add Offer' } },      
        
          
      { path: 'settings', component: SettingsComponent, data: { title: 'Settings' } },
      */
      //** Phase 2 Changes */

    ],
    canActivate: [AdminGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule]
})
export class AppRoutingModule { }
