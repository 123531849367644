<!-- Topbar Admin -->
<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">
        <!-- Topbar Admin -->
        <!-- <div class="inner-topbar sidebar-left" id="nav-sticky">
                    <button class="navbar-toggler pull-left res-btn-left" type="button" id="res-btn">
                        <i class="ft-align-left"></i>
                    </button>
                    <ul class="nav navbar-nav float-right ml-auto profile-menu">
    
                        <li class="nav-item maximize fullscreen-button">
                            <a href=""><i class="ft-maximize"></i></a>
                        </li>
    
                        <li class="dropdown nav-item active">
                            <a class="dropdown-toggle nav-link " href="javascript:void(0)" data-toggle="dropdown" aria-expanded="false">
                                <span class="avatar"><img src="assets/images/avatar.png" alt="avatar"></span>
                            </a>
                            <div class="dropdown-menu">
                                <div class="main-header-profile header-img">
                                    <div class="main-img-user"><img alt="" src="assets/images/avatar.png"></div>
                                    <h6>DIPTISUNDAR</h6><small>Administrator</small>
                                </div> <a class="dropdown-item" href=""><i class="far fa-user"></i> My Profile</a> <a
                                    class="dropdown-item" href=""><i class="far fa-edit"></i> Edit Profile</a> <a
                                    class="dropdown-item" href=""><i class="far fa-clock"></i> Activity Logs</a> <a
                                    class="dropdown-item" href=""><i class="fas fa-sliders-h"></i> Account Settings</a>
                                <a class="dropdown-item" href="login.html"><i class="fas fa-sign-out-alt"></i> Sign
                                    Out</a>
                            </div>
                        </li>
    
                    </ul>
                </div> -->
        <app-top-bar></app-top-bar>
        <div class="container-fluid">

            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page"> Dashboard</li>


                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row row-sm">
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="card overflow-hidden project-card">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="project-card-box primary">
                                <i class="ft-package"></i>
                            </div>
                            <div class="project-content">
                                <h6>Total Products</h6>
                                <ul>
                                    <!-- <li> <strong>Processing</strong> <span></span> </li>-->
                                    <li> <strong>Paid</strong> <span>{{totalProducts}}</span> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="card  overflow-hidden project-card">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="project-card-box danger">
                                <i class="ft-share-2"></i>
                            </div>
                            <div class="project-content">
                                <h6>Total Categories</h6>
                                <ul>
                                    <li> <strong>Essential</strong> <span>{{totalCategories}}</span> </li>
                                    <!-- <li> <strong>Not Essential</strong> <span></span> </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="card overflow-hidden project-card">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="project-card-box success">
                                <i class="ft-shopping-cart"></i>
                            </div>
                            <div class="project-content">
                                <h6>Total Orders</h6>
                                <ul>
                                    <li> <strong>Processing</strong> <span *ngIf="allOrders">{{allOrders.length}}</span> </li>
                                    <!-- <li> <strong>Accepted</strong> <span></span> </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

  </div>
  </div>
  