import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { RatingAndReview } from 'src/app/model/rating';

@Injectable()
export class ReviewService {

    constructor(private httpClient: HttpClient, private router: Router) { }

    baseUrl: string = environment.baseUrl;

    saveReviewUrl: string = this.baseUrl + '/api/addReview';
    updateReviewUrl: string = this.baseUrl + '/api/updateReviewById/';
    deleteReviewUrl: string = this.baseUrl + '/api/deleteReviewById/';
    getAllReviewUrl: string = this.baseUrl + '/api/getAllReviews';
    getReviewByIdUrl: string = this.baseUrl + '/api/getReviewById/';


    saveReview(review: RatingAndReview) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post(this.saveReviewUrl, review, { headers: reqHeader });
    }

    updateReview(review: RatingAndReview) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.put(this.updateReviewUrl, review, { headers: reqHeader });
    }

    deleteReview(review) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.delete(this.deleteReviewUrl + review, { headers: reqHeader });
    }

    getAllReviews() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<RatingAndReview[]>(this.getAllReviewUrl, { headers: reqHeader });
    }

    getReviewById(reviewId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<RatingAndReview>(this.getReviewByIdUrl + reviewId, { headers: reqHeader });
    }



}