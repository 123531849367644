import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { AddeditcategoryComponent } from './admin/addeditcategory/addeditcategory.component';
import { AddeditproductComponent } from './admin/addeditproduct/addeditproduct.component';
import { AdminComponent } from './admin/admin.component';

import { DeleteComponent } from './admin/delete/delete.component';
import { DisplaycategorylistComponent } from './admin/displaycategorylist/displaycategorylist.component';
import { DisplayproductlistComponent } from './admin/displayproductlist/displayproductlist.component';
import { WebpageDesignComponent } from './admin/webpage-design/webpage-design.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ForgetpasswordComponent } from './home/forgetpassword/forgetpassword.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './home/login/login.component';
import { SignupComponent } from './home/signup/signup.component';
import { VerifyemailComponent } from './home/verifyemail/verifyemail.component';
import { MaterialModule } from './material.module';
import { MenuComponent } from './menu/menu.component';
import { MakePaymentComponent } from './payments/make-payment/make-payment.component';
import { ProductdetailsComponent } from './productdetails/productdetails.component';
import { SafePipe } from './safe.pipe';
import { AuthenticationService } from './shared/services/authentication.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CartService } from './shared/services/cart.service';
import { CategoryService } from './shared/services/category.service';
import { CouponService } from './shared/services/coupon.service';
import { OrderService } from './shared/services/order.service';
import { ProductService } from './shared/services/product.service';
import { WishService } from './shared/services/wishlist.service';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { ShopproductsComponent } from './shopproducts/shopproducts.component';
import { UserComponent } from './user/user.component';
import { WishlistComponent } from './wishlist/wishlist.component';
import { PipesModule } from './pipe.module';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { ChartsModule } from 'ng2-charts';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

import { NgxStripeModule } from 'ngx-stripe';
import { DataHelper } from './shared/helper/data.helper';
import { OrderSuccessComponent } from './order-success/order-success.component';




import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialLoginModule,
  AmazonLoginProvider,
  SocialAuthServiceConfig
} from 'angularx-social-login';
import { DeliveryCostService } from './shared/services/delivery-cost.service';
import { EnquiryService } from './shared/services/enquiry.service';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { ReviewService } from './shared/services/review.service';
import { SettingsService } from './shared/services/settings.service';

import { FaqComponent } from './faq/faq.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { WholesaleDashboardComponent } from './wholesale/wholesale-dashboard/wholesale-dashboard.component';
import { WholesaleInventoryComponent } from './wholesale/wholesale-inventory/wholesale-inventory.component';
import { AddEditDeliverycostComponent } from './admin/add-edit-deliverycost/add-edit-deliverycost.component';
import { AddEditFaqComponent } from './admin/add-edit-faq/add-edit-faq.component';
import { AddEditTestimonialComponent } from './admin/add-edit-testimonial/add-edit-testimonial.component';
import { AddadminComponent } from './admin/addadmin/addadmin.component';
import { AddeditofferComponent } from './admin/addeditoffer/addeditoffer.component';
import { BreadcrumbComponent } from './admin/breadcrumb/breadcrumb.component';
import { CreateOrderComponent } from './admin/create-order/create-order.component';
import { DashboardComponent } from './admin/dashboard/dashboard.component';
import { DeliveryCostComponent } from './admin/delivery-cost/delivery-cost.component';
import { EnquiryComponent } from './admin/enquiry/enquiry.component';
import { FaqAdminComponent } from './admin/faq/faq.component';
import { OfferComponent } from './admin/offer/offer.component';
import { OrderDetailsComponent } from './admin/order-details/order-details.component';
import { OrdersComponent } from './admin/orders/orders.component';
import { ProfileComponent } from './admin/profile/profile.component';
import { SettingsComponent } from './admin/settings/settings.component';
import { SideBarComponent } from './admin/side-bar/side-bar.component';
import { TestimonialListComponent } from './admin/testimonial-list/testimonial-list.component';
import { TopBarComponent } from './admin/top-bar/top-bar.component';
import { UserListComponent } from './admin/user-list/user-list.component';
import { CKEditorModule } from 'ckeditor4-angular';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SignupComponent,
    ForgetpasswordComponent,
    VerifyemailComponent,
    MenuComponent,
    UserComponent,
    ShoppingCartComponent,
    DisplayproductlistComponent,
    ShopproductsComponent,
    ProductdetailsComponent,
    DisplaycategorylistComponent,
    AdminComponent,
    CheckoutComponent,
    AddeditcategoryComponent,
    AddeditproductComponent,
    WishlistComponent,
    DeleteComponent,
    WebpageDesignComponent,
    MakePaymentComponent,
    AboutUsComponent,
    ContactUsComponent,
    OrderSuccessComponent,

    FaqComponent,

    WholesaleDashboardComponent,

    WholesaleInventoryComponent,
    DashboardComponent,
    SideBarComponent,
    TopBarComponent,
    BreadcrumbComponent,
    OrdersComponent,
    OfferComponent,
    AddeditofferComponent,
    UserListComponent,
    ProfileComponent,
    DeliveryCostComponent,
    AddEditDeliverycostComponent,
    EnquiryComponent,
    SettingsComponent,
    FaqAdminComponent,
    AddEditFaqComponent,

    AddEditTestimonialComponent,
    TestimonialListComponent,
    CreateOrderComponent,
    OrderDetailsComponent,
    AddadminComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule, NgxStripeModule.forRoot(environment.stripeKey),
    BrowserAnimationsModule,
    CommonModule,
    NgxPaginationModule,
    HttpClientModule,
    MatDialogModule,
    MatTabsModule,
    MatSnackBarModule, SocialLoginModule, NgxDropzoneModule,
    MatTabsModule, MatDialogModule, MaterialModule, Ng2SearchPipeModule, PipesModule,
    NgxPayPalModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    BrowserAnimationsModule,
    CommonModule,
    NgxPaginationModule,
    HttpClientModule,
    Ng2SearchPipeModule, PipesModule,
    ChartsModule,
    NgxDropzoneModule,
    CKEditorModule
  ],
  entryComponents: [],
  providers: [OrderService, AuthenticationService, ProductService, DeliveryCostService, ReviewService,
    CategoryService, CartService, WishService, CouponService, DataHelper, EnquiryService, SettingsService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '663822936952-hu6a6rbjshljnntdvgg7aqjlu8p1qrl2.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('655423598409223'),
          },
          /*{
            id: AmazonLoginProvider.PROVIDER_ID,
            provider: new AmazonLoginProvider(
              'clientId'
            ),
          },*/
        ],
      } as SocialAuthServiceConfig,
    }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
