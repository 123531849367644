import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryService } from 'src/app/shared/services/category.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { CouponCode } from 'src/app/model/coupon';
import { CouponService } from 'src/app/shared/services/coupon.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.css']
})
export class DeleteComponent implements OnInit {

  constructor(private categoryService: CategoryService,private authenticationService: AuthenticationService,private serviceCoupon:CouponService,
     private ServiceProduct: ProductService, public dialogRef: MatDialogRef<DeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() { }

  delete() {
    if (this.data.deleteitem == 'category') {
      this.categoryService.deleteCategory(this.data.id).toPromise()
        .then(() => {
          this.dialogRef.close();
        });
    }
    else if (this.data.deleteitem == 'product') {
      this.ServiceProduct.deleteProduct(this.data.id).toPromise().then(() => {
        this.dialogRef.close();
      });
    }
    else if (this.data.deleteitem == 'coupon') {
      this.serviceCoupon.deleteCoupon(this.data.id).toPromise().then(() => {
        this.dialogRef.close();
      });
    }
    else if (this.data.deleteitem == 'user') {
      this.authenticationService.deleteUser(this.data.id).toPromise().then(() => {
        this.dialogRef.close();
      });
    }
  }
  cancel() {
    this.dialogRef.close();
  }
}
