<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<app-menu></app-menu>
<section>
    <div class="breadcrumbs_container">
        <div class="container">
            <div class="col-md-12">
                <div class="breadcrumbs">
                    <h1 class="breadcrumb-heading">FAQs</h1>
                    <ul>
                        <li><a href="javascript:void(0)" routerLink="/home">Home</a></li>
                        <li>FAQs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sec-gap">

    <div class="accordion_area">
        <div class="container">
            <div class="row">
                <div class="col-12 mb-2">
                    <h5><strong>Frequently Asked Questions</strong></h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div id="accordion" class="card__accordion" *ngIf="faqLists">
                        <div class="card card_dipult" *ngFor="let faq of faqLists; let i=index;">
                            <div class="card-header card_accor" id="heading{{i}}" (click)="setShowIndex(i)">
                                <button class="btn btn-link" [class.collapsed]="showIndex != i" data-toggle="collapse"
                                    aria-expanded="true">
                                    {{faq.question}}

                                    <i class="fa fa-plus" *ngIf="showIndex != i"></i>
                                    <i class="fa fa-minus" *ngIf="showIndex == i"></i>

                                </button>

                            </div>

                            <div id="collapse{{i}}" class="collapse" [class.show]="showIndex == i"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <p>{{faq.answer}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>