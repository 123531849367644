<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<app-menu></app-menu>

<section class="sec-gap bg-light">
    <div class="row">
        <div class="col-lg-8 col-md-10 col-sm-10 m-auto m-0 payment-card" *ngIf="!error && user && orderObj">
            <div class="card ">
                <div class="card-heading p-4">
                    <h6 class="text-center"><strong>Thank you placing order with us. Your order will be delivered in 3-5
                            business days.</strong></h6>
                </div>
                <div class="card-body p-4">
                    <div class="scrollbar scroller cupon-scroller">
                        <div class="panel-list mt-0">
                            <ul class="points">
                                <li>
                                    <div class="title"><i class="ft-user"></i> Name</div>
                                    <div class="value">{{user.firstName}} {{user.lastName}}</div>
                                </li>
                                <li>
                                    <div class="title"><i class="ft-mail"></i> Order Amount</div>
                                    <div class="value">{{currency}} {{orderObj.total_Amount_to_Pay}}</div>
                                </li>
                                <li *ngIf='orderObj.shippingDetails'>
                                    <div class="title"><i class="la la-building"></i> Shipping Address</div>
                                    <div class="value">
                                        {{orderObj.billingDetails.address_1}}<br>
                                        {{orderObj.billingDetails.address_2}}<br>
                                        {{orderObj.billingDetails.city}},{{orderObj.billingDetails.state}}<br>
                                        {{orderObj.billingDetails.country}} - {{orderObj.billingDetails.postcode}}
                                    </div>

                                </li>
                                <li *ngIf='orderObj.billingDetails'>
                                    <div class="title"><i class="la la-building"></i> Billing Address</div>
                                    <div class="value">
                                        {{orderObj.billingDetails.address_1}}<br>
                                        {{orderObj.billingDetails.address_2}}<br>
                                        {{orderObj.billingDetails.city}},{{orderObj.billingDetails.state}}<br>
                                        {{orderObj.billingDetails.country}} - {{orderObj.billingDetails.postcode}}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <h6><strong>Payment through {{orderObj.payment_method}} -
                                    ***{{orderObj.paymentDetails.last4}}</strong></h6>
                        </div>
                        <div class="col-md-6">
                            <button class="btn btn-success btn-sm mr-2 float-right" type="submit"
                                routerLink="/profile">View Orders</button>
                            <button class="btn btn-success btn-sm mr-2 float-right" type="submit"
                                routerLink="/shop-products">Continue Shopping</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-8 col-md-10 col-sm-10 m-auto m-0 payment-card" *ngIf="error">
            <div class="card ">
                <div class="card-heading p-4">
                    <h6 class="text-center text-danger"><strong>Technical Error. Sorry for the inconvenience.</strong>
                    </h6>
                </div>
                <div class="card-footer">
                    <div class="row">
                        <div class="col-md-6 text-left"></div>
                        <div class="col-md-6">
                            <button class="btn btn-success btn-sm mr-2 float-right" type="submit"
                                routerLink="/profile">View Orders</button>
                            <button class="btn btn-success btn-sm mr-2 float-right" type="submit"
                                routerLink="/shop-products">Continue Shopping</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</section>