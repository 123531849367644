
export class BannerImages {
    bannerS3Url: string[];
    createdBy: string;
    createdDate: Date;
    deletedFlag: boolean;
    id: string;
    imageName: string;
    lastupdatedDate: Date;
    location: string;
    updatedBy: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class FilterInput {
    currency: string;
    deletedFlag: Boolean;
    id: string;
    minOrder: number;
    priceInputs: PriceInput[];
    sortInputs: SortInput[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class PriceInput {
    code: string;
    descriptionForPriceRange: string;
    maxValue: number;
    minValue: number;
    priceRangeName: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

export class SortInput {
    code: string;
    deletedFlag: boolean;
    id: string;
    sortDesc: string;
    sortInput: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}