import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class DataHelper {

    private orderObj = new BehaviorSubject('');
    private orderId = new BehaviorSubject('');
    private routedFromPage = new BehaviorSubject('');

    getOrderObj = this.orderObj.asObservable();
    getOrderId = this.orderId.asObservable();
    getRoutedFromPage = this.routedFromPage.asObservable();


    constructor() { }

    setOrderObj(orderObj: string) {
        this.orderObj.next(orderObj);
    }

    setOrderId(orderID: string) {
        this.orderId.next(orderID);
    }

    setRoutedFromPage(page: string) {
        this.routedFromPage.next(page);
    }
}