import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient) { }

  baseUrl: string = environment.baseUrl;
  
  chargeStripeUrl: string = this.baseUrl + '/api/payment/charge_Stripe';
  paytmUrl: string = this.baseUrl + '/payment/redirectToPayTM';
  paytmUrlResponse: string = this.baseUrl + '/paytm/paymentResponse';


  chargeStripe(data){
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({'Authorization': authhdr});        
    return this.httpClient.post(this.chargeStripeUrl, data, {headers: reqHeader});
  }
  OnPaytm(data) {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.post(this.paytmUrl, data, { headers: reqHeader });
  }
  paytmResponse() {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.get(this.paytmUrlResponse, { headers: reqHeader });
  }
  getPaytmTransactionStatus(orderId) {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.get(this.baseUrl + '/transactionStatus?orderId=' + orderId, { headers: reqHeader });
  }
}
