<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<app-menu></app-menu>

<section class="sec-gap bg-light">

    <div class="col-lg-5 col-md-6 col-sm-12 login-left card front m-0 m-auto">
        <h4>Create Your Free Account</h4>
        <form [formGroup]="registerForm" (submit)="registerUser()">
            <div class="form-group">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-user"></i></span>
                    </div>
                    <input type="text" class="form-control" formControlName="firstName" placeholder="First Name">
                    <small class="error"><label
                            *ngIf="!registerForm.get('firstName').valid && registerForm.get('firstName').touched">
                            Please enter the First name !!!</label></small>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-user"></i></span>
                    </div>
                    <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name">
                    <small class="error"><label
                            *ngIf="!registerForm.get('lastName').valid && registerForm.get('lastName').touched">
                            Please enter the Last name !!!</label></small>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-phone-call"></i></span>
                    </div>
                    <input type="number" class="form-control" formControlName="mobileno" placeholder="Mobile">
                    <small class="error"><label
                            *ngIf="!registerForm.get('mobileno').valid && registerForm.get('mobileno').touched">
                            Please enter the Mobile number !!!</label></small>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-mail"></i></span>
                    </div>
                    <input type="email" class="form-control" formControlName="email" placeholder="Email">
                    <small class="error"><label
                            *ngIf="!registerForm.get('email').valid && registerForm.get('email').touched">
                            Please enter the Email !!!</label></small>
                </div>
            </div>
            <!-- <div class="form-group">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-user"></i></span>
                    </div>
                    <input type="email" class="form-control" formControlName="username" placeholder="User Name">
                    <small class="error"><label
                            *ngIf="!registerForm.get('username').valid && registerForm.get('username').touched">
                            Please enter the User Name !!!</label></small>
                </div>
            </div> -->


            <div class="form-group">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="Password" formControlName="password">
                    <small class="error"><label
                            *ngIf="!registerForm.get('password').valid && registerForm.get('password').touched">
                            Please enter the Password !!!</label></small>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="Re-enter Password" formControlName="confirmPassword">
                    <small class="error"><label
                            *ngIf="!registerForm.get('confirmPassword').valid  ">
                            Password does not match</label></small>
                </div>
            </div>


            <button type="submit" class="btn btn-block btn-success btn-lg" [disabled]="!registerForm.valid">Create My
                Account</button>
        </form>



    </div>

</section>


<div *ngIf="successMessage" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h6><strong>Thank you for registering your account.</strong></h6>
                <p class="text-success"><strong>You can now login and enjoy shopping with us.</strong></p>
            </div>
        </div>
    </div>
</div>

<div *ngIf="errorMessage" id="fail-modal" class="modal fade show " style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h6><strong>{{errorMessageHdr}}</strong></h6>
                <p class="text-danger"><strong>{{errorMessageStr}}</strong></p>
            </div>
        </div>
    </div>
</div>