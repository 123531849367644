<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>



<app-menu></app-menu>
<section class="sec-gap bg-light">
    <div class="container">
        <div class="card">
            <div class="card-body">
                <div class="col-lg-12 col-md-12 col-sm-12 bg-white front m-0 m-auto ">
                    <div id="my-account">

                        <div class="row full-width">
                            <div class="col-lg-3 col-md-4">
                                <div class="dashboard_menu">
                                    <ul class="nav nav-tabs flex-column" role="tablist">
                                        <!--<li class="nav-item">
                                            <a class="nav-link active" id="dashboard-tab" data-toggle="tab"
                                                href="#dashboard" role="tab" aria-controls="dashboard"
                                                aria-selected="false"><i class="ti-layout-grid2"></i>Dashboard</a>
                                        </li>-->
                                        <li class="nav-item">
                                            <a class="nav-link active" id="account-detail-tab" data-toggle="tab"
                                                (click)="getUserDetails()" href="#account-detail" role="tab"
                                                aria-controls="account-detail" aria-selected="true"><i
                                                    class="ti-id-badge"></i>Account details</a>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" id="address-tab" data-toggle="tab" href="#address"
                                                (click)="getUserDetails()" role="tab" aria-controls="address"
                                                aria-selected="true"><i class="ti-location-pin"></i>My Address</a>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" id="orders-tab" data-toggle="tab" href="#orders"
                                                (click)="getUserOrders()" role="tab" aria-controls="orders"
                                                aria-selected="false"><i class="ti-shopping-cart-full"></i>Orders</a>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" id="password-tab" data-toggle="tab" href="#password"
                                                (click)="loadPasswordTab()" role="tab" aria-controls="password"
                                                aria-selected="false"><i class="ti-shopping-cart-full"></i>Change
                                                Password</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-9 col-md-8">
                                <div class="tab-content dashboard_content">
                                    <div class="tab-pane fade" id="dashboard" role="tabpanel"
                                        aria-labelledby="dashboard-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3>Dashboard</h3>
                                            </div>
                                            <div class="card-body">
                                                Dashboard details
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3>My Orders</h3>
                                            </div>
                                            <div class="card-body">

                                                <table
                                                    class="full-width res-table table table-striped tbl-cart reflow-table reflow-table-w-50">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Status</th>
                                                            <th>Total</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody *ngIf="!myOrders || myOrders.length == 0">
                                                        <tr>
                                                            <td colspan="4" class="text-center">
                                                                <h5 class="text-center">No Orders</h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody *ngIf="myOrders && myOrders.length > 0">
                                                        <tr *ngFor="let order of myOrders">
                                                            <td>{{order.createdDate | date : 'medium'}}</td>
                                                            <td>{{order.status}}</td>
                                                            <td>
                                                                <ng-container
                                                                    *ngIf="order.shoppingCartEntry && order.shoppingCartEntry.length > 0">
                                                                    {{order.total_Amount_to_Pay}} for
                                                                    {{order.shoppingCartEntry.length}} item(s)
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="!order.shoppingCartEntry || order.shoppingCartEntry.length == 0">
                                                                    0
                                                                </ng-container>
                                                            </td>
                                                            <td><a href="javascript:void(0)"
                                                                    class="btn btn-success btn-sm" data-toggle="modal"
                                                                    data-target="#orderDetails"
                                                                    (click)="openOrderDetails(order)">View</a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="address" role="tabpanel"
                                        aria-labelledby="address-tab">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="card mb-3 mb-lg-0">
                                                    <div class="card-header">
                                                        <h3>Billing Address</h3>
                                                    </div>
                                                    <div class="card-body" *ngIf="user && user.billingAddress">
                                                        <address>
                                                            {{user.billingAddress.address_1}}<br>
                                                            {{user.billingAddress.address_2}}<br>
                                                            {{user.billingAddress.city}}<br>
                                                            {{user.billingAddress.state}}<br>
                                                            {{user.billingAddress.postcode}}
                                                        </address>
                                                        <p><strong>{{user.billingAddress.country}}</strong></p>
                                                        <a href="javascript:void(0)" class="btn btn-warning"
                                                            data-toggle="modal" data-target="#addressModal"
                                                            (click)="openBillingAddress(user.billingAddress, 'Edit')">Edit</a>
                                                    </div>
                                                    <div class="card-body" *ngIf="!user || !user.billingAddress">
                                                        <a href="javascript:void(0)" class="btn btn-success"
                                                            data-toggle="modal" data-target="#addressModal"
                                                            (click)="openBillingAddress(null, 'Add')">Add</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container
                                                *ngIf="user && user.shippingAddress && user.shippingAddress.length > 0">
                                                <ng-container
                                                    *ngFor="let shipAddr of user.shippingAddress; let i = index">
                                                    <div class="col-lg-6">
                                                        <div class="card">
                                                            <div class="card-header">
                                                                <h3>Shipping Address {{i+1}}</h3>
                                                            </div>
                                                            <div class="card-body">
                                                                <address>
                                                                    {{shipAddr.address_1}}<br>
                                                                    {{shipAddr.address_2}}<br>
                                                                    {{shipAddr.city}}<br>
                                                                    {{shipAddr.state}}<br>
                                                                    {{shipAddr.postcode}}
                                                                </address>
                                                                <p><strong>{{shipAddr.country}}</strong></p>
                                                                <a href="javascript:void(0)" class="btn btn-warning"
                                                                    data-toggle="modal" data-target="#addressModal"
                                                                    (click)="openShippingAddress(shipAddr, i, 'Edit')">Edit</a>
                                                                <ng-container
                                                                    *ngIf="(i == user.shippingAddress.length - 1)">
                                                                    <a href="javascript:void(0)"
                                                                        class="btn btn-success ml-2" data-toggle="modal"
                                                                        data-target="#addressModal"
                                                                        (click)="openShippingAddress(null, user.shippingAddress.length, 'Add')">Add</a>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="!user || !user.shippingAddress || user.shippingAddress.length == 0">
                                                <div class="col-lg-6">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h3>Shipping Address</h3>
                                                        </div>
                                                        <div class="card-body">
                                                            <a href="javascript:void(0)" class="btn btn-success"
                                                                data-toggle="modal" data-target="#addressModal"
                                                                (click)="openShippingAddress(null, 0, 'Add')">Add</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade active show" id="account-detail" role="tabpanel"
                                        aria-labelledby="account-detail-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3>Account Details</h3>
                                            </div>
                                            <div class="card-body">
                                                <form [formGroup]="userForm" *ngIf="userForm">
                                                    <div class="row">
                                                        <div class="form-group col-md-12">
                                                            <label>First Name <span class="required">*</span></label>
                                                            <input required="" class="form-control" name="name"
                                                                type="text" formControlName="firstName">
                                                        </div>
                                                        <div class="form-group col-md-12">
                                                            <label>Last Name <span class="required">*</span></label>
                                                            <input required="" class="form-control" name="name"
                                                                type="text" formControlName="lastName">
                                                        </div>
                                                        <div class="form-group col-md-12">
                                                            <label>Phone <span class="required">*</span></label>
                                                            <input required="" class="form-control" name="phone"
                                                                formControlName="mobileno">
                                                        </div>
                                                        <div class="form-group col-md-12">
                                                            <label>User Name<span class="required">*</span></label>
                                                            <input required="" class="form-control" name="dname"
                                                                type="text" formControlName="username">
                                                        </div>
                                                        <div class="form-group col-md-12">
                                                            <label>Email Address <span class="required">*</span></label>
                                                            <input required="" class="form-control" name="email"
                                                                type="email" formControlName="email">
                                                        </div>
                                                        <!--<div class="form-group col-md-12">
                                                            <label>Current Password <span
                                                                    class="required">*</span></label>
                                                            <input required="" class="form-control" name="password"
                                                                type="password">
                                                        </div>
                                                        <div class="form-group col-md-12">
                                                            <label>New Password <span class="required">*</span></label>
                                                            <input required="" class="form-control" name="npassword"
                                                                type="password">
                                                        </div>
                                                        <div class="form-group col-md-12">
                                                            <label>Confirm Password <span
                                                                    class="required">*</span></label>
                                                            <input required="" class="form-control" name="cpassword"
                                                                type="password">
                                                        </div>-->
                                                        <div class="col-md-12 mt-4">
                                                            <button type="submit" class="btn btn-primary" name="submit"
                                                                value="Submit" *ngIf="!isEditForm"
                                                                (click)="editUserForm()">Edit</button>
                                                            <button type="submit" class="btn btn-success" name="submit"
                                                                value="Submit" *ngIf="isEditForm"
                                                                (click)="saveUser()">Save</button>
                                                            <button type="submit" class="btn btn-danger ml-2"
                                                                name="submit" value="Submit" *ngIf="isEditForm"
                                                                (click)="createUserForm()">Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade active" id="password" role="tabpanel"
                                        aria-labelledby="password-tab">
                                        <div class="card">
                                            <div class="card-header">
                                                <h3>Change Password</h3>
                                                <h6 *ngIf="successMessage && successMessage != ''"
                                                    class="text-success text-center"><strong>{{successMessage}}</strong>
                                                </h6>
                                                <h6 *ngIf="errorMessage && errorMessage != ''"
                                                    class="text-danger text-center"><strong>{{errorMessage}}</strong>
                                                </h6>
                                            </div>
                                            <div class="card-body">
                                                <form [formGroup]="resetPasswordForm" *ngIf="resetPasswordForm">
                                                    <div class="form-group">
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i
                                                                        class="ft-lock"></i></span>
                                                            </div>
                                                            <input type="password" class="form-control"
                                                                placeholder="Old Password"
                                                                formControlName="oldPassword">
                                                            <small class="error"><label
                                                                    *ngIf="!resetPasswordForm.get('oldPassword').valid && resetPasswordForm.get('oldPassword').touched && resetPasswordForm.get('oldPassword').errors.required">
                                                                    Please enter your Old Password !!!</label></small>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i
                                                                        class="ft-lock"></i></span>
                                                            </div>
                                                            <input type="password" class="form-control"
                                                                placeholder="New Password" formControlName="password">
                                                            <small class="error"><label
                                                                    *ngIf="!resetPasswordForm.get('password').valid && resetPasswordForm.get('password').touched && resetPasswordForm.get('password').errors.required">
                                                                    Please enter your new Password !!!</label></small>
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="input-group mb-3">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text"><i
                                                                        class="ft-lock"></i></span>
                                                            </div>
                                                            <input type="password" class="form-control"
                                                                placeholder="Re-enter Password"
                                                                formControlName="confirmPassword">
                                                            <small class="error"><label
                                                                    *ngIf="!resetPasswordForm.get('confirmPassword').valid && resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('confirmPassword').errors.required">
                                                                    Please re-enter new Password !!!</label></small>
                                                            <small class="error"><label
                                                                    *ngIf="!resetPasswordForm.get('confirmPassword').valid && resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('confirmPassword').errors.notEquivalent">
                                                                    Password does not match</label></small>
                                                        </div>
                                                    </div>

                                                    <button class="btn btn-block btn-success btn-lg"
                                                        [disabled]="resetPasswordForm.invalid"
                                                        (click)="resetPassword()">Submit</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>


<div class="modal" tabindex="-1" role="dialog" id="orderDetails">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content" *ngIf="selectedOrder">
            <div class="modal-header">
                <h6 class="modal-title">Order Details <span class="badge"
                        [class.badge-primary]="selectedOrder.status == 'CREATED'"
                        [class.badge-danger]="selectedOrder.status == 'RETURNED' || selectedOrder.status == 'CANCELLED'"
                        [class.badge-success]="selectedOrder.status == 'DELIVERED'"
                        [class.badge-warning]="selectedOrder.status == 'DISPATCHED' || selectedOrder.status == 'OUT_FOR_DELIVERY'">{{selectedOrder.status}}</span>
                </h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-6 order-sm-0">
                        <address *ngIf='selectedOrder.billingDetails'>
                            <h6>Billing Address</h6>
                            {{selectedOrder.billingDetails.firstName}} {{selectedOrder.billingDetails.lastName}}<br>
                            {{selectedOrder.billingDetails.address_1}}<br>
                            {{selectedOrder.billingDetails.address_2}}<br>
                            {{selectedOrder.billingDetails.city}},{{selectedOrder.billingDetails.state}}<br>
                            {{selectedOrder.billingDetails.country}}-{{selectedOrder.billingDetails.postcode}}<br>
                            <i class="ft-phone"></i> Mob : {{selectedOrder.billingDetails.mobileNo}}
                        </address>
                    </div>

                    <div class="col-sm-6 order-sm-0">
                        <address *ngIf='selectedOrder.shippingDetails'>
                            <h6>Shipping Address</h6>
                            {{selectedOrder.shippingDetails.firstName}} {{selectedOrder.shippingDetails.lastName}}<br>
                            {{selectedOrder.shippingDetails.address_1}}<br>
                            {{selectedOrder.shippingDetails.address_2}}<br>
                            {{selectedOrder.shippingDetails.city}},{{selectedOrder.shippingDetails.state}}<br>
                            {{selectedOrder.shippingDetails.country}}-{{selectedOrder.shippingDetails.postcode}}<br>
                            <i class="ft-phone"></i> Mob : {{selectedOrder.shippingDetails.mobileNo}}
                        </address>
                    </div>
                </div>
                <div class="card no-shadow" *ngIf="selectedOrder.shoppingCartEntry">
                    <div class="scrollbar scroller prod-scroller ">
                        <table class="table res-table table-striped admin-tbl reflow-table prod-chkout">
                            <thead class="text-muted">
                                <tr>
                                    <th>Product</th>
                                    <th class="text-right">Quantity</th>
                                    <th class="text-right">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cartEntry of selectedOrder.shoppingCartEntry">
                                    <td data-th="Product ">
                                        <figure class="media">
                                            <!--<div class="img-wrap"><img *ngIf="cartEntry.imageUrl"
                                                    [src]="cartEntry.imageUrl | safe: 'url'" class="prod-img">
                                            </div>-->
                                            <figcaption class="media-body">
                                                <h6 class="title text-truncate">{{cartEntry.productName}} </h6>
                                                @ {{currency}} {{cartEntry.productCost}} each
                                            </figcaption>
                                        </figure>
                                    </td>
                                    <td class="text-right" data-th="Quantity">
                                        <div class="price-wrap text-right">
                                            <var class="price">{{cartEntry.quantity}}</var>
                                        </div>
                                    </td>
                                    <td data-th="Price">
                                        <div class="price-wrap text-right">
                                            <var class="price">{{currency}} {{(cartEntry.productTotalPrice)}}</var>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <table class="table res-table  admin-tbl reflow-table prod-chkout">
                        <tbody>
                            <tr>
                                <td class="text-right">
                                    Total
                                </td>

                                <td class="text-right" data-th="Total Quantity"></td>

                                <td class="text-right" data-th="Total Price">

                                    <strong>{{currency}} {{selectedOrder.total_Amount_to_Pay}}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mt-1 mb-4" *ngIf="selectedOrder && selectedOrder.paymentDetails">
                    <div class="col-sm-12 order-sm-0 mt-2 text-left">

                        <div class="panel-list mt-0">
                            <h6 class="Title mt-1 ml-2">Payment Details</h6>
                            <ul class="points">
                                <li>
                                    <div class="title">Payment Type</div>
                                    <div class="value">{{selectedOrder.paymentDetails.brand}}</div>
                                </li>
                                <li>
                                    <div class="title">Card # : Card ending</div>
                                    <div class="value">{{selectedOrder.paymentDetails.last4}}</div>
                                </li>
                                <li>
                                    <div class="title">Funding</div>
                                    <div class="value">{{selectedOrder.paymentDetails.funding}}</div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-8 order-sm-0 mt-2 text-left">
                        <div class=" form-group icon-form" *ngIf="selectedOrder.status == 'CREATED'">
                            <label class="label">Reason</label>
                            <i class="ft-package"></i>
                            <textarea class="form-control transparent-tb" [(ngModel)]="cancelComments"
                                name="cancelComments" placeholder=" Enter your reason here to Cancel"
                                style="color:black"></textarea>

                        </div>
                        <div class="panel-list mt-0" *ngIf="selectedOrder.status == 'CANCELLED'">
                            <ul class="points">
                                <li>
                                    <div class="title">Reason for Cancellation</div>
                                    <div class="value">{{selectedOrder.reasonForCancellation}}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-4 order-sm-0 mt-4 text-left">
                        <button class="btn btn-danger rounded-pill auto" *ngIf="selectedOrder.status == 'CREATED'"
                            [disabled]="!cancelComments || cancelComments == ''" (click)="cancelOrder()"
                            data-dismiss="modal">Cancel Order</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal" tabindex="-1" role="dialog" id="addressModal">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{operationType}} {{operationAddress}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="addressForm">
                <form [formGroup]="addressForm">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label>Address Line 1<span class="required">*</span></label>
                            <input required="" class="form-control" name="address1" type="text"
                                formControlName="address_1">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Address Line 2</label>
                            <input class="form-control" name="address2" type="text" formControlName="address_2">
                        </div>
                        <div class="form-group col-md-6">
                            <label>City <span class="required">*</span></label>
                            <input required="" class="form-control" name="city" formControlName="city">
                        </div>
                        <div class="form-group col-md-6">
                            <label>State <span class="required">*</span></label>
                            <input required="" class="form-control" name="state" formControlName="state">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Country <span class="required">*</span></label>
                            <input required="" class="form-control" name="country" formControlName="country">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Postal Code <span class="required">*</span></label>
                            <input required="" class="form-control" name="postcode" formControlName="postcode">
                        </div>
                        <div class="col-md-12 mt-4">
                            <button type="submit" class="btn btn-success" name="submit" (click)="saveAddress()"
                                [disabled]="addressForm.invalid" data-dismiss="modal">Save</button>
                            <button type="submit" class="btn btn-danger ml-2" name="submit" (click)="cancelAddress()"
                                data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>



<div *ngIf="successModal" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>{{successMessage}}</h4>
                <p class="text-success">Successfully </p>
                <button class="btn btn-success" data-dismiss="modal" (click)="closeModal()">Close</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="errorModal" id="fail-modal" class="modal fade show " style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Sorry</h4>
                <p class="text-danger">{{errorMessage}} </p>
                <button class="btn btn-success" data-dismiss="modal" (click)="closeModal()">Close</button>
            </div>
        </div>
    </div>
</div>