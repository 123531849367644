import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DeliveryCost } from 'src/app/model/deliveryCost';

@Injectable({
  providedIn: 'root'
})
export class DeliveryCostService {
  constructor(private httpClient: HttpClient, private router: Router) { }

  baseUrl: string = environment.baseUrl;

  saveDCUrl: string = this.baseUrl + '/api/addState';
  updateDCUrl: string = this.baseUrl + '/api/updateStateById';
  deleteDCUrl: string = this.baseUrl + '/api/deleteStateById/';
  getAllDCUrl: string = this.baseUrl + '/api/getAllStates';
  getStateByIdUrl: string = this.baseUrl + '/api/getStateById/';


  saveDC(dC: DeliveryCost) {
      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.post(this.saveDCUrl, dC, { headers: reqHeader });
  }

  updateDC(dC: DeliveryCost) {

      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.put(this.updateDCUrl, dC, { headers: reqHeader });
  }

  deleteDC(dC) {
      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.delete(this.deleteDCUrl + dC, { headers: reqHeader });
  }

  getAllDC() {
      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.get<DeliveryCost[]>(this.getAllDCUrl, { headers: reqHeader });
  }

  getStateById(dcId) {
      var bearerToken = localStorage.getItem('bearerToken');
      var authhdr = 'Bearer ' + bearerToken;
      let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
      return this.httpClient.get<DeliveryCost>(this.getStateByIdUrl + dcId, { headers: reqHeader });
  }



}