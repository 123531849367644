export class Faq {


    answer: string;
    createdDate: Date;
    deletedFlag: boolean;
    id: string;
    lastupdatedDate: Date;
    question: string;
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

}
