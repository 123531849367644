<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">

        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="d-flex flex-column flex-md-row align-items-center text-center text-md-left">

                <div class="breadcrumb-header justify-content-between">
                    <div class="row w-100">
                        <div class="col-md-8">
                            <h4 class="content-title mb-2">Hi, welcome back!</h4>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">

                                    <li class="breadcrumb-item active" *ngIf="!idFaq" aria-current="page">Add
                                        FAQ</li>
                                    <li class="breadcrumb-item active" *ngIf="idFaq" aria-current="page">Edit
                                        FAQ</li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-md-4 ml-auto">
                            <div
                                class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                                <div class="row w-100">
                                    <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                        <div> <span class="d-block"> <span class="label"></span> </span>
                                            <span class="value"> </span>
                                        </div>
                                        <div class="ml-3 mt-0">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                        <div>
                                            <span class="d-block"> <span class="label ">INCOME</span>
                                            </span>
                                            <span class="value">{{currency}} {{income}} </span>
                                        </div>
                                        <div class="ml-3 mt-0">
                                            <span class="sparkline-bar text-warning">
                                                <i class="ft-bar-chart-2"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row row-sm">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-0" *ngIf="!idFaq">Add FAQ</h4>
                            <h4 class="card-title mb-0" *ngIf="idFaq">Update FAQ</h4>
                            <p class="sub-text">It is Very Easy to Customize and it uses in your website
                                apllication.
                            </p>
                            <hr>
                            <section class="inner-section-gap" *ngIf="regiForm">
                                <form [formGroup]="regiForm">
                                    <div class="row-sm">
                                        <div class="justify-content-left row m-auto my-auto">

                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                                <label class="label">Question</label>
                                                <i class="ft-package"></i>
                                                <textarea class="form-control transparent-tb" formControlName="question"
                                                    placeholder="Question" style="color:black"></textarea>
                                                <small class="form-text text-danger"
                                                    *ngIf="!regiForm.get('question').valid && regiForm.get('question').touched">
                                                    Please enter the Question !!!</small>
                                            </div>
                                            <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                                <label class="label">Answer</label>
                                                <i class="ft-package"></i>
                                                <textarea class="form-control transparent-tb" formControlName="answer"
                                                    placeholder="Answer" style="color:black"></textarea>
                                                <small class="form-text text-danger"
                                                    *ngIf="!regiForm.get('answer').valid && regiForm.get('answer').touched">
                                                    Please enter the Answer !!!</small>
                                            </div>



                                            <div class="spacer-30"></div>

                                            <div class="mt-4 text-center col-md-4 m-auto">
                                                <button (click)="cancel()"
                                                    class="btn  btn-danger btn-sm rounded-pill mr-2">Cancel
                                                </button>
                                                <button type="submit" *ngIf="!idFaq" [disabled]="!regiForm.valid"
                                                    class=" btn btn-primary btn-sm rounded-pill ml-2"
                                                    (click)="onSubmit(regiForm.value)">Add </button>
                                                <button type="submit" *ngIf="idFaq" [disabled]="!regiForm.valid"
                                                    class=" btn btn-primary btn-sm rounded-pill ml-2"
                                                    (click)="onSubmit(regiForm.value)">Update </button>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </section>

                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="success" id="email-verifiacation-modal" class="modal fade show"
                style="display:block!important;">
                <div class="modal-dialog  modal-dialog-centered modal-md">
                    <div class="modal-content modal-confirm">
                        <div class="modal-header">
                            <div class="icon-box">
                                <i class="ft-check"></i>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body text-center">
                            <h4 *ngIf="!idFaq">FAQ Added</h4>
                            <h4 *ngIf="idFaq">FAQ Updated</h4>
                            <p class="text-success">Successfully </p>


                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="errorPopup" id="fail-modal" class="modal fade show" style="display:block!important;">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content modal-reject ">
                        <div class="modal-header">
                            <div class="icon-box">
                                <i class="ft-alert-triangle"></i>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                        </div>
                        <div class="modal-body text-center">
                            <h4>Sorry</h4>
                            <p class="text-danger">Some went wrong. Try again</p>
                            <p></p>

                        </div>
                    </div>
                </div>
            </div>