import { ShoppingCartRequest } from 'src/app/model/shoppingCartRequest';
import { ShoppingCartEntries, ShoppingCartResponse } from 'src/app/model/shoppingCartResponse';
import { User } from 'src/app/model/user';
import { CartService } from '../services/cart.service';
import { ProductService } from '../services/product.service';

export class CartHelpers {

    constructor(private cartService: CartService, private productService: ProductService) { }

    user: User;

    checkCartandUpdate(product, productQuantity): Promise<ShoppingCartEntries[]> {
        this.user = JSON.parse(localStorage.getItem('user'));
        let promise = new Promise<ShoppingCartEntries[]>((resolve, reject) => {
            this.cartService.getCartList(this.user.id).subscribe(response => {
                let existingCart: ShoppingCartResponse = response;
                let isExistingProduct: Boolean = false;
                if (existingCart && existingCart.shoppingCartEntries && existingCart.shoppingCartEntries.length > 0) {
                    let shoppingCart: ShoppingCartEntries[] = existingCart.shoppingCartEntries;
                    shoppingCart.forEach(cart => {
                        if (cart.productId == product.id) {
                            cart.quantity = cart.quantity + parseInt(productQuantity);
                            cart.productTotalPrice = (cart.quantity * parseInt(product.productCost));
                            isExistingProduct = true;
                        }
                    });
                    if (isExistingProduct) {
                        resolve(shoppingCart);
                    } else {
                        shoppingCart.push({
                            productId: product.id,
                            imageId: product.productImageUrl[0],
                            productImageUrl: product.productImageUrl,
                            productName: product.productName,
                            productCost: product.productCost,
                            productCategory: product.productCategory,
                            quantity: productQuantity,
                            productTotalPrice: (parseInt(productQuantity) * parseInt(product.productCost)),
                            packageQuantity:product.packageQuantity,
                            categoryId:product.categoryId,
                            assignPackageQuantity:false,
                        });
                        resolve(shoppingCart);
                    }
                } else {
                    let shoppingCart: ShoppingCartEntries[] = [];
                    shoppingCart.push({
                        productId: product.id,
                        imageId: product.productImageUrl[0],
                        productImageUrl: product.productImageUrl,
                        productName: product.productName,
                        productCategory: product.productCategory,
                        productCost: product.productCost,
                        quantity: productQuantity,
                        productTotalPrice: (parseInt(productQuantity) * parseInt(product.productCost)),
                        packageQuantity:product.packageQuantity,
                        categoryId:product.categoryId,
                        assignPackageQuantity:false
                    });
                    resolve(shoppingCart);
                }
            });

        });
        return promise;
    }

    updateCartQuantity(cartEntry, quantity): Promise<ShoppingCartEntries[]> {
        this.user = JSON.parse(localStorage.getItem('user'));
        let promise = new Promise<ShoppingCartEntries[]>((resolve, reject) => {
            this.cartService.getCartList(this.user.id).subscribe(response => {
                let existingCart: ShoppingCartResponse = response;
                let isExistingProduct: Boolean = false;
                if (existingCart && existingCart.shoppingCartEntries && existingCart.shoppingCartEntries.length > 0) {
                    let shoppingCart: ShoppingCartEntries[] = existingCart.shoppingCartEntries;
                    shoppingCart.forEach(cart => {
                        if (cart.productId == cartEntry.productId) {
                            cart.quantity = cart.quantity + parseInt(quantity);
                            cart.productTotalPrice = (cart.quantity * parseInt(cartEntry.productCost));
                            isExistingProduct = true;
                        }
                    });
                    resolve(shoppingCart);
                }
            });

        });
        return promise;
    }

    removeFromCart(i): Promise<ShoppingCartEntries[]> {
        this.user = JSON.parse(localStorage.getItem('user'));
        let promise = new Promise<ShoppingCartEntries[]>((resolve, reject) => {
            this.cartService.getCartList(this.user.id).subscribe(response => {
                let existingCart: ShoppingCartResponse = response;
                if (existingCart && existingCart.shoppingCartEntries && existingCart.shoppingCartEntries.length > 0) {
                    let shoppingCart: ShoppingCartEntries[] = existingCart.shoppingCartEntries;
                    shoppingCart.splice(i, 1);
                    resolve(shoppingCart);
                }
            });

        });
        return promise;
    }

}