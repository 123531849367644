import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { OrderService } from 'src/app/shared/services/order.service';

@Component({
  selector: 'app-addadmin',
  templateUrl: './addadmin.component.html',
  styleUrls: ['./addadmin.component.css']
})
export class AddadminComponent implements OnInit {
  registerForm: FormGroup;
  errorMessage: boolean = false;
  successMessage: boolean = false;
  loading: boolean = false;
  errorMessageStr: string = "";
  errorMessageHdr: string = "";

  currency: String = "";
  allCounts: any;
  income: number;
  user: User;
  constructor(private router: Router, private fb: FormBuilder, private orderService: OrderService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {

    this.user = JSON.parse(localStorage.getItem('user'));
    this.currency = localStorage.getItem('currency');
    this.getTotalCounts();
    this.createRegisterForm();



  }

  /** New Backend Changes */
  newUser: User;
  createRegisterForm() {
    this.registerForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      email: new FormControl(''),
      mobileno: new FormControl(''),
      username: new FormControl(''),
      userType: new FormControl(''),
      billingAddress: new FormGroup({
        address_1: new FormControl(''),
        address_2: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        country: new FormControl(''),
        postcode: new FormControl(''),
        email: new FormControl(''),
        firstName: new FormControl(''),
        lastName: new FormControl(''),
        mobileNo: new FormControl(''),

      })
    });

  }
  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }


  registerUser() {
    if (this.registerForm.valid) {
      this.loading = true;
      this.newUser = new User(this.registerForm.value);
      this.newUser.billingAddress.firstName = this.newUser.firstName;
      this.newUser.billingAddress.lastName = this.newUser.lastName;
      this.newUser.billingAddress.email = this.newUser.email;
      this.newUser.billingAddress.mobileNo = this.newUser.mobileno;
      console.log(this.newUser);
      this.authenticationService.register(this.newUser).toPromise().then(
        response => {
          this.loading = false;
          this.successMessage = true;
          setTimeout(() => {
            this.successMessage = false;
            this.router.navigate(['/admin/user-list']);
          }, 2000);
        }
      ).catch(
        error => {
          this.loading = false;
          if (error && error.error && error.error.failure) {
            this.errorMessageStr = error.error.failure;
            this.errorMessageHdr = "Sorry. Error while creating your account.";
          } else {
            this.errorMessageStr = "Server Error. Please try again after some time.";
            this.errorMessageHdr = "Sorry for the inconvenience.";
          }
          this.errorMessage = true;
          setTimeout(() => {
            this.errorMessage = false;
          }, 3000);
        }
      );
    }
  }
  addressForm: boolean = false;
  selectedUserType(e) {
    if (e == 'WHOLESALE') {
      this.addressForm = true;
    }
    else {
      this.addressForm = false;
    }


  }
  /** New Backend Changes */
}

