import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { Validators, FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { CartService } from 'src/app/shared/services/cart.service';
import { DataHelper } from 'src/app/shared/helper/data.helper';
import { Order } from 'src/app/model/order';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/shared/services/order.service';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.css']
})
export class MakePaymentComponent implements OnInit {

  handler: any;
  token: string;
  loading: Boolean = false;
  customStripeForm: FormGroup;
  message: any;
  submitted: boolean;
  formProcess: boolean;
  sessionOrderObjStr: string;
  sessionOrderObj: Order;
  successModal: Boolean = false;
  errorModal: Boolean = false;
  successMessage: string;
  errorMessageHeader: string;
  errorMessage: string;
  tokenObject: any;
  user: User;
  orderSubmitted: Boolean = false;
  currency: string = "";

  constructor(private paymentservice: PaymentService, private fb: FormBuilder,
    private cartService: CartService, private stripeService: StripeService, private orderService: OrderService,
    private router: Router, private dataHelper: DataHelper) { }

  ngOnInit() {
    this.dataHelper.setOrderId("");
    this.dataHelper.getOrderObj.subscribe(parameter => this.sessionOrderObjStr = parameter);
    this.orderSubmitted = false;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.currency = localStorage.getItem('currency');

    if (this.sessionOrderObjStr && this.sessionOrderObjStr.length > 0) {
      this.sessionOrderObj = JSON.parse(this.sessionOrderObjStr);
      if (this.sessionOrderObj && this.sessionOrderObj.billingDetails && this.sessionOrderObj.shippingDetails) {
        this.loadPaymentForm();
      } else {
        this.errorMessageHeader = "Sorry. Cart needs to be refreshed.";
        this.errorMessage = "Redirecting to Cart Page";
        this.errorModal = true;
        setTimeout(() => {
          this.errorMessage = "";
          this.errorMessageHeader = "";
          this.errorModal = false;
          this.router.navigate(['/shopping-cart']);
        }, 2000);
      }
    } else {
      this.errorMessageHeader = "Sorry. Cart needs to be refreshed.";
      this.errorMessage = "Redirecting to Cart Page";
      this.errorModal = true;
      setTimeout(() => {
        this.errorMessage = "";
        this.errorMessageHeader = "";
        this.errorModal = false;
        this.router.navigate(['/shopping-cart']);
      }, 2000);
    }
  }

  loadPaymentForm() {
    this.customStripeForm = this.fb.group({
      userName: new FormControl('', [Validators.required]),
      cardNumber: new FormControl('', [Validators.required]),
      expMonth: new FormControl('', [Validators.required]),
      expYear: new FormControl('', [Validators.required]),
      cvc: new FormControl('', [Validators.required])
    });
  }

  createToken() {
    if (this.customStripeForm.valid) {
      this.loading = true;
      let stripeData = {
        number: this.customStripeForm.get('cardNumber').value,
        exp_month: this.customStripeForm.get('expMonth').value,
        exp_year: this.customStripeForm.get('expYear').value,
        cvc: this.customStripeForm.get('cvc').value
      };

      (<any>window).Stripe.card.createToken(stripeData, (status: number, response: any) => {
        if (status === 200) {
          this.loading = false;
          this.tokenObject = response;
          let token = response.id;
          this.chargeStripe(token);
        } else {

          this.loading = false;
          this.errorMessageHeader = "Sorry. Payment Failed. Please try again later";
          this.errorMessage = response.error.message;
          this.errorModal = true;
          setTimeout(() => {
            this.errorMessage = "";
            this.errorMessageHeader = "";
            this.errorModal = false;
          }, 2000);
        }
      });
    }
  }

  chargeStripe(tokenId) {
    if (tokenId && this.sessionOrderObj && this.sessionOrderObj.total_Amount_to_Pay > 0) {
      this.loading = true;
      let data = {
        token: tokenId,
        amount: this.sessionOrderObj.total_Amount_to_Pay
      }
      this.paymentservice.chargeStripe(data).toPromise().then(
        response => {
          this.loading = false;
          this.submitOrder();
        }, error => {

          this.loading = false;
          this.errorMessageHeader = "Sorry. Payment Failed. Please try again later";
          this.errorMessage = error.error.message;
          this.errorModal = true;
          setTimeout(() => {
            this.errorMessage = "";
            this.errorMessageHeader = "";
            this.errorModal = false;
          }, 2000);
        }
      )
    }
  }

  submitOrder() {
    this.sessionOrderObj.payment_method = "CARD";
   
    this.sessionOrderObj.paymentDetails = {
      brand: this.tokenObject.card.brand,
      funding: this.tokenObject.card.funding,
      id: this.tokenObject.id,
      last4: this.tokenObject.card.last4,
      expMonth: this.tokenObject.card.expMonth,
      expYear: this.tokenObject.card.expYear,
      bankTxnId:null,
      gatewayName: null,
      chequeNo:null,
      paymentReceiptName: null,
  paymentReceiptUrl: null
    };
    this.orderService.createOrder(this.sessionOrderObj).toPromise().then(response => {
      this.loading = false;
      let order: Order = response as Order;
      this.dataHelper.setOrderId(order.id);
      this.router.navigate(['/order-success']);
    }, error => {

      this.loading = false;
      this.errorMessageHeader = "Sorry. Error while placing an order";
      this.errorMessage = error.error.message;
      this.errorModal = true;
      setTimeout(() => {
        this.errorMessage = "";
        this.errorMessageHeader = "";
        this.errorModal = false;
        this.router.navigate(['/shopping-cart']);
      }, 2000);
    });
  }

  refresh() {
    this.router.navigateByUrl('/payment', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/payment']);
    });
  }
}
