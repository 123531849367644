import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService,
    private _snackBar: MatSnackBar) { 
    
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.authenticationService.isAdmin()||this.authenticationService.isWholesale()){
      return true;
    }
    this._snackBar.open('Only Admins have the previllege to access this page.', 'OK', {
      duration: 2000
    });
    this.router.navigate(["/home"]);
    return false;    
  }
  
}
