<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<app-menu></app-menu>

<section>
    <div class="breadcrumbs_container">
        <div class="container">
            <div class="col-md-12">
                <div class="breadcrumbs">
                    <h1 class="breadcrumb-heading">ABOUT US</h1>
                    <ul>
                        <li><a href="javascript:void(0)" routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>






<section class="sec-gap bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mb-res-sm-50px">
                <div class="about-left-image">
                    <img src="assets/images/category-1.jpg" alt="" class="img-responsive">
                </div>
            </div>
            <div class="col-lg-6">
                <div class="content-section">
                    <h2>Welcome To Soyabag private limited</h2>
                    <p class="mb-30px">
                        Soyabag Private Limited is a startup food manufacturer, supplier and research based organization, aiming to distribute 100% Pure Vegetarian food with the highest level of purity in the best available price across the globe. We have been researching, manufacturing and marketing a great variety of delicious vegetarian food to tantalize the taste of all mouths. Our aim is to promote healthy diet without sacrificing any life stocks, hence promoting healthy vegetarianism.
By saying so, we make sure all our products are manufactured from the most natural and nutricious ingredients like the soy beans, soy fiber, wheat fiber and other vegetables. All food produced are manufactured using standardized food processors and are produced under optimum hygienic management, hence adopting the good manufacturing practice.
With your promising support and great aspiration of promoting healthy vegetarianism, Soybag Private Limited, with its team members, hereby dedicate our greatest gratitude to your love and support for the company. Along with manufacturing, we keep researching on various new products that are not readily available in the market, and tend to suit your daily needs along great taste, and to maintain a healthy Vegetarian diet, and thus a healthy life.
                    </p>


                </div>
            </div>
        </div>

    </div>
</section>