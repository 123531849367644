<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<section class="sec-gap bg-light">

    <div class="col-lg-5 col-md-6 col-sm-12 login-left card front m-0 m-auto">
        <h4>Forgot Password</h4>
        <h6 *ngIf="successMessage && successMessage != ''" class="text-success text-center"><strong>{{successMessage}}</strong></h6>
    <h6 *ngIf="errorMessage && errorMessage != ''" class="text-danger text-center"><strong>{{errorMessage}}</strong></h6>
        <ng-container *ngIf="hasResetKey">
        <form [formGroup]="resetPasswordForm" *ngIf="resetPasswordForm">
            <div class="form-group">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="Password" formControlName="password">
                    <small class="error"><label
                            *ngIf="!resetPasswordForm.get('password').valid && resetPasswordForm.get('password').touched && resetPasswordForm.get('password').errors.required">
                            Please enter your new Password !!!</label></small>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="Re-enter Password"
                        formControlName="confirmPassword">
                    <small class="error"><label
                            *ngIf="!resetPasswordForm.get('confirmPassword').valid && resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('confirmPassword').errors.required">
                            Please re-enter new Password !!!</label></small>
                    <small class="error"><label *ngIf="!resetPasswordForm.get('confirmPassword').valid && resetPasswordForm.get('confirmPassword').touched && resetPasswordForm.get('confirmPassword').errors.notEquivalent">
                            Password does not match</label></small>
                </div>
            </div>

            <button class="btn btn-block btn-success btn-lg" [disabled]="resetPasswordForm.invalid"
                (click)="finishForgotPassword()">Submit</button>
        </form>

    </ng-container>
    <ng-container *ngIf="!hasResetKey">
        <div class="form-group">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text"><i class="ft-mail"></i></span>
                </div>
                <input type="text" class="form-control" #passwordResetEmal="ngModel"
                    [(ngModel)]="passwordResetEmail" name="passwordResetEmail" placeholder="Email Address">
                <small class="error" *ngIf="passwordResetEmal.invalid && passwordResetEmal.touched"><label>Required
                        Field</label></small>
            </div>
        </div>

        <button class="btn btn-block btn-success btn-lg"
            [disabled]="!passwordResetEmail || passwordResetEmail == ''" (click)="sendEmail()">Retrieve
            Password</button>
    </ng-container>


    <div class="text-center mt-4 rel-z-1">
        Login <a routerLink="/login" href="javascript:void(0)">Click Here</a>
    </div>

    </div>

</section>