import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductService } from '../../shared/services/product.service';

@Component({
  selector: 'app-displaycategorylist',
  templateUrl: './displaycategorylist.component.html',
  styleUrls: ['./displaycategorylist.component.css']
})
export class DisplaycategorylistComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  allCategories: any;
  user: User;
  categoryFilter: string;
  loading: boolean = false;
  successDelete: boolean = false;
  income: number;
  allCounts: any;
  currency: String = "";
  selectedCategory: any;

  constructor(private categoryService: CategoryService, private serviceDialog: MatDialog, private router: Router,
    private ServiceProduct: ProductService, private orderService: OrderService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.getCategorylist();
      this.getTotalCounts();
    }
  }
  getCategorylist() {
    this.loading = true;
    this.categoryService.getAllCategories().subscribe(response => {
      this.allCategories = response;
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }
  addCategoryPage() {
    this.loading = true;
    this.router.navigate(['/admin/add-editCategory']);
  }

  delete(category) {
    this.successDelete=false;
    this.loading = true;
    this.categoryService.deleteCategory(category).toPromise()
      .then(() => {
        this.loading = false;
        
        this.getCategorylist();
      });

  }



  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }
  selectedcategory(category){
    this.selectedCategory=category.id;
    this.successDelete = true;
  }
  cancel(){
    this.successDelete=false;
  }
}
