<!-- Topbar Admin -->
<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">
        <!-- Topbar Admin -->
        <!-- <div class="inner-topbar sidebar-left" id="nav-sticky">
                    <button class="navbar-toggler pull-left res-btn-left" type="button" id="res-btn">
                        <i class="ft-align-left"></i>
                    </button>
                    <ul class="nav navbar-nav float-right ml-auto profile-menu">
    
                        <li class="nav-item maximize fullscreen-button">
                            <a href=""><i class="ft-maximize"></i></a>
                        </li>
    
                        <li class="dropdown nav-item active">
                            <a class="dropdown-toggle nav-link " href="javascript:void(0)" data-toggle="dropdown" aria-expanded="false">
                                <span class="avatar"><img src="assets/images/avatar.png" alt="avatar"></span>
                            </a>
                            <div class="dropdown-menu">
                                <div class="main-header-profile header-img">
                                    <div class="main-img-user"><img alt="" src="assets/images/avatar.png"></div>
                                    <h6>DIPTISUNDAR</h6><small>Administrator</small>
                                </div> <a class="dropdown-item" href=""><i class="far fa-user"></i> My Profile</a> <a
                                    class="dropdown-item" href=""><i class="far fa-edit"></i> Edit Profile</a> <a
                                    class="dropdown-item" href=""><i class="far fa-clock"></i> Activity Logs</a> <a
                                    class="dropdown-item" href=""><i class="fas fa-sliders-h"></i> Account Settings</a>
                                <a class="dropdown-item" href="login.html"><i class="fas fa-sign-out-alt"></i> Sign
                                    Out</a>
                            </div>
                        </li>
    
                    </ul>
                </div> -->
        <app-top-bar></app-top-bar>
        <div class="container-fluid">

            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page"> Dashboard</li>


                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">

                                    </div>
                                </div>
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div>
                                        <span class="d-block"> <span class="label ">INCOME</span>
                                        </span>
                                        <span class="value">{{currency}} {{income}}</span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                        <span class="sparkline-bar text-warning">
                                            <i class="ft-bar-chart-2"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row row-sm">
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="card overflow-hidden project-card">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="project-card-box primary">
                                <i class="ft-package"></i>
                            </div>
                            <div class="project-content">
                                <h6><b>Total Products</b></h6>
                                <ul>
                                    <!-- <li> <strong>Processing</strong> <span></span> </li>-->
                                    <li> <span><b>{{totalProducts}}</b></span> </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="card  overflow-hidden project-card">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="project-card-box danger">
                                <i class="ft-share-2"></i>
                            </div>
                            <div class="project-content">
                                <h6><b>Total Categories</b></h6>
                                <ul>
                                    <li> <span><b>{{totalCategories}}</b></span> </li>
                                    <!-- <li> <strong>Not Essential</strong> <span></span> </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="card overflow-hidden project-card">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="project-card-box success">
                                <i class="ft-shopping-cart"></i>
                            </div>
                            <div class="project-content">
                                <h6><b>Total Orders</b></h6>
                                <ul>
                                    <li> <span><b>{{totalOrders}}</b></span> </li>
                                    <!-- <li> <strong>Accepted</strong> <span></span> </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="card overflow-hidden project-card">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="project-card-box warning">
                                <i class="ft-users"></i>
                            </div>
                            <div class="project-content">
                                <h6><b>Total Users</b></h6>
                                <ul>
                                    <li> <span><b>{{totalUsers}}</b></span> </li>
                                    <!-- <li> <strong>Free</strong> <span></span> </li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-sm justify-content-center ">
            <div class="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                <div class="card overflow-hidden">
                    <div class="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-10">Monthly Order</h4>
                        </div>
                        <p class="tx-12 text-muted mb-2"></p>
                    </div>
                    <div class="card-body">

                        <canvas *ngIf="lineChartData && lineChartData.length > 0" height="100" baseChart
                            [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
                            [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType"
                            [plugins]="lineChartPlugins">
                        </canvas>
                    </div>
                </div>
            </div>

            <div class="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                <div class="card overflow-hidden">
                    <div class="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-10">Sales Report</h4>
                        </div>
                        <p class="tx-12 text-muted mb-2"></p>
                    </div>
                    <div class="card-body">
                        <canvas *ngIf="barChartData && barChartData.length > 0" height="20vh" width="80vw" baseChart
                            [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                            [legend]="barChartLegend" [chartType]="barChartType">
                        </canvas>
                    </div>
                </div>
            </div>


            <div class="col-xl-11 col-lg-12 col-md-12 col-sm-12">
                <div class="card overflow-hidden">
                    <div class="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-10">Most Selling Product Category</h4>
                        </div>
                        <p class="tx-12 text-muted mb-2"></p>
                    </div>
                    <div class="card-body">
                        <canvas baseChart height="20vh" width="80vw" [data]="pieChartData" [labels]="pieChartLabels"
                            [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                            [legend]="pieChartLegend" [colors]="pieChartColors">
                        </canvas>
                    </div>
                </div>
            </div>


            <!-- <div class="row row-sm col-md-12">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="card overflow-hidden">
                        <div class="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0">
                            <div class="d-flex justify-content-between">
                                <h4 class="card-title mg-b-10">Todays Orders</h4>
                            </div>
                            <p class="tx-12 text-muted mb-2"></p>
                        </div>
                        <div class="card-body ">

                            <table class="table res-table table-striped admin-tbl reflow-table">
                                <thead>
                                    <tr>
                                        <th>Order No</th>
                                        <th>Customer Name</th>
                                        <th>Details</th>
                                        <th>Order Date</th>
                                        <th>Order Amount</th>
                                        <th>Payment Status</th>
                                        <th>Order Status </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="allOrders">
                                    <tr
                                        *ngFor="let order of allOrders| paginate: { itemsPerPage: count, currentPage: p };">
                                        <td data-th="Order No">{{order.orderNumber}}</td>
                                        <td data-th="Customer "> {{order.billingDetails.firstName}}</td>
                                        <td data-th="Address "><button (click)="address(order)"
                                                class=" btn btn-sm btn-outline-success auto" data-tooltip="tooltip"
                                                title="" data-original-title="View Descrition" data-toggle="modal"
                                                data-target="#address"><i class="ft-eye"></i></button> </td>
                                        <td data-th="Order Date "> {{order.createdDate | date : 'medium'}}</td>
                                        <td data-th="Order Amount">{{currency}} {{order.total_Amount_to_Pay}} </td>
                                        <td data-th="Payment Status">
                                            <span class="badge badge-success" *ngIf="order.set_paid">Paid</span>
                                            <span class="badge badge-danger" *ngIf="!order.set_paid">Pending</span>
                                        </td>
                                        <td data-th="Order Status ">
                                            <span class="badge" [class.badge-primary]="order.status == 'CREATED'"
                                                [class.badge-danger]="order.status == 'RETURNED' || order.status == 'CANCELLED'"
                                                [class.badge-success]="order.status == 'DELIVERED'"
                                                [class.badge-warning]="order.status == 'DISPATCHED' || order.status == 'OUT_FOR_DELIVERY'">{{order.status}}</span>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <div class="row d-flex">
                                <div class=" col-md-12 ml-auto mt-2 text-right">
                                    <pagination-controls directionLinks="true" autoHide="true" responsive="true"
                                        (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> -->


        </div>
    </div>

</div>
<div class="modal" tabindex="-1" role="dialog" id="address">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Order Details</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-6 order-sm-0">
                        <address *ngIf='billingAddress'>
                            <h6>Billing Address</h6>
                            {{billingAddress.firstName}} {{billingAddress.lastName}}<br>
                            {{billingAddress.address_1}}<br>
                            {{billingAddress.address_2}}<br>
                            {{billingAddress.city}},{{billingAddress.state}}<br>
                            {{billingAddress.country}}-{{billingAddress.postcode}}<br>
                            <i class="ft-phone"></i> Mob : {{billingAddress.mobileNo}}
                        </address>
                    </div>

                    <div class="col-sm-6 order-sm-0">
                        <address *ngIf='shippingAddress'>
                            <h6>Shipping Address</h6>
                            {{shippingAddress.firstName}} {{shippingAddress.lastName}}<br>
                            {{shippingAddress.address_1}}<br>
                            {{shippingAddress.address_2}}<br>
                            {{shippingAddress.city}},{{shippingAddress.state}}<br>
                            {{shippingAddress.country}}-{{shippingAddress.postcode}}<br>
                            <i class="ft-phone"></i> Mob : {{shippingAddress.mobileNo}}
                        </address>
                    </div>
                </div>
                <div class="card no-shadow">
                    <div class="scrollbar scroller prod-scroller ">
                        <table class="table res-table table-striped admin-tbl reflow-table prod-chkout">
                            <thead class="text-muted">
                                <tr>
                                    <th>Product</th>
                                    <th class="text-right">Quantity</th>
                                    <th class="text-right">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cartEntry of shoppingList">
                                    <td data-th="Product ">
                                        <figure class="media">
                                            <div class="img-wrap"><img *ngIf="cartEntry.imageUrl"
                                                    [src]="cartEntry.imageUrl | safe: 'url'" class="prod-img">
                                            </div>
                                            <figcaption class="media-body">
                                                <h6 class="title text-truncate">{{cartEntry.productName}} </h6>
                                                @ {{currency}} {{cartEntry.productCost}} each
                                            </figcaption>
                                        </figure>
                                    </td>
                                    <td class="text-right" data-th="Quantity">
                                        <div class="price-wrap text-right">
                                            <var class="price">{{cartEntry.quantity}}</var>
                                        </div>
                                    </td>
                                    <td data-th="Price">
                                        <div class="price-wrap text-right">
                                            <var class="price">{{currency}} {{(cartEntry.productTotalPrice)}}</var>
                                        </div>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <table class="table res-table  admin-tbl reflow-table prod-chkout">
                        <tbody>
                            <tr>
                                <td class="text-right">
                                    Cart Total
                                </td>

                                <td class="text-right" data-th="Total Quantity">

                                </td>

                                <td class="text-right" data-th="Total Price">

                                    <strong>{{currency}} {{cartTotal}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-right">
                                    Delivery Charge
                                </td>

                                <td class="text-right" data-th="Total Quantity">

                                </td>

                                <td class="text-right" data-th="Total Price">

                                    <strong>{{currency}} {{deliveryCharge}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-right">
                                    Total
                                </td>

                                <td class="text-right" data-th="Total Quantity">

                                </td>

                                <td class="text-right" data-th="Total Price">

                                    <strong>{{currency}} {{total}}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mt-1 mb-4" *ngIf="userorder && userorder.paymentDetails">
                    <div class="col-md-12 order-sm-0">
                        <h6>Payment Details</h6>
                        <div class="col-md-4"><strong>Type</strong>: {{userorder.paymentDetails.brand}}</div>
                        <div class="col-md-4"><strong>Card#</strong>: Card ending
                            {{userorder.paymentDetails.last4}}</div>
                        <div class="col-md-4"><strong>Funding</strong>: {{userorder.paymentDetails.funding}}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 order-sm-0">
                        <select class="form-control" [(ngModel)]="selectedOrderStatus" name="selectedOrderStatus">
                            <option value="DISPATCHED">Dispatched</option>
                            <option value="OUT_FOR_DELIVERY">Out For Delivery</option>
                            <option value="DELIVERED">Delivered</option>
                            <option value="RETURNED">Returned</option>
                            <option value="CANCELLED">Cancelled</option>
                        </select>
                    </div>
                    <div class="col-sm-6 order-sm-0 mt-2 text-right">
                        <button class="btn btn-primary rounded-pill auto"
                            [disabled]="!selectedOrderStatus || selectedOrderStatus == ''" (click)="updateOrder()"
                            data-dismiss="modal">Update Status</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>






<div *ngIf="successModal" class="modal fade show" id="success-Modal" style="display:block!important;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header border-0">
                <button type="button" class="close" data-dismiss="modal" aria-label=""><span>×</span></button>
            </div>

            <div class="modal-body">
                <div class="success-pop">

                    <h1 class="text-success">Order Updated Successfully!</h1>

                </div>
            </div>

        </div>
    </div>
</div>

<div *ngIf="errorModal" class="modal fade show" id="fail-Modal" style="display:block!important;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header border-0">
                <button type="button" class="close" data-dismiss="modal" aria-label=""><span>×</span></button>
            </div>

            <div class="modal-body">
                <div class="success-pop">

                    <h1 class="text-danger">Ooops!!!</h1>
                    <p>Order Update Failed. Please try again later.</p>
                </div>
            </div>

        </div>
    </div>
</div>