
export class Category {

    categoryCode: string;
    categoryDescription: string;
    categoryName: string;
    categoryImage: string;
    createdBy: string;
    createdDate: Date;
    deletedFlag: Boolean;
    id: string;
    lastupdatedDate: Date;
    updatedBy: string;
    productCount: number;
    totalSold: number;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}