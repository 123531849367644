import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BillingDetails, Order, PaymentDetails } from 'src/app/model/order';
import { Product } from 'src/app/model/product';
import { ShoppingCartRequest } from 'src/app/model/shoppingCartRequest';
import { ShoppingCartEntries, ShoppingCartResponse } from 'src/app/model/shoppingCartResponse';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { CartHelpers } from 'src/app/shared/helper/cart.helper';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { DataHelper } from 'src/app/shared/helper/data.helper';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { Paytm } from 'src/app/model/paytm';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.css']
})
export class CreateOrderComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  allCategories: any;
  user: User;
  categoryFilter: string;
  loading: boolean = false;
  successDelete: boolean = false;
  income: number;
  allCounts: any;
  order: Order;
  currency: String = "";
  quantity: number;
  totalAmount: number;
  product: Product[] = [];
  value: any;
  productShopping: ShoppingCartResponse;
  shoppingList: any[];
  total: number = 0.00;
  counter: number = 1;
  allproducts: Product[];
  productAddedList: ShoppingCartEntries[] = [];
  addressForm: FormGroup;
  successModal: boolean = false;
  errorModal: boolean = false;
  routedFromPage: string;
  productId: any;
  amountPaid: number;
  paymentField: boolean = false;
  productFilter: string;
  enquryProduct: Product;
  showIndex: number;
  fourDigit: string;
  chequeNo: string;
  card: boolean = false;
  cheque: boolean = false;
  paymentdetails: PaymentDetails;
  cardBrand: string = "";
  paymentStatus: string = "";
  paymentMethod: string = "";
  selectedPackagePrice: any = [];
  allUsers: User[];
  wholesalerDropDown: any[];
  selectedUserDetails: User;
  orderType: string = "";
  isExistingUser: boolean = true;
  paytmUrl: any;
  cartTotal: number;


  constructor(private cartService: CartService, private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute, private paymentservice: PaymentService,
    private productService: ProductService, private orderService: OrderService,
    private authenticationService: AuthenticationService, private dataHelper: DataHelper) { }

  private cartHelper: CartHelpers = new CartHelpers(this.cartService, this.productService);

  ngOnInit() {
    this.dataHelper.getRoutedFromPage.subscribe(parameter => this.routedFromPage = parameter);
    if (this.authenticationService.isLoggedIn()) {
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.getTotalCounts();
      if (this.user.userType == 'WHOLESALE') {
        this.selectedUserDetails = this.user;
        this.orderType = "WHOLESALE";
      } else {
        this.createAddressForm();
      }
    }
    if (this.route.snapshot.queryParams['id']) {
      this.route.queryParams.subscribe(params => {
        this.productId = params['id'];
        this.getProductDetails(this.productId);
      })
    }
  }

  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }

  selectedUserTemp: any;
  selectionChanged(object) {
    console.log(object.obj);
    this.selectedUserDetails = object.obj;
  }

  getAllUsers() {
    this.loading = true;
    this.allUsers = [];
    this.selectedUserDetails = null;
    let userType = 'WHOLESALE';
    if (this.orderType == 'OFFLINE') {
      userType = 'RETAIL';
    }
    this.authenticationService.getUsersByUsertype(userType).subscribe(response => {
      this.allUsers = response as User[];
      if (this.allUsers && this.allUsers.length > 0) {
        this.wholesalerDropDown = [];
        this.allUsers.forEach(user => {
          this.wholesalerDropDown.push({ 'obj': user, 'description': user.firstName + " " + user.lastName });
        });
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }

  config = {
    displayFn: (item: any) => { return item.description; },//to support flexible text displaying for each item
    displayKey: "description", //if objects array passed which key to be displayed defaults to description
    search: true,//true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { },// a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 0, // number thats limits the no of options displayed in the UI (if zero, options will not be limited)
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  }

  getProductDetails(id) {
    this.productService.getProductByProductId(id).subscribe(
      product => {
        this.enquryProduct = product;

        let enquiryproduct: ShoppingCartEntries = {
          productId: this.enquryProduct.id,
          productCategory: this.enquryProduct.categoryName,
          imageId: null,
          productImageUrl: this.enquryProduct.productImageUrl,
          productName: this.enquryProduct.productName,
          productCost: this.enquryProduct.packagingQuantities[0].packageDiscountedPrice.toString(),
          quantity: 1,
          productTotalPrice: this.enquryProduct.packagingQuantities[0].packageDiscountedPrice,
          packageQuantity: this.enquryProduct.packagingQuantities[0],
          categoryId: this.enquryProduct.categoryId,
          assignPackageQuantity: false,
        }
        this.productAddedList.push(enquiryproduct);
        this.loading = false;
      }, error => {
        this.loading = false;
      }
    );
  }

  //=================Billing Details======//
  createAddressForm() {
    this.addressForm = this.fb.group({
      'firstName': [null, Validators.required],
      'lastName': [null],
      'address_1': [null, Validators.required],
      'address_2': [null],
      'city': [null, Validators.required],
      'state': [null, Validators.required],
      'country': [null, Validators.required],
      'postcode': [null, [Validators.required, Validators.maxLength(6), Validators.pattern('^[0-9]+$')]],
      'mobileNo': [null, [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]+$')]],
      'email': [null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
  }

  //===========Cart Items=====//

  increment(i) {
    this.productAddedList[i].quantity += 1;
    this.productAddedList[i].productTotalPrice = (parseInt(this.productAddedList[i].productCost) * this.productAddedList[i].quantity);
    this.getTotal();
  }

  changeQuantity(i, quantityValue) {
    this.productAddedList[i].quantity = quantityValue;
    this.productAddedList[i].productTotalPrice = (parseInt(this.productAddedList[i].productCost) * this.productAddedList[i].quantity);
    this.getTotal();
  }

  decrement(i) {
    if (this.productAddedList[i].quantity > 1) {
      this.productAddedList[i].quantity -= 1;
      this.productAddedList[i].productTotalPrice = (parseInt(this.productAddedList[i].productCost) * this.productAddedList[i].quantity);
      this.getTotal();
    }
  }

  removeFromCart(i) {
    this.productAddedList.splice(i, 1);
    this.getTotal();
  }
  //==========end cart list======//

  //=============Product List=======//
  selectProduct() {
    this.showIndex = null;
    this.getAllProduct();
  }

  setIndex(index) {
    if (this.showIndex == index) {
      this.showIndex = null;
    } else {
      this.showIndex = index;
    }
  }

  getAllProduct() {
    this.loading = true;
    this.productService.getAllProducts().subscribe(
      product => {
        this.allproducts = product;
        this.loading = false;
      }, error => {
        this.loading = false;
      }
    );
  }

  addToCart(product, packageQuantity) {
    //var productAdded:ShoppingCartEntries = product;
    let productAdded: ShoppingCartEntries = {
      productId: product.id,
      productCategory: product.categoryName,
      imageId: null,
      productImageUrl: product.productImageUrl,
      productName: product.productName,
      productCost: packageQuantity.packagePrice.toString(),
      quantity: 1,
      productTotalPrice: packageQuantity.packagePrice,
      packageQuantity: packageQuantity,
      categoryId: product.categoryId,
      assignPackageQuantity: false,
    }
    if (this.orderType && this.orderType != 'WHOLESALE') {
      productAdded.productCost = packageQuantity.retailPrice;
      productAdded.productTotalPrice = packageQuantity.retailPrice;
    } else {
      productAdded.productCost = packageQuantity.wholesalePrice;
      productAdded.productTotalPrice = packageQuantity.wholesalePrice;
    }
    this.productAddedList.push(productAdded);
    this.getTotal();
  }

  getTotal() {
    this.cartTotal = 0;
    if (!this.productAddedList) return this.cartTotal;
    this.productAddedList.forEach(product => {
      this.cartTotal = this.cartTotal + product.productTotalPrice;
    });
    return this.cartTotal;
  }

  selectedPayment(e) {
    this.paymentStatus = e;
    this.amountPaid = 0;
    if (e == 'PAID') {
      this.paymentField = true;
      this.amountPaid = this.cartTotal;
    } else if (e == 'ADVANCE_PAID') {
      this.paymentField = true;
    } else {
      this.paymentField = false;
    }
  }

  onSubmit() {
    this.paymentdetails = {
      brand: this.cardBrand,
      funding: null,
      id: null,
      last4: this.fourDigit,
      expMonth: null,
      expYear: null,
      chequeNo: this.chequeNo,
      paymentReceiptName: null,
      paymentReceiptUrl: null,
      bankTxnId: null,
      gatewayName: null,
    }

    this.loading = true;

    this.order = {
      id: null,
      sequenceName: null,
      orderNumber: null,
      cartAmount: this.cartTotal,
      deliveryCharge: 0.00,
      total_Amount_to_Pay: this.cartTotal,
      payment_method: this.paymentMethod,
      paymentDetails: this.paymentdetails,
      payment_method_title: null,
      shippingDetails: null,
      billingDetails: null,
      shoppingCartEntry: this.productAddedList,
      userId: this.user.id,
      status: 'CREATED',
      orderDateForEmail: null,
      lastupdatedDate: null,
      createdDate: null,
      deletedFlag: false,
      reasonForCancellation: null,
      modeOfOrder: this.orderType,
      createdby: null,
      updatedby: null,
      paymentStatus: this.paymentStatus,
      paymentFailureReason: null,
      paymentAdvanceAmt: this.amountPaid,
      stockInStatus: false,
      stockOutStatus: false,
      wholesaleOrder: false,
    }

    if (!this.isExistingUser) {
      this.order.billingDetails = this.addressForm.value;
      //this.order.shippingDetails = this.addressForm.value;
    } else {
      this.order.billingDetails = this.selectedUserDetails.billingAddress;
      this.order.billingDetails.firstName = this.selectedUserDetails.firstName;
      this.order.billingDetails.lastName = this.selectedUserDetails.lastName;
      this.order.billingDetails.email = this.selectedUserDetails.email;
      this.order.billingDetails.mobileNo = this.selectedUserDetails.mobileno;

      // this.order.shippingDetails = this.selectedUserDetails.billingAddress;
      // this.order.shippingDetails.firstName = this.selectedUserDetails.firstName;
      // this.order.shippingDetails.lastName = this.selectedUserDetails.lastName;
      // this.order.shippingDetails.mobileNo = this.selectedUserDetails.mobileno;
      // this.order.shippingDetails.address_1=this.selectedUserDetails.billingAddress.address_1;
      // this.order.shippingDetails.address_2=this.selectedUserDetails.billingAddress.address_2;
      // this.order.shippingDetails.city=this.selectedUserDetails.billingAddress.city;
      // this.order.shippingDetails.country=this.selectedUserDetails.billingAddress.country;
      // this.order.shippingDetails.postcode=this.selectedUserDetails.billingAddress.postcode;
      // this.order.shippingDetails.state=this.selectedUserDetails.billingAddress.state;
      this.order.userId = this.selectedUserDetails.id;



      if (this.orderType && this.orderType == 'WHOLESALE') {
        this.order.wholesaleOrder = true;
      } else {
        this.order.wholesaleOrder = false;
      }
    }
    if (this.uploadContentFile) {
      this.order.paymentDetails.paymentReceiptName = this.uploadContentFile.name;

    } else {
      this.uploadContentFile = null;
    }
    console.log(this.order);
    var formData = new FormData();
    formData.append("paymentReceipt ", this.uploadContentFile);
    formData.append("orderRequest", JSON.stringify(this.order));

    this.orderService.createOrder(formData).toPromise().then(response => {
      this.loading = false;
      this.order = response as Order;
      this.successModal = true;
      setTimeout(() => {
        this.successModal = false;
        this.router.navigate(['/admin/display-orders']);
      }, 2000);
    }, error => {
      this.loading = false;
      this.errorModal = true;
      setTimeout(() => {
        this.errorModal = false;
      }, 2000)
    })
  }

  back() {
    if (this.routedFromPage && this.routedFromPage != '') {
      this.router.navigate(['admin/' + this.routedFromPage]);
    } else {
      this.router.navigate(['admin/display-orders']);
    }
  }

  selectedMethod(e) {
    this.paymentMethod = e;
    if (e == 'CARD') {
      this.card = true;
      this.cheque = false;
    } else if (e == 'CHEQUE') {
      this.cheque = true;
      this.card = false;
    } else {
      this.card = false;
      this.cheque = false;
    }
  }
  receipt: boolean = false;
  gpay: boolean = false;
  paytm: boolean = false;
  selectedPaymentMethod(paymentmethod) {
    this.paymentMethod = paymentmethod;
    if (paymentmethod == 'RECEIPT') {
      this.receipt = true;
      this.gpay = false;
    } else if (paymentmethod == 'GPAY') {
      this.gpay = true;
      this.receipt = false;
    } else {
      this.paytm = true;
      this.gpay = false;
      this.receipt = false;

    }
  }
  //=====================Add item to cart====//
  selectedSale(e, i, p) {

    if (e == 'Retail') {
      this.allproducts[i].packagingQuantities[p].packagePrice = this.allproducts[i].packagingQuantities[p].retailPrice;
    } else if (e == 'Wholesale') {
      this.allproducts[i].packagingQuantities[p].packagePrice = this.allproducts[i].packagingQuantities[p].wholesalePrice;
    }

    else {
      this.allproducts[i].packagingQuantities[p].packagePrice = 0;
    }
  }

  selectUser(event) {
    let userList = this.allUsers.filter(data => (data.id == event.target.value));
    this.selectedUserDetails = userList[0];
  }
  ///===============================GOOGLE PAYMENT =========================///
  buttonColor = "black";
  buttonType = "buy";
  isCustomSize = false;
  buttonWidth = 240;
  buttonHeight = 40;

  paymentRequest: google.payments.api.PaymentDataRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["AMEX", "VISA", "MASTERCARD"]
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "example",
            gatewayMerchantId: "exampleGatewayMerchantId"

          }
        }
      }
    ],
    merchantInfo: {
      merchantId: "12345678901234567890",
      merchantName: "Demo Merchant"
    },
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPriceLabel: "Total",
      totalPrice: "100.00",
      currencyCode: "USD",
      countryCode: "US"
    },
    //callbackIntents:['PAYMENT_AUTHORIZATION']
  };

  onLoadPaymentData(event) {
    console.log("load payment data", event.detail);
  }
  onPaymentDataAuthorized: google.payments.api.PaymentAuthorizedHandler = (
    paymentData
  ) => {
    console.log('payment authorized', paymentData);
    return {
      transactionState: 'SUCCESS'
    }
  }
  onError = (event: ErrorEvent): void => {
    console.error('error', event.error);

  }
  ////==================paytm=====================//
  payOnPaytm() {
    this.paymentdetails = {
      brand: this.cardBrand,
      funding: null,
      id: null,
      last4: this.fourDigit,
      expMonth: null,
      expYear: null,
      chequeNo: this.chequeNo,
      bankTxnId: '',
      gatewayName: '',
      paymentReceiptName: '',
      paymentReceiptUrl: ''
    }

    this.loading = true;

    this.order = {
      id: null,
      sequenceName: null,
      orderNumber: null,
      cartAmount: this.getTotal(),
      deliveryCharge: 0.00,
      total_Amount_to_Pay: this.getTotal(),
      //set_paid: true,
      payment_method: this.paymentMethod,
      paymentDetails: this.paymentdetails,
      payment_method_title: null,
      shippingDetails: null,
      billingDetails: null,
      shoppingCartEntry: this.productAddedList,
      userId: this.user.id,
      status: 'CREATED',
      orderDateForEmail: null,
      lastupdatedDate: null,
      createdDate: null,
      deletedFlag: false,
      reasonForCancellation: null,
      modeOfOrder: this.orderType,
      createdby: null,
      updatedby: null,
      paymentStatus: this.paymentStatus,
      paymentFailureReason: null,
      paymentAdvanceAmt: this.amountPaid,
      stockInStatus: false,
      stockOutStatus: false,
      wholesaleOrder: false,
    }

    if (!this.isExistingUser) {
      this.order.billingDetails = this.addressForm.value;
      this.order.shippingDetails = this.addressForm.value;
    } else {
      this.order.billingDetails = this.selectedUserDetails.billingAddress;
      this.order.billingDetails.firstName = this.selectedUserDetails.firstName;
      this.order.billingDetails.lastName = this.selectedUserDetails.lastName;
      this.order.billingDetails.email = this.selectedUserDetails.email;
      this.order.billingDetails.mobileNo = this.selectedUserDetails.mobileno;

      this.order.shippingDetails = this.selectedUserDetails.billingAddress;
      this.order.shippingDetails.firstName = this.selectedUserDetails.firstName;
      this.order.shippingDetails.lastName = this.selectedUserDetails.lastName;
      this.order.shippingDetails.mobileNo = this.selectedUserDetails.mobileno;
      this.order.userId = this.selectedUserDetails.id;

      if (this.orderType && this.orderType == 'WHOLESALE') {
        this.order.wholesaleOrder = true;
      } else {
        this.order.wholesaleOrder = false;
      }
    }
    let data = {
      channelId: null,
      customerId: this.user.id,
      industryTypeId: null,
      merchantId: null,
      merchantKey: null,
      orderId: null,
      paytmUrl: null,
      paytmRedirectUrl: null,
      transactionAmount: this.getTotal(),
      website: null,
      orderRequest: this.order
    }
    console.log(data);
    this.paymentservice.OnPaytm(data).toPromise().then(response => {
      this.paytmUrl = response as Paytm;
      console.log(this.paytmUrl);
      window.location.href = (this.paytmUrl.paytmRedirectUrl);
      // this.router.navigate([this.paytmUrl.paytmPaymentUrl]);
    })
  }

  //============================Receipt==================//
  fileTypeError: Boolean = false;
  uploadContentFile: File;
  uploadContentFileSize: string;

  handleReceiptFile(event) {
    var files = event.target.files;
    if (!isNullOrUndefined(files[0])) {
      var mimeType = files[0].type;
      this.fileTypeError = false;
      this.uploadContentFile = null;
      if (mimeType != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && mimeType != 'application/pdf'
        && mimeType != 'text/plain' && mimeType != 'application/msword' && mimeType.match(/image\/*/) < 0) {
        this.fileTypeError = true;
        return;
      }
      this.uploadContentFile = files[0];
      if (this.uploadContentFile.size < 1000) {
        this.uploadContentFileSize = this.uploadContentFile.size + " B";
      } else if (this.uploadContentFile.size < 1000 * 1000) {
        this.uploadContentFileSize = (this.uploadContentFile.size / 1000) + " KB";
      } else if (this.uploadContentFile.size < 1000 * 1000 * 1000) {
        this.uploadContentFileSize = (this.uploadContentFile.size / 1000 / 1000) + " MB";
      }
    } else {
      this.uploadContentFile = null;
      this.uploadContentFileSize = null;
    }
  }

  /*registerCustomer() {
    if (this.addressForm.valid) {
      let newUser = new User();
      let billingAddress = new BillingDetails(this.addressForm.value);
      newUser.firstName = this.addressForm.get('firstName').value;
      newUser.lastName = this.addressForm.get('lastName').value;
      newUser.email = this.addressForm.get('email').value;
      newUser.mobileno = this.addressForm.get('mobileno').value;
      newUser.billingAddress = billingAddress;
      newUser.shippingAddress = [];
      newUser.shippingAddress[0] = billingAddress;
      console.log(newUser);
      this.authenticationService.register(newUser).toPromise().then(
        response => {
          this.loading = false;
          this.successMessage = true;
          setTimeout(() => {
            this.successMessage = false;
            this.router.navigate(['/admin/user-list']);
          }, 2000);
        }
      ).catch(
        error => {
          this.loading = false;
          if (error && error.error && error.error.failure) {
            this.errorMessageStr = error.error.failure;
            this.errorMessageHdr = "Sorry. Error while creating your account.";
          } else {
            this.errorMessageStr = "Server Error. Please try again after some time.";
            this.errorMessageHdr = "Sorry for the inconvenience.";
          }
          this.errorMessage = true;
          setTimeout(() => {
            this.errorMessage = false;
          }, 3000);
        }
      );
    }
  }*/
}