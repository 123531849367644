<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<app-menu></app-menu>

<section>
    <div class="breadcrumbs_container">
        <div class="container">
            <div class="col-md-12">
                <div class="breadcrumbs">
                    <h1 class="breadcrumb-heading">CONTACt US</h1>
                    <ul>
                        <li><a href="javascript:void(0)" routerLink="/">Home</a></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>






<section class="sec-gap bg-light">
    <div class="container">
        <div class="row">

            <div class="col-md-4">
                <div class="contact-info-con">
                    <div class="contact-info-box">
                        <div class="contact-icon">
                            <i class="fa fa-phone"></i>
                        </div>
                        <div class="contact-info-dec">
                            <p>+91 98320 59345</p>
                            
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-icon">
                            <i class="fa fa-globe"></i>
                        </div>
                        <div class="contact-info-dec">
                            <p><a href="javascript:void(0)">support@soy-bag.com</a></p>
                            <p><a href="javascript:void(0)">https://www.soy-bag.com/</a></p>
                        </div>
                    </div>
                    <div class="contact-info-box">
                        <div class="contact-icon">
                            <i class="fa fa-map-marker"></i>
                        </div>
                        <div class="contact-info-dec">
                            <p>SOYBAG PRIVATE LIMITED,</p>
                            <p> B-30, GROUND FLOOR,</p>
                            <p>KATJU NAGAR, KOLKATA,</p>
                            <p>WEST BENGAL, INDIA - 700032</p>
                        </div>
                    </div>
                    <div class="contact-social">
                        <h3>Follow Us</h3>
                        <div class="social-info">
                            <ul>
                                <li class="fb">
                                    <a href="https://www.facebook.com/Soybag-Private-Limited-107121007976317" target="https://www.facebook.com/Soybag-Private-Limited-107121007976317"><i
                                            class="ft-facebook"></i></a>
                                </li>
                                <li class="tw">
                                    <a href="https://twitter.com/SoybagL" target="https://twitter.com/SoybagL"><i
                                            class="ft-twitter"></i></a>
                                </li>
                                <li class="insta">
                                    <a href="	https://www.instagram.com/soybagprivate/" target="	https://www.instagram.com/soybagprivate/"><i
                                            class="ft-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCuyWns6nl8a9bG8dlx7nUiQ" target="https://www.youtube.com/channel/UCuyWns6nl8a9bG8dlx7nUiQ"><i class="la la-youtube"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-md-8">


                <div class="contact-form">
                    <div class="contact-title mb-30">
                        <h2>Get In Touch</h2>
                    </div>
                    <form [formGroup]="regiForm" (submit)="sendMail()" class="contact-form-style" id="contact-form"
                        method="post">
                        <div class="row">

                            <div class="form-group col-md-12">
                                <div class="input-group ">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ft-user"></i></span>
                                    </div>
                                    <input type="text" class="form-control" formControlName="username"
                                        placeholder="Username">
                                    <small class="error"><label
                                            *ngIf="!regiForm.get('username').valid && regiForm.get('username').touched">
                                            Please enter the Username !!!</label></small>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <div class="input-group ">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ft-at-sign"></i></span>
                                    </div>
                                    <input type="text" class="form-control" formControlName="email"
                                        placeholder="Email Address">
                                    <small class="error"><label
                                            *ngIf="!regiForm.get('email').valid && regiForm.get('email').touched">
                                            Please enter the Email Address !!!</label></small>
                                </div>
                            </div>

                            <div class="form-group col-md-6">
                                <div class="input-group ">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ft-phone-call"></i></span>
                                    </div>
                                    <input type="text" class="form-control" formControlName="contactNumber"
                                        placeholder="Contact Number">
                                    <small class="error"><label
                                            *ngIf="!regiForm.get('contactNumber').valid && regiForm.get('contactNumber').touched">
                                            Please enter the Phone Number !!!</label></small>
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <div class="input-group ">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ft-grid"></i></span>
                                    </div>
                                    <input type="text" class="form-control" formControlName="subject"
                                        placeholder="Subject">
                                    <small class="error"><label
                                            *ngIf="!regiForm.get('subject').valid && regiForm.get('subject').touched">
                                            Please enter the Subject !!!</label></small>
                                </div>
                            </div>

                            <div class="form-group col-md-12">
                                <div class="input-group ">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="ft-mail"></i></span>
                                    </div>
                                    <textarea name="message" class="form-control" formControlName="message"
                                        placeholder="Your Message*"></textarea>
                                    <small class="error"><label
                                            *ngIf="!regiForm.get('message').valid && regiForm.get('message').touched">
                                            Please enter the Message !!!</label></small>
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <button class="btn btn-success btn-lg rounded-pill" [disabled]="!regiForm.valid"> <i
                                        class="
                                        ft-mail"></i> Send Mail</button>
                            </div>



                        </div>
                    </form>
                    <p class="form-messege"></p>
                </div>

            </div>


        </div>

    </div>
</section>


<div *ngIf="success" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h6>Thnak you. We have received your message</h6>

                <p class="text-success">We will contact you soon. </p>


            </div>
        </div>
    </div>
</div>