<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<app-menu></app-menu>

<section class="sec-gap bg-light">
    <div class="container">
        <div class="card">
            <div class="card-body">

                <div class="row d-flex" *ngIf="productShopping">
                    <div class="col-lg-12 col-md-12 ">
                        <section class="inner-title">
                            <h4><i class="ft-shopping-cart"></i> My Cart
                                <button class="btn btn-primary rounded-pill float-right"
                                    *ngIf="shoppingList && shoppingList.length > 0" (click)="emptyCart()">Clear</button>
                            </h4>
                        </section>
                    </div>
                </div>



                <table class="full-width res-table reflow-table table table-striped tbl-cart">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Unit Price</th>
                            <th>Qty</th>
                            <th>Action</th>
                            <th>Subtotal</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="!shoppingList || shoppingList.length == 0">
                        <tr>
                            <td colspan="6" class="text-center">
                                <h5 class="text-center">No items in Cart</h5>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="shoppingList && shoppingList.length>0">
                        <tr
                            *ngFor="let cartEntry of shoppingList | paginate: { itemsPerPage: count, currentPage: p }; let i=index; ">
                            <td>
                                <div class="product-thumbnail"><a href="javascript:void(0)"><img
                                            *ngIf="cartEntry.imageId" [src]="cartEntry.imageId | safe: 'url'"
                                            alt=""></a></div>
                            </td>
                            <td><a href="javascript:void(0)" (click)="productDetail(cartEntry)"
                                    data-link-action="quickview" title="Quick view" data-toggle="modal"
                                    data-target="#ProductModal">{{cartEntry.productName}}[{{cartEntry.packageQuantity.packageQuantity}}]</a></td>
                            <td><span class="amount">{{currency}} {{cartEntry.productCost}}</span></td>
                            <td>
                                <div class="qty">
                                    <span class="minus bg-dark" (click)=" decrementQuantity(cartEntry)"><i
                                            class="ft-minus"></i></span>
                                    <input type="number" class="count ml-1 mr-1" name="qty"
                                        value="{{cartEntry.quantity}}" disabled="">
                                    <span class="plus bg-dark" (click)=" incrementQuantity(cartEntry)"><i
                                            class="ft-plus"></i></span>
                                </div>
                            </td>
                            <td><button class=" btn btn-sm btn-outline-danger auto delete" data-tooltip="tooltip"
                                    (click)="removeFromCart(i)" title="" data-original-title="Delete"><i
                                        class="ft-trash"></i></button>
                            </td>
                            <td class="product-subtotal">{{currency}} {{(cartEntry.productTotalPrice)}}</td>
                        </tr>

                    </tbody>
                </table>
                <div class="row d-flex">
                    <div class=" col-md-12 ml-auto mt-2 text-right">
                        <pagination-controls *ngIf="shoppingList && shoppingList.length > 0" (pageChange)="p = $event"
                            class="my-pagination">
                        </pagination-controls>
                    </div>
                </div>
                <hr>


                <div class="row mt-4" *ngIf="shoppingList && shoppingList.length > 0">
                    <div class="col-md-6">
                        <div class="heading_s1 mb-3">
                            <h6>Apply Coupon</h6>
                            <div class="input-group">
                                <input type="text" value="" class="form-control form-control-sm"
                                    placeholder="Enter Coupon Code..">
                                <div class="input-group-append">
                                    <button class="btn btn-success btn-sm" type="submit">Apply Coupon</button>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="col-md-6">
                        <div class="border p-3 p-md-4">
                            <div class="heading_s1 mb-3">
                                <h6>Cart Totals</h6>
                            </div>
                            <div class="table-responsive">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td class="cart_total_label">Cart Subtotal</td>
                                            <td class="cart_total_amount">{{currency}} {{total}}</td>
                                        </tr>
                                        <tr>
                                            <td class="cart_total_label">Shipping</td>
                                            <td class="cart_total_amount">Free Shipping</td>
                                        </tr>
                                        <tr>
                                            <td class="cart_total_label">Total</td>
                                            <td class="cart_total_amount"><strong>{{currency}} {{total}}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>


                <div class=" mt-4 text-right" *ngIf="!productShopping || total < minOrder">
                    <h6 class="text-danger"><i class="ft-shopping-cart"></i> Minimum order amount is S$ 20</h6>
                </div>
                <div class=" mt-4 text-right">
                    <button class="btn btn-success btn-lg rounded-pill"
                        [disabled]="!productShopping || total <= minOrder" (click)="estimate()"><i
                            class="ft-shopping-cart"></i> Proceed To CheckOut</button>
                </div>
            </div>
        </div>
    </div>

</section>
<div *ngIf="success" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Item deleted</h4>

                <p class="text-success">Successfully </p>


            </div>
        </div>
    </div>
</div>
<div *ngIf="errorPopup" id="fail-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Sorry</h4>
                <p class="text-danger">Something went wrong. Try again</p>
                <p></p>

            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade show" id="ProductModal" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">

            <div class="modal-header">
                <h6> Product Details</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span></button>
            </div>

            <div class="modal-body" *ngIf="productQuickView">
                <div class="row">
                    <div class="col-md-5 col-sm-12 col-xs-12">
                        <img *ngIf="productQuickView.imageId" [src]="productQuickView.imageId | safe: 'url'"
                            class="img-responsive">
                    </div>

                    <div class="col-md-7 col-sm-12 col-xs-12">
                        <div class="product-details-content">
                            <h2>{{productQuickView.productName}}</h2>
                            <div class="product-decs p-0">
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price">{{currency}} {{productQuickView.productCost}}</li>
                                        <li class="current-price">{{currency}}
                                            {{productQuickView.productDiscountedCost}}</li>
                                        <li class="discount-price">-{{productQuickView.offer}}%</li>
                                    </ul>
                                </div>
                            </div>
                            <p>{{productQuickView.productDescription}}
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div> -->