<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">

        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">

                                <li class="breadcrumb-item active" aria-current="page">User List</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div>
                                        <span class="d-block"> <span class="label ">INCOME</span>
                                        </span>
                                        <span class="value">{{currency}} {{income}} </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                        <span class="sparkline-bar text-warning">
                                            <i class="ft-bar-chart-2"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <div class="row row-sm ">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="card overflow-hidden">
                    <!-- <div class="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-10">All Users</h4>
                        </div>
                        <p class="tx-12 text-muted mb-2"></p>
                    </div>
                    <div class="card-header text-right">
                        <div class="row d-flex">
                            <div class="input-group col-md-6 mr-auto">
                                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="filter">
                            </div>
                        </div>
                    </div> -->
                    <div class="card-body ">
                        <div class="row d-flex">
                            <div class=" col-md-6 ml-auto mt-2">
                                <h4 class="card-title mb-0">Users</h4>
                                <div class="clearfix"></div>
                                
                            </div>

                            <div class=" col-md-6 ml-auto mt-2 text-right">
                                <button class="btn btn-primary btn-sm auto ml-2 rounded-pill" type="button"
                                routerLink="/admin/addadmin"><i class="ft-plus"></i>
                                    Add Users </button>
                            </div>
                        </div>
                        <hr>

                        <div class="card-header text-right">
                            <div class="row d-flex">
                                <div class="input-group col-md-6 mr-auto">
                                    <input type="text" class="form-control" placeholder="Search" [(ngModel)]="filter">
                                </div>
                                <div class=" col-md-6 ml-auto ">
                                    <select type="text" class="form-control" (change)="getUsersByType()"
                                    [(ngModel)]="changedOrder" name="changedOrder">
                                    <option value="ALL">ALL</option>
                                    <option value="ADMIN">ADMIN</option>
                                    <option value="WHOLESALE">WHOLESALER</option>
                                    <option value="RETAIL">RETAILER</option>
                                    
                                </select>
                                </div>
                            </div>
                        </div>
                        <table class="table res-table table-striped admin-tbl reflow-table">
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Mobile Number</th>
                                    <th>Email</th>
                                    <th>Signup Date</th>
                                    <th>Action</th>
                                    <th>Resend Mail</th>

                                </tr>
                            </thead>
                            <tbody *ngIf="allUsers">
                                <tr
                                    *ngFor="let user of allUsers| filter: filter| paginate: { itemsPerPage: count, currentPage: p };">
                                    <td data-th="First Name ">{{user.firstName}}</td>
                                    <td data-th="Last Name"> {{user.lastName}}</td>

                                    <td data-th="Mobile Number"> {{user.mobileno}}</td>
                                    <td data-th="Email">{{user.email}} </td>
                                    <td data-th="Signup Date">{{user.createdDate | date: 'medium'}}</td>
                                    <td data-th="Action">
                                        <ng-container *ngIf="user.deletedFlag">
                                            <button class="btn btn-secondary disabled" disabled>Deleted</button>
                                        </ng-container>
                                        <ng-container *ngIf="!user.deletedFlag" >
                                            <button class=" btn btn-sm btn-outline-success auto "
                                                (click)="userDetails(user)" data-tooltip="tooltip" title=""
                                                data-original-title="View Descrition" data-toggle="modal"
                                                data-target="#userDetails"><i class="ft-eye"></i></button>
                                            <button (click)="selecteduser(user)" class=" btn btn-sm btn-outline-danger auto "
                                                data-tooltip="tooltip" title="" data-original-title="Delete"><i
                                                    class="la la-trash"></i></button>
                                        </ng-container>
                                    </td>
                                    <td data-th="Resend Mail"> <button class="btn btn-primary " (click)="resendmail(user)" [disabled]="user.userType=='RETAIL'" >Resend</button></td>

                                </tr>

                            </tbody>
                        </table>
                        <div class="row d-flex">
                            <div class=" col-md-12 ml-auto mt-2 text-right">
                                <pagination-controls directionLinks="true" autoHide="true" responsive="true"
                                    (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="modal" tabindex="-1" role="dialog" id="userDetails" *ngIf="selectedUserObj">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">User Details  <span class="badge"   [class.badge-success]="selectedUserObj.userType">{{selectedUserObj.userType}}</span></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" *ngIf="selectedUserObj">
                        <div class="scrollbar scroller cupon-scroller">
                            <div class="panel-list mt-0">
                                <ul class="points">
                                    <li>
                                        <div class="title"><i class="ft-user"></i> Name</div>
                                        <div class="value">{{selectedUserObj.firstName}} {{selectedUserObj.lastName}}
                                        </div>
                                    </li>
                                    <li>
                                        <div class="title"><i class="ft-mail"></i> Email</div>
                                        <div class="value">{{selectedUserObj.email}}</div>
                                    </li>
                                    <li>
                                        <div class="title"><i class="ft-phone"></i> Phone</div>
                                        <div class="value">{{selectedUserObj.mobileno}}</div>
                                    </li>

                                    <li
                                        *ngIf='selectedUserObj.shippingAddress && selectedUserObj.shippingAddress.length > 0'>
                                        <ng-container
                                            *ngFor="let shipAddr of selectedUserObj.shippingAddress; let i = index">
                                            <div class="title"><i class="la la-building"></i> Shipping Address {{i+1}}
                                            </div>
                                            <div class="value">
                                                {{shipAddr.address_1}}<br>
                                                {{shipAddr.address_2}}<br>
                                                {{shipAddr.city}},{{shipAddr.state}}<br>
                                                {{shipAddr.country}}-{{shipAddr.postcode}}
                                            </div>
                                        </ng-container>

                                    </li>
                                    <li *ngIf='selectedUserObj.billingAddress'>
                                        <div class="title"><i class="la la-building"></i> Billing Address</div>
                                        <div class="value">{{selectedUserObj.billingAddress.firstName}}
                                            {{selectedUserObj.billingAddress.lastName}}<br>
                                            {{selectedUserObj.billingAddress.address_1}}<br>
                                            {{selectedUserObj.billingAddress.address_2}}<br>
                                            {{selectedUserObj.billingAddress.city}},{{selectedUserObj.billingAddress.state}}<br>
                                            {{selectedUserObj.billingAddress.country}}-{{selectedUserObj.billingAddress.postcode}}
                                        </div>

                                    </li>
                                    <li *ngIf="myOrders">
                                        <div class="title"><i class="ft-phone"></i>Number of orders placed:</div>
                                        <div class="value">{{myOrders.length}}</div>
                                    </li>
                                    <li *ngIf="selectedUserObj.userType=='WHOLESALE'">
                                        <table class="table res-table table-striped admin-tbl reflow-table ">
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Package Quantity</th>
                                                    <th>Available Stock</th>
                                                    <th>Stock Out Quantity</th>
                                                    <th>Stock In Quantity</th>
                                                    <!-- <th>Action</th> -->
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="!allInventories || allInventories.productsInWholesaleOrd.length == 0">
                                                <tr>
                                                    <td colspan="5" class="text-center">
                                                        <h6 class="text-center">No inventory is created</h6>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="allInventories">
                                            
                                                <tr
                                                    *ngFor="let inventory of allInventories.productsInWholesaleOrd">
                
                                                    <td data-th="Product Id">{{inventory.productName}}</td>
                                                    <td data-th="Package Quantity">{{inventory.packageQuantity.packageQuantity}}</td>
                                                    <td data-th="Available Stock">{{inventory.packageQuantity.stockCount}} </td>
                                                    <td data-th="Stock Out Quantity">{{inventory.stockOutQuantity}}</td>
                                                    <td data-th="Stock In Quantity">{{inventory.stockInQuantity}}</td>
                
                                                    <!-- <td data-th="Action">
                                                        <a routerLink="/admin/add-editCategory" [queryParams]="{ key: category.id }"
                                                            class="btn btn-sm btn-outline-primary auto" data-tooltip="tooltip" title=""
                                                            data-original-title="Disable"><i class="la la-edit"></i></a>
                                                        <button (click)="selectedcategory(category)" class=" btn btn-sm btn-outline-danger auto"
                                                            data-tooltip="tooltip" title="" data-original-title="Delete"><i
                                                                class="la la-trash"></i></button>
                                                    </td> -->
                                                </tr>
                
                                            </tbody>
                                        </table>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="successDelete" id="email-verifiacation-modal" class="modal fade show"
            style="display:block!important;">
            <div class="modal-dialog  modal-dialog-centered modal-md">
                <div class="modal-content modal-confirm">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-check"></i>
                        </div>
                        <button type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4></h4>
                        <p class="text-success">Are you sure.Do you want to delete ? </p> 

                        <button (click)="delete(selectedUser)" class="btn btn-success" data-dismiss="modal">YES</button>
                            <button (click)="cancel()" class="btn btn-danger ml-1" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="resendSuccess" id="email-verifiacation-modal" class="modal fade show"
            style="display:block!important;">
            <div class="modal-dialog  modal-dialog-centered modal-md">
                <div class="modal-content modal-confirm">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-check"></i>
                        </div>
                        <button type="button" (click)="resendSuccess=false;" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4></h4>
                        <p class="text-success">Login credentials sent successfully.Check your email. </p> 

                       
                           
                    </div>
                </div>
            </div>
        </div>