<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-primary " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">
        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <!--  <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">

                                <li class="breadcrumb-item active" aria-current="page">Enquiry List</li>
                            </ol>
                        </nav> -->
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">

                                    </div>
                                </div>
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div>
                                        <span class="d-block"> <span class="label ">INCOME</span>
                                        </span>
                                        <span class="value">{{currency}} {{income}}</span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                        <span class="sparkline-bar text-warning">
                                            <i class="ft-bar-chart-2"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row row-sm">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row d-flex">
                            <div class=" col-md-6 ml-auto mt-2">
                                <h4 class="card-title mb-0">FAQ</h4>
                                <div class="clearfix"></div>

                            </div>

                            <div class=" col-md-6 ml-auto mt-2 text-right">
                                <button class="btn btn-primary btn-sm auto ml-2 rounded-pill" (click)="addFaqPage()"
                                    type="button"><i class="ft-plus"></i>
                                    Add FAQ </button>
                            </div>
                        </div>
                        <hr>

                        <div class="card-header text-right">
                            <div class="row d-flex">
                                <div class="input-group col-md-6 mr-auto">
                                    <input type="text" class="form-control" placeholder="Search"
                                        [(ngModel)]="categoryFilter">
                                </div>
                            </div>
                        </div>

                        <table class="table res-table table-striped admin-tbl reflow-table ">
                            <thead>
                                <tr>


                                    <th>Question</th>
                                    <th>Answer</th>
                                    <th>Enquiry Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody *ngIf="enquries">
                                <tr
                                    *ngFor="let enquiry of enquries |filter: categoryFilter | paginate: { itemsPerPage: count, currentPage: p } ">

                                    <td data-th="User Name">{{enquiry.question}}</td>
                                    <td data-th="User Name">{{enquiry.answer}}</td>

                                    <td data-th="Comments">{{enquiry.createdDate| date : 'medium'}} </td>

                                    <td data-th="Action">
                                        <a routerLink="/admin/add-editFaq" [queryParams]="{ key: enquiry.id }"
                                            class="btn btn-sm btn-outline-primary auto mr-2" data-tooltip="tooltip" title=""
                                            data-original-title="Disable"><i class="la la-edit"></i></a>
                                        <button (click)="selectedFaq(enquiry)" class=" btn btn-sm btn-outline-danger auto ml-2"
                                            data-tooltip="tooltip" title="" data-original-title="Delete"><i
                                                class="la la-trash"></i></button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <div class="row d-flex">
                            <div class=" col-md-12 ml-auto mt-2 text-right">
                                <pagination-controls directionLinks="true" autoHide="true" responsive="true"
                                    (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="success" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
            <div class="modal-dialog  modal-dialog-centered modal-md">
                <div class="modal-content modal-confirm">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-check"></i>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">

                        <h4>Replied</h4>
                        <p class="text-success">Successfully </p>


                    </div>
                </div>
            </div>
        </div>



        <div *ngIf="answer" class="modal" tabindex="-1" role="dialog" id="address">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h6 class="modal-title">Reply</h6>
                        <button type="button" (click)="close()" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form [formGroup]="answerForm" *ngIf="answerForm">
                            <div class=" form-group icon-form">
                                <label class="label">Reply</label>
                                <i class="ft-package"></i>
                                <textarea class="form-control transparent-tb" formControlName="answer"
                                    placeholder="Reply" style="color:black"></textarea>

                            </div>
                            <div class="mt-4 text-center col-md-4 m-auto">

                                <button class=" btn btn-primary btn-sm rounded-pill" data-dismiss="modal"
                                    (click)="submitForm()">Reply
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="successDelete" id="email-verifiacation-modal" class="modal fade show"
style="display:block!important;">
<div class="modal-dialog  modal-dialog-centered modal-md">
    <div class="modal-content modal-confirm">
        <div class="modal-header">
            <div class="icon-box">
                <i class="ft-check"></i>
            </div>
            <button type="button" (click)="cancel()" class="close" data-dismiss="modal" aria-hidden="true">×</button>
        </div>
        <div class="modal-body text-center">
            <h4></h4>
            <p class="text-success">Are you sure.Do you want to delete ? </p> 

            <button (click)="delete(selectedFAQ)" class="btn btn-success" data-dismiss="modal">YES<i
                class="ft-chevron-right"></i></button>
                <button (click)="cancel()" class="btn btn-danger ml-1" data-dismiss="modal">No</button>
        </div>
    </div>
</div>
</div>