<!-- <a  (click)="handlePayment()"  class="btn btn-primary py-3 px-4">Proceed to Checkout</a> -->

<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<app-menu></app-menu>


<section class="sec-gap bg-light">
    <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-10 m-auto m-0 payment-card">
            <div class="card ">
                <div class="card-heading p-4">

                    <h3 class="text-center">Payment Details</h3>
                    <div class="text-center"><img class="img-responsive cc-img" src="assets/images/creditcardicons.png">
                    </div>

                </div>
                <h1>{{message}}</h1>
                <form role="form" id="myForm" [formGroup]="customStripeForm" *ngIf="customStripeForm">
                    <div class="card-body p-4">

                        <div class="input-group mb-3">
                            <input type="text" class="form-control" formControlName="cardNumber"
                                placeholder="Card Number" aria-label="Recipient's Card Number"
                                aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <span class="input-group-text"><i class="fa fa-credit-card"></i></span>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="col-xs-7 col-md-7  mb-3">
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <label>EXP MONTH</label>
                                        <input class="form-control" formControlName="expMonth" placeholder="MM" size="2"
                                            type="number">
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label>EXP YEAR</label>
                                        <input class="form-control" formControlName="expYear" placeholder="YY" size="2"
                                            type="number">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-5 col-md-5 pull-right  mb-3">
                                <div class="form-group">
                                    <label>CVV</label>
                                    <input type="tel" formControlName="cvc" class="form-control" placeholder="CVC" />
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-12">
                            <div class="form-group  mb-3">
                                <label>Name as in Card</label>
                                <input type="text" formControlName="userName" class="form-control"
                                    placeholder="Name as in Card" />
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="col-xs-12">
                            <button class="btn btn-warning btn-lg btn-block" type="submit" (click)="createToken()"
                                [disabled]="customStripeForm.invalid">Process Payment</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>

</section>

<div *ngIf="successModal" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h6>Success</h6>
                <p class="text-success">{{successMessage}}</p>
                <button class="btn btn-success" routerLink="/shop-products" data-dismiss="modal">Continue
                    Shopping</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="errorModal" id="fail-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h6>{{errorMessageHeader}}</h6>
                <p class="text-danger">{{errorMessage}}</p>
                <button class="btn btn-danger" routerLink="/shopping-cart" data-dismiss="modal">View Cart</button>
            </div>
        </div>
    </div>
</div>