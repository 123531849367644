import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Category } from 'src/app/model/category';

@Injectable()
export class CategoryService {

    constructor(private httpClient: HttpClient, private router: Router) { }

    baseUrl: string = environment.baseUrl;

    saveCategoryUrl: string = this.baseUrl + '/api/admin/createCategories';
    updateCategoryUrl: string = this.baseUrl + '/api/admin/updateCategory';
    deleteCategoryUrl: string = this.baseUrl + '/api/admin/deleteCategory?id=';
    getAllCategoryUrl: string = this.baseUrl + '/api/getAllCategories';
    getAllCategoryForHomePageUrl: string = this.baseUrl + '/api/home/getAllCategories';
    getCategoryByIdUrl: string = this.baseUrl + '/api/getCategory/';
    getCategoryByCategoryIdUrl: string = this.baseUrl + '/api/getCategory?id=';
    getBestSellingCategoryForHomePageUrl: string = this.baseUrl + '/api/getBestSellingCategories';

    saveCategory(product) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post(this.saveCategoryUrl, product, { headers: reqHeader });
    }

    updateCategory(product) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.put(this.updateCategoryUrl, product, { headers: reqHeader });
    }

    deleteCategory(product) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.delete(this.deleteCategoryUrl + product, { headers: reqHeader });
    }

    getAllCategories() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.getAllCategoryUrl, { headers: reqHeader });
    }

    getCategoryByCategoryId(productId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.getCategoryByIdUrl + productId, { headers: reqHeader });
    }

    getCategorysByCatgoryId(categoryId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.getCategoryByCategoryIdUrl + categoryId, { headers: reqHeader });
    }

    getAllCategoriesForHomePage() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Category[]>(this.getAllCategoryForHomePageUrl);
    }

    getBestSellingCategoriesForHomePage() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<Category[]>(this.getBestSellingCategoryForHomePageUrl);
    }
}