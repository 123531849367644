<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">

        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">

                                <li class="breadcrumb-item active" *ngIf="!idProduct" aria-current="page">Add Product
                                </li>
                                <li class="breadcrumb-item active" *ngIf="idProduct" aria-current="page">Edit Product
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div>
                                        <span class="d-block"> <span class="label ">INCOME</span>
                                        </span>
                                        <span class="value">{{currency}} {{income}} </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                        <span class="sparkline-bar text-warning">
                                            <i class="ft-bar-chart-2"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>



        <div class="row row-sm">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-0">Add Products</h4>
                        <p class="sub-text">It is Very Easy to Customize and it uses in your website
                            application.
                        </p>
                        <hr>
                        <section class="inner-section-gap" *ngIf="regiForm">
                            <form [formGroup]="regiForm">
                                <div class="row-sm">
                                    <div class="justify-content-left row m-auto my-auto">

                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                            <label class="label">Select Category</label>
                                            <i class="ft-package"></i>
                                            <select #categoryVal formControlName="categoryId"
                                                (change)="setCategoryName($event)" class="form-control transparent-tb">
                                                <option value="">--Select Any--</option>
                                                <option *ngFor="let categorie of categories" [value]="categorie.id">
                                                    {{categorie.categoryName}}</option>
                                            </select>
                                            <small class="form-text text-danger"
                                                *ngIf="!regiForm.get('categoryId').valid && regiForm.get('categoryId').touched">
                                                Please enter the category name !!!</small>
                                        </div>

                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group icon-form">
                                            <label class="label">Product Name</label>
                                            <i class="ft-package"></i>
                                            <input type="text" class="form-control transparent-tb"
                                                placeholder="Product Name" formControlName="productName">
                                            <small class="form-text text-danger"
                                                *ngIf="!regiForm.get('productName').valid && regiForm.get('productName').touched">
                                                Please enter the Product Name !!!</small>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group icon-form">
                                            <label class="label mb-1">Product Description</label>
                                            <ckeditor [config]="config" formControlName="productDescription"></ckeditor>

                                            <!--<textarea formControlName="productDescription"
                                                class="form-control transparent-tb"
                                                placeholder="Product Description"></textarea>-->
                                            <small class="form-text text-danger"
                                                *ngIf="!regiForm.get('productDescription').valid && regiForm.get('productDescription').touched">
                                                Please enter the description !!!</small>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group icon-form switch-box">
                                            <label class="label">Hot Deals</label>
                                            <div class="form-control transparent-tb">
                                                <i class="ft-package"></i>
                                                <label class="switch float-right">
                                                    <input type="checkbox" class="primary" formControlName="hotDeals">
                                                    <span class="slider round"></span>
                                                </label>

                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-sm-12 form-group icon-form switch-box">
                                            <label class="label">New Arrival</label>
                                            <div class="form-control transparent-tb">
                                                <i class="ft-package"></i>
                                                <label class="switch float-right">
                                                    <input type="checkbox" class="primary"
                                                        formControlName="newArrivals">
                                                    <span class="slider round"></span>
                                                </label>
                                            </div>

                                        </div>

                                        <div class="col-md-12">
                                            <div class="custom-dropzone" id="fileInput" ngx-dropzone
                                                [accept]="'image/*'" [multiple]="true" (change)="onFileUpload($event)">
                                                <ngx-dropzone-label>Drag and drop your product images to upload!
                                                </ngx-dropzone-label>
                                                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                                    [file]="uploadedFile" *ngFor="let uploadedFile of uploadedFiles"
                                                    [removable]="true" (removed)="onFileRemoved(uploadedFile)">
                                                    <!--<ngx-dropzone-label>{{ uploadedFile.name }}
														({{ uploadedFile.type }})
													</ngx-dropzone-label>-->
                                                </ngx-dropzone-image-preview>

                                            </div>
                                            <div class="row justify-content-center mt-4">
                                                <button type="submit" (click)="chooseFile()"
                                                    class="btn col-md-3 btn-success">Upload
                                                    Image</button>

                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group icon-form">
                                            <ul>
                                                <li *ngFor="let file of files">

                                                    <span id="file-label">
                                                        <img [src]="imgSrc | safe: 'url'" width="100px" height="100px">
                                                        {{file.data.name}}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-sm-12 form-group icon-form switch-box">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <h4 class="card-title mb-0">Package Quantity</h4>
                                                </div>
                                                <div class="col-md-6  text-right">
                                                    <button class="btn btn-sm btn-primary mr-4 "
                                                        (click)="addProductChoice()">Add</button>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="clearfix"></div>

                                        <ng-container formArrayName="packagingQuantities">

                                            <div class="row col-md-12 full-width "
                                                *ngFor="let prodChoiceControl of packagingQuantities.controls; index as i">

                                                <div class="col-lg-3 col-md-3 col-sm-12 form-group icon-form"
                                                    [formGroupName]="i">
                                                    <label class="label">Package Quantity</label>
                                                    <i class="ft-package"></i>
                                                    <select class="form-control transparent-tb"
                                                        formControlName="packageQuantity">
                                                        <option value="100gms">100 Grams</option>
                                                        <option value="250gms">250 Grams</option>
                                                        <option value="500gms">500 Grams</option>
                                                        <option value="1kg">1 Kg</option>
                                                    </select>
                                                </div>

                                                <div class="col-lg-3 col-md-3 col-sm-12 form-group icon-form"
                                                    [formGroupName]="i">
                                                    <label class="label">Retail Price</label>
                                                    <i class="ft-package"></i>
                                                    <input type="number" class="form-control transparent-tb"
                                                        placeholder="Retail Price" formControlName="retailPrice">
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-12 form-group icon-form"
                                                    [formGroupName]="i">
                                                    <label class="label">Wholesale Price</label>
                                                    <i class="ft-package"></i>
                                                    <input type="number" class="form-control transparent-tb"
                                                        placeholder="Wholesale Price" formControlName="wholesalePrice">
                                                </div>

                                                <div class="col-lg-3 col-md-3 col-sm-12 form-group icon-form"
                                                    [formGroupName]="i">
                                                    <label class="label">Package Discount</label>
                                                    <i class="ft-package"></i>
                                                    <input type="text" class="form-control transparent-tb"
                                                        placeholder="Discount" formControlName="packageDiscount">
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-12 form-group icon-form"
                                                    [formGroupName]="i">
                                                    <label class="label">Total Stock Count</label>
                                                    <i class="ft-package"></i>
                                                    <input type="text" class="form-control transparent-tb" disabled
                                                        placeholder="Total Stock Count" formControlName="totalStockCnt">
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-12 form-group icon-form"
                                                    [formGroupName]="i">
                                                    <label class="label">Available Stock</label>
                                                    <i class="ft-package"></i>
                                                    <input type="text" class="form-control transparent-tb disabled"
                                                        placeholder="Available Stock" disabled
                                                        formControlName="availableStockCnt">
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-12 form-group icon-form"
                                                    [formGroupName]="i">
                                                    <label class="label">Sold out Stock</label>
                                                    <i class="ft-package"></i>
                                                    <input type="text" class="form-control transparent-tb disabled"
                                                        disabled placeholder="Sold Stock"
                                                        formControlName="soldStockCnt">
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-12 form-group icon-form"
                                                    [formGroupName]="i">
                                                    <label class="label">Barcode</label>
                                                    <i class="ft-package"></i>
                                                    <input type="text" class="form-control transparent-tb"
                                                        placeholder="Barcode" formControlName="barcode">
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-sm-12 form-group icon-form switch-box"
                                                    [formGroupName]="i">
                                                    <label class="label"> Stock In / Out</label>
                                                    <div class="form-control transparent-tb">
                                                        <i class="ft-package"></i>
                                                        <label class="switch float-right">
                                                            <input type="checkbox" class="primary"
                                                                formControlName="packageStatus">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>


                                                <div class="col-lg-3 col-md-3 col-sm-12 form-group icon-form mt-2">
                                                    <button class="btn btn-primary btn-sm rounded-pill"
                                                        (click)="showStockCountModal(i)">Add Stock</button>
                                                    <button class="btn btn-danger btn-sm rounded-pill ml-2"
                                                        (click)="removeProductChoice(i)">Delete</button>
                                                </div>
                                            </div>


                                        </ng-container>
                                        <div class="spacer-30"></div>
                                        <div class="mt-4 text-center col-md-4 m-auto">
                                            <button (click)="cancel()"
                                                class="btn btn-danger btn-sm rounded-pill mr-2">Cancel
                                            </button>
                                            <button class="btn btn-primary btn-sm rounded-pill " *ngIf="!idProduct"
                                                [disabled]="regiForm.invalid" (click)="onSubmit()">Add </button>
                                            <button class=" btn btn-primary btn-sm rounded-pill " *ngIf="idProduct"
                                                [disabled]="regiForm.invalid" (click)="onSubmit()">Update </button>
                                        </div>

                                    </div>
                                </div>
                            </form>
                        </section>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="success" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4 *ngIf="!idProduct">Product Added</h4>
                <h4 *ngIf="idProduct">Product Updated</h4>
                <p class="text-success">Successfully </p>


            </div>
        </div>
    </div>
</div>


<div *ngIf="errorPopup" id="fail-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Sorry</h4>
                <p class="text-danger">Some went wrong. Try again</p>
                <p></p>

            </div>
        </div>
    </div>
</div>

<div *ngIf="quantityModal" class="modal fade show" id="ModalFailed" style="display:block!important;" tabindex="-1"
    role="dialog" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <h4>Add Stock Quantity</h4>
                <button type="button" (click)="closeQuantityModal()" class="close" data-dismiss="modal"
                    aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">

                <form>
                    <div class="row d-flex mb-3 mt-2 col-md-12">
                        <div class="input-group col-md-6 mr-auto ml-auto ">
                            <input type="number" class="form-control" [(ngModel)]="newStockCount"
                                name="assigningQuantity" placeholder="Stock Count to add">
                        </div>
                    </div>
                    <button type="submit" class="btn btn-success" [disabled]="newStockCount == 0"
                        (click)="addStockCount()" data-dismiss="modal">Add</button>
                    <button class="btn btn-danger ml-3" (click)="closeQuantityModal()"
                        data-dismiss="modal">Close</button>
                </form>

            </div>
        </div>
    </div>
</div>