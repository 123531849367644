<div class="inner-topbar sidebar-left" id="nav-sticky">
    <button class="navbar-toggler pull-left res-btn-left" type="button" id="res-btn">
        <i class="ft-align-left"></i>
    </button>
    <ul class="nav navbar-nav float-right ml-auto profile-menu">

        <!--<li class="nav-item maximize fullscreen-button">
            <a href=""><i class="ft-maximize"></i></a>
            </li>-->

        <li class="dropdown nav-item active">
            <a class="dropdown-toggle nav-link " href="javascript:void(0)" data-toggle="dropdown" aria-expanded="false">
                <span class="avatar"><img src="assets/images/logo.jpg" alt="avatar"></span>
            </a>
            <div class="dropdown-menu">
                <div class="main-header-profile header-img">
                    <div class="main-img-user"><img alt="" src="../../../assets/images/logo.jpg"></div>
                    <h6>{{user.username}}</h6><small>Administrator</small>
                </div> <a class="dropdown-item" href="javascript:void(0)" routerLink="/admin/profile"><i
                        class="fa fa-user"></i> My Profile</a>
                <a class="dropdown-item" (click)="logout()"><i class="ft-log-out"></i> Sign
                    Out</a>
            </div>
        </li>

    </ul>
</div>