import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { TestimonialService } from 'src/app/shared/services/testimonial.service';

@Component({
  selector: 'app-testimonial-list',
  templateUrl: './testimonial-list.component.html',
  styleUrls: ['./testimonial-list.component.css']
})
export class TestimonialListComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  allTestimonials: any;
  user: User;
  categoryFilter: string;
  loading: boolean = false;
  successDelete: boolean = false;
  income: number;
  allCounts: any;
  currency: String = "";
  selectedtestimonial: any;

  constructor(private serviceTestimonial: TestimonialService, private router: Router,
    private ServiceProduct: ProductService, private orderService: OrderService,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.getTestimoniallist();
      this.getTotalCounts();
    }
  }
  getTestimoniallist() {
    this.loading = true;
    this.serviceTestimonial.getAllTestimonials().subscribe(response => {
      this.allTestimonials = response;
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }
  addTestimonialPage() {
    this.loading = true;
    this.router.navigate(['/admin/add-editTestimonial']);
  }

  delete(testimonial) {
    this.successDelete=false;
    this.loading = true;
    this.serviceTestimonial.deleteTestimonial(testimonial).toPromise()
      .then(() => {
        this.loading = false;
        this.getTestimoniallist();
      });

  }



  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }
  selectedTestimonial(testimonial){
    this.selectedtestimonial=testimonial.id;
    this.successDelete = true;
  }
  cancel(){
    this.successDelete=false;
  }
}

