import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router,
    private _snackBar: MatSnackBar) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLoggedIn()) {
      if (!this.authService.isAdmin()) {
        return true;
      }
      this._snackBar.open('You are logged as in Admin. You are accessing a page specfic to customer. Kindly logout and log in as a customer.', 'OK', {
        duration: 2000,
        verticalPosition: "bottom"
      });
      this.router.navigate(["/admin/admin-dashboard"]);
      return false;
    }
    this._snackBar.open('You need to be logged in before accessing this page.', 'OK', {
      duration: 2000,
      verticalPosition: "bottom"
    });
    this.router.navigate(["/login"]);
    return false;
  }

}
