import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Faq } from 'src/app/model/faq';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FaqService } from 'src/app/shared/services/faq.service';
import { OrderService } from 'src/app/shared/services/order.service';

@Component({
  selector: 'app-add-edit-faq',
  templateUrl: './add-edit-faq.component.html',
  styleUrls: ['./add-edit-faq.component.css']
})
export class AddEditFaqComponent implements OnInit {
  regiForm: FormGroup;
  user: User;
  faq: Faq;
  myDate = new Date();
  idFaq: any;
  loading: boolean = false;
  success: boolean = false;
  errorPopup: boolean = false;
  allCounts: any;
  income: number;
  currency: string = "";

  constructor(private fb: FormBuilder,
    private serviceFaq: FaqService,
    private orderService: OrderService,
    private authenticationService: AuthenticationService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.route.snapshot.queryParams['key']) {
      this.loading = true;
      this.route.queryParams.subscribe(params => {

        this.idFaq = params['key'];


      })
    }
    this.currency = localStorage.getItem('currency');
    this.faqDetails();
    this.getTotalCounts();
  }
  faqDetails() {
    this.loading = true;
    if (!this.idFaq) {
      this.serviceFaq.getAllFaq()
        .subscribe(faqs => {
          this.faq = faqs as Faq;
          this.initalizeFaq(null);
          this.loading = false;
        }, error => {
          this.loading = false;
        }
        );
    }

    else {
      this.loading = true;
      this.serviceFaq.getFaqbyId(this.idFaq)
        .subscribe((faqs) => {
          this.faq = faqs as Faq;
          this.initalizeFaq(faqs);
          this.loading = false;

        }, error => {
          this.loading = false;
        }
        )
    }
  }
  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }
  initalizeFaq(answers) {

    this.regiForm = this.fb.group({
      'id': [answers ? answers.id : null],

      'question': [answers ? answers.question : null, Validators.required],
      'createdDate': [answers ? answers.createdDate : null],
      'deletedFlag': [answers ? answers.deletedFlag : false],
      'lastupdatedDate': [answers ? answers.lastupdatedDate : null],

      'answer': [answers ? answers.answer : null, Validators.required],
    })
  }
  onSubmit(form) {


    if (this.regiForm.valid) {
      let faq: Faq = {
        id: this.idFaq ? this.idFaq : '',
        question: form.question,
        answer: form.answer,

        createdDate: this.myDate,
        deletedFlag: false,
        lastupdatedDate: this.myDate,

      }
      if (!this.idFaq) {
        this.loading = true;
        this.serviceFaq.saveFaq(faq).toPromise().then(
          response => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {
              this.success = false;
              this.router.navigate(['/admin/faq-admin']);
            }, 2000);


          }, error => {
            this.loading = false;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;
            }, 2000);

          }
        )

      }
      else {
        this.loading = true;
        this.serviceFaq.updateFaq(faq).toPromise().then(
          response => {
            this.loading = false;
            this.success = true;
            setTimeout(() => {

              this.success = false;
              this.router.navigate(['/admin/faq-admin']);
            }, 2000);


          }, error => {
            this.loading = false;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;

            }, 2000);

          }

        )
      }
    }
  }
  cancel() {
    this.loading = true;
    this.router.navigate(['/admin/faq-admin']);
  }


}
