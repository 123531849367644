<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">

        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">

                                <li class="breadcrumb-item active" aria-current="page">Orders</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6" *ngIf="user.userType=='ADMIN'">
                                    <div>
                                        <span class="d-block"> <span class="label ">INCOME</span>
                                        </span>
                                        <span class="value">{{currency}} {{income}} </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                        <span class="sparkline-bar text-warning">
                                            <i class="ft-bar-chart-2"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <div class="row row-sm ">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="card overflow-hidden">
                    <div class="card-header bg-transparent pd-b-0 pd-t-20 bd-b-0"
                        *ngIf="allOrders && user.userType=='ADMIN'">
                        <div class="col-md-12 text-right">
                            <div class="col-md-3 float-right input-group" *ngIf="allOrders && user.userType=='ADMIN'">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ft-filter"></i></span>
                                </div>
                                <select type="text" class="form-control" (change)="getOrdersByStatus()"
                                    [(ngModel)]="changedOrder" name="changedOrder">
                                    <option value="ALL">All</option>
                                    <option value="CREATED">Created</option>
                                    <option value="DISPATCHED">Dispatched</option>
                                    <option value="OUT_FOR_DELIVERY">Out For Delivery</option>
                                    <option value="DELIVERED">Delivered</option>
                                    <option value="RETURNED">Returned</option>
                                    <option value="CANCELLED">Cancelled</option>
                                </select>
                            </div>
                            <div class="col-md-2 float-left" *ngIf="allOrders && allOrders.length > 0 && user.userType=='ADMIN'">
                                <button class="btn btn-danger btn-block" (click)="downloadPDF()">Download PDF</button>
                            </div>
                            <div class="col-md-3 float-right" *ngIf="allOrders && allOrders.length > 0 && user.userType=='ADMIN'">
                                <button class="btn btn-success btn-block" (click)="downloadExcel()">Download
                                    Excel</button>
                            </div>


                        </div>
                    </div>
                    <div class="card-header text-right">
                        <div class="row d-flex">
                            <div class="input-group col-md-6 mr-auto">
                                <input type="text" class="form-control" placeholder="Search" [(ngModel)]="ordersFilter">
                            </div>
                            <div class="col-md-3 float-right">
                                <button class="btn btn-primary btn-sm auto ml-2 rounded-pill" (click)="addOrderPage()"
                                    type="button"><i class="ft-plus"></i>Create Order</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body " id="htmlData" #htmlData>

                        <table class="table res-table table-striped admin-tbl reflow-table">
                            <thead>
                                <tr>
                                    <th>Order No</th>
                                    <th>Customer Name</th>
                                    <th>Details</th>
                                    <th>Order Date</th>
                                    <th>Order Amount</th>
                                    <th>Order Type</th>
                                    <th>Payment Status</th>
                                    <th>Order Status</th>
                                    <th>Created By</th>

                                </tr>
                            </thead>
                            <tbody *ngIf="allOrders">
                                <tr
                                    *ngFor="let order of allOrders| filter: ordersFilter| paginate: { itemsPerPage: count, currentPage: p };">
                                    <td data-th="Order No">{{order.orderNumber}}</td>
                                    <td data-th="Customer "> {{order.billingDetails.firstName}}</td>
                                    <td data-th="Address "><a href="javascript:void(0)"
                                        routerLink="/admin/orderDetails"
                                        [queryParams]="{key : order.id}"
                                            class=" btn btn-sm btn-outline-success auto" ><i class="ft-eye"></i></a> </td>
                                    <td data-th="Order Date "> {{order.createdDate | date : 'medium'}}</td>
                                    <td data-th="Deliver Date">{{currency}} {{order.total_Amount_to_Pay}} </td>
                                    <td data-th="Order Type"> {{order.modeOfOrder}} </td>
                                    <td data-th="Payment Status">
                                        <span class="badge badge-success"
                                            *ngIf="order.paymentStatus == 'PAID'">Paid</span>
                                        <span class="badge badge-info"
                                            *ngIf="order.paymentStatus == 'ADVANCE_PAID'">Advance Paid</span>
                                        <span class="badge badge-warning" *ngIf="order.paymentStatus == 'NOT_PAID'">Not
                                            Paid</span>
                                        <span class="badge badge-danger"
                                            *ngIf="order.paymentStatus == 'FAILED'">FAILED</span>
                                    </td>
                                    <td data-th="Order Status ">
                                        <span class="badge" [class.badge-primary]="order.status == 'CREATED'"
                                            [class.badge-danger]="order.status == 'RETURNED' || order.status == 'CANCELLED'"
                                            [class.badge-success]="order.status == 'DELIVERED'"
                                            [class.badge-warning]="order.status == 'DISPATCHED' || order.status == 'OUT_FOR_DELIVERY'">{{order.status}}</span>
                                    </td>
                                    <td data-th="Created By">{{order.createdby}}</td>
                                </tr>

                            </tbody>
                        </table>
                        <div class="row d-flex">
                            <div class=" col-md-12 ml-auto mt-2 text-right">
                                <pagination-controls directionLinks="true" autoHide="true" responsive="true"
                                    (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="modal" tabindex="-1" role="dialog" id="address">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content" *ngIf="userorder">
                    <div class="modal-header">
                        <h6 class="modal-title">Order Details <span class="badge"
                                [class.badge-primary]="userorder.status == 'CREATED'"
                                [class.badge-danger]="userorder.status == 'RETURNED' || userorder.status == 'CANCELLED'"
                                [class.badge-success]="userorder.status == 'DELIVERED'"
                                [class.badge-warning]="userorder.status == 'DISPATCHED' || userorder.status == 'OUT_FOR_DELIVERY'">{{userorder.status}}</span>
                        </h6>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-sm-6 order-sm-0">
                                <address *ngIf='billingAddress'>
                                    <h6>Billing Address</h6>
                                    {{billingAddress.firstName}} {{billingAddress.lastName}}<br>
                                    {{billingAddress.address_1}}<br>
                                    {{billingAddress.address_2}}<br>
                                    {{billingAddress.city}},{{billingAddress.state}}<br>
                                    {{billingAddress.country}}-{{billingAddress.postcode}}<br>
                                    <i class="ft-phone"></i> Mob : {{billingAddress.mobileNo}}
                                </address>
                            </div>

                            <div class="col-sm-6 order-sm-0">
                                <address *ngIf='shippingAddress'>
                                    <h6>Shipping Address</h6>
                                    {{shippingAddress.firstName}} {{shippingAddress.lastName}}<br>
                                    {{shippingAddress.address_1}}<br>
                                    {{shippingAddress.address_2}}<br>
                                    {{shippingAddress.city}},{{shippingAddress.state}}<br>
                                    {{shippingAddress.country}}-{{shippingAddress.postcode}}<br>
                                    <i class="ft-phone"></i> Mob : {{shippingAddress.mobileNo}}
                                </address>
                            </div>
                        </div>
                        <div class="card no-shadow">
                            <div class="scrollbar scroller prod-scroller ">
                                <table class="table res-table table-striped admin-tbl reflow-table prod-chkout">
                                    <thead class="text-muted">
                                        <tr>
                                            <th>Product</th>
                                            <th>Price/Unit</th>
                                            <th class="text-right">Quantity</th>
                                            <th class="text-right">Total Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let cartEntry of shoppingList">
                                            <td data-th="Product ">
                                                <figure class="media">
                                                    <div class="img-wrap"><img *ngIf="cartEntry.imageUrl"
                                                            [src]="cartEntry.imageUrl | safe: 'url'" class="prod-img">
                                                    </div>
                                                    <figcaption class="media-body">
                                                        <h6 class="title text-truncate">{{cartEntry.productName}} </h6>
                                                        @ {{currency}} {{cartEntry.packageQuantity}} each
                                                    </figcaption>
                                                </figure>
                                            </td>
                                            <td data-th="Quantity">
                                                <div class="price-wrap">
                                                    <var class="price"> {{currency}} {{cartEntry.productCost}}</var>
                                                </div>
                                            </td>
                                            <td class="text-right" data-th="Quantity">
                                                <div class="price-wrap text-right">
                                                    <var class="price">{{cartEntry.quantity}}</var>
                                                </div>
                                            </td>
                                            <td data-th="Price">
                                                <div class="price-wrap text-right">
                                                    <var class="price">{{currency}}
                                                        {{(cartEntry.productTotalPrice)}}</var>
                                                </div>
                                            </td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <table class="table res-table  admin-tbl reflow-table prod-chkout">
                                <tbody>
                                    <tr>
                                        <td class="text-right">
                                            Cart Total
                                        </td>

                                        <td class="text-right" data-th="Total Quantity">

                                        </td>

                                        <td class="text-right" data-th="Total Price">

                                            <strong>{{currency}} {{cartTotal}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">
                                            Delivery Charge
                                        </td>

                                        <td class="text-right" data-th="Total Quantity">

                                        </td>

                                        <td class="text-right" data-th="Total Price">

                                            <strong>{{currency}} {{deliveryCharge}}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-right">
                                            Total
                                        </td>

                                        <td class="text-right" data-th="Total Quantity">

                                        </td>

                                        <td class="text-right" data-th="Total Price">

                                            <strong>{{currency}} {{total}}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row mt-1 mb-4">
                            <div class="col-md-12 order-sm-0">
                                <div class="panel-list mt-0">
                                    <h6 class="Title mt-1 ml-2">Payment Details</h6>
                                    <ul class="points">
                                        <li>
                                            <div class="title">Payment Status</div>
                                            <div class="value text-capitalize">{{userorder.paymentStatus}}</div>
                                        </li>
                                        <ng-container
                                            *ngIf="userorder.paymentStatus == 'PAID' || userorder.paymentStatus == 'ADVANCE_PAID'">
                                            <li>
                                                <div class="title">Amount Paid</div>
                                                <div class="value text-capitalize">{{userorder.paymentAdvanceAmt}}</div>
                                            </li>
                                            <li>
                                                <div class="title">Payment Made Through</div>
                                                <div class="value text-capitalize">{{userorder.payment_method}}</div>
                                            </li>
                                            <ng-container
                                                *ngIf="userorder.payment_method == 'CARD' && userorder.paymentDetails">
                                                <li>
                                                    <div class="title">Payment Type</div>
                                                    <div class="value">{{userorder.paymentDetails.brand}}</div>
                                                </li>
                                                <li>
                                                    <div class="title">Card # : Card ending</div>
                                                    <div class="value">{{userorder.paymentDetails.last4}}</div>
                                                </li>
                                                <!--<li>
                                                    <div class="title">Funding</div>
                                                    <div class="value">{{userorder.paymentDetails.funding}}</div>
                                                </li>-->
                                            </ng-container>
                                            <ng-container
                                                *ngIf="userorder.payment_method == 'CHEQUE' && userorder.paymentDetails">
                                                <li>
                                                    <div class="title">Payment Cheque No</div>
                                                    <div class="value">{{userorder.paymentDetails.chequeNo}}</div>
                                                </li>
                                            </ng-container>
                                        </ng-container>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row"
                            *ngIf="userorder && (userorder.status == 'CANCELLED' || userorder.status == 'RETURNED')">
                            <div class="col-sm-12 order-sm-0 mt-2 text-right">
                                <div class="panel-list mt-0">
                                    <ul class="points">
                                        <li>
                                            <div class="title">Reason for Cancellation</div>
                                            <div class="value">{{userorder.reasonForCancellation}}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="userorder && (userorder.status != 'CANCELLED' && userorder.status != 'RETURNED')">
                            <div class="col-sm-12 order-sm-0">
                                <select class="form-control" [(ngModel)]="selectedOrderStatus"
                                    name="selectedOrderStatus">
                                    <option value="DISPATCHED">Dispatched</option>
                                    <option value="OUT_FOR_DELIVERY">Out For Delivery</option>
                                    <option value="DELIVERED">Delivered</option>
                                    <option value="RETURNED">Returned</option>
                                    <option value="CANCELLED">Cancelled</option>
                                </select>
                            </div>
                            <div class="col-sm-12 order-sm-0 mt-2 text-left">
                                <div class=" form-group icon-form">
                                    <label class="label">Reason</label>
                                    <i class="ft-package"></i>
                                    <textarea class="form-control transparent-tb" [(ngModel)]="cancelComments"
                                        name="cancelComments" placeholder=" Enter your comments here"
                                        style="color:black"></textarea>
                                    <small class="error"
                                        *ngIf="(selectedOrderStatus == 'CANCELLED' || selectedOrderStatus == 'RETURNED') && (!cancelComments || cancelComments == '')">Reason
                                        Mandatory for Cancelled and Returned Status</small>
                                </div>
                            </div>
                            <div class="col-sm-12 order-sm-0 mt-2 text-center">
                                <button class="btn btn-primary rounded-pill auto float-center"
                                    [disabled]="!selectedOrderStatus || selectedOrderStatus == '' ||
                                    ((selectedOrderStatus == 'CANCELLED' || selectedOrderStatus == 'RETURNED') && (!cancelComments || cancelComments == ''))" data-dismiss="modal"
                                    (click)="updateOrder()">Update Status</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div id="fail-modal" *ngIf="errorModal" class="modal fade show" style="display:block!important;">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content modal-reject ">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-alert-triangle"></i>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4>Failure</h4>
                        <p class="text-danger">Order Update Failed. Please try again later.</p>
                        <button class="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="successModal" id="email-verifiacation-modal" class="modal fade show"
            style="display:block!important;">
            <div class="modal-dialog  modal-dialog-centered modal-md">
                <div class="modal-content modal-confirm">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-check"></i>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4>Success</h4>
                        <p class="text-success">Order Updated Successfully </p>
                        <button class="btn btn-success" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>