<header>
    <!-- Top Bar Section  -->
    <div class="topbar">
        <div class="container">
            <div class="row d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                <div class="col-lg-8 col-md-12 mr-auto">
                    <ul class="topbar-left">
                        <li> <i class="ft-phone-call"></i>+91 98320 59345 </li>
                        <li> <i class="ft-mail"></i>support@soy-bag.com </li>
                        <li class="text-warning"> <i class="ft-info"></i>3-5 BUSINESS DAYS DELIVERY & FREE RETURNS
                        </li>
                    </ul>
                </div>

                <div class="auto topbar-right ">
                    <ul class="nav navbar-nav ">

                        <li class="dropdown dropdown-user nav-item" *ngIf="user"> <a
                                class="dropdown-toggle nav-link dropdown-user-link" href="javascript:void(0)"
                                data-toggle="dropdown" aria-expanded="false"> <span class="avatar"><img
                                        src="assets/images/logo.jpg" alt="avatar"></span> <span
                                    class="user-name">{{user.username}}</span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <ng-container *ngIf="isAdmin">
                                    <a class="dropdown-item" routerLink="/admin/admin-dashboard"><i class="ft-user"></i>
                                        Dashboard</a>
                                    <a class="dropdown-item" routerLink="/admin/display-orders"><i
                                            class="ft-shopping-cart">
                                        </i> Orders</a>
                                </ng-container>
                                <ng-container *ngIf="!isAdmin">
                                    <a class="dropdown-item" routerLink="/profile"><i class="ft-user"></i> My
                                        Account</a>
                                    <a class="dropdown-item" routerLink="/shopping-cart"><i class="ft-shopping-cart">
                                        </i> My Cart</a>
                                    <a class="dropdown-item" routerLink="/wishlist"><i class="ft-heart"></i>Wish
                                        List</a>
                                </ng-container>

                                <a class="dropdown-item" (click)="logout()"><i class="ft-power"></i>Logout</a>
                            </div>
                        </li>

                        <li class="link-btn" *ngIf="!user"> <a routerLink="/login"
                                class="btn btn-md btn-success rounded-pill">
                                <i class="ft-lock"></i> Sign In</a>
                        </li>
                        <!-- <li class="link-btn" *ngIf="!user"> <a routerLink="/signup"
                                class="btn btn-md btn-success rounded-pill">
                                <i class="ft-lock"></i> Sign Up</a>
                        </li> -->

                    </ul>
                </div>

            </div>
        </div>
    </div>


    <!-- Menubar -->
    <div class="head-menu-bar p-0" id="nav-sticky">
        <div class="container">
            <nav class="navbar navbar-expand-lg m-0 p-0">
                <a class="front-logo" href="javascript:void(0)"><img src="assets/images/logo.jpg"></a>


                <div class="form-inline  ml-auto cart-search-box">
                    <!--<button class="btn btn-default search-btn" id="search-btn"><i class="ft-search"></i></button>-->

                    <!-- <div class="mini-cart-warp" *ngIf="user && !isAdmin">
                        <a routerLink="/shopping-cart" class="count-cart"><span
                                class="number">{{myCartSize}}</span><span *ngIf="myCartSize > 0">{{currency}}
                                {{myCartPrice}}</span></a>
                    </div> -->

                    <button class="navbar-toggler front-res-btn" type="button" data-toggle="collapse"
                        data-target="#NavMenuBar" aria-controls="NavMenuBar" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <i class="fa fa-bars"></i>
                    </button>
                </div>


                <div class="collapse navbar-collapse ml-auto" id="NavMenuBar">
                    <ul class="navbar-nav">
                        <li class="nav-item active">
                            <a class="nav-link" routerLink="/home">
                                <i class="ft-home"></i> Home
                            </a>
                        </li>

                        <li class="nav-item dropdown"> <a class="nav-link dropdown-toggle" href="javascript:void(0)"
                                id="shopDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"> <i class="ft-shopping-cart"> </i> Products </a>
                            <div class="dropdown-menu" aria-labelledby="shopDropdown">
                                <a class="dropdown-item" *ngFor="let category of allCategories"
                                    href="javascript:void(0)" routerLink="/shop-products"
                                    [queryParams]="{ key: category.id }">{{category.categoryName}}</a>

                            </div>
                        </li>


                        <li class="nav-item">
                            <a class="nav-link" routerLink="/about-us">
                                <i class="ft-users"></i> About Us
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLink="/faq">
                                <i class="ft-help-circle"></i> FAQs
                            </a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link" routerLink="/contact-us">
                                <i class="ft-phone-call"></i> Contact Us
                            </a>
                        </li>


                    </ul>

                </div>

            </nav>

        </div>
        <div class="search-box-con" id="search-box-con">
            <div class="container search-div">
                <input type="text" class="form-control" placeholder="Search News Here">
                <button class="search-close-btn" id="search-close-btn"><i class="ft-x"></i></button>
            </div>
        </div>
    </div>






</header>