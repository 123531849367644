import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

import { WishListRequest } from 'src/app/model/wishlistRequest';
import { WishListResponse } from 'src/app/model/wishistResponse';

@Injectable()
export class WishService {

    constructor(private httpClient: HttpClient, private router: Router) { }

    baseUrl: string = environment.baseUrl;

    addToWishListUrl: string = this.baseUrl + '/api/wishlist/addToWishlist';
    deleteWishListUrl: string = this.baseUrl + '/api/wishlist/removeWishlist?userId=';
    getWishListUrl: string = this.baseUrl + '/api/wishlist/viewWishlist?userId=';
    

    addToWishlist(wishlist: WishListRequest){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr});        
        return this.httpClient.post(this.addToWishListUrl,wishlist, {headers: reqHeader});
    }

    deleteWishList(userId){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr});
        return this.httpClient.delete(this.deleteWishListUrl + userId, {headers: reqHeader});
    }

    getWishList(id){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr}); 
        return this.httpClient.get<WishListResponse>(this.getWishListUrl+id, {headers: reqHeader});
    }

    

}