<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">

        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">

                                <li class="breadcrumb-item active" aria-current="page">Order Details</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6"
                                    *ngIf="user.userType=='ADMIN'">
                                    <div>
                                        <span class="d-block"> <span class="label ">INCOME</span>
                                        </span>
                                        <span class="value">{{currency}} {{income}} </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                        <span class="sparkline-bar text-warning">
                                            <i class="ft-bar-chart-2"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row row-sm" *ngIf="userorder">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row d-flex">
                            <div class=" col-md-6 ml-auto mt-2">
                                <h4 class="card-title mb-0">View Order Details<span class="badge"
                                        [class.badge-primary]="userorder.status == 'CREATED'"
                                        [class.badge-danger]="userorder.status == 'RETURNED' || userorder.status == 'CANCELLED'"
                                        [class.badge-success]="userorder.status == 'DELIVERED'"
                                        [class.badge-warning]="userorder.status == 'DISPATCHED' || userorder.status == 'OUT_FOR_DELIVERY'">{{userorder.status}}</span>
                                </h4>
                                <div class="clearfix"></div>
                            </div>

                            <div class=" col-md-6 ml-auto mt-2 text-right">
                                <button class="btn btn-primary btn-sm auto ml-2 rounded-pill" type="button"
                                    routerLink="/admin/display-orders">Back to Orders </button>
                            </div>
                        </div>
                        <hr>




                        <section>
                            <div class="col-xs-12 mt-4 order-details-tab">
                                <nav>
                                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                        <a class="nav-item nav-link active" id="order-details-tab" data-toggle="tab"
                                            href="#order-details" aria-selected="true">Order
                                            Details</a>

                                        <a class="nav-item nav-link" *ngIf="user.userType=='ADMIN'" id="con-details-tab"
                                            data-toggle="tab" href="#con-details" role="tab"
                                            aria-selected="false">Assign Stock
                                        </a>
                                        <a class="nav-item nav-link" *ngIf="user.userType=='ADMIN'"
                                            id="order-status-tab" data-toggle="tab" href="#order-status" role="tab"
                                            aria-selected="false">Update Status
                                        </a>
                                    </div>
                                </nav>
                                <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                                    <div class="tab-pane fade show active" id="order-details" role="tabpanel"
                                        aria-labelledby="order-details-tab">

                                        <div class="row">
                                            <div class="col-sm-6 order-sm-0">
                                                <div class="card overflow-hidden project-card" *ngIf='billingAddress'>
                                                    <div class="card-body">
                                                        <div class="d-flex">
                                                            <div class="project-card-box primary">
                                                                <i class="ft-map-pin"></i>
                                                            </div>
                                                            <div class="project-content">
                                                                <h6 class="text-danger"><strong>Billing Address</strong>
                                                                </h6>
                                                                <div>
                                                                    <b>{{billingAddress.firstName}}
                                                                        {{billingAddress.lastName}}</b><br>
                                                                    {{billingAddress.address_1}}
                                                                    {{billingAddress.address_2}}
                                                                    {{billingAddress.city}}
                                                                    {{billingAddress.state}}
                                                                    {{billingAddress.country}}-{{billingAddress.postcode}}
                                                                    <br>
                                                                    TEL: {{billingAddress.mobileNo}}
                                                                    <br>
                                                                    Email: {{billingAddress.email}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-sm-6 order-sm-0">
                                                <div class="card overflow-hidden project-card" *ngIf='shippingAddress'>
                                                    <div class="card-body">
                                                        <div class="d-flex">
                                                            <div class="project-card-box primary">
                                                                <i class="ft-map-pin"></i>
                                                            </div>
                                                            <div class="project-content">
                                                                <h6 class="text-danger"><strong>Shipping
                                                                        Address</strong></h6>
                                                                <div>
                                                                    <b>{{shippingAddress.firstName}}
                                                                        {{shippingAddress.lastName}}</b><br>
                                                                    {{shippingAddress.address_1}}
                                                                    {{shippingAddress.address_2}}
                                                                    {{shippingAddress.city}}
                                                                    {{shippingAddress.state}}
                                                                    {{shippingAddress.country}}-{{shippingAddress.postcode}}
                                                                    <br>
                                                                    TEL: {{shippingAddress.mobileNo}}
                                                                    <br>
                                                                    Email: {{billingAddress.email}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card no-shadow">
                                            <div class="scrollbar scroller prod-scroller ">
                                                <table
                                                    class="table res-table table-striped admin-tbl reflow-table prod-chkout">
                                                    <thead class="text-muted">
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>Price/Unit</th>
                                                            <th class="text-right">Quantity</th>
                                                            <th class="text-right">Total Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let cartEntry of shoppingList">
                                                            <td data-th="Product ">
                                                                <figure class="media">
                                                                    <div class="img-wrap"><img
                                                                            *ngIf="cartEntry.productImageUrl"
                                                                            [src]="cartEntry.productImageUrl[0] | safe: 'url'"
                                                                            class="prod-img">
                                                                    </div>
                                                                    <figcaption class="media-body">
                                                                        <h6 class="title text-truncate">
                                                                            {{cartEntry.productName}} </h6>
                                                                        [{{cartEntry.packageQuantity.packageQuantity}}]
                                                                    </figcaption>
                                                                </figure>
                                                            </td>
                                                            <td data-th="Quantity">
                                                                <div class="price-wrap">
                                                                    <var class="price"> {{currency}}
                                                                        {{cartEntry.productCost}}</var>
                                                                </div>
                                                            </td>
                                                            <td class="text-right" data-th="Quantity">
                                                                <div class="price-wrap text-right">
                                                                    <var class="price">{{cartEntry.quantity}}</var>
                                                                </div>
                                                            </td>
                                                            <td data-th="Price">
                                                                <div class="price-wrap text-right">
                                                                    <var class="price">{{currency}}
                                                                        {{(cartEntry.productTotalPrice)}}</var>
                                                                </div>
                                                            </td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                            <table class="table res-table  admin-tbl reflow-table prod-chkout">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-right">
                                                            Cart Total
                                                        </td>

                                                        <td class="text-right" data-th="Total Quantity">

                                                        </td>

                                                        <td class="text-right" data-th="Total Price">

                                                            <strong>{{currency}} {{cartTotal}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">
                                                            Delivery Charge
                                                        </td>

                                                        <td class="text-right" data-th="Total Quantity">

                                                        </td>

                                                        <td class="text-right" data-th="Total Price">

                                                            <strong>{{currency}} {{deliveryCharge}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-right">
                                                            Total
                                                        </td>

                                                        <td class="text-right" data-th="Total Quantity">

                                                        </td>

                                                        <td class="text-right" data-th="Total Price">

                                                            <strong>{{currency}} {{total}}</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row mt-1 mb-4">
                                            <div class="col-md-12 order-sm-0">
                                                <div class="panel-list mt-0">
                                                    <h6 class="Title mt-1 ml-2">Payment Details</h6>
                                                    <ul class="points">
                                                        <li>
                                                            <div class="title">Payment Status</div>
                                                            <div class="value text-capitalize">
                                                                {{userorder.paymentStatus}}</div>
                                                        </li>
                                                        <ng-container
                                                            *ngIf="userorder.paymentStatus == 'PAID' || userorder.paymentStatus == 'ADVANCE_PAID'">
                                                            <li>
                                                                <div class="title">Amount Paid</div>
                                                                <div class="value text-capitalize">
                                                                    {{userorder.paymentAdvanceAmt}}</div>
                                                            </li>
                                                            <li>
                                                                <div class="title">Payment Made Through</div>
                                                                <div class="value text-capitalize">
                                                                    {{userorder.payment_method}}</div>
                                                            </li>
                                                            <ng-container
                                                                *ngIf="userorder.payment_method == 'CARD' && userorder.paymentDetails">
                                                                <li>
                                                                    <div class="title">Payment Type</div>
                                                                    <div class="value">
                                                                        {{userorder.paymentDetails.brand}}</div>
                                                                </li>
                                                                <li>
                                                                    <div class="title">Card # : Card ending</div>
                                                                    <div class="value">
                                                                        {{userorder.paymentDetails.last4}}</div>
                                                                </li>
                                                                <!--<li>
                                                            <div class="title">Funding</div>
                                                            <div class="value">{{userorder.paymentDetails.funding}}</div>
                                                        </li>-->
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="userorder.payment_method == 'CHEQUE' && userorder.paymentDetails">
                                                                <li>
                                                                    <div class="title">Payment Cheque No</div>
                                                                    <div class="value">
                                                                        {{userorder.paymentDetails.chequeNo}}</div>
                                                                </li>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="tab-pane fade" id="con-details" role="tabpanel"
                                        aria-labelledby="con-details-tab">
                                        <div class="card no-shadow">
                                            <div class="scrollbar scroller prod-scroller ">
                                                <table
                                                    class="table res-table table-striped admin-tbl reflow-table prod-chkout">
                                                    <thead class="text-muted">
                                                        <tr>
                                                            <th>Product Name</th>
                                                            <th>Price/Unit</th>
                                                            <th>Ordered Quantity</th>
                                                            <!-- <th>Barcode</th>
                                                            <th>Quantity</th> -->
                                                            <th>Assign</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let cartEntry of shoppingList, let i=index;">
                                                            <td data-th="Product ">
                                                                <h6 class="title text-truncate">
                                                                    {{cartEntry.productName}} </h6>
                                                                [{{cartEntry.packageQuantity.packageQuantity}}]
                                                            </td>
                                                            <td data-th="Quantity">
                                                                <div class="price-wrap">
                                                                    <var class="price"> {{currency}}
                                                                        {{cartEntry.productCost}}</var>
                                                                </div>
                                                            </td>
                                                            <td data-th="Quantity">
                                                                <div class="price-wrap ">
                                                                    <var class="price">{{cartEntry.quantity}}</var>
                                                                </div>
                                                            </td>

                                                            <!-- <td><input type="text" class="form-control"  placeholder="barcode"></td>
                                                            <td><input type="number" class="form-control" placeholder="packing Qty"></td> -->
                                                            <td><button (click)="openBarcodeScanner(i)"
                                                                    *ngIf="!cartEntry.assignPackageQuantity"
                                                                    class="btn btn-outline-primary btn-sm auto ml-2 rounded-pill">Assign
                                                                    Item</button>
                                                                    <p *ngIf="cartEntry.assignPackageQuantity"
                                                                    class="text-center text-success">Assigned</p>
                                                            </td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <div class="col-sm-12 order-sm-0 mt-2 text-center"
                                                    *ngIf="!userorder.stockOutStatus">
                                                    <button
                                                        class="btn btn-primary btn-sm auto  rounded-pill float-center"
                                                        (click)="allStockout()">Stock Out</button>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                    <div class="tab-pane fade" id="order-status" role="tabpanel"
                                        aria-labelledby="order-status-tab">
                                        <ng-container *ngIf="userorder.paymentStatus!= 'PAID'">
                                            <fieldset class="custom-fs col-md-12">
                                                <legend>Payment Details</legend>
                                                <div class="row">
                                                    <div class="form-group col-md-6">
                                                        <label>Payment Status <span class="required">*</span></label>
                                                        <select (change)="selectedPayment($event.target.value)"
                                                            class="form-control">
                                                            <option value="">Select</option>
                                                            <option value="PAID">Fully Paid</option>
                                                            <option value="ADVANCE_PAID">Advance Paid</option>
                                                            <option value="NOT_PAID">Not Paid</option>
                                                            <option value="FAILED">Payment Failed</option>
                                                        </select>
                                                    </div>
                                                    <div *ngIf="paymentField" class="form-group col-md-6">
                                                        <label>Payment</label>
                                                        <input class="form-control" #amount="ngModel"
                                                            [(ngModel)]="amountPaid" name="amountPaid">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="form-group col-md-6">
                                                        <label>Payment Method <span class="required">*</span></label>
                                                        <select (change)="selectedMethod($event.target.value)"
                                                            class="form-control">
                                                            <option value="">Select Type of Payment</option>
                                                            <option value="CASH">Cash</option>
                                                            <option value="CARD">Card</option>
                                                            <option value="CHEQUE">Cheque</option>
                                                        </select>
                                                    </div>

                                                    <div *ngIf="card" class="form-group col-md-6">
                                                        <label>Brand</label>
                                                        <select #cardbrand="ngModel" [(ngModel)]="cardBrand"
                                                            name="cardBrand" class="form-control">
                                                            <option value="">Select Brand</option>
                                                            <option value="Master">Master</option>
                                                            <option value="VISA">VISA</option>

                                                        </select>

                                                    </div>

                                                    <div *ngIf="card" class="form-group col-md-6">
                                                        <label>Card last 4 digit</label>
                                                        <input class="form-control" maxlength="4" #fourdigit="ngModel"
                                                            [(ngModel)]="fourDigit" name="fourDigit">
                                                    </div>
                                                    <div *ngIf="cheque" class="form-group col-md-6">
                                                        <label>Cheque Number</label>
                                                        <input class="form-control" #chequeno="ngModel"
                                                            [(ngModel)]="chequeNo" name="chequeNo">
                                                    </div>
                                                </div>
                                                <div class="row justify-content-center mt-4">
                                                    <button type="submit" (click)="updatePaymentStatus()"
                                                        class="btn col-md-3 btn-success">Update
                                                        Payment</button>

                                                </div>
                                            </fieldset>
                                        </ng-container>
                                        <ng-container *ngIf="!userorder.stockOutStatus">
                                            <div class="card">
                                                <div class="row col-md-12 justify-content-center text-danger">
                                                    <h6><strong>Stock is not Assigned yet</strong></h6>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="userorder.stockOutStatus">
                                            <div class="row"
                                                *ngIf="userorder && (userorder.status == 'CANCELLED' || userorder.status == 'RETURNED')">
                                                <div class="col-sm-12 order-sm-0 mt-2 text-right">
                                                    <div class="panel-list mt-0">
                                                        <ul class="points">
                                                            <li>
                                                                <div class="title">Reason for Cancellation</div>
                                                                <div class="value">{{userorder.reasonForCancellation}}
                                                                </div>
                                                            </li>
                                                            <!-- <li *ngIf="userorder && (userorder.status == 'CANCELLED' || userorder.status == 'RETURNED') && (!userorder.stockInStatus)"><div class="col-sm-12 order-sm-0 mt-2 text-center"
                                                                >
                                                                <button class="btn btn-primary rounded-pill auto float-center"
                                                                    data-dismiss="modal" (click)="stockInAll()">Stock In</button>
                                                            </div></li> -->
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row"
                                                *ngIf="userorder && (userorder.status != 'CANCELLED' && userorder.status != 'RETURNED')">
                                                <div class="col-sm-12 order-sm-0">
                                                    <select class="form-control" [(ngModel)]="selectedOrderStatus"
                                                        name="selectedOrderStatus">
                                                        <option value="DISPATCHED">Dispatched</option>
                                                        <option value="OUT_FOR_DELIVERY">Out For Delivery</option>
                                                        <option value="DELIVERED">Delivered</option>
                                                        <option value="RETURNED">Returned</option>
                                                        <option value="CANCELLED">Cancelled</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-12 order-sm-0 mt-2 text-left">
                                                    <div class=" form-group icon-form">
                                                        <label class="label">Reason</label>
                                                        <i class="ft-package"></i>
                                                        <textarea class="form-control transparent-tb"
                                                            [(ngModel)]="cancelComments" name="cancelComments"
                                                            placeholder=" Enter your comments here"
                                                            style="color:black"></textarea>
                                                        <small class="error"
                                                            *ngIf="(selectedOrderStatus == 'CANCELLED' || selectedOrderStatus == 'RETURNED') && (!cancelComments || cancelComments == '')">Reason
                                                            Mandatory for Cancelled and Returned Status</small>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-sm-12 order-sm-0 mt-2 text-center">
                                                    <button *ngIf="selectedOrderStatus"
                                                        class="btn btn-primary rounded-pill auto float-center"
                                                        [disabled]="!selectedOrderStatus || selectedOrderStatus == '' ||
                                        ((selectedOrderStatus == 'CANCELLED' || selectedOrderStatus == 'RETURNED') && (!cancelComments || cancelComments == ''))"
                                                        data-dismiss="modal" (click)="updateOrder()">Update
                                                        Status</button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>

        <div id="fail-modal" *ngIf="errorModal" class="modal fade show" style="display:block!important;">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content modal-reject ">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-alert-triangle"></i>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4>Failure</h4>
                        <p class="text-danger">Order Update Failed. Please try again later.</p>
                        <button class="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="successModal" id="email-verifiacation-modal" class="modal fade show"
            style="display:block!important;">
            <div class="modal-dialog  modal-dialog-centered modal-md">
                <div class="modal-content modal-confirm">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-check"></i>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4>Success</h4>
                        <p class="text-success">Order Updated Successfully </p>
                        <button class="btn btn-success" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="showBarcodeScanModal" class="modal fade show" id="ModalFailed" style="display:block!important;"
            tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog  modal-dialog-centered modal-md">
                <div class="modal-content modal-confirm">
                    <div class="modal-header">
                        <h4>Barcode</h4>
                        <button type="button" (click)="showBarcodeScanModal = false; loading = false;" class="close"
                            data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <ng-container *ngIf="!stockAssigned">
                            <form>
                                <div class="row d-flex mb-3 mt-2 col-md-12">
                                    <div class="input-group col-md-6 mr-auto ml-auto ">
                                        <input type="text" class="form-control" #assigningbarcode="ngModel"
                                            [(ngModel)]="assigningBarCode" name="assigningBarCode"
                                            placeholder="Barcode">
                                    </div>
                                    <div class="input-group col-md-6 mr-auto ml-auto ">
                                        <input type="number" class="form-control" #assigningquantity="ngModel"
                                            [(ngModel)]="assigningQuantity" name="assigningQuantity"
                                            placeholder="Quantity">
                                    </div>
                                </div>

                                <small class="form-text text-danger mb-5" *ngIf="error">
                                    {{errorMessage}}!</small>
                                <button type="submit" class="btn btn-success" (click)="assignPackage()">Assign
                                    Package</button>
                                <button class="btn btn-danger ml-3"
                                    (click)="showBarcodeScanModal = false; loading = false;"
                                    data-dismiss="modal">Close</button>
                            </form>
                        </ng-container>


                    </div>
                </div>
            </div>
        </div>