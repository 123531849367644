import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Product } from '../model/product';
import { ShoppingCartRequest } from '../model/shoppingCartRequest';
import { User } from '../model/user';
import { WishListResponse } from '../model/wishistResponse';
import { WishListRequest } from '../model/wishlistRequest';
import { CartHelpers } from '../shared/helper/cart.helper';
import { WishListHelpers } from '../shared/helper/wishlist.helper';
import { AuthenticationService } from '../shared/services/authentication.service';
import { CartService } from '../shared/services/cart.service';
import { ProductService } from '../shared/services/product.service';
import { WishService } from '../shared/services/wishlist.service';



@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  wishlistproduct: any[];
  counter: number = 1;
  userid: string;
  user: User;
  product: Product[] = [];
  productWishlist: WishListResponse;
  wishlist: any[];
  loading: boolean = false;
  success: boolean = false;
  errorPopup: boolean = false;
  successCart: boolean = false;
  currency: String = "";
  selectedPackagePrice: number=0;
  selectedProductName: string;

  constructor(private serviceWishlist: WishService, private authenticationService: AuthenticationService
    , private router: Router, private cartService: CartService, private productService: ProductService,
    private _snackBar: MatSnackBar, ) { }
  private wishHelper: WishListHelpers = new WishListHelpers(this.serviceWishlist);
  private cartHelper: CartHelpers = new CartHelpers(this.cartService, this.productService);

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;

      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.refreshWishList();
      //this.deleteWishList();
    }
  }
  refreshWishList() {
    this.loading = true;
    this.serviceWishlist.getWishList(this.user.id).subscribe(WishList => {
      this.productWishlist = WishList;
      console.log(this.productWishlist);
      this.wishlist = this.productWishlist.myWishlist;
      this.loading = false;

    }, error => {
      this.loading = false;
    }
    );
    this.loading = false;
  }

  addCart(product) {
    this.addToCart(product, this.counter,this.selectedPackagePrice);
  }

  addToCart(product, counter,price) {
    this.loading = true;
    let productNew = {
      id: product.productId,
      imageId: product.imageId,
      imageUrl: product.imageUrl,
      productCost: product.productCost,
      productName: product.productName
    }
    this.cartHelper.checkCartandUpdate(productNew, counter).then(updatedCartEntries => {
      let cart: ShoppingCartRequest = {
        shoppingCart: updatedCartEntries,
        userId: this.user.id
      }
      this.cartService.addToCart(cart).toPromise()
        .then(() => {
          this.loading = false;
          this.successCart = true;
          this.refresh();

          setTimeout(() => {
            this.successCart = false;
          }, 2000);
        },
          error => {
            this.loading = false;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;
            }, 2000);
          });
    },
      error => {
        this.loading = false;
      });
  }

  cart() {
    this.router.navigate(['/shopping-cart']);
  }

  removeFromWishList(i) {

    this.loading = true;
    this.wishHelper.removeFromWishList(i).then(updatedmyWishlist => {
      let wishList: WishListRequest = {
        productsForWishList: updatedmyWishlist,
        userId: this.user.id
      }
      this.serviceWishlist.addToWishlist(wishList).toPromise()
        .then(() => {
          this.loading = false;
          this.success = true;
          setTimeout(() => {
            this.success = false;
          }, 2000);
          this.refreshWishList();
        }, error => {
          this.loading = false;
          this.errorPopup = true;
          setTimeout(() => {
            this.errorPopup = false;
          }, 2000);
        });


    }, error => {
      this.loading = false;
    });
  }

  emptyWishlist() {
    this.loading = true;
    this.serviceWishlist.deleteWishList(this.user.id).toPromise().then(
      response => {
        this.refreshWishList();
      }, error => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  refresh() {
    this.router.navigateByUrl('/wishlist', { skipLocationChange: true }).then(() => {
      this.router.navigate(['WishlistComponent']);
    });
  }
  setSelectedProduct(event,product) {
    if (event.target.value) {
      product.packagingQuantities.forEach(product => {
        if (event.target.value == product.packageQuantity) {
          this.selectedPackagePrice = product.packageDiscountedPrice;
          this.selectedProductName = product.packageQuantity;
        }
      });
    }
   
  }
}
