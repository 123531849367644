import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { BillingDetails, ShippingDetails, Order } from 'src/app/model/order';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductService } from 'src/app/shared/services/product.service';
declare function reflowTable(): any;
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  user: User;
  allOrders: any;
  orderId: any;
  billingAddress: any;
  userorder: Order;
  shippingDetails: ShippingDetails[];
  shippingAddress: any;
  shoppingList: any[];
  total: number;
  loading: boolean = false;
  @ViewChild('htmlData') htmlData: ElementRef;
  allCounts: any;
  income: number;
  changedOrder: string = "ALL";
  ordersFilter: string;
  cartTotal: number;
  deliveryCharge: number;
  cancelComments: string;
  currency: String;

  constructor(private authenticationService: AuthenticationService,
    private orderService: OrderService, private router: Router,
    private productService: ProductService, ) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      if(this.user.userType=='ADMIN'){
        this.getOrderlist();
      }else{
        this.getMyOrder(this.user.id);
      }
     
      this.getTotalCounts();
    }
  }

  getOrderlist() {
    this.loading = true;
    this.allOrders = [];
    this.orderService.getAllOrders().subscribe(response => {
      this.allOrders = response;
      reflowTable();
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  getOrdersByStatusList(status) {
    this.loading = true;
    this.allOrders = [];
    this.orderService.getOrdersByStatus(status).subscribe(response => {
      this.allOrders = response;
      reflowTable();
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  address(userorder) {
    this.userorder = userorder;
    this.total = this.userorder.total_Amount_to_Pay;
    this.cartTotal = this.userorder.cartAmount;
    this.deliveryCharge = this.userorder.deliveryCharge;
    this.billingAddress = this.userorder.billingDetails;
    this.shippingAddress = this.userorder.shippingDetails;
    this.shoppingList = this.userorder.shoppingCartEntry;
  }
  getMyOrder(id){
    this.loading = true;
    this.allOrders = [];
    this.orderService.getMyOrders(id).subscribe(response => {
      this.allOrders = response;
      reflowTable();
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  public downloadPDF(): void {
    let orderData: any[] = [];
    this.allOrders.forEach(orderObj => {
      orderData.push([orderObj.orderNumber,
      orderObj.billingDetails.firstName + ' ' + orderObj.billingDetails.lastName,
      new Date(orderObj.createdDate.toString()).toISOString().substr(0, 10),
      orderObj.total_Amount_to_Pay,orderObj.modeOfOrder,orderObj.paymentStatus,
      orderObj.status,orderObj.createdby])
    });
    let DATA = this.htmlData.nativeElement;
    let doc = new jsPDF('p', 'pt', 'a1');

    let handleElement = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    var img = new Image();
    img.src = '/assets/images/logo.jpg';
    doc.addImage(img, "PNG", 770, 60, 100, 80, { align: "left" });
    doc.setTextColor(0, 102, 0);
    doc.setFontSize(40);
    doc.setTextColor(100);
    doc.text("List of Orders", 825, 180, { align: "center" });
    doc.setFont("arial", "bold");
    (doc as any).autoTable({
      head: [["Order No", "Customer Name", "Order Date", "Order Amount","Order Type", "Payment Status","Order Status","Created By"]],
      body: orderData,
      theme: 'grid',
      startY: 200,
      showHeader: 'firstPage',
      margin: { right: 100, left: 100 },
      tableWidth: 'auto',
      headerStyles: { fillColor: 120, textColor: 255, halign: "center" },
      styles: {
        fontSize: 20
      },
    })

    doc.save('orders.pdf');
  }

  // downloadExcel() {
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.htmlData.nativeElement);
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Orders');
  //   XLSX.writeFile(wb, 'orders.xlsx');
  // }
  downloadExcel() {
    this.orderService.exportExcel().subscribe(excel => {

      const blob: any = new Blob([excel], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      let link = document.createElement("a");

      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", 'orders');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
  }
  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }

  selectedOrderStatus: string;
  errorModal: Boolean = false;
  successModal: Boolean = false;

  updateOrder() {
    if (this.selectedOrderStatus && this.selectedOrderStatus != "") {
      this.userorder.status = this.selectedOrderStatus;
      this.userorder.reasonForCancellation = this.cancelComments;
      this.loading = true;
      this.orderService.updateOrder(this.userorder.id, this.userorder).toPromise().then(
        response => {
          this.selectedOrderStatus = null;
          this.loading = false;
          this.successModal = true;
          this.getOrderlist();
          setTimeout(() => {
            this.successModal = false;
          }, 2000);
        }, error => {
          this.loading = false;
          this.selectedOrderStatus = null;
          this.getOrderlist();
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
          }, 2000);
        }
      )
    }
  }

  getOrdersByStatus() {
    if (this.changedOrder == "ALL") {
      this.getOrderlist();
    } else {
      this.getOrdersByStatusList(this.changedOrder);
    }
  }

  addOrderPage() {
    this.router.navigate(['/admin/createOrder']);
  }
}

