import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Category } from 'src/app/model/category';
import { Enquiry } from 'src/app/model/enquiry';

@Injectable()
export class EnquiryService {

    constructor(private httpClient: HttpClient, private router: Router) { }

    baseUrl: string = environment.baseUrl;

    saveEnquiryUrl: string = this.baseUrl + '/api/addContactUsPage';
    updateEnquiryUrl: string = this.baseUrl + '/api/updateContactUsPageById';
    deleteEnquiryUrl: string = this.baseUrl + '/api/deleteContactUsPageById/';
    getAllEnquiryUrl: string = this.baseUrl + '/api/getAllContactUsPages';
    getEnquiryByIdUrl: string = this.baseUrl + '/api/getContactUsPageById/';
    

    saveEnquiry(enquiry: Enquiry){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr});
        return this.httpClient.post(this.saveEnquiryUrl, enquiry, {headers: reqHeader});
    }

    updateEnquiry(enquiry: Enquiry){
        
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr});
        return this.httpClient.put(this.updateEnquiryUrl, enquiry, {headers: reqHeader});
    }

    deleteenquiry(enquiry){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr});
        return this.httpClient.delete(this.deleteEnquiryUrl+enquiry, {headers: reqHeader});
    }

    getAllEnquiry(){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr}); 
        return this.httpClient.get(this.getAllEnquiryUrl, {headers: reqHeader});
    }

    getEnquiryById(Id){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr});
        return this.httpClient.get(this.getEnquiryByIdUrl+Id, {headers: reqHeader});
    }

    

}