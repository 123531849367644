<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">
    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>
    <div class="content" id="content-sec">
        <app-top-bar></app-top-bar>
        <div class="container-fluid">
            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page">Settings</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div>
                                        <span class="d-block"> <span class="label ">INCOME</span>
                                        </span>
                                        <span class="value">{{currency}} {{income}} </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                        <span class="sparkline-bar text-warning">
                                            <i class="ft-bar-chart-2"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>



        <div class="row row-sm">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-0">Settings</h4>
                        <p class="sub-text">
                        </p>
                        <hr>
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="card tab2-card">
                                        <div class="card-body">
                                            <ul class="nav nav-tabs nav-material" id="top-tab" role="tablist">
                                                <li class="nav-item"><a class="nav-link active show" id="top-banner-tab"
                                                        data-toggle="tab" href="#top-banner" role="tab"
                                                        aria-controls="top-banner" aria-selected="true"
                                                        (click)="getAllBannerImages('HOME_SLIDER')">
                                                        <i class="ft-settings"></i> Home Slider</a>
                                                </li>
                                                <!--<li class="nav-item"><a class="nav-link" id="homeCatgry-tab"
                                                        data-toggle="tab" href="#homeCatgry" role="tab"
                                                        aria-controls="homeCatgry" aria-selected="false"
                                                        (click)="getAllBannerImages('HOME_CATEGORY')">
                                                        <i class="ft-settings"></i> Home Category</a>
                                                </li>-->
                                                <li class="nav-item"><a class="nav-link" id="Filters-tab"
                                                        data-toggle="tab" href="#Filters" role="tab"
                                                        (click)="loadFilters()" aria-controls="Filters"
                                                        aria-selected="false">
                                                        <i class="ft-settings"></i> Filters</a>
                                                </li>
                                            </ul>
                                            <div class=" tab-content" id="top-tabContent">
                                                <div class="tab-pane fade active show" id="top-banner" role="tabpanel"
                                                    aria-labelledby="top-banner-tab">
                                                    <div class="col-md-12 mt-4 mb-4" *ngIf="homeSlider">
                                                        <h6><strong>{{homeSlider.imageName}} Images</strong></h6>
                                                        <p class="text-danger">Please upload image with 1920*700 resolution </p>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'"
                                                            [multiple]="true" (change)="onFileUpload($event)" [expandable]="true">
                                                            <ngx-dropzone-label>Drag and drop your product images to
                                                                upload!
                                                            </ngx-dropzone-label>
                                                            <ngx-dropzone-image-preview
                                                                ngProjectAs="ngx-dropzone-preview" [file]="uploadedFile"
                                                                *ngFor="let uploadedFile of uploadedFiles"
                                                                [removable]="true" 
                                                                (removed)="onFileRemoved(uploadedFile)">
                                                                <!--<ngx-dropzone-label>{{ uploadedFile.name }}
                                                                    ({{ uploadedFile.type }})
                                                                </ngx-dropzone-label>-->
                                                            </ngx-dropzone-image-preview>
                                                        </div>
                                                    </div>
                                                    <div class="spacer-30"></div>

                                                    <div class="mt-4 text-center col-md-4 m-auto">
                                                        <button (click)="cancel()"
                                                            class="btn  btn-danger btn-sm rounded-pill mr-2">Cancel
                                                        </button>
                                                        <button class=" btn btn-primary btn-sm rounded-pill ml-2"
                                                            *ngIf="!this.homeSlider || !this.homeSlider.id"
                                                            [disabled]="!uploadedFiles || uploadedFiles.length == 0"
                                                            (click)="saveHomeSlider()">Add
                                                        </button>
                                                        <button class=" btn btn-primary btn-sm rounded-pill ml-2"
                                                            *ngIf="this.homeSlider && this.homeSlider.id"
                                                            [disabled]="!uploadedFiles || uploadedFiles.length == 0"
                                                            (click)="saveHomeSlider()">Update </button>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="homeCatgry" role="tabpanel"
                                                    aria-labelledby="homeCatgry-tab">
                                                    <div class="col-md-12 mt-4 mb-4" *ngIf="homeCategory">
                                                        <h6><strong>{{homeCategory.imageName}} Images</strong></h6>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'"
                                                            [multiple]="true" (change)="onFileUpload($event)">
                                                            <ngx-dropzone-label>Drag and drop your product images to
                                                                upload!
                                                            </ngx-dropzone-label>
                                                            <ngx-dropzone-image-preview
                                                                ngProjectAs="ngx-dropzone-preview" [file]="uploadedFile"
                                                                *ngFor="let uploadedFile of uploadedFiles"
                                                                [removable]="true"
                                                                (removed)="onFileRemoved(uploadedFile)">
                                                                <!--<ngx-dropzone-label>{{ uploadedFile.name }}
                                                                    ({{ uploadedFile.type }})
                                                                </ngx-dropzone-label>-->
                                                            </ngx-dropzone-image-preview>
                                                        </div>
                                                    </div>
                                                    <div class="spacer-30"></div>

                                                    <div class="mt-4 text-center col-md-4 m-auto">
                                                        <button (click)="cancel()"
                                                            class="btn  btn-danger btn-sm rounded-pill mr-2">Cancel
                                                        </button>
                                                        <button class=" btn btn-primary btn-sm rounded-pill ml-2"
                                                            *ngIf="!this.homeCategory || !this.homeCategory.id"
                                                            [disabled]="!uploadedFiles || uploadedFiles.length == 0"
                                                            (click)="saveHomeCategory()">Add
                                                        </button>
                                                        <button class=" btn btn-primary btn-sm rounded-pill ml-2"
                                                            *ngIf="this.homeCategory && this.homeCategory.id"
                                                            [disabled]="!uploadedFiles || uploadedFiles.length == 0"
                                                            (click)="saveHomeCategory()">Update </button>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="Filters" role="tabpanel"
                                                    aria-labelledby="Filters-tab">
                                                    <form [formGroup]="filterInpForm" *ngIf="filterInpForm">
                                                        <div class="table-responsive profile-table">
                                                            <table
                                                                class="table res-table table-striped admin-tbl reflow-table reflow-table-w-50">
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="text-left ml-2"><strong>Currency to
                                                                                be
                                                                                displayed on Screen</strong></td>
                                                                        <td><input type="text" class="form-control"
                                                                                placeholder="INR / INR / $"
                                                                                formControlName="currency">
                                                                            <small class="error"><label
                                                                                    *ngIf="!filterInpForm.get('currency').valid && filterInpForm.get('currency').touched && filterInpForm.get('currency').errors.required">
                                                                                    Required Field</label></small>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="text-left ml-2"><strong>Minimum Order
                                                                                Amount</strong></td>
                                                                        <td><input type="number" class="form-control"
                                                                                placeholder="0"
                                                                                formControlName="minOrder">
                                                                            <small class="error"><label
                                                                                    *ngIf="!filterInpForm.get('minOrder').valid && filterInpForm.get('minOrder').touched && filterInpForm.get('minOrder').errors.required">
                                                                                    Required Field</label></small>
                                                                        </td>
                                                                    </tr>
                                                                    <!-- <tr>
                                                                        <td class="text-left ml-2"><strong>Available
                                                                                Sorting for Users in web page</strong>
                                                                        </td>
                                                                        <td>
                                                                            <ul class="points"
                                                                                *ngIf="filterInput && filterInput.sortInputs && filterInput.sortInputs.length > 0">
                                                                                <li
                                                                                    *ngFor="let sortInput of filterInput.sortInputs">
                                                                                    <div class="value text-left">
                                                                                        {{sortInput.sortInput}}</div>
                                                                                </li>
                                                                            </ul>
                                                                            <h6
                                                                                *ngIf="!filterInput || !filterInput.sortInputs || filterInput.sortInputs.length == 0">
                                                                                No Sorting available.
                                                                            </h6>
                                                                        </td>
                                                                    </tr> -->
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div class="mt-4 text-center col-md-4 m-auto">
                                                            <button class="btn btn-sm btn-success rounded-pill"
                                                                [disabled]="filterInpForm.invalid"
                                                                (click)="saveFilterInput()">Submit</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="success" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Success!!</h4>
                <p class="text-success">{{successMessage}} </p>


            </div>
        </div>
    </div>
</div>
<div *ngIf="errorPopup" id="fail-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Sorry. Failed!</h4>
                <p class="text-danger">{{errorMessage}}</p>
                <p></p>

            </div>
        </div>
    </div>
</div>