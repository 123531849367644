import { RatingAndReview } from './rating';

export class Product {

  id: string;
  productName: string;
  productDescription: string;
  // imageUrl: string;
  productImageUrl: string[];
  categoryId: string;
  categoryName: string;
  reviews: RatingAndReview[];
  packagingQuantities: PackageQuantities[];
  createdBy: string;
  createdDate: Date;
  deletedFlag: boolean;
  lastupdatedDate: Date;
  updatedBy: string;
  hotDeals: boolean;
  newArrivals: boolean;
  totalSold: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class PackageQuantities {
  packageQuantity: string;
  packageStatus: boolean; //STOCK AVAILABLE or OUT OF STOCK
  packageDiscount: number;
  packageDiscountedPrice: number;
  totalStockCnt: number;
  availableStockCnt: number;
  soldStockCnt: number;
  barcode: string;
  retailPrice: number;
  wholesalePrice: number;
  packagePrice: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}