import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Product } from 'src/app/model/product';
import { ShoppingCartRequest } from 'src/app/model/shoppingCartRequest';
import { ShoppingCartResponse } from 'src/app/model/shoppingCartResponse';

@Injectable()
export class CartService {

    constructor(private httpClient: HttpClient, private router: Router) { }

    baseUrl: string = environment.baseUrl;

    addToCartUrl: string = this.baseUrl + '/api/cart/addToCart';
    deleteCartUrl: string = this.baseUrl + '/api/cart/removeCart?userId=';
    getCartListUrl: string = this.baseUrl + '/api/cart/viewCart?userId=';
    

    addToCart(cart: ShoppingCartRequest){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr});        
        return this.httpClient.post(this.addToCartUrl,cart, {headers: reqHeader});
    }

    deleteCart(userId){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr});
        return this.httpClient.delete(this.deleteCartUrl + userId, {headers: reqHeader});
    }

    getCartList(id){
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({'Authorization': authhdr}); 
        return this.httpClient.get<ShoppingCartResponse>(this.getCartListUrl+id, {headers: reqHeader});
    }

    

}