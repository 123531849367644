import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors, AbstractControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { Category } from 'src/app/model/category';
import { Product } from 'src/app/model/product';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { CategoryService } from 'src/app/shared/services/category.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { BannerImages, FilterInput, PriceInput } from 'src/app/model/settings';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  myDate = new Date();
  user: User;
  loading: boolean = false;
  allCounts: any;
  income: number;
  errorPopup: boolean = false;
  success: boolean = false;
  uploadedFiles: File[] = [];
  bannerImgList: BannerImages[];
  homeSlider: BannerImages = new BannerImages();
  homeCategory: BannerImages = new BannerImages();
  filterInpForm: FormGroup;
  priceInputs: any;
  errorMessage: string;
  successMessage: string;
  currency: string = "";

  constructor(private fb: FormBuilder, private serviceCategorie: CategoryService,
    private orderService: OrderService, private serviceProduct: ProductService,
    private route: ActivatedRoute, private router: Router, private settingService: SettingsService) {
  }

  ngOnInit() {
    this.loading = true;
    this.getTotalCounts();
    this.currency = localStorage.getItem('currency');
    this.loadFilters();
  }

  loadFilters() {
    this.getFilterInputs().then(() => {
      this.createFilterInputForm();
      //this.priceInputs = this.filterInpForm.get('priceInputs') as FormArray;
      this.getAllBannerImages('HOME_SLIDER');
    }).catch(() => {
      this.createFilterInputForm();
      //this.priceInputs = this.filterInpForm.get('priceInputs') as FormArray;
      this.getAllBannerImages('HOME_SLIDER');
    });
  }

  createFilterInputForm() {
    // let priceInputArray = this.fb.array([]);
    // if (this.filterInput && this.filterInput.priceInputs && this.filterInput.priceInputs.length > 0) {
    //   this.filterInput.priceInputs.forEach(priceInput => {
    //     let priceInputGrp = this.fb.group({
    //       "code": [priceInput.code],
    //       "descriptionForPriceRange": [priceInput.descriptionForPriceRange, Validators.required],
    //       "maxValue": [priceInput.maxValue, Validators.required],
    //       "minValue": [priceInput.minValue, Validators.required],
    //       "priceRangeName": [priceInput.priceRangeName, Validators.required]
    //     });
    //     priceInputArray.push(priceInputGrp);
    //   });
    // } else {
    //   priceInputArray.push(this.fb.group({
    //     "code": [""],
    //     "descriptionForPriceRange": ["", Validators.required],
    //     "maxValue": [0, Validators.required],
    //     "minValue": [0, Validators.required],
    //     "priceRangeName": ["", Validators.required]
    //   }));
    // }
    this.filterInpForm = this.fb.group({
      "id": [this.filterInput ? this.filterInput.id : null],
      "currency": [this.filterInput ? this.filterInput.currency : 'INR', Validators.required],
      "deletedFlag": [this.filterInput ? this.filterInput.deletedFlag : false],
       "minOrder": [this.filterInput ? this.filterInput.minOrder : 0, Validators.required],
      // "priceInputs": priceInputArray
    });
  }

  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);
    })
  }

  getAllBannerImages(selectedTab) {
    this.settingService.getAllBannerImages().toPromise().then(response => {
      this.bannerImgList = response;
      if (this.bannerImgList && this.bannerImgList.length > 0) {
        this.bannerImgList.forEach(bannerImge => {
          if (bannerImge.imageName && bannerImge.imageName.toLowerCase() == 'home slider') {
            this.homeSlider = bannerImge;
          }
          if (bannerImge.imageName && bannerImge.imageName.toLowerCase() == 'home category') {
            this.homeCategory = bannerImge;
          }
        });

        if (selectedTab == 'HOME_SLIDER') {
          this.showPreview(this.homeSlider);
        }
        if (selectedTab == 'HOME_CATEGORY') {
          this.showPreview(this.homeCategory);
        }
      }
      this.loading = false;
    }, error => {
      console.log(error);
      this.loading = false;
    })
  }

  filterInput: FilterInput;
  getFilterInputs(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      this.settingService.getAllFilterInputs().toPromise().then(response => {
        let filterInputList: FilterInput[] = response;
        if (filterInputList && filterInputList.length > 0) {
          this.filterInput = filterInputList[0];
        }
        resolve();
      }, error => {
        console.log(error);
        reject();
      });
    });
    return promise;
  }

  saveHomeSlider() {
    if (this.uploadedFiles && this.uploadedFiles.length > 0) {
      if (this.homeSlider || !this.homeSlider.id) {
        this.loading = true;
        var formData = new FormData();
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          formData.append("bannerImage", this.uploadedFiles[i]);
        }
        this.homeSlider.imageName = "Home Slider";
        formData.append("bannerRequest", JSON.stringify(this.homeSlider));
        this.saveBanner(formData);
      }
      else {
        this.loading = true;
        var formData = new FormData();
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          formData.append("file", this.uploadedFiles[i]);
        }
        formData.append("bannerRequest", JSON.stringify(this.homeSlider));
        this.updateBanner(formData);
      }
    }
  }

  saveHomeCategory() {
    if (this.uploadedFiles && this.uploadedFiles.length > 0) {
      if (!this.homeCategory || !this.homeCategory.id) {
        this.loading = true;
        var formData = new FormData();
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          formData.append("bannerImage", this.uploadedFiles[i]);
        }
        this.homeCategory.imageName = "Home Category";
        formData.append("bannerRequest", JSON.stringify(this.homeCategory));
        this.saveBanner(formData);
      }
      else {
        this.loading = true;
        var formData = new FormData();
        for (let i = 0; i < this.uploadedFiles.length; i++) {
          formData.append("file", this.uploadedFiles[i]);
        }
        formData.append("bannerRequest", JSON.stringify(this.homeCategory));
        this.updateBanner(formData);
      }
    }
  }


  saveBanner(formData) {
    this.settingService.saveBannerImages(formData).toPromise().then(
      response => {
        this.loading = false;
        this.successMessage = "Settings updated successfully.";
        this.success = true;
        setTimeout(() => {
          this.success = false;
          this.successMessage = "";
          this.reloadPage();
        }, 2000);
      }, error => {
        this.loading = false;
        this.errorPopup = true;
        this.errorMessage = error.error.failure;
        setTimeout(() => {
          this.errorMessage = "";
          this.errorPopup = false;
        }, 2000);
      }
    )
  }

  updateBanner(formData) {
    this.settingService.updateBannerImages(formData).toPromise().then(
      response => {
        this.loading = false;
        this.success = true;
        this.successMessage = "Settings updated successfully.";
        setTimeout(() => {
          this.success = false;
          this.successMessage = "";
          this.reloadPage();
        }, 2000);
      }, error => {
        this.loading = false;
        this.errorPopup = true;
        this.errorMessage = error.error.failure;
        setTimeout(() => {
          this.errorMessage = "";
          this.errorPopup = false;
        }, 2000);

      }
    )
  }

  cancel() {
    this.reloadPage();
  }

  onFileUpload(event) {
    var addedFiles = event.addedFiles;
    addedFiles.forEach(file => {
      if (this.uploadedFiles.indexOf(file.name) === -1) {
        this.uploadedFiles.push(file);
      }
    })
  }

  onFileRemoved(removedFile: File) {
    this.uploadedFiles.splice(this.uploadedFiles.indexOf(removedFile), 1);
  }

  async showPreview(bannerObject: BannerImages) {
    this.uploadedFiles = [];
    if (bannerObject && bannerObject.bannerS3Url && bannerObject.bannerS3Url.length > 0) {
      bannerObject.bannerS3Url.forEach(async bannerImageUrl => {
        let decodedURL = decodeURI(bannerImageUrl);
        let filename = decodedURL.substring(decodedURL.lastIndexOf("/") + 1, decodedURL.length);
        this.serviceProduct.getProductImageBlob("SoyaBag Portal/Banners/" + filename).toPromise().then(response => {
          let data = response.body;
          let metadata = {
            type: 'image/jpeg'
          };
          let file = new File([data], filename, metadata);
          this.uploadedFiles.push(file);
        }, error => {
          console.log(error);
          this.loading = false;
        })
      });
      this.loading = false;
    }
  }

  addPriceInput() {
    let priceInput: PriceInput = new PriceInput();
    this.priceInputs.push(this.fb.group({
      "code": [""],
      "descriptionForPriceRange": ["", Validators.required],
      "maxValue": [0, Validators.required],
      "minValue": [0, Validators.required],
      "priceRangeName": ["", Validators.required]
    }));
  }

  removePriceInput(index) {
    this.priceInputs.removeAt(index);
  }

  saveFilterInput() {
    //if (this.filterInpForm.valid) {
      this.loading = true;
      let tobeSavedFilterObj: FilterInput = new FilterInput(this.filterInpForm.value);
      if (!tobeSavedFilterObj.id) {
        this.settingService.saveFilterInputs(tobeSavedFilterObj).toPromise().then(
          response => {
            this.loading = false;
            this.success = true;
            this.successMessage = "Settings updated successfully.";
            this.filterInput = response as FilterInput;
            setTimeout(() => {
              this.successMessage = "";
              this.success = false;
              this.reloadPage();
            }, 2000);
          }, error => {
            this.loading = false;
            this.errorMessage = error.error.failure;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorMessage = "";
              this.errorPopup = false;
            }, 2000);

          }
        )
      } else {
        this.settingService.updateFilterINputs(tobeSavedFilterObj).toPromise().then(
          response => {
            this.loading = false;
            this.success = true;
            this.successMessage = "Settings updated successfully.";
            this.filterInput = response as FilterInput;
            setTimeout(() => {
              this.success = false;
              this.successMessage = "";
              this.reloadPage();
            }, 2000);
          }, error => {
            this.loading = false;
            this.errorMessage = error.error.failure;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorMessage = "";
              this.errorPopup = false;
            }, 2000);

          }
        )
      }
   // }
  }

  reloadPage() {
    this.router.navigateByUrl('/admin/settings', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin/settings']);
      this.refresh();
    });
  }


  refresh() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
}
