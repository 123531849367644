import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { switchMap, map } from 'rxjs/operators';
import { AuthenticationService } from '../shared/services/authentication.service';
import { User } from '../model/user';
import { BillingDetails, ShippingDetails, Order } from '../model/order';
import { OrderService } from '../shared/services/order.service';
import { ProductService } from '../shared/services/product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  userForm: FormGroup;
  user: User;
  loading: boolean = false;
  isEditForm: boolean = false;
  cancelComments: string;
  currency: String = "";

  constructor(private fb: FormBuilder, private authenticationService: AuthenticationService, private router: Router,
    private orderService: OrderService, private productService: ProductService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.refresh();
    this.getUserDetails();
    this.currency = localStorage.getItem('currency');
  }

  getUserDetails() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.authenticationService.getUser(this.user.username).toPromise().then(
        response => {
          this.loading = false;
          this.user = response;
          this.authenticationService.setLoggedInData(this.user);
          this.createUserForm();
        }, error => {
          console.log(error);
          this.loading = false;
        }
      )
    }
  }

  createUserForm() {
    this.userForm = this.fb.group({
      firstName: new FormControl(this.user.firstName, Validators.required),
      lastName: new FormControl(this.user.lastName, Validators.required),
      email: new FormControl(this.user.email, Validators.required),
      password: new FormControl(this.user.password, Validators.required),
      mobileno: new FormControl(this.user.mobileno, Validators.required),
      username: new FormControl(this.user.username, Validators.required)
    });
    this.isEditForm = false;
    this.userForm.disable();
  }

  editUserForm() {
    this.isEditForm = true;
    this.userForm.enable();
  }

  saveUser() {
    this.getUserDetails();
    this.isEditForm = false;
    // Call Update User and show success and error pop ups.
    this.userForm.disable();
  }

  operationType: string;
  operationAddress: string;
  selectedShippingIndex: number = 0;
  addressForm: FormGroup;
  errorMessage: string;
  successMessage: string;
  successModal: Boolean = false;
  errorModal: Boolean = false;

  openBillingAddress(billingAddrObj, operType) {
    this.operationType = operType;
    this.operationAddress = "Billing Address";
    this.createAddressForm(billingAddrObj);
  }

  openShippingAddress(shippingAddrObj, addressIndex, operType) {
    this.operationType = operType;
    this.operationAddress = "Shipping Address";
    this.selectedShippingIndex = addressIndex;
    this.createAddressForm(shippingAddrObj);
  }

  createAddressForm(address) {
    this.addressForm = this.fb.group({
      'firstName': [this.user ? this.user.firstName : null, Validators.required],
      'lastName': [this.user ? this.user.lastName : null, Validators.required],
      'address_1': [address ? address.address_1 : null, Validators.required],
      'address_2': [address ? address.address_2 : null],
      'city': [address ? address.city : null, Validators.required],
      'state': [address ? address.state : null, Validators.required],
      'country': [address ? address.country : null, Validators.required],
      'postcode': [address ? address.postcode : null, Validators.required]
    });
  }

  saveAddress() {
    if (this.addressForm.valid) {
      this.loading = true;
      if (this.operationAddress.toLowerCase() == 'billing address') {
        this.user.billingAddress = new BillingDetails(this.addressForm.value);
      } else {
        if (this.operationType.toLowerCase() == 'add') {
          if (this.selectedShippingIndex == 0) {
            this.user.shippingAddress = [];
          }
          this.user.shippingAddress.push(new ShippingDetails(this.addressForm.value));

        } else {
          if (this.user.shippingAddress[this.selectedShippingIndex]) {
            this.user.shippingAddress[this.selectedShippingIndex] = new ShippingDetails(this.addressForm.value);
          }
        }
      }
      this.authenticationService.updateuser(this.user.id, this.user).toPromise().then(
        response => {
          this.user = response;
          this.loading = false;
          this.successMessage = "User update done";
          this.successModal = true;
          setTimeout(() => {
            this.successMessage = "";
            this.successModal = false;
          }, 2000);
        }, error => {
          console.log(error);
          this.errorMessage = error.error.message;
          this.loading = false;
          this.errorModal = true;
          setTimeout(() => {
            this.errorMessage = null;
            this.errorModal = false;
          }, 2000);
        }
      )
    }
  }

  closeModal() {
    this.operationType = null;
    this.operationAddress = null;
    this.selectedShippingIndex = 0;
  }

  cancelAddress() {
    this.getUserDetails();
  }

  myOrders: Order[];
  selectedOrder: Order;

  openOrderDetails(orderObj) {
    this.selectedOrder = orderObj;
  }

  getUserOrders() {
    this.loading = true;
    this.orderService.getMyOrders(this.user.id).toPromise().then(
      response => {
        this.myOrders = response;
        this.loading = false;
      }, error => {
        console.log(error);
        this.loading = false;
      }
    )
  }

  cancelOrder() {
    this.selectedOrder.status = "CANCELLED";
    this.selectedOrder.reasonForCancellation = this.cancelComments;
    this.loading = true;
    this.orderService.updateOrder(this.selectedOrder.id, this.selectedOrder).toPromise().then(
      response => {
        this.loading = false;
        this.successMessage = "Order Cancelled";
        this.successModal = true;
        this.getUserOrders();
        setTimeout(() => {
          this.successMessage = "";
          this.successModal = false;
        }, 2000);
      }, error => {
        this.loading = false;
        console.log(error);
        this.errorMessage = error.error.message;
        this.getUserOrders();
        this.errorModal = true;
        setTimeout(() => {
          this.errorMessage = "";
          this.errorModal = false;
        }, 2000);
      }
    )
  }
  refresh() {
    this.router.navigateByUrl('/profile', { skipLocationChange: true }).then(() => {
      this.router.navigate(['//profile']);
    });
  }

  resetPasswordForm: FormGroup;
  loadPasswordTab() {
    this.resetPasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    },
      { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') })
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })
      }
      else {
        if (passwordConfirmationInput.hasError('notEquivalent')) {
          return passwordConfirmationInput.updateValueAndValidity();
        }
      }
    }
  }

  resetPassword() {
    if (this.resetPasswordForm.valid) {
      this.loading = true;
      let data = {
        usernameOrEmail: this.user.email,
        password: this.resetPasswordForm.get('oldPassword').value,
        newPassword: this.resetPasswordForm.get('password').value
      }
      this.authenticationService.resetPassword(data).toPromise().then(
        response => {
          this.loading = false;
          this.resetPasswordForm.reset();
          this.successMessage = "Password reset Successful. Logging out adn redirecting to Login Page.";
          setTimeout(() => {
            this.successMessage = null;
            this.authenticationService.logOut();
          }, 2000);
        }, error => {
          this.loading = false;
          console.log(error);
          this.resetPasswordForm.reset();
          this.errorMessage = error.error.message;
          setTimeout(() => {
            this.errorMessage = null;
          }, 2000);
        }
      )
    }
  }

}
