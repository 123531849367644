<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">

        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">

                                <li class="breadcrumb-item active" aria-current="page">Create Orders</li>

                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6"
                                    *ngIf="user.userType=='ADMIN'">
                                    <div>
                                        <span class="d-block"> <span class="label ">INCOME</span>
                                        </span>
                                        <span class="value">{{currency}} {{income}} </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                        <span class="sparkline-bar text-warning">
                                            <i class="ft-bar-chart-2"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <div class="row row-sm">
            <div class="col-lg-12">
                <div class="card">
                    <!--<div class="row" *ngIf="user.userType=='ADMIN'">
                        <div class="col-md-6">
                            <div class="upload-group">
                                <div class="upload upload-danger">
                                    <input type="radio" id="radio-bulk-upload" name="saleType" [checked]="!retail"
                                        (click)="multiple()">
                                    <label for="radio-bulk-upload">
                                        <div class="upload-img"><img src="assets/images/wholesale.jpg">
                                        </div>
                                        <div class="upload-model-type">Whole sale</div>
                                        <small>Create Order</small>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="upload-group">
                                <div class="upload upload-danger">
                                    <input type="radio" id="radio-one-by-one" name="saleType" [checked]="retail"
                                        value="singleProduct" (click)="single()">
                                    <label for="radio-one-by-one">
                                        <div class="upload-img"><img src="assets/images/retail.jpg">
                                        </div>
                                        <div class="upload-model-type">Retail sale</div>
                                        <small>Create Order </small>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>-->

                    <div class="card-body">
                        <h4 class="card-title mb-0">Create Order</h4>

                        <p class="sub-text">It is Very Easy to Customize and it uses in your website
                            apllication.
                        </p>
                        <hr>
                        <section class="sec-gap bg-light">

                            <div class=" mt-70">
                                <div class="container">

                                    <div class="checkout_form">
                                        <div class="row col-md-12 justify-content-center">
                                            <div class="col-lg-12 col-md-12 ">
                                                <div class="row col-md-12">
                                                    <div class="col-md-6">
                                                        <label><strong>Order Type:</strong></label>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <select class="form-control" [(ngModel)]="orderType"
                                                            (change)="getAllUsers()">
                                                            <ng-container *ngIf="user.userType == 'ADMIN'">
                                                                <option value="" selected>--Select--</option>
                                                                <option value="OFFLINE">Retail Order</option>
                                                                <option value="WHOLESALE">Wholesale Order</option>
                                                            </ng-container>
                                                            <ng-container *ngIf="user.userType == 'WHOLESALE'">
                                                                <option value="WHOLESALE" selected>Wholesale Order
                                                                </option>
                                                            </ng-container>

                                                        </select>
                                                    </div>
                                                </div>

                                                <ng-container
                                                    *ngIf="(orderType && orderType != '') && user.userType == 'ADMIN'">
                                                    <div class="row col-md-12 mt-3">
                                                        <div class="col-md-6">
                                                            <label><strong>Existing Customer:</strong></label>
                                                        </div>
                                                        <div class="col-md-6 form-group icon-form switch-box">
                                                            <div class="form-control transparent-tb">
                                                                <label class="switch float-right">
                                                                    <input type="checkbox" class="primary"
                                                                        [(ngModel)]="isExistingUser">
                                                                    <span class="slider round"></span>
                                                                </label>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row col-md-12 mt-3" *ngIf="isExistingUser">
                                                        <div class="col-md-6">
                                                            <label><strong>Select Customer:</strong></label>
                                                        </div>
                                                        <div class="col-md-6 form-group">
                                                            <!-- <ngx-select-dropdown [multiple]="false" [config]="config"
                                                                [options]="wholesalerDropDown" style="width:100%"
                                                                [(ngModel)]="selectedUserTemp"
                                                                (change)="selectionChanged(selectedUserTemp)">
                                                            </ngx-select-dropdown> -->

                                                            <select class="form-control transparent-tb"
                                                                (change)="selectUser($event)">
                                                                <option value="">--Select Any--</option>
                                                                <option *ngFor="let user of allUsers" [value]="user.id">
                                                                    {{user.firstName}} {{user.lastname}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="selectedUserDetails">
                                                    <div class="row col-md-12">
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-xl-6 col-lg-6 col-md-12">
                                                                    <div class="row">
                                                                        <div
                                                                            class="col-xl-12 col-lg-12col-md-12 col-sm-12">
                                                                            <div
                                                                                class="card overflow-hidden project-card">
                                                                                <div class="card-body">
                                                                                    <div class="d-flex">
                                                                                        <div
                                                                                            class="project-card-box primary">
                                                                                            <i class="ft-map-pin"></i>
                                                                                        </div>
                                                                                        <div class="project-content">
                                                                                            <h6>Deliver
                                                                                                Address
                                                                                            </h6>
                                                                                            <div>
                                                                                                <b>{{selectedUserDetails.firstName}}
                                                                                                    {{selectedUserDetails.lastName}}</b><br>
                                                                                                {{selectedUserDetails.billingAddress.address_1}}
                                                                                                {{selectedUserDetails.billingAddress.address_2}}
                                                                                                {{selectedUserDetails.billingAddress.city}}
                                                                                                {{selectedUserDetails.billingAddress.state}}
                                                                                                {{selectedUserDetails.billingAddress.country}}-{{selectedUserDetails.billingAddress.postcode}}
                                                                                                <br>
                                                                                                TEL
                                                                                                :{{selectedUserDetails.mobileno}}
                                                                                                <br>
                                                                                                Email
                                                                                                :{{selectedUserDetails.email}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-lg-6 col-md-12">
                                                                    <div class="row">

                                                                        <div
                                                                            class="col-xl-12 col-lg-12col-md-12 col-sm-12">
                                                                            <div
                                                                                class="card overflow-hidden project-card">
                                                                                <div class="card-body">
                                                                                    <div class="d-flex">
                                                                                        <div
                                                                                            class="project-card-box primary">
                                                                                            <i class="ft-map-pin"></i>
                                                                                        </div>
                                                                                        <div class="project-content">
                                                                                            <h6>Invoice
                                                                                                Address
                                                                                            </h6>

                                                                                            <div>
                                                                                                <b>{{selectedUserDetails.firstName}}
                                                                                                    {{selectedUserDetails.lastName}}</b><br>
                                                                                                {{selectedUserDetails.billingAddress.address_1}}
                                                                                                {{selectedUserDetails.billingAddress.address_2}}
                                                                                                {{selectedUserDetails.billingAddress.city}}
                                                                                                {{selectedUserDetails.billingAddress.state}}
                                                                                                {{selectedUserDetails.billingAddress.country}}-{{selectedUserDetails.billingAddress.postcode}}
                                                                                                <br>
                                                                                                TEL
                                                                                                :{{selectedUserDetails.mobileno}}
                                                                                                <br>
                                                                                                Email
                                                                                                :{{selectedUserDetails.email}}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>




                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="orderType && orderType != '' && !isExistingUser && user.userType == 'ADMIN'">
                                                    <form [formGroup]="addressForm" *ngIf="addressForm">
                                                        <div class="row">

                                                            <div class="form-group col-md-6">
                                                                <label>First Name<span class="required">*</span></label>
                                                                <input required="" class="form-control" name="firstName"
                                                                    type="text" formControlName="firstName">
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Last Name</label>
                                                                <input class="form-control" name="lastName" type="text"
                                                                    formControlName="lastName">
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Email<span class="required">*</span></label>
                                                                <input required="" class="form-control" name="email"
                                                                    formControlName="email">
                                                                <small class="form-text text-danger"
                                                                    *ngIf="!addressForm.get('email').valid && addressForm.get('email').touched">
                                                                    Please enter correct format of email</small>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Mobile Number <span
                                                                        class="required">*</span></label>
                                                                <input required="" class="form-control" name="mobileNo"
                                                                    formControlName="mobileNo"
                                                                    placeholder="+91 xxxxxxxxxx">
                                                                <small class="form-text text-danger"
                                                                    *ngIf="!addressForm.get('mobileNo').valid && addressForm.get('mobileNo').touched">
                                                                    Please enter correct format of mobileNo</small>
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Address Line 1<span
                                                                        class="required">*</span></label>
                                                                <input required="" class="form-control" name="address1"
                                                                    type="text" formControlName="address_1">
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Address Line 2</label>
                                                                <input class="form-control" name="address2" type="text"
                                                                    formControlName="address_2">
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>City <span class="required">*</span></label>
                                                                <input required="" class="form-control" name="city"
                                                                    formControlName="city">
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>State <span class="required">*</span></label>
                                                                <input required="" class="form-control" name="state"
                                                                    formControlName="state">
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Country <span class="required">*</span></label>
                                                                <input required="" class="form-control" name="country"
                                                                    formControlName="country">
                                                            </div>
                                                            <div class="form-group col-md-6">
                                                                <label>Postal Code <span
                                                                        class="required">*</span></label>
                                                                <input required="" placeholder="123456"
                                                                    class="form-control" name="postcode"
                                                                    formControlName="postcode">
                                                                <small class="form-text text-danger"
                                                                    *ngIf="!addressForm.get('postcode').valid && addressForm.get('postcode').touched">
                                                                    Please enter correct format of postcode</small>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </ng-container>

                                            </div>
                                        </div>
                                        <hr>
                                        <ng-container
                                            *ngIf="orderType && orderType != '' && ((isExistingUser && selectedUserDetails) || (!isExistingUser && addressForm && addressForm.valid))">
                                            <div class="card">
                                                <div class="card-body">
                                                    <button class="btn btn-primary rounded-pill float-right"
                                                        data-tooltip="tooltip" title="" (click)="selectProduct()"
                                                        data-original-title="View Descrition" data-toggle="modal"
                                                        data-target="#productsList">Add Product</button>
                                                    <div class="row d-flex" *ngIf="productAddedList">
                                                        <div class="col-lg-12 col-md-12 ">
                                                            <section class="inner-title">
                                                                <h5><strong><i class="ft-shopping-cart"></i>
                                                                        Products</strong></h5>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <table
                                                        class="full-width res-table reflow-table table table-striped tbl-cart">
                                                        <thead>
                                                            <tr>
                                                                <th>Product Name</th>
                                                                <th>Package Quantity</th>
                                                                <th>Price</th>
                                                                <th>Qty</th>
                                                                <th>Action</th>
                                                                <th>Subtotal</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            *ngIf="!productAddedList || productAddedList.length == 0">
                                                            <tr>
                                                                <td colspan="6" class="text-center">
                                                                    <h6 class="text-center">No items in Cart</h6>
                                                                </td>
                                                            </tr>
                                                        </tbody>

                                                        <tbody *ngIf="productAddedList && productAddedList.length>0">
                                                            <tr
                                                                *ngFor="let cartEntry of productAddedList | paginate: { itemsPerPage: count, currentPage: p }; let i=index; ">

                                                                <td><a href="javascript:void(0)"
                                                                        data-link-action="quickview" title="Quick view"
                                                                        data-toggle="modal"
                                                                        data-target="#ProductModal">{{cartEntry.productName}}</a>
                                                                </td>
                                                                <td>{{cartEntry.packageQuantity.packageQuantity}}</td>
                                                                <td><span class="amount">INR
                                                                        {{cartEntry.productCost}}</span></td>
                                                                <td>
                                                                    <div class="qty">
                                                                        <span class="minus bg-dark mr-2"
                                                                            (click)=" decrement(i)"><i
                                                                                class="ft-minus"></i></span>
                                                                        <span><input type="text" class="customFormCtrl"
                                                                                (change)="changeQuantity(i,$event.target.value)"
                                                                                value="{{cartEntry.quantity}}"></span>

                                                                        <span class="plus bg-dark ml-2"
                                                                            (click)=" increment(i)"><i
                                                                                class="ft-plus"></i></span>
                                                                    </div>
                                                                </td>
                                                                <td><button
                                                                        class=" btn btn-sm btn-outline-danger auto delete"
                                                                        data-tooltip="tooltip"
                                                                        (click)="removeFromCart(i)" title=""
                                                                        data-original-title="Delete"><i
                                                                            class="ft-trash"></i></button>
                                                                </td>
                                                                <td class="product-subtotal"><strong>{{currency}}
                                                                        {{cartEntry.productTotalPrice}}</strong>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="5">
                                                                    <strong>Total</strong>
                                                                </td>
                                                                <td><strong>{{currency}} {{cartTotal}} </strong></td>
                                                            </tr>

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                            <hr>
                                            <div class="d-block my-3" *ngIf="user.userType=='WHOLESALE'">
                                                <h4 class="mb-3">Payment Option</h4>

                                                <div class="gateway-group">


                                                    <div class="row">
                                                        <div class="gateway gateway-select">
                                                            <input type="radio" id="banktfr"
                                                                (click)="selectedPaymentMethod('RECEIPT')"
                                                                value="RECEIPT" name="radio-button" value="banktfr">
                                                            <label for="banktfr">
                                                                <div class="gateway-icon"><img
                                                                        src="assets/images/bank.png"></div>
                                                                <div class="gatewaytxt"> Bank Transfer </div>
                                                            </label>
                                                        </div>

                                                        <div class="gateway gateway-select">
                                                            <input type="radio" value="GPAY"
                                                                (click)="selectedPaymentMethod('GPAY')" id="googlepay"
                                                                name="radio-button" value="googlepay">
                                                            <label for="googlepay">
                                                                <div class="gateway-icon"><img
                                                                        src="assets/images/gpay.png"></div>
                                                                <div class="gatewaytxt"> Google Pay
                                                                </div>
                                                            </label>
                                                        </div>




                                                        <div class="gateway gateway-select">
                                                            <input type="radio" id="paytm"
                                                                (click)="selectedPaymentMethod('PAYTM')"
                                                                name="radio-button" value="PAYTM">
                                                            <label for="paytm">
                                                                <div class="gateway-icon"><img
                                                                        src="assets/images/paytm.png"></div>
                                                                <div class="gatewaytxt"> Paytm
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="row col-md-12 mb-3" *ngIf="receipt">

                                                    <div class="col-md-6 form-group">
                                                        <div class="input-group mt-3 mb-3">
                                                            <div class="custom-file receipt">
                                                                <input type="file" (change)="handleReceiptFile($event)"
                                                                    multiple="" class="custom-file-input">
                                                                <label class="custom-file-label"
                                                                    for="inputGroupFile01">UPLOAD THE
                                                                    PAYMENT RECEIPT</label>
                                                            </div>
                                                        </div>
                                                        <!-- <input type="file"  (change)="handleReceiptFile($event)"
                                                        class="form-control"> -->
                                                    </div>
                                                </div>
                                                <div class="row col-md-12 mb-3" *ngIf="gpay">
                                                    <div class="col-md-6">
                                                        <label><strong>Google Pay <span
                                                                    class="required">*</span></strong></label>
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <google-pay-button environment="TEST"
                                                            [buttonColor]="buttonColor" [buttonType]="buttonType"
                                                            [buttonSizeMode]="isCustomSize ? 'fill' : 'static'"
                                                            [paymentRequest]="paymentRequest"
                                                            [style.width.px]="buttonWidth"
                                                            [style.height.px]="buttonHeight"
                                                            (loadpaymentdata)="onLoadPaymentData($event)"
                                                            (error)="onError($event)"
                                                            [paymentAuthorizedcallback]="onPaymentDataAuthorized">
                                                        </google-pay-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row col-md-12 mb-3" *ngIf="user.userType=='ADMIN'">
                                                <div class="col-md-6">
                                                    <label><strong>Payment Status <span
                                                                class="required">*</span></strong></label>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <select (change)="selectedPayment($event.target.value)"
                                                        class="form-control">
                                                        <option value="">Select</option>
                                                        <option value="PAID">Fully Paid</option>
                                                        <option value="ADVANCE_PAID">Advance Paid</option>
                                                        <option value="NOT_PAID">Not Paid</option>
                                                        <option value="FAILED">Payment Failed</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row col-md-12 mb-3" *ngIf="paymentField">
                                                <div class="col-md-6">
                                                    <label><strong>Paid Amount <span
                                                                class="required">*</span></strong></label>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <input class="form-control" #amount="ngModel"
                                                        [(ngModel)]="amountPaid" name="amountPaid">
                                                </div>
                                            </div>

                                            <div class="row col-md-12 mb-3" *ngIf="paymentField">
                                                <div class="col-md-6">
                                                    <label><strong>Payment Method <span
                                                                class="required">*</span></strong></label>
                                                </div>
                                                <div class="col-md-6 form-group" *ngIf="user.userType=='ADMIN'">
                                                    <select (change)="selectedMethod($event.target.value)"
                                                        class="form-control">
                                                        <option value="">Select Type of Payment</option>
                                                        <option value="CASH">Cash</option>
                                                        <option value="CARD">Card</option>
                                                        <option value="CHEQUE">Cheque</option>
                                                    </select>
                                                </div>

                                            </div>

                                            <div class="row col-md-12 mb-3" *ngIf="card">
                                                <div class="col-md-6">
                                                    <label><strong>Last 4 digts <span
                                                                class="required">*</span></strong></label>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <input class="form-control" maxlength="4" #fourdigit="ngModel"
                                                        [(ngModel)]="fourDigit" name="fourDigit">
                                                </div>
                                            </div>
                                            <div class="row col-md-12 mb-3" *ngIf="cheque">
                                                <div class="col-md-6">
                                                    <label><strong>Cheque No <span
                                                                class="required">*</span></strong></label>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <input class="form-control" #chequeno="ngModel"
                                                        [(ngModel)]="chequeNo" name="chequeNo">
                                                </div>
                                            </div>
                                            <hr>
                                            <div class="row justify-content-center">
                                                <button type="submit" (click)="onSubmit()" *ngIf="!paytm"
                                                    [disabled]="!productAddedList || productAddedList.length == 0 "
                                                    class="btn col-md-3 btn-success">CREATE
                                                    ORDER</button>
                                                <button type="submit" *ngIf="paytm" (click)=" payOnPaytm()"
                                                    class="btn col-md-3 btn-success">CREATE
                                                    ORDER</button>
                                                <button type="submit" (click)="back()"
                                                    class="btn ml-2 col-md-3 btn-danger">Cancel</button>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="successModal" id="email-verifiacation-modal" class="modal fade show"
            style="display:block!important;">
            <div class="modal-dialog  modal-dialog-centered modal-md">
                <div class="modal-content modal-confirm">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-check"></i>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4>Order Created</h4>

                        <p class="text-success">Successfully </p>


                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="errorModal" id="fail-modal" class="modal fade show" style="display:block!important;">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content modal-reject ">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-alert-triangle"></i>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4>Sorry</h4>
                        <p class="text-danger">Something went wrong. Try again</p>
                        <p></p>

                    </div>
                </div>
            </div>
        </div>


        <!--View Product List-->
        <div class="modal" tabindex="-1" role="dialog" id="productsList">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content" *ngIf="allproducts">
                    <div class="modal-header">
                        <h5>Select Products to add in Cart</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">


                        <div class="card no-shadow">
                            <div class="scrollbar scroller prod-scroller ">
                                <div class="card-header text-right">
                                    <div class="row d-flex">
                                        <div class="input-group col-md-6 mr-auto">
                                            <input type="text" class="form-control" placeholder="Search any column"
                                                [(ngModel)]="productFilter">
                                        </div>

                                    </div>
                                </div>

                                <div id="productAccordion">
                                    <div id="accordion" class="p-4" *ngIf="allproducts">
                                        <div class="card"
                                            *ngFor="let product of allproducts | filter: productFilter| paginate: { itemsPerPage: count, currentPage: p }, let i = index">
                                            <div class="card-header collapsed" data-toggle="collapse"
                                                aria-expanded="false" id="heading{{i}}" (click)="setIndex(i)">
                                                <div class="prod-box-desc">
                                                    <p><strong>
                                                            <span>{{product.categoryName}}</span> -
                                                            <span
                                                                class="text-danger text-capitalize">{{product.productName}}</span>
                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>

                                            <div id="collapse{{i}}" class="collapse" [class.show]="showIndex == i"
                                                data-parent="#accordion">
                                                <div class="card-body">
                                                    <table
                                                        class="table res-table table-striped admin-tbl reflow-table ">
                                                        <thead>
                                                            <tr>
                                                                <th>Package Quantity</th>
                                                                <th>Price</th>
                                                                <th>Stock Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            *ngFor="let prodChoice of product.packagingQuantities,let p=index;">
                                                            <tr>
                                                                <td data-th="Package Quantity">
                                                                    {{prodChoice.packageQuantity}}</td>
                                                                <td data-th="Price"
                                                                    *ngIf="orderType && orderType != 'WHOLESALE'">
                                                                    INR {{prodChoice.retailPrice}}</td>
                                                                <td data-th="Price"
                                                                    *ngIf="orderType && orderType == 'WHOLESALE'">
                                                                    INR {{prodChoice.wholesalePrice}}</td>
                                                                <td data-th="Stock Status">
                                                                    <span class="badge badge-success"
                                                                        *ngIf="prodChoice.packageStatus">Available
                                                                        ({{prodChoice.availableStockCnt}})</span>
                                                                    <span class="badge badge-danger"
                                                                        *ngIf="!prodChoice.packageStatus">Out of
                                                                        Stock</span>
                                                                </td>
                                                                <td data-th="Action">
                                                                    <div class="custom-control custom-radio mr-2 mt-2">
                                                                        <input type="radio"
                                                                            [disabled]="!prodChoice.packageStatus"
                                                                            (click)="addToCart(product, prodChoice)"
                                                                            class="custom-control-radio">
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-flex">
                                            <div class=" col-md-12 ml-auto mt-2 text-right">
                                                <pagination-controls directionLinks="true" autoHide="true"
                                                    responsive="true" (pageChange)="p = $event"></pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
            </div>

        </div>

        <!---View Product End-->