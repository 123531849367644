<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<div class="flex main-content">

    <nav class="side-nav" id="sideNav">
        <app-side-bar></app-side-bar>
    </nav>


    <div class="content" id="content-sec">

        <app-top-bar></app-top-bar>
        <div class="container-fluid">


            <div class="breadcrumb-header justify-content-between">
                <div class="row w-100">
                    <div class="col-md-8">
                        <h4 class="content-title mb-2">Hi, welcome back!</h4>
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">

                                <li class="breadcrumb-item active" aria-current="page">Product List</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-4 ml-auto">
                        <div
                            class="right-page w-100 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                            <div class="row w-100">
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6">
                                    <div> <span class="d-block"> <span class="label"></span> </span>
                                        <span class="value"> </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center  col-md-6 col-sm-6"
                                    *ngIf="user.userType=='ADMIN'">
                                    <div>
                                        <span class="d-block"> <span class="label ">INCOME</span>
                                        </span>
                                        <span class="value">{{currency}} {{income}} </span>
                                    </div>
                                    <div class="ml-3 mt-0">
                                        <span class="sparkline-bar text-warning">
                                            <i class="ft-bar-chart-2"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- <div class="row row-sm">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row d-flex">
                            <div class=" col-md-6 ml-auto mt-2">
                                <h4 class="card-title mb-0">Manage Products</h4>
                                <div class="clearfix"></div>
                                <p class="sub-text">VIEW, EDIT, REMOVE AND LIST PRODUCTS HERE</p>
                            </div>

                            <div class=" col-md-6 ml-auto mt-2 text-right">
                                <button class="btn btn-primary btn-sm auto ml-2 rounded-pill" type="button"
                                    (click)="addProductPage()"><i class="ft-plus"></i>
                                    Add Products </button>
                            </div>
                        </div>
                        <hr>
                        <div class="card-header text-right">
                            <div class="row d-flex">
                                <div class="input-group col-md-6 mr-auto">
                                    <input type="text" class="form-control" placeholder="Search"
                                        [(ngModel)]="productFilter">
                                </div>

                                <div class=" col-md-6 ml-auto mt-2">
                                    <button class="btn btn-primary dropdown-toggle btn-sm auto rounded-pill"
                                        type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">{{selectedCategory}}</button>

                                    <div class="dropdown-menu  dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:void(0)"
                                            (click)="getProductsList('ALL', 'ALL')">All</a>
                                        <a class="dropdown-item" *ngFor="let category of allCategories"
                                            href="javascript:void(0)"
                                            (click)="getProductsList(category.id, category.categoryName)">{{category.categoryName}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table class="table res-table table-striped admin-tbl reflow-table ">
                            <thead>
                                <tr>
                                    <th>Product Image</th>
                                    <th>Category</th>
                                    <th>Product Name</th>
                                    <th>Price(per Kg)</th>
                                    <th>Available Stock</th>
                                    <th>Product sold</th>
                                    <th>Reviews</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody *ngIf="allproducts">
                                <tr
                                    *ngFor="let product of allproducts| filter: productFilter | paginate: { itemsPerPage: count, currentPage: p }">

                                    <td data-th="Product Image"><img *ngIf="product.productImageUrl"
                                            [src]="product.productImageUrl[0] | safe: 'url'"
                                            class="img-responsive prod-img"></td>
                                    <td data-th="Category">{{product.categoryName}}</td>
                                    <td data-th="Product Name">{{product.productName}}</td>
                                    <td data-th="Price(per Kg)">{{currency}} {{product.productDiscountedCost}} </td>
                                    <td data-th="Available Stock">{{product.available_stock}}</td>
                                    <td data-th="Product sold">{{product.sold}}</td>
                                    <td data-th="Reviews" *ngIf="product.reviews"><a href="javascript:void(0)"
                                            data-toggle="modal" data-target="#review-modal"
                                            (click)="openReviewsModal(product)">{{product.reviews.length}} Reviews</a>
                                    </td>
                                    <td data-th="Reviews" *ngIf="!product.reviews">0 Review</td>
                                    <td data-th="Action">
                                        <a routerLink="/admin/add-editProduct" [queryParams]="{ key: product.id }"
                                            class="btn btn-sm btn-outline-primary auto" data-tooltip="tooltip" title=""
                                            data-original-title="Disable"><i class="la la-edit"></i></a>
                                        <button (click)="delete(product)" class=" btn btn-sm btn-outline-danger auto"
                                            data-tooltip="tooltip" title="" data-original-title="Delete"><i
                                                class="la la-trash"></i></button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                        <div class="row d-flex">
                            <div class=" col-md-12 ml-auto mt-2 text-right">
                                <pagination-controls directionLinks="true" autoHide="true" responsive="true"
                                    (pageChange)="p = $event"></pagination-controls>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div> -->
        <div class="row row-sm">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <div class="row d-flex" *ngIf="user.userType=='ADMIN'">
                            <div class=" col-md-6 ml-auto mt-2">
                                <h4 class="card-title mb-0">Manage Products</h4>
                                <div class="clearfix"></div>
                                <p class="sub-text">VIEW, EDIT, REMOVE AND LIST PRODUCTS HERE</p>
                            </div>

                            <div class=" col-md-6 ml-auto mt-2 text-right">
                                <button class="btn btn-primary btn-sm auto ml-2 rounded-pill" type="button"
                                    (click)="addProductPage()"><i class="ft-plus"></i>
                                    Add Products </button>
                            </div>
                        </div>
                        <hr>

                        <div class="card-header text-right">
                            <div class="row d-flex">
                                <div class="input-group col-md-6 mr-auto">
                                    <input type="text" class="form-control" placeholder="Search any column"
                                        [(ngModel)]="productFilter">
                                </div>
                                <div class=" col-md-6 ml-auto mt-2">
                                    <button class="btn btn-primary dropdown-toggle btn-sm auto rounded-pill"
                                        type="button" id="dropdownMenuButton" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">{{selectedCategory}}</button>

                                    <div class="dropdown-menu  dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:void(0)"
                                            (click)="getProductsList('ALL', 'ALL')">All</a>
                                        <a class="dropdown-item" *ngFor="let category of allCategories"
                                            href="javascript:void(0)"
                                            (click)="getProductsList(category.id, category.categoryName)">{{category.categoryName}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="productAccordion">
                            <div id="accordion" class="p-4" *ngIf="allproducts">
                                <div class="card"
                                    *ngFor="let product of allproducts | filter: productFilter| paginate: { itemsPerPage: count, currentPage: p }, let i = index">
                                    <div class="card-header collapsed" data-toggle="collapse" aria-expanded="false"
                                        id="heading{{i}}" (click)="showIndex = i">
                                        <div class="prod-box">
                                            <img *ngIf="product.productImageUrl" class="img-responsive prod-img"
                                                [src]="product.productImageUrl[0] | safe :'url' " alt="">
                                        </div>
                                        <div class="prod-box-desc">
                                            <h4>{{product.productName}}</h4>
                                            <h5>{{product.categoryName}} {{product.productType}}</h5>
                                            <span class="badge" *ngIf="product.hotDeals"
                                                [class.badge-danger]="product.hotDeals">hot deals</span>
                                            <span class="badge" *ngIf="product.newArrivals"
                                                [class.badge-success]="product.newArrivals">New</span>
                                        </div>

                                        <div class="prod-box-desc float-right" *ngIf="user.userType=='ADMIN'">
                                            <button class=" btn btn-sm btn-danger rounded-pill auto float-right mr-2"
                                                data-tooltip="tooltip" title="" (click)="selectedproduct(product)"
                                                data-original-title="Delete"><i class="la la-trash"></i></button>
                                            <button class="btn btn-sm btn-primary rounded-pill auto float-right ml-2"
                                                data-tooltip="tooltip" title="" routerLink="/admin/add-editProduct"
                                                [queryParams]="{ key: product.id }" data-original-title="Disable"><i
                                                    class="la la-edit"></i></button>
                                        </div>
                                    </div>

                                    <div id="collapse{{i}}" class="collapse" [class.show]="showIndex == i"
                                        data-parent="#accordion">
                                        <div class="card-body">

                                            <table class="table res-table table-striped admin-tbl reflow-table ">
                                                <thead>
                                                    <tr>
                                                        <th>Product Name</th>
                                                        <th>Package Quantity</th>
                                                        <th>Retail Price</th>
                                                        <th>Wholesale Price</th>
                                                        <th>Total Stock Cnt</th>
                                                        <th>Available Cnt</th>
                                                        <th>Sold Cnt</th>
                                                        <th *ngIf="user.userType=='ADMIN'">Reviews</th>
                                                    </tr>
                                                </thead>

                                                <tbody *ngFor="let prodChoice of product.packagingQuantities;">
                                                    <tr>
                                                        <td data-th="Product Name">{{product.productName}}</td>
                                                        <td data-th="Package Quantity">{{prodChoice.packageQuantity}}
                                                        </td>
                                                        <td data-th="Retail Price">INR {{prodChoice.retailPrice}}</td>
                                                        <td data-th="Wholesale Price">INR {{prodChoice.wholesalePrice}}
                                                        </td>
                                                        <td data-th="Total Stock Cnt">{{prodChoice.totalStockCnt}}</td>
                                                        <td data-th="Available Cnt">{{prodChoice.availableStockCnt}}
                                                        </td>
                                                        <td data-th="Sold Cnt">{{prodChoice.soldStockCnt}}</td>
                                                        <td data-th="Reviews" *ngIf="user.userType=='ADMIN'">
                                                            <a href="javascript:void(0)" *ngIf="product.reviews"
                                                                data-toggle="modal" data-target="#review-modal"
                                                                (click)="openReviewsModal(product)">{{product.reviews.length}}
                                                                Reviews</a>
                                                            <a href="javascript:void(0)" *ngIf="!product.reviews"
                                                                data-toggle="modal" data-target="#review-modal"
                                                                (click)="openReviewsModal(product)">0 Reviews</a>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex">
                                    <div class=" col-md-12 ml-auto mt-2 text-right">
                                        <pagination-controls directionLinks="true" autoHide="true" responsive="true"
                                            (pageChange)="p = $event"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="successDelete" id="email-verifiacation-modal" class="modal fade show"
            style="display:block!important;">
            <div class="modal-dialog  modal-dialog-centered modal-md">
                <div class="modal-content modal-confirm">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-check"></i>
                        </div>
                        <button type="button" (click)="cancel()" class="close" data-dismiss="modal"
                            aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4></h4>
                        <p class="text-success">Are you sure.Do you want to delete? </p>

                        <button (click)="delete(selectedProduct)" class="btn btn-success" data-dismiss="modal">YES<i
                                class="ft-chevron-right"></i></button>
                        <button (click)="cancel()" class="btn btn-danger ml-1" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="reviewsModal" id="review-modal" class="modal fade show" style="display:block!important;">
            <div class="modal-dialog  modal-dialog-centered modal-lg">
                <div class="modal-content modal-confirm">
                    <div class="modal-header">
                        <h6 class="modal-title">Rating and Reviews</h6>
                        <button type="button" class="close" data-dismiss="modal" (click)="reviewsModal = false"
                            aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <div class="scrollbar scroller cupon-scroller">

                            <div class="review_rating_container"
                                *ngIf="ratingAndreviewsList && ratingAndreviewsList.length > 0">
                                <div
                                    class="row d-flex flex-column flex-md-row align-items-center text-center text-md-left">
                                    <div class="review_rating col-md-4">
                                        <div class="review_rating_num">{{averageRating}}</div>
                                        <div class="review_rating_stars">
                                            <div *ngIf="averageRating >= 1 && averageRating < 2"
                                                class="rating_r rating_r_1">
                                                <i></i><i></i><i></i><i></i><i></i>
                                            </div>
                                            <div *ngIf="averageRating >= 2 && averageRating < 3"
                                                class="rating_r rating_r_2">
                                                <i></i><i></i><i></i><i></i><i></i>
                                            </div>
                                            <div *ngIf="averageRating >= 3 && averageRating < 4"
                                                class="rating_r rating_r_3">
                                                <i></i><i></i><i></i><i></i><i></i>
                                            </div>
                                            <div *ngIf="averageRating >= 4 && averageRating < 5"
                                                class="rating_r rating_r_4">
                                                <i></i><i></i><i></i><i></i><i></i>
                                            </div>
                                            <div *ngIf="averageRating >= 5" class="rating_r rating_r_5">
                                                <i></i><i></i><i></i><i></i><i></i>
                                            </div>
                                            <div class="review_rating_text">({{totalRatingAndreviews}} Ratings)
                                            </div>
                                        </div>
                                    </div>
                                    <div class="review_rating_bars col-md-8">
                                        <ul>
                                            <li><span>5 Star</span>
                                                <div class="review_rating_bar">
                                                    <div [style.width.%]='fiveStarRatingPercent' class="bg-success">
                                                    </div>
                                                </div>
                                            </li>
                                            <li><span>4 Star</span>
                                                <div class="review_rating_bar">
                                                    <div [style.width.%]='fourStarRatingPercent' class="bg-primary">
                                                    </div>
                                                </div>
                                            </li>
                                            <li><span>3 Star</span>
                                                <div class="review_rating_bar">
                                                    <div [style.width.%]='threeStarRatingPercent' class="bg-info">
                                                    </div>
                                                </div>
                                            </li>
                                            <li><span>2 Star</span>
                                                <div class="review_rating_bar">
                                                    <div [style.width.%]='twoStarRatingPercent' class="bg-warning">
                                                    </div>
                                                </div>
                                            </li>
                                            <li><span>1 Star</span>
                                                <div class="review_rating_bar">
                                                    <div [style.width.%]='oneStarRatingPercent' class="bg-danger">
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="review_rating_container"
                                *ngIf="!ratingAndreviewsList || ratingAndreviewsList.length == 0">
                                <h6>No Rating and Reviews</h6>
                            </div>
                            <div class="review-wrapper" *ngIf="ratingAndreviewsList && ratingAndreviewsList.length > 0">
                                <ng-container *ngFor="let ratingAndReviewObj of ratingAndreviewsList">
                                    <div class="single-review mt-4">
                                        <div class="review-img">
                                            <img src="assets/images/noimage_person.png" alt="">
                                        </div>
                                        <div class="review-content">
                                            <div class="review-top-wrap">
                                                <div class="review-left">
                                                    <div class="review-name">
                                                        <h4>{{ratingAndReviewObj.username}}</h4>
                                                    </div>
                                                    <div class="rating-product">
                                                        <ng-container *ngIf="ratingAndReviewObj.star == 'ONE_STAR'">
                                                            <i class="fa fa-star"></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="ratingAndReviewObj.star == 'TWO_STAR'">
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="ratingAndReviewObj.star == 'THREE_STAR'">
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="ratingAndReviewObj.star == 'FOUR_STAR'">
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="ratingAndReviewObj.star == 'FIVE_STAR'">
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                            <i class="fa fa-star"></i>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="review-right mr-4">
                                                    <a href="javascript:void(0)"
                                                        (click)="openReplyComment(ratingAndReviewObj.id)">Reply</a>
                                                </div>
                                            </div>
                                            <div class="review-bottom text-left">
                                                <p>
                                                    {{ratingAndReviewObj.reviewComment}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-container
                                        *ngIf="replyToReview && selectedReplyToReviewId == ratingAndReviewObj.id">
                                        <div class="review-content mt-4 mb-4  mr-4 ml-4">
                                            <textarea placeholder="Your Reply" name="replyComment"
                                                #replyComments="ngModel" class="form-control" autofocus
                                                [(ngModel)]="replyComment"></textarea>
                                            <button (click)="cancelReplyComment()"
                                                class="btn btn-danger btn-sm mt-2 ml-2 float-right rounded-pill">Cancel</button>
                                            <button class="btn btn-success btn-sm mt-2 float-right rounded-pill"
                                                [disabled]="!replyComment || replyComment == ''"
                                                (click)="submitReplyComment(ratingAndReviewObj.id)">Submit</button>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="ratingAndReviewObj.replies && ratingAndReviewObj.replies.length > 0">
                                        <div class="single-review child-review mt-3"
                                            *ngFor="let replies of ratingAndReviewObj.replies">
                                            <div class="review-img">
                                                <img src="assets/images/noimage_person.png" alt=""
                                                    *ngIf="replies.username != 'Zasta Infotek'">
                                                <img src="../../../assets/images/logo.jpg" alt=""
                                                    *ngIf="replies.username == 'Zasta Infotek'">
                                            </div>
                                            <div class="review-content">
                                                <div class="review-top-wrap">
                                                    <div class="review-left">
                                                        <div class="review-name">
                                                            <h4>{{replies.username}}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="review-bottom text-left">
                                                    <p>{{replies.reviewComment}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-sm btn-danger rounded-pill" data-dismiss="modal"
                            (click)="reviewsModal = false">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="successModal" id="email-verifiacation-modal" class="modal fade show"
            style="display:block!important;">
            <div class="modal-dialog  modal-dialog-centered">
                <div class="modal-content modal-confirm">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-check"></i>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" (click)="successModal = false"
                            aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4>{{messageHeader}}</h4>
                        <p class="text-success">{{successMessage}}</p>
                        <button class="btn btn-success" data-dismiss="modal"
                            (click)="successModal = false">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="errorModal" id="fail-modal" class="modal fade show" style="display:block!important;">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content modal-reject ">
                    <div class="modal-header">
                        <div class="icon-box">
                            <i class="ft-alert-triangle"></i>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" (click)="errorModal = false"
                            aria-hidden="true">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <h4>{{messageHeader}}</h4>
                        <p class="text-danger">{{errorMessaage}}</p>
                        <button class="btn btn-danger" data-dismiss="modal" (click)="errorModal = false">Close</button>
                    </div>
                </div>
            </div>
        </div>