import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Testimonial } from 'src/app/model/testimonial';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestimonialService {
  constructor(private httpClient: HttpClient, private router: Router) { }

  baseUrl: string = environment.baseUrl;

  saveTestimonialUrl: string = this.baseUrl + '/api/addTestimonial';
  updateTestimonialUrl: string = this.baseUrl + '/api/updateTestimonialById';
  deleteTestimonialUrl: string = this.baseUrl + '/api/deleteTestimonialById/';
  getAllTestimonialUrl: string = this.baseUrl + '/api/getAllTestimonials';
  getTestimonialByIdUrl: string = this.baseUrl + '/api/getTestimonialById/';


  saveTestimonial(testimonial: Testimonial) {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.post(this.saveTestimonialUrl, testimonial, { headers: reqHeader });
  }

  updateTestimonial(testimonial: Testimonial) {

    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.put(this.updateTestimonialUrl, testimonial, { headers: reqHeader });
  }

  deleteTestimonial(testimonial) {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.delete(this.deleteTestimonialUrl + testimonial, { headers: reqHeader });
  }

  getAllTestimonials() {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.get(this.getAllTestimonialUrl, { headers: reqHeader });
  }

  getTestimonialByTestimonialId(testimonial) {
    var bearerToken = localStorage.getItem('bearerToken');
    var authhdr = 'Bearer ' + bearerToken;
    let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
    return this.httpClient.get(this.getTestimonialByIdUrl + testimonial, { headers: reqHeader });
  }


}