<div class="fixed-sidebar">
    <div class="main-sidebar-header">
        <a href="javaxript:void(0)" routerLink="/home">
            <img src="assets/images/logo.jpg" class="main-logo">
        </a>
    </div>
    <button class="navbar-toggler res-close ml-auto" type="button" id="res-close">
        <i class="ft-x"></i>
    </button>
    <div class="clearfix"></div>
    <div class="scrollbar scroller menu-scroller">
        <div class="profile-box">
            <div class="user-pic"> <img src="assets/images/logo.jpg" alt="user-img" class="user-img img-90 rounded-circle">
            </div>
            <div class="user-info">
                <h6>{{user.username}}</h6>
                <small>Administrator</small>
            </div>
        </div>

        <!-- <div class="sidebar-navs">
                <ul class="nav  nav-pills-circle">
                    <li class="nav-item" data-toggle="tooltip" data-placement="top" title=""
                        data-original-title="Settings"> <a class="nav-link text-center m-2" href="javascript:void(0)" routerLink="/admin/profile"> <i
                                class="ft-settings"></i>
                        </a> </li>
                    <li class="nav-item" data-toggle="tooltip" data-placement="top" title=""
                        data-original-title="Chat">
                        <a class="nav-link text-center m-2" href="javascript:void(0)" routerLink="/admin/enquiries"> <i class="ft-mail"></i> </a> </li>
                    <li class="nav-item" data-toggle="tooltip" data-placement="top" title=""
                        data-original-title="Followers"> <a class="nav-link text-center m-2" href="javascript:void(0)" routerLink="/admin/user-list"> <i
                                class="ft-user"></i>
                        </a> </li>
                    <li class="nav-item" data-toggle="tooltip" data-placement="top" title=""
                        data-original-title="Logout"> <a (click)="logout()" class="nav-link text-center m-2"> <i
                                class="ft-power"></i>
                        </a> </li>
                </ul>
            </div>-->

        <ul class="sidebar navbar-nav" *ngIf="user">
            <!-- <li class="nav-item"> <a href="javascript:void(0)" routerLink="/admin/addadmin" routerLinkActive="active"
                class="nav-link"> <i class="smt-icon ft-users"></i>
                <span>Add Admin</span> </a>
        </li> -->

            <li class="nav-item" *ngIf="user.userType=='ADMIN'" > <a href="javascript:void(0)" routerLink="/admin/admin-dashboard"
                    routerLinkActive="active" class="nav-link  "> <i class="smt-icon la la-dashboard"></i>
                    <span>Dashboard</span> </a>
            </li>
            <li class="nav-item" *ngIf="user.userType=='WHOLESALE'" > <a href="javascript:void(0)" routerLink="/admin/wholesaleDashboard"
                routerLinkActive="active" class="nav-link  "> <i class="smt-icon la la-dashboard"></i>
                <span>Dashboard</span> </a>
            </li>
            <li class="nav-item" *ngIf="user.userType=='WHOLESALE'" > <a href="javascript:void(0)" routerLink="/admin/display-products"
                routerLinkActive="active" class="nav-link  "> <i class="smt-icon la la-dashboard"></i>
                <span>Product List</span> </a>
            </li>

            <li class="nav-item " *ngIf="user.userType=='ADMIN'"> <a href="javascript:void(0)" data-toggle="collapse" data-target="#submenu-1"
                    class="nav-link dropdown-toggle collapsed" aria-expanded="false"> <i
                        class="smt-icon ft-package"></i>
                    <span>Products</span> </a>
                <ul id="submenu-1" class="collapse" aria-expanded="false">
                    <li><a href="javascript:void(0)" routerLink="/admin/add-editProduct" routerLinkActive="active"><i
                                class="ft-plus"></i> Add Products</a>
                    </li>
                    <li><a href="javascript:void(0)" routerLink="/admin/display-products" routerLinkActive="active"><i
                                class="la la-eye"></i> Products List</a></li>
                </ul>
            </li>

            <li class="nav-item " *ngIf="user.userType=='ADMIN'"> <a href="javascript:void(0)" data-toggle="collapse" data-target="#submenu-2"
                    class="nav-link dropdown-toggle collapsed" aria-expanded="false"> <i
                        class="smt-icon ft-share-2"></i>
                    <span>Category</span> </a>
                <ul id="submenu-2" class="collapse" aria-expanded="false">
                    <li><a href="javascript:void(0)" routerLink="/admin/add-editCategory" routerLinkActive="active"><i
                                class="ft-plus"></i> Add Category</a>
                    </li>
                    <li><a href="javascript:void(0)" routerLink="/admin/display-categoryList"
                            routerLinkActive="active"><i class="la la-eye"></i> Category List</a></li>
                </ul>
            </li>
            <!--<li class="nav-item "> <a href="javascript:void(0)" data-toggle="collapse" data-target="#submenu-5"
                    class="nav-link dropdown-toggle collapsed" aria-expanded="false"> <i
                        class="smt-icon ft-share-2"></i>
                    <span>State List</span> </a>
                <ul id="submenu-5" class="collapse" aria-expanded="false">
                    <li><a href="javascript:void(0)" routerLink="/admin/add-editDeliveryCost"
                            routerLinkActive="active"><i class="ft-plus"></i> Add State</a>
                    </li>
                    <li><a href="javascript:void(0)" routerLink="/admin/delivery-Cost" routerLinkActive="active"><i
                                class="la la-eye"></i> State List</a></li>
                </ul>
            </li>-->


            <li class="nav-item" > <a href="javascript:void(0)" routerLink="/admin/display-orders"
                    routerLinkActive="active" class="nav-link"> <i class="smt-icon ft-shopping-cart"></i>
                    <span>Orders</span> </a>
            </li>
            <li class="nav-item"  *ngIf="user.userType=='WHOLESALE'"> <a href="javascript:void(0)" routerLink="/admin/Inventory"
                routerLinkActive="active" class="nav-link"> <i class="smt-icon ft-package"></i>
                <span>Inventory</span> </a>
            </li>
            <li class="nav-item" *ngIf="user.userType=='ADMIN'"> <a href="javascript:void(#)" routerLink="/admin/enquiries" routerLinkActive="active"
                class="nav-link"> <i class="smt-icon ft-users"></i>
                <span>enquiries List</span> </a>
            </li>
            <li class="nav-item " *ngIf="user.userType=='ADMIN'"> <a href="javascript:void(0)" routerLinkActive="active" data-toggle="collapse"
                data-target="#submenu-13" class="nav-link dropdown-toggle collapsed" aria-expanded="false"> <i
                    class="smt-icon ft-help-circle"></i>
                <span>FAQ</span> </a>
            <ul id="submenu-13" class="collapse" aria-expanded="false">
                <li><a href="javascript:void(0)" routerLink="/admin/add-editFaq"><i
                    class="ft-plus"></i> Add
                        FAQ</a>
                </li>
                <li><a href="javascript:void(0)" routerLink="/admin/faq-admin"><i class="la la-eye"></i> FAQ
                        List</a></li>
            </ul>
        </li>
            <!--<li class="nav-item "> <a href="javascript:void(0)" routerLinkActive="active" data-toggle="collapse"
                    data-target="#submenu-3" class="nav-link dropdown-toggle collapsed" aria-expanded="false"> <i
                        class="smt-icon la la-gift"></i>
                    <span>Offers</span> </a>
                <ul id="submenu-3" class="collapse" aria-expanded="false">
                    <li><a href="javascript:void(0)" routerLink="/admin/add-editOffer"><i class="ft-coffee"></i> Add
                            Offers</a>
                    </li>
                    <li><a href="javascript:void(0)" routerLink="/admin/display-offer"><i class="la la-eye"></i> Offers
                            List</a></li>
                </ul>
            </li>
            
           

            -->
            <li class="nav-item " *ngIf="user.userType=='ADMIN'"> <a href="javascript:void(0)" routerLinkActive="active" data-toggle="collapse"
                data-target="#submenu-15" class="nav-link dropdown-toggle collapsed" aria-expanded="false"> <i
                    class="smt-icon ft-feather"></i>
                <span>What Customer Says</span> </a>
            <ul id="submenu-15" class="collapse" aria-expanded="false">
                <li><a href="javascript:void(0)" routerLink="/admin/add-editTestimonial"><i
                    class="ft-plus"></i>
                        Add
                        Customer Feedback</a>
                </li>
                <li><a href="javascript:void(0)" routerLink="/admin/testimonialList"><i class="la la-eye"></i>
                        Customer Feedback 
                        List</a></li>
            </ul>
        </li>
            <li class="nav-item" *ngIf="user.userType=='ADMIN'"> <a href="javascript:void(0)" routerLink="/admin/user-list" routerLinkActive="active"
                    class="nav-link"> <i class="smt-icon ft-users"></i>
                    <span>Users List</span> </a>
            </li>
            <li class="nav-item" *ngIf="user.userType=='ADMIN'"> <a href="javascript:void(0)" routerLink="/admin/settings" routerLinkActive="active"
                    class="nav-link"> <i class="smt-icon ft-settings"></i>
                    <span>Settings</span> </a>
            </li>





        </ul>
    </div>
</div>