<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>



<app-menu></app-menu>
<section *ngIf="!homeSlider || homeSlider.bannerS3Url && homeSlider.bannerS3Url.length <= 0">

    <div class="owl-carousel" id="home-banner-slider">

        <div class="home-slider">
            <div class="slider-content">
                <div class="container">
                    <div class="slider-content-sec text-left">
                        <!--<span class="animated">100% quality</span>
                        <h1 class="animated">Fresh Stock <br>
                            vegetable and Fruits</h1>-->
                        <!--<p class="animated text-white text-uppercase">fresh New pack Brusting Fruits</p>-->
                        <!--<ng-container *ngIf="!isAdmin">
                            <a href="javascript:void(0)" *ngIf="!isLoggedIn" routerLink="/login"
                                class="shop-btn animated">SHOP NOW</a>
                            <a href="javascript:void(0)" *ngIf="isLoggedIn" routerLink="/shop-products"
                                class="shop-btn animated">SHOP NOW</a>
                        </ng-container>-->
                    </div>
                </div>
            </div>
            <img src="assets/images/background.jpg">
        </div>
    </div>

</section>
<!-- Slider Section -->
<section *ngIf="homeSlider && homeSlider.bannerS3Url && homeSlider.bannerS3Url.length > 0">

    <div class="owl-carousel" id="home-banner-slider">

        <div class="home-slider" *ngFor="let bannerUrl of homeSlider.bannerS3Url">
            <div class="slider-content">
                <div class="container">
                    <div class="slider-content-sec text-left">
                        <!--<span class="animated">100% quality</span>
                        <h1 class="animated">Fresh Stock <br>
                            vegetable and Fruits</h1>
                        <!--<p class="animated text-white text-uppercase">fresh New pack Brusting Fruits</p>-->
                        <!---->
                        <!--<ng-container *ngIf="!isAdmin">
                            <a href="javascript:void(0)" *ngIf="!isLoggedIn" routerLink="/login"
                                class="shop-btn animated">SHOP NOW</a>
                            <a href="javascript:void(0)" *ngIf="isLoggedIn" routerLink="/shop-products"
                                class="shop-btn animated">SHOP NOW</a>
                        </ng-container>-->
                    </div>
                </div>
            </div>
            <img [src]="bannerUrl | safe: 'url'">
        </div>
    </div>

</section>

<!-- Content Section -->
<section class="sec-gap">
    <div class="container">
        <div class="ribbon-area-wrap p-0">
            <div class="row">
                <!-- Static ribbon Item Start -->
                <div class="col-lg-3 col-xs-12 col-md-6 col-sm-6 p-0 ribbon-box">
                    <div class="ribbon-static pb-res-md-0 pb-res-sm-0 pb-res-xs-0 bg-warning">
                        <img src="assets/images/free.png" alt="" class="img-responsive">
                        <div class="ribbon-static-meta">
                            <h4>Free Shipping</h4>
                            <p>On all orders over {{currency}} 75.00</p>
                        </div>
                    </div>
                </div>
                <!-- Static ribbon Item End -->
                <!-- Static ribbon Item Start -->
                <div class="col-lg-3 col-xs-12 col-md-6 col-sm-6 p-0 ribbon-box">
                    <div class="ribbon-static pb-res-md-0 pb-res-sm-0 pb-res-xs-0 pt-res-xs-20 bg-danger">
                        <img src="assets/images/return-box.png" alt="" class="img-responsive">
                        <div class="ribbon-static-meta">
                            <h4>Free Returns</h4>
                            <p>Returns are free within 9 days</p>
                        </div>
                    </div>
                </div>
                <!-- Static ribbon Item End -->
                <!-- Static ribbon Item Start -->
                <div class="col-lg-3 col-xs-12 col-md-6 col-sm-6 p-0 ribbon-box">
                    <div class="ribbon-static pt-res-md-30 pb-res-sm-30 pb-res-xs-0 pt-res-xs-20 bg-success">
                        <img src="assets/images/secure-payment.png" alt="" class="img-responsive">
                        <div class="ribbon-static-meta">
                            <h4>100% Payment Secure</h4>
                            <p>Your payment are safe with us.</p>
                        </div>
                    </div>
                </div>
                <!-- Static ribbon Item End -->
                <!-- Static ribbon Item Start -->
                <div class="col-lg-3 col-xs-12 col-md-6 col-sm-6 p-0  ribbon-box">
                    <div class="ribbon-static pt-res-md-30 pb-res-sm-30 pt-res-xs-20 bg-primary">
                        <img src="assets/images/customer-service.png" alt="" class="img-responsive">
                        <div class="ribbon-static-meta">
                            <h4>Support 24/7</h4>
                            <p>Contact us 24 hours a day</p>
                        </div>
                    </div>
                </div>
                <!-- Static ribbon Item End -->
            </div>
        </div>
    </div>
</section>


<!--<section class="sec-gap bg-light ftco-section ftco-category ftco-no-pt">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-6 order-md-last align-items-stretch d-flex">
                        <div class="category-wrap-2 ftco-animate img align-self-stretch d-flex fadeInUp ftco-animated"
                            style="background-image: url(assets/images/category.jpg);">
                            <div class="text text-center">
                                <h2>Vegetables</h2>
                                <p>Protect the health of every home</p>
                                <p *ngIf="!isAdmin">
                                    <a href="javascript:void(0)" *ngIf="isLoggedIn" routerLink="/shop-products"
                                        class="btn btn-primary custom-btn rounded-pill">Shop now</a>
                                    <a href="javascript:void(0)" *ngIf="!isLoggedIn" routerLink="/login"
                                        class="btn btn-primary custom-btn rounded-pill">Shop now</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="category-wrap ftco-animate img mb-4 d-flex align-items-end fadeInUp ftco-animated"
                            style="background-image: url(assets/images/category-1.jpg);">
                            <div class="text px-3 py-1">
                                <h2 class="mb-0"><a href="javascript:void(0)">Fruits</a></h2>
                            </div>
                        </div>
                        <div class="category-wrap ftco-animate img d-flex align-items-end fadeInUp ftco-animated"
                            style="background-image: url(assets/images/category-2.jpg);">
                            <div class="text px-3 py-1">
                                <h2 class="mb-0"><a href="javascript:void(0)">Vegetables</a></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="category-wrap ftco-animate img mb-4 d-flex align-items-end fadeInUp ftco-animated"
                    style="background-image: url(assets/images/category-3.jpg);">
                    <div class="text px-3 py-1">
                        <h2 class="mb-0"><a href="javascript:void(0)">Juices</a></h2>
                    </div>
                </div>
                <div class="category-wrap ftco-animate img d-flex align-items-end fadeInUp ftco-animated"
                    style="background-image: url(assets/images/category-4.jpg);">
                    <div class="text px-3 py-1">
                        <h2 class="mb-0"><a href="javascript:void(0)">Dried</a></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<section class="sec-gap bg-light">
    <div class="container">
        <div class="row">
            <!-- Hot Deals -->
            <div class="col-sm-12 col-md-4 col-lg-4" *ngIf="hotDeals&&hotDeals.length>0">
                <div class="section-title">
                    <h2>Hot Deals</h2>
                    <p>Add hot products to weekly line up</p>
                </div>
                <!-- Hot Deal Products -->

                <div class="hot-slider owl-carousel owl-nav-style inner-slider-prod mid-slider" *ngIf="hotDeals">
                    <ng-container *ngFor="let product of hotDeals ">
                        <div class="col-lg-12 col-md-12 col-sm-12 p-0 ">
                            <article class="list-product">
                                <div class="img-block">
                                    <a href="javascript:void(0)" routerLink="/product-details"
                                        [queryParams]="{key : product.id}" class="thumbnail">
                                        <img class="first-img" *ngIf="product.productImageUrl[0]"
                                            [src]="product.productImageUrl[0] | safe: 'url'" alt="" />
                                    </a>

                                </div>

                                <ul class="product-flag">
                                    <li class="new">Hot</li>
                                </ul>

                                <div class="hot-deals-des text-center pt-4">
                                    <h6>{{product.categoryName}}</h6>
                                    <h4>{{product.productName}}</h4>
                                    <div class="clearfix"></div>
                                    <!-- <div class="review_rating_stars m-0">
                                    <div class="rating_r rating_r_4">
                                        <i></i><i></i><i></i><i></i><i></i>
                                    </div>
                                </div> -->

                                    <div class="clearfix"></div>

                                    <div class="pricing-meta">
                                        <!-- <ul>
                                        <li class="old-price">{{currency}}-{{}}</li>
                                        <li class="current-price">$21.51</li>
                                        <li class="discount-price">-10%</li>
                                    </ul> -->
                                    </div>

                                    <!-- <div class="mt-3">
                                    <div class="clockdiv">
                                        <h6>Hurry Up! Offers ends in</h6>
                                        <div data-countdown="2020/10/01"></div>
                                    </div>
                                </div> -->
                                </div>
                            </article>
                        </div>
                    </ng-container>

                </div>
            </div>

            <!-- Popular Categories -->
            <div class="col-sm-12 col-md-8 col-lg-8" id="newArrivals" *ngIf="newArrivals&&newArrivals.length>0">
                <div class="section-title">
                    <h2>New Arrivals</h2>
                    <p>Add Popular New Arrivals to weekly line up</p>
                </div>
                <!-- New Arrival Products Products -->

                <div class="new-arrival-slider owl-carousel owl-nav-style" *ngIf="newArrivals">

                    <article class="list-product" *ngFor="let product of newArrivals ">
                        <div class="img-block">
                            <a href="javascript:void(0)" routerLink="/product-details"
                                [queryParams]="{key : product.id}" class="thumbnail">
                                <img class="first-img imgNewArrival" *ngIf="product.productImageUrl[0]"
                                    [src]="product.productImageUrl[0] | safe: 'url'" alt="" />
                            </a>
                        </div>

                        <ul class="product-flag">
                            <li class="new">New</li>
                        </ul>

                        <div class="product-decs">
                            <a class="inner-link" href="javascript:void(0)"><span>{{product.categoryName}}</span></a>
                            <h2>
                                <a href="javascript:void(0)" class="product-link">{{product.productName}}</a>
                            </h2>

                            <div class="pricing-meta">
                                <!-- <ul>
                                <li class="old-price not-strike">$23.90</li>
                            </ul> -->
                            </div>
                        </div>
                        <!-- <div class="add-to-link">
                        <ul>
                            <li class="cart btn btn-sm btn-outline-success rounded-pill">
                                <i class="ft-plus"></i> ADD TO CART
                            </li>
                            <li>
                                <a href="javascript:void(0)"><i class="ft-heart"></i></a>
                            </li>
                        </ul>
                    </div> -->
                    </article>

                </div>

            </div>
        </div>
    </div>
</section>

<!-- Ads Section -->
<section class="sec-gap">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-xs-12">
                <a href="javascript:void(0)"><img src="assets/images/8.png" alt="" class="img-responsive"></a>
            </div>
            <div class="col-md-6 col-xs-12">
                <a href="javascript:void(0)"><img src="assets/images/9.png" alt="" class="img-responsive"></a>
            </div>
            <div class="col-md-3 col-xs-12">
                <a href="javascript:void(0)"><img src="assets/images/10.png" alt="" class="img-responsive"></a>
            </div>
        </div>
    </div>
</section>

<!--Popular Categories  -->
<div class="sec-gap" *ngIf="allBestCategory">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 com-sm-12">
                <div class="section-title">
                    <h2>Popular Categories</h2>
                    <p>Add Popular Categories to weekly line up</p>
                </div>


                <div class="popular-slider owl-carousel owl-nav-style">
                    <ng-container *ngFor="let category of allBestCategory">
                      <div class="row">
                          <div class="col-md-6 category-box pr-0">
                           
                                <div class="details-categoreis">
                                <h4>{{category.categoryName}}</h4>
                                <h6>{{category.productCount}}</h6>
                                <a href="javascript:void(0)" routerLink="/shop-products"
                                    [queryParams]="{ key: category.id }"
                                    class="cart btn btn-sm btn-outline-success rounded-pill mt-4">
                                    Shop Now
                                </a>
                            </div>
                          </div>
                          <div class="col-md-6 pl-0"> <img *ngIf="category.categoryImage" [src]="category.categoryImage | safe: 'url'" /></div>
                      </div>   
                        
                           
                           
                        
                    </ng-container>
                </div>
            </div>


        </div>
    </div>
</div>
<div class="sec-gap">
    <div class="container">
        <div class="row">

            <div class="section-title mb-2">
                <h2>What Customers Say</h2>
            </div>
            <div class="col-lg-12 text-center col-md-12 com-sm-12 testimonial">

                <div class="testimonials owl-carousel owl-nav-style" id="testimonials" *ngIf="testimonial">

                    <div class="testi-box " *ngFor="let testimonial of testimonial;">
                        <div class="testi-img mt-3">
                            <img src="../../assets/images/noimage_person.png" />
                        </div>

                        <div class="testi-content mb-2">
                            <p>
                                {{testimonial.comment}}
                            </p>
                            <div class="author-text">
                                <h4>{{testimonial.name}} <span>Customer </span></h4>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="sec-gap">
    <div class="container">
        <div class="text-center">
            <img src="assets/images/ad-banner.jpg" alt="" class="img-responsive" />
        </div>
    </div>
</div> -->


<section class="sec-gap" id="bestSales" *ngIf="allBestSellingProducts && allBestSellingProducts.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Popular Products</h2>
            <p>Add bestselling products to weekly line up</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12" *ngFor="let product of allBestSellingProducts;">
                <article class="list-product">
                    <div class="img-block">
                        <a href="javascript:void(0)" class="thumbnail" routerLink="/product-details"
                            [queryParams]="{key : product.id}">
                            <img class="first-img imgClass" *ngIf="product.productImageUrl[0]"
                                [src]="product.productImageUrl[0] | safe: 'url'" alt="">
                        </a>
                    </div>


                    <ul class="product-flag">
                        <li class="new">New</li>
                    </ul>

                    <div class="product-decs">
                        <a class="inner-link" href="javascript:void(0)"><span>{{product.categoryName}}</span></a>
                        <h2><a href="javascript:void(0)" class="product-link">{{product.productName}}</a></h2>

                        <div class="pricing-meta">
                            <!-- <ul>
                                <li class="old-price">{{currency}} {{product.productCost}}</li>
                                <li class="current-price">{{currency}} {{product.productDiscountedCost}}</li>
                                <li class="discount-price">-{{product.offer}}%</li>
                            </ul> -->
                        </div>
                    </div>
                    <!-- <div class="add-to-link">
                        <ul>
                            <li class="cart btn btn-sm btn-outline-success rounded-pill">
                                <i class="ft-plus"></i> ADD TO CART
                            </li>
                            <li>
                                <a href="javascript:void(0)"><i class="ft-heart"></i></a>
                            </li>

                        </ul>
                    </div> -->

                </article>
            </div>





        </div>




    </div>
</section>


<footer class="footer-area">
    <div class="footer-top">
        <div class="container">
            <div class="row">

                <div class="col-md-6 col-lg-4">
                    <!-- footer logo -->
                    <div class="footer-logo">
                        <a href="javascript:void(0)" routerLink="/home"><img src="assets/images/logo.jpg"
                                alt=""></a>
                    </div>
                    <!-- footer logo -->

                    <p class="text-info"></p>
                    <div class="call-us">
                        <p class="phone-info">
                            NEED HELP?
                            <span>
                                +91 98320 59345 <br>

                            </span>
                        </p>

                        <div class="social-info">
                            <ul>
                                <li>
                                    <a href="	
                                    https://www.facebook.com/Soybag-Private-Limited-107121007976317" target="	
                                    https://www.facebook.com/Soybag-Private-Limited-107121007976317"><i
                                            class="la la-facebook"></i></a>
                                </li>

                                <li>
                                    <a href="https://www.youtube.com/channel/UCuyWns6nl8a9bG8dlx7nUiQ"
                                        target="https://www.youtube.com/channel/UCuyWns6nl8a9bG8dlx7nUiQ"><i
                                            class="la la-youtube"></i></a>
                                </li>

                                <li>
                                    <a href="https://www.instagram.com/soybagprivate/"
                                        target="https://www.instagram.com/soybagprivate/"><i
                                            class="la la-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div class="col-md-6 col-lg-4">
                    <h4 class="footer-heading">Information</h4>
                    <div class="footer-links">
                        <ul>
                            <li><a href="javascript:void(0)" routerLink="/shop-products">Our Products</a></li>
                            <li><a href="javascript:void(0)" routerLink="/about-us">About Us</a></li>
                            <li><a href="javascript:void(0)" routerLink="/contact-us">Contact Us</a></li>
                            <li><a href="javascript:void(0)" routerLink="/faq">FAQ</a></li>

                        </ul>
                    </div>
                </div>


                <div class="col-md-6 col-lg-4 ">
                    <h4 class="footer-heading">Custom Links</h4>
                    <div class="footer-links">
                        <ul>
                            <li><a href="https://www.privacypolicygenerator.info/live.php?token=K2MaQuye4vvLNR8Y59nuZMpH0HTAzZys"
                                    target="https://www.privacypolicygenerator.info/live.php?token=K2MaQuye4vvLNR8Y59nuZMpH0HTAzZys">Privacy
                                    Policy</a></li>
                            <li><a href="javascript:void(0)">Prices Drop</a></li>
                            <li><a href="#newArrivals">New Products</a></li>
                            <li><a href="#bestSales">Best Sales</a></li>
                            <li><a href="javascript:void(0)" routerLink="/login">Login</a></li>
                        </ul>
                    </div>
                </div>


                <!-- <div class="col-md-6 col-lg-4">
                    <div class="single-wedge">
                        <h4 class="footer-heading">Newsletter</h4>
                        <div class="subscribe-text">
                            <p>You may unsubscribe at any moment. For that purpose, please find our contact info
                                in the legal notice.</p>
                        </div>

                        <section class="newsletter">
                            <div class="input-group">
                                <input type="email" class="form-control" placeholder="Enter your email">
                                <span class="input-group-btn">
                                    <button class="btn" type="submit">Subscribe Now</button>
                                </span>
                            </div>
                        </section>


                        <div class="img_app">
                            <a href="javascript:void(0)"><img src="assets/images/app_store.png" alt="" /></a>
                            <a href="javascript:void(0)"><img src="assets/images/google_play.png" alt="" /></a>
                        </div>



                        <img class="mt-4" src="assets/images/payment.png" alt="">

                    </div>
                </div> -->


            </div>
        </div>
    </div>

</footer>