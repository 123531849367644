import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/model/user';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationService {

    constructor(private httpClient: HttpClient, private router: Router) { }

    baseUrl = environment.baseUrl;

    registerUser(user: User) {
        return this.httpClient.post(this.baseUrl + '/api/auth/signup', user);
    }

    register(user: User) {
        if (user.userType == "ADMIN") {
            return this.httpClient.post(this.baseUrl + '/api/auth/admin/signup', user);
        } else {
            var bearerToken = localStorage.getItem('bearerToken');
            var authhdr = 'Bearer ' + bearerToken;
            let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
            return this.httpClient.post(this.baseUrl + '/api/auth/wholesaleUser/signup', user, { headers: reqHeader });
        }
    }

    registerAdmin(user: User) {
        return this.httpClient.post(this.baseUrl + '/api/auth/admin/signup', user);
    }

    registerWholesale(user: User) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post(this.baseUrl + '/api/auth/wholesaleUser/signup', user, { headers: reqHeader });

    }
    authenticateUser(username, password) {
        let bodyObj = {
            email: username,
            password: password
        }
        return this.httpClient.post(this.baseUrl + '/api/auth/signin', bodyObj);
    }

    authenticateSocialUser(user) {
        return this.httpClient.post(this.baseUrl + '/api/auth/socialsignup/authenticate', user);
    }

    setAccessToken(token) {
        localStorage.setItem('bearerToken', token);
    }

    getUser(usernameOrEmail) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<User>(this.baseUrl + '/api/auth/me?name=' + usernameOrEmail, { headers: reqHeader });
    }

    setLoggedInData(user: User) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    isLoggedIn() {
        var bearerToken = localStorage.getItem('bearerToken');
        return ((bearerToken && bearerToken != '') ? true : false);
    }

    isAdmin() {
        if (this.isLoggedIn()) {
            let user = JSON.parse(localStorage.getItem('user'));
            return user.admin;
        } else {
            return false;
        }
    }
    isWholesale() {
        if (this.isLoggedIn()) {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user.userType == 'WHOLESALE') {
                return true;
            } else {
                return false;
            }

        } else {
            return false;
        }
    }
    getUserbyId(id) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.baseUrl + '/api/auth/userId?userId=' + id, { headers: reqHeader });
    }

    getAllUser() {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.baseUrl + '/api/auth/users/findAll', { headers: reqHeader });
    }

    deleteUser(userid) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.delete(this.baseUrl + '/api/auth/delete/userId?userId=' + userid, { headers: reqHeader });
    }

    updateuser(userId, userObj) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.put<User>(this.baseUrl + '/api/auth/user/update?userId=' + userId, userObj, { headers: reqHeader });
    }

    forgotPasswordSendEmail(email: string) {
        return this.httpClient.post(this.baseUrl + '/api/auth/account/forgot-password/init', email);
    }

    forgotPasswordFinish(data) {
        return this.httpClient.post(this.baseUrl + '/api/auth/account/forgot-password/finish', data);
    }

    resetPassword(data) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.post(this.baseUrl + '/api/auth/account/reset-password/finish', data, { headers: reqHeader });
    }

    logOut() {
        localStorage.removeItem('user');
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('currency');
        localStorage.removeItem('minOrder');
        this.router.navigate(['home']);
    }
    getUsersByUsertype(usertype) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get<User[]>(this.baseUrl + '/api/auth/users/findAllUsersByUserType/' + usertype, { headers: reqHeader });
    }
    resendLoginCredential(userId) {
        var bearerToken = localStorage.getItem('bearerToken');
        var authhdr = 'Bearer ' + bearerToken;
        let reqHeader = new HttpHeaders({ 'Authorization': authhdr });
        return this.httpClient.get(this.baseUrl + '/api/auth/sentLoginCredentials/' + userId, { headers: reqHeader });
    }

}