import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { Wholesale } from 'src/app/model/wholesale';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { CategoryService } from 'src/app/shared/services/category.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { WholesaleService } from 'src/app/shared/services/wholesale.service';

@Component({
  selector: 'app-wholesale-inventory',
  templateUrl: './wholesale-inventory.component.html',
  styleUrls: ['./wholesale-inventory.component.css']
})
export class WholesaleInventoryComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  allCategories: any;
  user: User;
  categoryFilter: string;
  loading: boolean = false;
  successDelete: boolean = false;
  income: number;
  allCounts: any;
  currency: String = "";
  selectedCategory: any;
  allInventories: Wholesale;
  registerForm: any;
  selectedProductId: string;

  constructor(private wholesaleService: WholesaleService,  private router: Router,
     private orderService: OrderService, private fb: FormBuilder,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.getInventorylist(this.user.id);
      this.getTotalCounts();
    }
  }
  getInventorylist(id) {
    this.loading = true;
    this.wholesaleService.getwholeSaleProductbyuserId(id).subscribe(response => {
      this.allInventories = response as Wholesale;
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }
  stockOutForm(){
    
    this.registerForm = this.fb.group({
      orderId: new FormControl(this.allInventories.orderId),
      productId: new FormControl( this.selectedProductId),
      soldQuantity: new FormControl('',Validators.required),
      wholesaleUserId: new FormControl(this.user.id),
      
    });
  
 }
 showUpdateQuantity:boolean=false;
  openUpdateQuantity(index) {
    this.loading = true;
   this.showUpdateQuantity = true;
   this.selectedProductId=this.allInventories.productsInWholesaleOrd[index].productId;
    this.stockOutForm();
    
    
  }
  closeBarcodeModal() {
    this.showUpdateQuantity = false;
    this.loading = false;
    
  }
  success:boolean=false;
  errorPopup:boolean=false;
  stockOut(){
    this.loading=true;
    this.wholesaleService.updateWholesaleStock(this.registerForm.value).subscribe(response=>{
      console.log(response);
      this.loading=false;
      
      this.success = true;
      setTimeout(() => {
        this.success = false;
        this.getInventorylist(this.user.id);
        this.showUpdateQuantity = false;
      }, 2000);
      
    },err=>{
      this.loading = false;
            this.errorPopup = true;
            setTimeout(() => {
              this.errorPopup = false;
              this.showUpdateQuantity = false;
            }, 2000);
    })
  }
 



  getTotalCounts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.income = parseInt(this.allCounts.income);

    })
  }
  
}
