<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<app-menu></app-menu>
<section class="sec-gap bg-light">
    <div class="container">
        <div class="card">
            <div class="card-body">

                <div class="row d-flex">
                    <div class="col-lg-12 col-md-12">
                        <section class="inner-title">
                            <h3><i class="ft-heart"></i>My Wishlist
                                <button class="btn btn-primary rounded-pill float-right"
                                    *ngIf="wishlist && wishlist.length > 0" (click)="emptyWishlist()">Clear</button>
                            </h3>
                        </section>
                    </div>

                </div>




                <table class="full-width res-table reflow-table table table-striped tbl-cart">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Product Name</th>
                            <!-- <th>Package Quantity</th> -->
                            <th>Until Price</th>
                            <!--  <th>Qty</th> -->
                            <th>Delete</th>
                            <th>Add To Cart</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="!wishlist || wishlist.length == 0">
                        <tr>
                            <td colspan="5" class="text-center">
                                <h5 class="text-center">No items in Wishlist</h5>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="wishlist && wishlist.length > 0">
                        <tr
                            *ngFor="let wishItem of wishlist | paginate: { itemsPerPage: count, currentPage: p }; let i=index;">
                            <td>
                                <div class="product-thumbnail"><a href="javascript:void(0)"><img
                                            *ngIf="wishItem.productImageUrl"
                                            [src]="wishItem.productImageUrl[0]| safe: 'url'" alt=""></a></div>
                            </td>

                            <td><a href="javascript:void(0)">{{wishItem.productName}}</a></td>
                            <!-- <td><label for="">Select</label>
                                <select class="form-control" (change)="setSelectedProduct($event,wishItem)"
                                    [(ngModel)]="selectedProductName" name="productName">
                                    <option *ngFor="let packageQuantity of wishItem.packagingQuantities">{{packageQuantity.packageQuantity}}
                                    </option>
                                </select></td> -->
                            <td><span class="amount">{{currency}} {{wishItem.productCost}}</span></td>
                            <!-- <td>
                              <div class="qty">
                                  <span class="minus bg-dark"><i class="ft-minus"></i></span>
                                  <input type="number" class="count" name="qty" value="1" disabled="">
                                  <span class="plus bg-dark"><i class="ft-plus"></i></span>
                              </div>
                          </td> -->
                            <td><button class=" btn btn-sm btn-outline-danger auto delete" data-tooltip="tooltip"
                                    title="" data-original-title="Delete" (click)="removeFromWishList(i)"><i
                                        class="ft-trash"></i></button>
                            </td>
                            <td><button class="btn btn-success btn-md rounded-pill" (click)="addCart(wishItem)"><i
                                        class="ft-shopping-cart"></i>
                                    Add
                                    to cart</button>
                            </td>
                        </tr>



                    </tbody>
                </table>
                <div class="row d-flex">
                    <div class=" col-md-12 ml-auto mt-2 text-right">
                        <pagination-controls *ngIf="wishlist && wishlist.length > 0" (pageChange)="p = $event"
                            class="my-pagination"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<div *ngIf="success" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Wishlist deleted</h4>

                <p class="text-success">Successfully </p>


            </div>
        </div>
    </div>
</div>
<div *ngIf="errorPopup" id="fail-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Sorry</h4>
                <p class="text-danger">Some went wrong. Try again</p>
                <p></p>

            </div>
        </div>
    </div>
</div>
<div *ngIf="successCart" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Product added to cart</h4>

                <p class="text-success">Successfully </p>


            </div>
        </div>
    </div>
</div>