<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>

<app-menu></app-menu>

<section>
    <div class="breadcrumbs_container">
        <div class="container">
            <div class="col-md-12">
                <div class="breadcrumbs">
                    <h1 class="breadcrumb-heading">Grocery</h1>
                    <ul>
                        <li><a href="javascript:void(0)" routerLink="/home">Home</a></li>
                        <li>Shopping</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sec-gap bg-light">
    <div class="container">
        <div class="row">

            <div class="col-lg-3 col-md-12 col-sm-12" id="left-filter">

                <div class="sidebar-widget">
                    <h4 class="pro-sidebar-title">Categories</h4>
                    <div class="sidebar-widget-list">
                        <div class="form-check" *ngFor="let category of allCategories">
                            <label>
                                <input type="checkbox" name="check" (click)="selectCategory(category)"
                                    [checked]="selectedCategories && selectedCategories.includes(category)"> <span
                                    class="label-text">{{category.categoryName}}</span>
                                <span class="badge badge-success ml-auto">{{category.productCount}}</span>
                            </label>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="sidebar-widget"
                    *ngIf="filterInput && filterInput.priceInputs && filterInput.priceInputs.length > 0">
                    <h4 class="pro-sidebar-title">Price Range</h4>
                    <div class="sidebar-widget-list">
                        <div class="form-check" *ngFor="let priceInput of filterInput.priceInputs">
                            <label>
                                <input type="checkbox" name="Price"
                                    [checked]="selectedPriceRangeIds && selectedPriceRangeIds.includes(priceInput.priceRangeName)"
                                    (click)="selectPriceRange(priceInput)"> <span class="label-text">
                                    {{priceInput.priceRangeName}}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9 col-md-12 col-sm-12" id="right-prod-filter">

                <div class="filter-row d-flex">

                    <div class="shop-tabs-con nav mr-auto">
                        <a href="#grid-block" class="btn btn-sm btn-light active" data-toggle="tab"
                            (click)="resetCounter()">
                            <i class="ft-grid"></i></a>
                        <a href="#list-block" class="btn btn-sm btn-light" data-toggle="tab" (click)="resetCounter()">
                            <i class="ft-list"></i></a>
                    </div>

                    <div class="ml-auto"
                        *ngIf="filterInput && filterInput.sortInputs && filterInput.sortInputs.length  > 0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="ft-airplay"></i></span>
                            </div>
                            <select type="text" class="form-control" (change)="selectSorting($event)">
                                <option value="{{sortInput.code}}" *ngFor="let sortInput of filterInput.sortInputs">
                                    {{sortInput.sortInput}}</option>
                            </select>
                        </div>
                    </div>

                </div>


                <div class="clearfix"></div>

                <div class="tab-content">
                    <div class="tab-pane active" id="grid-block">
                        <ng-container *ngIf="allproducts && allproducts.length > 0">
                            <div class="row" *ngIf="allproducts">
                                <div class="col-lg-4 col-md-6 col-sm-12"
                                    *ngFor="let product of allproducts| paginate: { itemsPerPage: count, currentPage: p }; let i = index">
                                    <article class="list-product" *ngIf="allproducts && allproducts.length > 0">
                                        <div class="img-block">
                                            <a href="javascript:void(0)" class="thumbnail" routerLink="/product-details"
                                                [queryParams]="{key : product.id}">
                                                <img class="first-img" *ngIf="product.productImageUrl"
                                                    [src]="product.productImageUrl[0] | safe: 'url'" alt="">
                                            </a>
                                            <!-- <div class="quick-view">
                                                <a class="quick_view" href="javascript:void(0)"
                                                    (click)="productQuickview(product)" data-link-action="quickview"
                                                    title="Quick view" data-toggle="modal"
                                                    data-target="#ProductModal"><i class="ft-search"></i>
                                                </a>
                                            </div> -->
                                        </div>
                                        <ul class="product-flag">
                                            <li class="new">New</li>
                                        </ul>
                                        <div class="product-decs">
                                            <a class="inner-link"><span>{{product.categoryName}}</span></a>
                                            <h2><a class="product-link" href="javascript:void(0)"
                                                    routerLink="/product-details"
                                                    [queryParams]="{key : product.id}">{{product.productName}}</a>
                                            </h2>
                                            <div class="pricing-meta">
                                                <!-- <ul>
                                                    <li class="old-price">{{currency}} {{product.productCost}}</li>
                                                    <li class="current-price">
                                                        {{currency}} {{product.productDiscountedCost}}</li>
                                                    <li class="discount-price">{{product.offer}}%</li>
                                                </ul> -->
                                            </div>
                                        </div>
                                        <div class="add-to-link">
                                            <!-- <ul>
                                                 <li class="cart btn btn-sm btn-outline-success rounded-pill"
                                                    (click)="addToCart(product)">
                                                    <i class="ft-plus"></i>ADD TO CART
                                                </li> 
                                                <li>
                                                    <a routerLink="/shop-products" *ngIf="product.isExistingProduct"
                                                        (click)="addToWishList(product)"><i
                                                            class="text-warning ft-heart"></i></a> -->
                                            <!-- <a routerLink="/shop-products" *ngIf="product.exist"
                                                        (click)="addToWishList(product)"><i class="ft-plus"></i></a> -->
                                            <!-- </li>

                                            </ul> -->
                                        </div>

                                    </article>
                                </div>
                            </div>
                            <div class="row d-flex">
                                <div class=" col-md-12 ml-auto mt-2 text-right">
                                    <pagination-controls directionLinks="true" autoHide="true" responsive="true"
                                        (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!allproducts || allproducts.length == 0">
                            <div class="row justify-content-center">
                                <h5>No products found for the selected category</h5>
                            </div>
                        </ng-container>
                    </div>

                    <div class="tab-pane" id="list-block">
                        <ng-container *ngIf="allproducts && allproducts.length > 0">
                            <div class="card mb-3 product-list"
                                *ngFor="let listproduct of allproducts; let index = index">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4 col-sm-12 col-xs-12">
                                            <a href="javascript:void(0)" class="thumbnail" routerLink="/product-details"
                                                [queryParams]="{key : listproduct.id}">
                                                <img *ngIf="listproduct.productImageUrl[0]"
                                                    [src]="listproduct.productImageUrl[0] | safe: 'url'">
                                            </a>
                                        </div>
                                        <div class="col-md-8 col-sm-12 col-xs-12">
                                            <div class="product-details-content">
                                                <h2><a href="javascript:void(0)" (click)="productQuickview(listproduct)"
                                                        data-link-action="quickview" title="Quick view"
                                                        data-toggle="modal"
                                                        data-target="#ProductModal">{{listproduct.productName}}</a></h2>

                                                <div class="product-decs p-0">
                                                    <div class="pricing-meta">
                                                        <!-- <ul>
                                                            <li class="old-price">
                                                                {{currency}} {{listproduct.productCost}}</li>
                                                            <li class="current-price">
                                                                {{currency}} {{listproduct.productDiscountedCost}}
                                                            </li>
                                                            <li class="discount-price">{{listproduct.offer}}%</li>
                                                        </ul> -->
                                                    </div>
                                                </div>


                                                <div [innerHTML]="listproduct.productDescription | safe: 'html'"></div>

                                                <!--<div class="qty mt-5">
                                                    <span class="minus bg-dark" (click)=" decrementQuantity()"><i
                                                            class="ft-minus"></i></span>
                                                    <input type="text" id="quantity{{index}}" name="quantity" class="count"
                                                        value="{{counter}}">
                                                    <span class="plus bg-dark" (click)=" incrementQuantity()"><i
                                                            class="ft-plus"></i></span>
                                                </div>

                                                <div class="text-left mt-4 mb-4">
                                                    <button class="btn btn-outline-success btn-cart-modal"
                                                        (click)="addToCart(listproduct)">
                                                        <i class="ft-plus"></i>ADD TO CART</button>
                                                    <button class="btn btn-outline-success btn-cart-modal ml-1"
                                                        (click)="addToWishList(listproduct)"> <i class="ft-heart">
                                                        </i> Add to Wishlist
                                                    </button>
                                                </div>-->

                                                <div class="social-info-box">
                                                    <span>Share</span>
                                                    <div class="social-share">
                                                        <ul>
                                                            <li class="fb">
                                                                <a href="https://www.facebook.com/Soybag-Private-Limited-107121007976317"
                                                                    target="https://www.facebook.com/Soybag-Private-Limited-107121007976317"><i
                                                                        class="ft-facebook"></i></a>
                                                            </li>
                                                            <li class="tw">
                                                                <a href="https://twitter.com/SoybagL"
                                                                    target="https://twitter.com/SoybagL"><i
                                                                        class="ft-twitter"></i></a>
                                                            </li>
                                                            <li class="insta">
                                                                <a href="	https://www.instagram.com/soybagprivate/"
                                                                    target="	https://www.instagram.com/soybagprivate/"><i
                                                                        class="ft-instagram"></i></a>
                                                            </li>
                                                            <li>
                                                                <a href="https://www.youtube.com/channel/UCuyWns6nl8a9bG8dlx7nUiQ"
                                                                    target="https://www.youtube.com/channel/UCuyWns6nl8a9bG8dlx7nUiQ"><i
                                                                        class="la la-youtube"></i></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex">
                                <div class=" col-md-12 ml-auto mt-2 text-right">
                                    <pagination-controls directionLinks="true" autoHide="true" responsive="true"
                                        (pageChange)="p = $event"></pagination-controls>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!allproducts || allproducts.length == 0">
                            <div class="row justify-content-center">
                                <h5>No products found for the selected category</h5>
                            </div>
                        </ng-container>

                    </div>

                </div>
            </div>

        </div>
    </div>





</section>



<div class="modal fade show" id="ProductModal" tabindex="-1" role="dialog" aria-modal="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">x</span></button>
            </div>

            <div class="modal-body">
                <div class="row" *ngIf="productQuickView">
                    <div class="col-md-5 col-sm-12 col-xs-12">
                        <img *ngIf="productQuickView.productImageUrl"
                            [src]="productQuickView.productImageUrl[0] | safe: 'url'" class="img-responsive">
                    </div>


                    <div class="col-md-7 col-sm-12 col-xs-12">
                        <div class="product-details-content">
                            <h2>{{productQuickView.productName}}</h2>

                            <div class="product-decs p-0">
                                <div class="pricing-meta">
                                    <ul>
                                        <li class="old-price">{{currency}} {{productQuickView.productCost}}</li>
                                        <li class="current-price">{{currency}}
                                            {{productQuickView.productDiscountedCost}}
                                        </li>
                                        <li class="discount-price">-{{productQuickView.offer}}%</li>
                                    </ul>
                                </div>
                            </div>

                            <div [innerHTML]="productQuickView.productDescription | safe: 'html'"></div>

                            <div class="qty mt-5">
                                <span class="minus bg-dark" (click)="decrementQuantity()" *ngIf="counter > 1"><i
                                        class="ft-minus"></i></span>
                                <input type="number" id="quantity" name="quantity" class="count ml-1 mr-1"
                                    [(ngModel)]="counter" min="1">
                                <span class="plus bg-dark" (click)=" incrementQuantity()"><i class="ft-plus"></i></span>
                            </div>

                            <div class="text-left mt-4 mb-4">
                                <button (click)="addToCart(productQuickView)" data-dismiss="modal"
                                    class="btn btn-outline-success btn-cart-modal"> <i class="ft-plus"></i> ADD
                                    TO CART</button>
                                <button (click)="addToWishList(productQuickView)" data-dismiss="modal"
                                    class="btn btn-outline-success btn-cart-modal ml-1 "> <i class="ft-heart">
                                    </i> Add to Wishlist
                                </button>
                            </div>

                            <div class="social-info-box">
                                <span>Share</span>
                                <div class="social-share">
                                    <ul>
                                        <li class="fb">
                                            <a href="javascript:void(0)"><i class="ft-facebook"></i></a>
                                        </li>
                                        <li class="tw">
                                            <a href="javascript:void(0)"><i class="ft-twitter"></i></a>
                                        </li>
                                        <li class="insta">
                                            <a href="javascript:void(0)"><i class="ft-instagram"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!--MOdal Section-->
<!-- <div  class="modal fade show">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Heading Text</h4>
                <p class="text-success">Successful Text will come here </p>
                <p>Infornmation text will come here</p>
                <button class="btn btn-success" data-dismiss="modal">Button Text<i
                        class="ft-chevron-right"></i></button>
            </div>
        </div>
    </div>
</div> -->

<div *ngIf="successCart" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" (click)="closeCart()" class="close" data-dismiss="modal"
                    aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Product added to Cart</h4>
                <p class="text-success">Successfully </p>

                <button (click)="viewCart()" class="btn btn-success" data-dismiss="modal">View Cart<i
                        class="ft-chevron-right"></i></button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="successWishlist" id="email-verifiacation-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog  modal-dialog-centered modal-md">
        <div class="modal-content modal-confirm">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-check"></i>
                </div>
                <button type="button" (click)="closeWishlist()" class="close" data-dismiss="modal"
                    aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Product added to Wishlist</h4>
                <p class="text-success">Successfully </p>

                <button class="btn btn-success" (click)="viewWishlist()" data-dismiss="modal">View Wishlist<i
                        class="ft-chevron-right"></i></button>
            </div>
        </div>
    </div>
</div>