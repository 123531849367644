import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from '../model/category';
import { User } from '../model/user';
import { AuthenticationService } from '../shared/services/authentication.service';
import { CategoryService } from '../shared/services/category.service';
import { ProductService } from '../shared/services/product.service';
import { SettingsService } from '../shared/services/settings.service';
import { BannerImages } from '../model/settings';
import { TestimonialService } from '../shared/services/testimonial.service';
import { Product } from '../model/product';
declare function carouselFn(): any;
declare function productCarousel(): any;
declare function owlFn(): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  testimonial: any;
  hotDeals: Product[];
  newArrivals: Product[];
  allBestSellingProducts: Product[];
  allBestCategory: Category[];

  constructor(
    private serviceCategory: CategoryService,
    private serviceProduct: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private serviceTestimonial: TestimonialService,
    private settingService: SettingsService) { }

  user: User;
  categories: Category;
  allproducts: Product[];
  categoryKey: string;
  allCategories: any;
  value: any;
  productQuickView: any;
  loading: boolean = false;
  isLoggedIn: Boolean = false;
  isAdmin: Boolean = false;
  selectedImageIndex: number = 0;
  bannerImgList: BannerImages[];
  homeSlider: BannerImages;
  homeCategory: BannerImages;
  currency: string;
  max: number = 0;
  min: number = 0;

  ngOnInit() {
    this.loading = true;
    if (this.authenticationService.isLoggedIn()) {
      this.isLoggedIn = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.isAdmin = (this.user && this.user.admin) ? true : false;
    }
    this.getAllBannerImages().then(() => {
      this.getProductsList();
     this.getTestimonial();
     this.getBestSellingProduct();
     this.getBestSellingCategory();
    });
    this.currency = localStorage.getItem('currency');

  }
Price:number[]=[];
  getProductsList() {
    this.serviceProduct.getAllProductsForHomePage().subscribe(
      product => {
        this.allproducts = product as Product[];
        // this.hotDeals=this.allproducts.filter()
        this.hotDeals=this.allproducts.filter(product => product.hotDeals == true);
        this.newArrivals=this.allproducts.filter(product=>product.newArrivals==true);
        this.serviceCategory.getAllCategoriesForHomePage().subscribe(Category => {
          this.allCategories = Category;
          this.loading = false;
        }, error => {
          this.loading = false;
        });
      }, error => {
        this.serviceCategory.getAllCategoriesForHomePage().subscribe(Category => {
          this.allCategories = Category;
          this.loading = false;
        }, error => {
          this.loading = false;
        });
      }
    );
  }

  productDetail(product) {
    this.productQuickView = product;
    productCarousel();
  }

  refresh() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/']);
    });
  }


  getAllBannerImages(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      this.settingService.getAllBannerImages().toPromise().then(response => {
        this.bannerImgList = response;
        if (this.bannerImgList && this.bannerImgList.length > 0) {
          this.bannerImgList.forEach(bannerImge => {
            if (bannerImge.imageName && bannerImge.imageName.toLowerCase() == 'home slider') {
              this.homeSlider = bannerImge;
              carouselFn();
            }
            if (bannerImge.imageName && bannerImge.imageName.toLowerCase() == 'home category') {
              this.homeCategory = bannerImge;
            }
          });

        }
        resolve();
      }, error => {
        console.log(error);
        reject();
      })
    });
    return promise;
  }

  getTestimonial() {
    this.serviceTestimonial.getAllTestimonials().subscribe(response => {
      this.testimonial = response;
      this.loading = false;
      owlFn();
    }, error => {
      this.loading = false;
    })
  }
  getBestSellingProduct() {
    this.serviceProduct.getBestSellingProductsForHomePage().subscribe(response => {
      this.allBestSellingProducts = response as Product[];
      this.loading = false;
     
    }, error => {
      this.loading = false;
    })
  }
  getBestSellingCategory() {
    this.serviceCategory.getBestSellingCategoriesForHomePage().subscribe(response => {
      this.allBestCategory = response as Category[];
      this.loading = false;
     
    }, error => {
      this.loading = false;
    })
  }
}
