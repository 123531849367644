import { ShoppingCartEntries } from './shoppingCartResponse';
import { ShippingDetails, BillingDetails } from './order';

export class User {

  accessToken: string;
  admin: Boolean;
  createdBy: string;
  createdDate: Date;
  deletedFlag: true;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  lastUpdatedDate: Date;
  mobileno: string;
  password: string;
  roles: Roles[];
  shoppingCartEntries: ShoppingCartEntries[];
  socialFbLogin: Boolean;
  socialLogin: Boolean;
  updatedBy: string;
  username: string;
  shippingAddress: ShippingDetails[];
  billingAddress: BillingDetails;
  userType:string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Roles {
  id: string;
  name: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
