<div *ngIf="loading" class="overlay-page">
    <div class="loading-box">
        <div class="loading-img"><img src="assets/images/loader.gif"></div>
        <div class="spinner-border text-white " role="status"></div>
    </div>
</div>
<section class="sec-gap bg-light">
    <div class="row h-100">
        <div class="col-lg-5 col-md-6 col-sm-12 login-left">
            <div class="login-logo"><img src="assets/images/logo.jpg"></div>
            <h4>Sign In</h4>
            <div class="form-group">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-user"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Username Or Email
            " #username="ngModel" [(ngModel)]="userName" name="userName" required>
                    <small class="error"><label></label></small>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group ">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ft-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="Password" #userpassword="ngModel"
                        [(ngModel)]="userPassword" name="userPassword" required>
                    <small class="error"><label></label></small>
                </div>
            </div>


            <button class="btn btn-block btn-success btn-lg" (click)="login()">LOGIN</button>

            <div class="text-center mt-4 rel-z-1">
                Forgot Your Password? <a href="javascript:void(0)" routerLink="/forgot-password">Click Here</a>
            </div>

        </div>

        <div class="col-lg-7 col-md-6 col-sm-12 login-right-bg">
            <h1 class="heading">LOGIN PAGE HEADING </h1>
            <h6 class="sub-heading">Login Sub Heading</h6>
            <p>
                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the
                visual form of a document or a typeface without relying on meaningful content.
            </p>
        </div>
    </div>
</section>

<div *ngIf="errorPopup" id="fail-modal" class="modal fade show" style="display:block!important;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content modal-reject ">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="ft-alert-triangle"></i>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body text-center">
                <h4>Sorry</h4>
                <p class="text-danger"><strong>{{errorMessage}} </strong></p>
                <p></p>

            </div>
        </div>
    </div>
</div>