import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Colors, SingleDataSet } from 'ng2-charts';
import { mergeMap, map } from 'rxjs/operators';
import { BillingDetails, Order, ShippingDetails } from 'src/app/model/order';
import { OrderUserReportObject, OnlineOfflineOrdersObject, MostSellingReportObject } from 'src/app/model/report';
import { TotalCount } from 'src/app/model/totalcount';
import { User } from 'src/app/model/user';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { OrderService } from 'src/app/shared/services/order.service';
declare function reflowTable(): any;

@Component({
  selector: 'app-wholesale-dashboard',
  templateUrl: './wholesale-dashboard.component.html',
  styleUrls: ['./wholesale-dashboard.component.css']
})
export class WholesaleDashboardComponent implements OnInit {
  p: number = 1;
  count: number = 10;
  user: User;
  allOrders: any;
  billingDetails: BillingDetails[];
  orderId: any;
  billingAddress: any;
  loading: boolean = false;
  allCounts: any;
  totalProducts: number;
  totalCategories: number;
  totalUsers: number;
  totalOrders: number;
  income: number;
  changedOrder: string = "ALL";
  userorder: Order;
  shippingDetails: ShippingDetails[];
  shippingAddress: any;
  shoppingList: any[];
  total: number;
  cartTotal: number;
  deliveryCharge: number;

  /**Line Chart Options Start */
  lineChartUserData: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  lineChartOrdersData: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  /**Line Chart Options Start */
  lineChartData: ChartDataSets[] = [];

  lineChartLabels: Label[] = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  lineChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
          stepSize: 5
        },
        scaleLabel: {
          display: true,
          labelString: 'Orders/Users',
          fontSize: 20
        }
      }]
    }
  };

  lineChartColors: Colors[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,255,0,0.28)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  /**Line Chart Options End */

  /**Bar Chart Options Start */
  barChartOptions: ChartOptions = {
    responsive: true,
    animation: {
      duration: 10,
    },
    tooltips: {
      mode: 'label'
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false
        },
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
          stepSize: 5
        },
        scaleLabel: {
          display: true,
          labelString: 'Orders',
          fontSize: 20
        }
      }],
    }, // scales
    legend: {
      display: true
    }
  };
  barChartLabels: Label[] = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  
  barChartData: ChartDataSets[] = [];
  barChartOnlineData: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  barChartOfflineData: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    
  /**BAR Chart Options End */

  /**Pie Chart Options Start */

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = ['', '', '', ''];
  public pieChartData: SingleDataSet = [0, 0, 0, 0];
  public pieChartType: ChartType = 'pie';
  public pieChartColors: Colors[] = [{
    backgroundColor: [
      "#4b77a9",
      "#5f255f",
      "#d21243",
      "#B27200",
      "#009900"
    ],
    borderColor: "#fff"
  }]
  public pieChartLegend = true;
  public pieChartPlugins = [];

  /**Pie Chart Options End */

  currency: string = "";
  constructor(private authenticationService: AuthenticationService, private orderService: OrderService) { }

  ngOnInit() {
    if (this.authenticationService.isLoggedIn()) {
      this.loading = true;
      this.user = JSON.parse(localStorage.getItem('user'));
      this.currency = localStorage.getItem('currency');
      this.getChartData();
      this.getTotalCouts();
      this.getOrder(this.user.id);
     // this.getOrderTodaylist();

    }
  }
  getTotalCouts() {
    this.orderService.getAllCountsForAdmin().subscribe(counts => {
      this.allCounts = counts as TotalCount;
      this.totalProducts = parseInt(this.allCounts.totalProducts);
      this.totalCategories = parseInt(this.allCounts.totalCategories);
      this.totalOrders = parseInt(this.allCounts.totalOrders);
      this.totalUsers = parseInt(this.allCounts.totalUsers);
      this.income = parseInt(this.allCounts.income);
      this.loading=false;
    })
  }
  getOrder(id){
    this.loading = true;
    this.allOrders = [];
    this.orderService.getMyOrders(id).subscribe(response => {
      this.allOrders = response;
      reflowTable();
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }
  getOrderTodaylist() {
    this.orderService.getOrderForToday().subscribe(response => {
      this.allOrders = response;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  getOrdersByStatusList(status) {
    this.loading = true;
    this.allOrders = [];
    this.orderService.getOrdersByStatus(status).subscribe(response => {
      this.allOrders = response;
      reflowTable();
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  address(userorder) {
    this.userorder = userorder;
    this.total = this.userorder.total_Amount_to_Pay;
    this.cartTotal = this.userorder.cartAmount;
    this.deliveryCharge = this.userorder.deliveryCharge;
    this.billingAddress = this.userorder.billingDetails;
    this.shippingAddress = this.userorder.shippingDetails;
    this.shoppingList = this.userorder.shoppingCartEntry;
  }

  selectedOrderStatus: string;
  errorModal: Boolean = false;
  successModal: Boolean = false;

  updateOrder() {
    if (this.selectedOrderStatus && this.selectedOrderStatus != "") {
      this.userorder.status = this.selectedOrderStatus;
      this.loading = true;
      this.orderService.updateOrder(this.userorder.id, this.userorder).toPromise().then(
        response => {
          this.selectedOrderStatus = null;
          this.loading = false;
          this.successModal = true;
          this.getOrderTodaylist();
          setTimeout(() => {
            this.successModal = false;
          }, 2000);
        }, error => {
          this.loading = false;
          this.selectedOrderStatus = null;

          this.getOrderTodaylist();
          this.errorModal = true;
          setTimeout(() => {
            this.errorModal = false;
          }, 2000);
        }
      )
    }
  }

  getOrdersByStatus() {
    if (this.changedOrder == "ALL") {
      this.getOrderTodaylist();
    } else {
      this.getOrdersByStatusList(this.changedOrder);
    }
  }


  monthlyOrdersUserObj: OrderUserReportObject[];
  monthlyOnOfflineObj: OnlineOfflineOrdersObject[];
  mostSellingObjList: MostSellingReportObject[] = [];

  getChartData() {
    this.orderService.getMonthlyOrdersUserCnt().pipe(
      mergeMap(monthlyOrdersUsers => this.orderService.getOfflineOnlineOrders().pipe(
        mergeMap(monthlyOffOnline => this.orderService.getMostSellingProdCategry().pipe(
          map(mostSellProdts => {
            return ([monthlyOrdersUsers, monthlyOffOnline, mostSellProdts])
          }))
        )))).subscribe(([monthlyOrdersUsers, monthlyOffOnline, mostSellProdts]) => {
          this.monthlyOrdersUserObj = monthlyOrdersUsers as OrderUserReportObject[];
          if (this.monthlyOrdersUserObj) {
            this.monthlyOrdersUserObj.forEach(orderObj => {
              if (this.lineChartLabels.includes(orderObj.month.toUpperCase())) {
                this.lineChartOrdersData[this.lineChartLabels.indexOf(orderObj.month.toUpperCase())] = orderObj.orders;
                this.lineChartUserData[this.lineChartLabels.indexOf(orderObj.month.toUpperCase())] = orderObj.users;
              }
            });
          }
          this.monthlyOnOfflineObj=monthlyOffOnline as OnlineOfflineOrdersObject[];
          if(this.monthlyOnOfflineObj){
            this.monthlyOnOfflineObj.forEach(onoffLine=>{
              if (this.barChartLabels.includes(onoffLine.month.toUpperCase())) {
                this.barChartOnlineData[this.lineChartLabels.indexOf(onoffLine.month.toUpperCase())] = onoffLine.onlineOrders;
                this.barChartOfflineData[this.lineChartLabels.indexOf(onoffLine.month.toUpperCase())] = onoffLine.offlineOrders;
              }
            })
          }
          this.mostSellingObjList = mostSellProdts as MostSellingReportObject[];
          if (this.mostSellingObjList) {
            this.pieChartData = []
            this.pieChartLabels = [];
            this.mostSellingObjList.forEach(sellingProdObj => {
              this.pieChartLabels.push(sellingProdObj.productCategory);
              this.pieChartData.push(sellingProdObj.count);
            });
          }
          this.loadChartData();
        });
  }

  loadChartData() {
    this.lineChartData = [{
      label: "ORDERS",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgb(60,158,229)",
      borderColor: "rgb(60,158,229)", // The main line color
      borderCapStyle: 'square',
      borderDash: [], // try [5, 15] for instance
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: "black",
      pointBackgroundColor: "white",
      pointBorderWidth: 1,
      pointHoverRadius: 8,
      pointHoverBackgroundColor: "yellow",
      pointHoverBorderColor: "brown",
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 10,
      // notice the gap in the data and the spanGaps: true
      data: this.lineChartOrdersData,
      spanGaps: true,
    }, {
      label: "USERS",
      fill: false,
      lineTension: 0.1,
      backgroundColor: "rgb(76,199,144)",
      borderColor: "rgb(76,199,144)", // The main line color
      borderCapStyle: 'square',
      borderDash: [], // try [5, 15] for instance
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: "black",
      pointBackgroundColor: "white",
      pointBorderWidth: 1,
      pointHoverRadius: 8,
      pointHoverBackgroundColor: "yellow",
      pointHoverBorderColor: "brown",
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 10,
      // notice the gap in the data and the spanGaps: true
      data: this.lineChartUserData,
      spanGaps: true,
    }

    ]
    this.barChartData=[{
      
        label: 'ONLINE ORDERS',
        data: this.barChartOnlineData,
        backgroundColor: "rgba(5, 158, 51, 0.7)",
        hoverBackgroundColor: "rgba(5, 158, 51, 0.7)",
        hoverBorderWidth: 2,
        hoverBorderColor: 'lightgrey'
      
      
    },{
    label: 'OFFLINE ORDERS',
    data: this.barChartOfflineData,
    backgroundColor: "rgba(173, 42, 55, 0.7)",
    hoverBackgroundColor: "rgba(173, 42, 55, 0.7)",
    hoverBorderWidth: 2,
    hoverBorderColor: 'lightgrey'
    }]
  }
}

